import { ButtonV2ItemPosition, ButtonV2Type } from '../Component/Buttons/ButtonV2/ButtonV2';
import { CaptchaTokenType } from '../Component/Captcha/Captcha';
import { IAccessTokenMeta } from './UserSessionUtil';
import { CustomTypeMod, OperationResultType, RequestResponseType } from './InterfaceAndTypeUtil';
import React from 'react';
import { CognitoHostedUIIdentityProvider, CognitoUserSession } from './AmplifyAuth';
import { SignInOutput, SignUpOutput } from 'aws-amplify/auth';

export interface UserProfileType {
  name: string,
  email: string,
  id: string,
}

export interface IButtonV2TopMenuData {
  type: ButtonV2Type,
  displayStarsIcon?: boolean,
  positionStarsIcon?: ButtonV2ItemPosition,
  displayStarsIconForSubscription?: string[],
  round?: boolean,
  roundSmall?: boolean,
  displayExternalIcon?: boolean,
  positionExternalIcon?: ButtonV2ItemPosition,
  positionStarsIconMobile?: ButtonV2ItemPosition,
  onClick?: (event: React.MouseEvent) => void,
}

export interface TopMenuItem {
  title: string,
  link: string,
  selected?: boolean,
  new?: boolean,
  target?: string,
  upgradeButton?: boolean,
  displayAsButton?: IButtonV2TopMenuData,
}

export interface AuthBaseCallbackPayload {
  '@type': string,
}

export type AuthCallbackPayload = AuthSuccessCallbackPayload | AuthFailCallbackPayload;

export interface AuthSuccessCallbackPayload extends AuthBaseCallbackPayload {
  expiration_time: number,
  expires_in: number,
  refresh_token: string,
  token: string,
  token_type: string,
}

export interface AuthFailCallbackPayload extends AuthBaseCallbackPayload {
  '~error': AuthError,
}

export interface AuthError {
  code: string,
  msg: string,
}

export const isAuthError = (obj: unknown): boolean => {
  return obj != null && typeof (obj as AuthError).code === 'string' && typeof (obj as AuthError).msg === 'string';
};

export interface ErrorDisplay {
  msg: string,
  placement: 'general' | 'email' | 'password' | 'first_name' | 'last_name',
  variant?: AlertType,
  padding?: string,
}

export type IndyAuthFlow = 'form' | 'register';

export interface PasswordValidationRule {
  title: string,
  regexp: RegExp,
  valid?: boolean,
  error?: boolean,
}

export interface QueryParam {
  key: string,
  value: string,
}

export interface ModalProps {
  show: boolean,
  close: () => void,
  full_page?: boolean,
}

export type AlertType = 'success' | 'warning' | 'danger' | 'info';

export interface FormInputProps {
  onSubmit: (e: any) => void,
  value: string,
  setValue: (value: string) => void,
}

export type IProcessUserSessionResult = CustomTypeMod<
  OperationResultType,
  {
    data: IAccessTokenMeta['data'],
  }
>;

export type IRefreshUserSessionViaAmplifyResult = CustomTypeMod<
  OperationResultType,
  {
    data: CognitoUserSession,
  }
>;

export interface IChangeStatusCodeInUserSessionCookiesProps {
  newStatusCode: UserSessionStatusCodeEnum,
}

export interface IUserSessionLifespan {
  // backend response
  currentUts: number, // backend current uts
  currentUiUts: number, // ui current uts

  accessTokenBaseUts: number, // currentUts
  accessTokenBaseUiUts: number, // currentUiUts
  accessTokenTtl: number, // seconds
  accessTokenUiTtl: number, // accessTokenTtl with some minutes subtracted to invalidate a bit earlier
  accessTokenExpiryUts: number, // currentUts + accessTokenTtl
  accessTokenExpiryUiUts: number, // currentUiUts + accessTokenUiTtl

  refreshTokenBaseUts: number, // currentUts (from the session initialization)
  refreshTokenBaseUiUts: number, // currentUiUts (from the session initialization)
  refreshTokenTtl: number, // seconds
  refreshTokenUiTtl: number, // refreshTokenTtl with some minutes subtracted to invalidate a bit earlier
  refreshTokenExpiryUts: number, // currentUts + refreshTokenTtl
  refreshTokenExpiryUiUts: number, // currentUiUts + refreshTokenUiTtl
}

export enum UserSessionStatusCodeEnum {
  LoggedOut = '0', // logged out
  Active = '1', // active user session
  LegacySendToRefresh = '-1', // legacy flag that will redirect the user to profile refresh page and back
  LoginRequired = '-2', // when a refresh has failed mainly because of an expired refresh token we send the user to login again
  RefreshRequired = '-3', // new way of refresh is required
}

export type ICreateUserSessionResult = CustomTypeMod<
  OperationResultType,
  {
    data: {
      sessionId: string,
      sessionLifespan: IUserSessionLifespan,
      userInfoCookie: string,
    },
  }
>;

export type IRefreshUserSessionResult = CustomTypeMod<
  OperationResultType,
  {
    data: {
      accessToken: string,
      sessionLifespan: IUserSessionLifespan,
      userInfoCookie: string,
      recentlyConsumed?: boolean, // TODO: (high) provide the backend logic for this
    },
  }
>;

export interface IUserSessionMeta {
  // slightly similar to JWT to have the params shorter
  id: string, // session id; used for debugging
  iss: number, // issued at uts
  atexp: number, // access token expiry uts
  rtexp: number, // refresh token expiry uts
}

export interface IUserSessionLogOut {
  context: string, // log id
  skipBackendUserSessionDeletion?: boolean, // defaults to false; set to true if no backend user session is present
  skipUserSessionStatusCodeCookieChange?: boolean, // defaults to false; set to true if other parts of the logic may tweak the status code in it's own way
  /**
   * @deprecated Trying to "await AmplifyAuth.signOut();" in a try catch block will always take place from now on
   */
  skipAmplifySignOut?: boolean,
  reason?: any,
}

export interface IProcessUserSessionProps {
  context: string, // log id
  handleRedirectionLogic?: boolean,
  // redirectionHook?: (redirectUrl: string) => string | null, // useful for debugging, tweaking the redirection url, disabling redirection
  preferDethrottleCache?: boolean,
  sessionVerificationRes?: IVerifyUserSessionResult,
}

export interface IUserSessionRedirectProps {
  context: string,
  originUrl: string,
  reason?: any,
}

export type IVerifyUserSessionResult = CustomTypeMod<
  OperationResultType,
  {
    data: IAccessTokenMeta['data'],
  }
>;

export interface AuthUtil {
  // initAuth: (config?: ICognitoConfig) => Promise<void>,
  processUserSession: (prs: IProcessUserSessionProps) => Promise<IProcessUserSessionResult>,
  loginUser: (email: string, password: string, username?: string) => Promise<LoginStatus | LoginStatusClassicUserToBeMigrated>,
  registerUser: ({
    email,
    password,
    firstName,
    lastName,
    emailConsent,
    user_origin,
    user_origin_signed,
    captchaToken,
  }: {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    emailConsent: boolean,
    user_origin: UserOrigin,
    user_origin_signed?: string,
    captchaToken?: CaptchaTokenType,
  }) => Promise<{ response: AuthError | SignUpOutput, username: string }>,
  /**
   * @deprecated Legacy user session refresh logic kept for backwards compatibility
   */
  refreshUserSessionViaRedirect: (prs: IUserSessionRedirectProps) => void,
  restartUserSessionViaRedirect: (prs: IUserSessionRedirectProps) => void,
  userSessionRedirectionLogicHandler: (userSessionVerificationRes: IVerifyUserSessionResult) => void,
  /**
   * @deprecated Legacy user session refresh logic kept for backwards compatibility, use the function exposed from UserSessionUtil.ts
   */
  logOutViaRedirect: (prs: IUserSessionRedirectProps) => void,
  logOut: (prs: IUserSessionLogOut) => Promise<void>,
  sendResetPassword: (email: string) => Promise<boolean | AuthError>,
  verifyResetPassword: (username: string, referenceId: string, newPassword: string) => Promise<boolean | AuthError>,
  resendVerificationEmail: (username: string) => Promise<boolean | AuthError>,
  storeTokens: (auth_code: string) => Promise<void>,
  getCurrentUser: () => Promise<any>,
  updateName: (first_name: string, last_name: string) => Promise<string>,
  updateEmail: (email: string, verified?: boolean) => Promise<RequestResponseType>,
  refreshUserSession: (context: string) => Promise<IRefreshUserSessionResult>,
  // submitEmailConfirmationCode: (code: string) => Promise<RequestResponseType>,
  getCurrentUserInfo: () => Promise<RequestResponseType>,
  updateCachedUserAttribute: (key: string, value: any) => OperationResultType,
  getCachedUserData: () => OperationResultType,
  getCachedUserAttribute: (key: string) => OperationResultType,
  userIsLoggedIn: (prs?: { context: string }) => Promise<boolean>,
  federatedLogin: (provider: CognitoHostedUIIdentityProvider | string) => Promise<LoginStatus>,
  autoLogin: (callback?: () => void) => Promise<SignInOutput>,
}

export type UserOrigin = 'profile' | 'classic' | 'classic_verified'; //users where this is not defined will be from hosted ui

export interface LoginStatus {
  status: 'loggedin' | 'failed' | 'classic_user_with_name' | 'classic_incorrect_password',
  error?: AuthError,
}

export interface LoginStatusClassicUserToBeMigrated extends LoginStatus {
  first_name: string,
  last_name: string,
  email_verified: boolean,
  signed: string,
}

export const isClassicUserToBeMigrated = (obj: unknown): boolean => {
  return obj != null && (obj as LoginStatusClassicUserToBeMigrated).status === 'classic_user_with_name';
};

export const isLoginStatus = (obj: unknown): boolean => {
  return obj != null && typeof (obj as LoginStatus).status === 'string';
};

export type DeployEnvironment = 'develop' | 'staging' | 'prod';
