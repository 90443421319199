/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect
  // useMemo
} from 'react'
import { TopMenuItem } from '../../Util/Interfaces'
import { MENU_ITEMS_V2, ITopMenu } from './menuItems'
import {
  appIsProfile,
  getPathfinderUrl,
} from '../../Util/EnvironmentUtil'
import UserProfile from '../UserProfile/UserProfile'
import LoadingModal from '../LoadingModal/LoadingModal'
import LoginModal from '../LoginModal/LoginModal'
import {
  getQueryParamNamed,
} from '../../Util/QueryParamsUtil'
import {
  cacheRedirectUrl,
  getSanitizedRedirectUrl,
  handleUserProfileClose,
  safeRedirect,
  tweakRedirectUrlOnSuccessfulSignup,
} from '../../Util/RedirectUtil'
import { authUtil } from '../../Util/AuthUtil'
import W3LogoButton from '../Buttons/W3LogoButton/W3LogoButton'
import MobileMenuButton from '../Buttons/MobileMenuButton/MobileMenuButton'
import { NewIcon } from './New'
import { logging } from '../../Util/LoggingUtil'
import { sendGoogleAnalyticsEvent } from '../../Util/GoogleAnalyticsUtil'
// import { capitalizeFirstLetter } from '../../Util/StrUtil';
import ButtonV2 from '../Buttons/ButtonV2/ButtonV2'

import styles from './TopBarMenuLegacy2.module.scss'

export interface TopBarMenuLegacyProps {
  menu_items?: ITopMenu
  full_page?: boolean
  full_page_mode?: boolean
  start_signup?: boolean
  onLoginChange?: (loggedIn: boolean) => void
  allow_unauthenticated?: boolean
  skip_authentication?: boolean
  authenticated?: boolean
  fixed?: boolean
  children?: React.ReactNode
  alt_authenticated?: boolean
  show_terms_footer_in_modal?: boolean
  subscription_plan?: string
  subscription_upgradeable?: boolean
  assumeLoggedIn?: boolean
  id?: string
  className?: string
  buttonbarClassName?: string
}

const getMenuItem = (
  item: TopMenuItem,
  subscription_upgradeable: boolean,
  keyValue: string | number,
  subscriptionPlan?: string
) => {
  if (!!item.upgradeButton) {
    return !!subscription_upgradeable ? (
      <ButtonV2
        key={`upgrade-btn-${keyValue}`}
        text={item.title}
        type='purple'
        typeOnMobile='noframe'
        displayStarsIcon
        positionStarsIcon='left'
        positionStarsIconMobile='right'
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          document.location.href = item.link
        }}
        className={styles.menu_item_as_btn}
        textClassName={styles.btn_text}
      />
    ) : undefined
  } else if (!item.upgradeButton && !!item.displayAsButton) {
    let starsIconDisplayed = item.displayAsButton.displayStarsIcon || false

    if (
      !!item.displayAsButton.displayStarsIconForSubscription &&
      item.displayAsButton.displayStarsIconForSubscription.length > 0 &&
      !!subscriptionPlan
    ) {
      starsIconDisplayed =
        item.displayAsButton.displayStarsIconForSubscription.findIndex(
          (item) => item === subscriptionPlan
        ) > -1 || false
    }

    if (item.selected) {
      return (
        <div key={`btn-${keyValue}`} className={styles.button_wrapper}>
          <ButtonV2
            text={item.title}
            type={item.displayAsButton.type}
            displayStarsIcon={starsIconDisplayed}
            positionStarsIcon={item.displayAsButton.positionStarsIcon}
            positionStarsIconMobile={
              item.displayAsButton.positionStarsIconMobile
            }
            round={item.displayAsButton.round}
            roundSize={item.displayAsButton.roundSmall ? 'small' : 'default'}
            displayExternalIcon={item.displayAsButton.displayExternalIcon}
            positionExternalIcon={item.displayAsButton.positionExternalIcon}
            onClick={
              item.displayAsButton.onClick
                ? item.displayAsButton.onClick
                : (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  window.open(
                    item.link,
                    item.target || '_self',
                    'noopener noreferrer'
                  )
                }
            }
            className={`${styles.menu_item_as_btn}${item.displayAsButton.type === 'noframe'
              ? ` ${styles.button_as_no_frame}`
              : ''
              }`}
            textClassName={`${styles.button_text} ${styles.btn_selected}`}
          />
          <div className={styles.selctedLine} />
        </div>
      )
    }

    return (
      // do like this so ... on text work on right hand side
      <ButtonV2
        key={`btn-${keyValue}`}
        text={item.title}
        type={item.displayAsButton.type}
        displayStarsIcon={starsIconDisplayed}
        positionStarsIcon={item.displayAsButton.positionStarsIcon}
        positionStarsIconMobile={item.displayAsButton.positionStarsIconMobile}
        round={item.displayAsButton.round}
        roundSize={item.displayAsButton.roundSmall ? 'small' : 'default'}
        displayExternalIcon={item.displayAsButton.displayExternalIcon}
        positionExternalIcon={item.displayAsButton.positionExternalIcon}
        onClick={
          item.displayAsButton.onClick
            ? item.displayAsButton.onClick
            : (event) => {
              event.preventDefault()
              event.stopPropagation()
              window.open(
                item.link,
                item.target || '_self',
                'noopener noreferrer'
              )
            }
        }
        className={`${styles.menu_item_as_btn}${item.displayAsButton.type === 'noframe'
          ? ` ${styles.button_as_no_frame}`
          : ''
          }`}
        textClassName={styles.button_text}
      />
    )
  }

  return (
    <a
      key={`menu-item-${keyValue}`}
      className={`${styles.topmenubutton}${item.selected ? ` ${styles.selected}` : ''
        }`}
      href={item.link}
      target={item.target ? item.target : undefined}
      onClick={() =>
        sendGoogleAnalyticsEvent({
          name: `Clicked on "${item.title}" in topbar when logged in`,
          category: 'Topbar after login'
        })
      }
    >
      {item.title}
      {item.selected && <div className={styles.selctedLine} />}
    </a>
  )
}

const DEFAULT_REDIRECT_URL = getPathfinderUrl()

const TopBarMenuLegacy2 = ({
  menu_items,
  full_page_mode,
  start_signup,
  onLoginChange,
  allow_unauthenticated,
  skip_authentication,
  authenticated,
  alt_authenticated,
  fixed = true,
  children,
  show_terms_footer_in_modal = true,
  subscription_plan,
  subscription_upgradeable = false,
  assumeLoggedIn = false,
  id,
  className,
  buttonbarClassName
}: TopBarMenuLegacyProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [placeholder_height, setPlaceholderHeight] = useState(0)
  const [menuOpen, setMenuOpen] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [loading, setLoading] = useState(!assumeLoggedIn)
  // const [redirectUrl, setRedirectUrl] = useState<string | undefined>()
  const [loggedIn, setLoggedIn] = useState(assumeLoggedIn)
  const [recalculateItems, setRecalculateItems] = useState(true)

  const updatePlaceholderHeight = () => {
    if (!fixed) {
      return 0
    }

    //@ts-ignore
    setPlaceholderHeight(ref.current.clientHeight)

    return 0
  }

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const getRedirectUrl = (redirectUrl: string | undefined) => {
    setRecalculateItems(false)
    let redirectUrlToUse = redirectUrl

    if (redirectUrlToUse === undefined) {
      redirectUrlToUse = getSanitizedRedirectUrl()
    }
    if (redirectUrlToUse === undefined) {
      redirectUrlToUse = DEFAULT_REDIRECT_URL
    }

    const auth_code = getQueryParamNamed('authorization_code') || {
      value: sessionStorage.getItem('authorization_code')
    }
    const auth_state = getQueryParamNamed('state') || {
      value: sessionStorage.getItem('state')
    }

    if (auth_code !== undefined && auth_code?.value) {
      if (auth_state !== undefined && auth_state?.value) {
        return `${redirectUrlToUse}?code=${auth_code.value}&state=${auth_state.value}`
      } else {
        return `${redirectUrlToUse}?code=${auth_code.value}`
      }
    }

    return redirectUrlToUse
  }

  const [items, setItems] = useState<ITopMenu>()

  useEffect(() => {
    if (recalculateItems) {
      setRecalculateItems(false)
      let result: ITopMenu

      if (menu_items !== undefined) {
        result = menu_items
      } else {
        result = MENU_ITEMS_V2
      }

      const currentUrl = window.location.href

      let selectedItemUrl = ''

      result.start.forEach((item: TopMenuItem) => {
        if (
          currentUrl.indexOf(item.link) === 0 && // startsWith
          item.link.length > selectedItemUrl.length // cherry pick longest match
        ) {
          selectedItemUrl = item.link
        }
      })

      result.end.forEach((item: TopMenuItem) => {
        if (
          currentUrl.indexOf(item.link) === 0 && // startsWith
          item.link.length > selectedItemUrl.length // cherry pick longest match
        ) {
          selectedItemUrl = item.link
        }
      })

      setItems({
        start: result.start.map((item: TopMenuItem) => {
          item.selected = item.link === selectedItemUrl

          return item
        }),

        end: result.end.map((item: TopMenuItem) => {
          item.selected = item.link === selectedItemUrl

          return item
        })
      })
    }
  }, [menu_items, recalculateItems])

  useEffect(() => {
    setRecalculateItems(true)
  }, [window.location.href])

  const checkUrlParams = async () => {
    const expiredFlag = getQueryParamNamed('expired')

    if (expiredFlag && expiredFlag.value === 'true') {
      await authUtil.logOut({
        context: 'TBMC1',
        reason: {
          topBarMenuHadExpiredUrlQueryParamFlag: expiredFlag
        }
      })
    }

    // let redirect_url = getSanitizedRedirectUrl()

    // if (redirect_url !== undefined) {
    //   logging.logDebug('redirect url: ', redirect_url)
    //   setRedirectUrl(redirect_url)
    // }
  }

  const checkLoginStatus = async () => {
    if (authenticated) {
      logging.logDebug('TopBarMenu -> checkLoginStatus -> authenticated')

      setShowLoginModal(false)
      setLoading(false)
      setLoggedIn(true)

      return
    }

    const userIsLoggedIn = await authUtil.userIsLoggedIn({
      context: 'TopBarMenu -> checkLoginStatus'
    })

    if (userIsLoggedIn) {
      setShowLoginModal(false)
      setLoading(false)
      setLoggedIn(true)

      if (appIsProfile()) {
        handleLoggedIn()
      }
    } else if (skip_authentication) {
      setShowLoginModal(false)
      setLoading(false)
      setLoggedIn(false)
    } else if (alt_authenticated) {
      setLoading(false)
      setLoggedIn(true)
    } else {

      // Skolon/Feide login
      const isSkolonLogin = getQueryParamNamed('skolon_login');
      const isFeideLogin = getQueryParamNamed('feide_login');
      if (isSkolonLogin?.value === 'true' || isFeideLogin?.value === 'true') {
        setShowLoginModal(false)

        sessionStorage.clear();
        const redirect_uri = getQueryParamNamed('redirect_uri');
        if (redirect_uri !== undefined) {
          sessionStorage.setItem('redirect_uri', redirect_uri.value);
        }

        cacheRedirectUrl();

        const federatedLoginRes = await authUtil.federatedLogin(isSkolonLogin ? 'Skolon' : 'Feide');

        if (federatedLoginRes.status === 'loggedin') {
          tweakRedirectUrlOnSuccessfulSignup(); // TODO: (mid) find a way to distinguish between signup and login
        }

        return;
      }

      setShowLoginModal(true)
      setLoading(false)
      setLoggedIn(false)
      if (onLoginChange !== undefined) {
        onLoginChange(false)
      }
    }
  }

  const handleCloseLoginModal = () => {
    logging.logDebug('handleCloseLoginModal -> init');

    if (full_page_mode || !allow_unauthenticated) {
      handleUserProfileClose();
    } else {
      setShowLoginModal(false)
    }
  }

  const handleLoggedIn = async () => {
    // should be called in profile only
    setLoading(true)
    setLoggedIn(true)
    setShowLoginModal(false)

    const auth_code = getQueryParamNamed('authorization_code') || {
      value: sessionStorage.getItem('authorization_code')
    }

    if (auth_code.value) {
      await authUtil.storeTokens(auth_code.value)
    }

    if (!skip_authentication) {
      if (onLoginChange !== undefined) {
        onLoginChange(true)
      }

      let redirectUrl = getSanitizedRedirectUrl()

      let _redirectUrl = full_page_mode
        ? getRedirectUrl(redirectUrl)
        : redirectUrl

      sessionStorage.clear() // Clear session here so that the next login in same browser session dosen't redirect

      _redirectUrl = safeRedirect(_redirectUrl)

      // we expect the code below to never be called
      if (typeof _redirectUrl !== 'undefined' && _redirectUrl) {
        logging.logError('safeRedirect -> fallback logic')
        setLoggedIn(false)
        setShowLoginModal(true)
        await authUtil.logOut({
          context: 'TBMC2',
          reason: {
            safeRedirectFailed: true,
            full_page_mode,
            _redirectUrl,
            redirectUrl
          }
        })
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    logging.logDebug('TopBarMenu -> init');

    if (appIsProfile()) {
      cacheRedirectUrl();
    }

    logging.logDebug('subscription_upgradeable: ', subscription_upgradeable)

    async function check() {
      await checkUrlParams()
      await checkLoginStatus()
    }

    check()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      updatePlaceholderHeight()
    }

    if (fixed) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [fixed])

  useLayoutEffect(() => {
    updatePlaceholderHeight()
  })

  return (
    <div id={id} className={className ? className : ''}>
      {loggedIn ? (
        <div className={`${styles['top-bar-menu-banner']}`}>
          <div
            className={`${styles['top-bar-menu-banner-inner']} ${styles['black']}`}
          >
            <div className={styles['top-bar-menu-banner-badge-new']}>NEW</div>
            <div className={styles['top-bar-menu-banner-text-new']}>
              Build dynamic websites with backend support in Spaces
              <a
                className={styles['top-bar-menu-banner-link-new']}
                href={`https://www.w3schools.com/spaces/`}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() =>
                  sendGoogleAnalyticsEvent({
                    name: `Clicked on "Learn more" for Dynamic spaces in banner`,
                    category: 'Topbar after login'
                  })
                }
              >
                {' '}
                Learn more
              </a>
            </div>
          </div>
        </div>
      ) : null}

      {fixed && (
        <div
          className={styles['top-bar-menu-ph']}
          style={{ height: placeholder_height + 'px' }}
        >
          &nbsp;
        </div>
      )}

      <div
        ref={ref}
        className={
          styles.topbarmenu +
          ' ' +
          (loggedIn ? '' : styles.full_page) +
          ' ' +
          (fixed ? (loggedIn ? styles.fixed_logged_in : styles.fixed) : '')
        }
      >
        <div
          className={`${styles.buttonbar}${buttonbarClassName ? ` ${buttonbarClassName}` : ''
            }`}
        >
          <W3LogoButton className={styles.logo} loggedIn={loggedIn} />

          <nav
            className={menuOpen ? styles.open : ''}
            id='navigation'
            onClick={(event: any) => event.stopPropagation()}
            onTouchStart={(event: any) => event.stopPropagation()}
            onTouchMove={(event: any) => event.stopPropagation()}
          >
            {loggedIn && (
              <div key='top-menu-start' className={styles.start_wrapper}>
                {items &&
                  items.start.map((item: TopMenuItem, index) => {
                    return item.new ? (
                      <div
                        key={`new-menu-item-start-${index}`}
                        className={styles.new_button}
                      >
                        {getMenuItem(
                          item,
                          subscription_upgradeable,
                          `start-${index}`,
                          subscription_plan
                        )}
                        <NewIcon
                          style={{ float: 'right', marginLeft: '8px' }}
                        />
                      </div>
                    ) : (
                      getMenuItem(
                        item,
                        subscription_upgradeable,
                        `start-${index}`,
                        subscription_plan
                      )
                    )
                  })}
              </div>
            )}

            <div key='top-menu-end' className={styles.end_wrapper}>
              {loggedIn && (
                <>
                  {items &&
                    items.end.map((item: TopMenuItem, index) => {
                      return item.new ? (
                        <div
                          key={`new-menu-item-end-${index}`}
                          className={styles.new_button}
                        >
                          {getMenuItem(
                            item,
                            subscription_upgradeable,
                            `end-${index}`,
                            subscription_plan
                          )}
                          <NewIcon
                            style={{ float: 'right', marginLeft: '8px' }}
                          />
                        </div>
                      ) : (
                        getMenuItem(
                          item,
                          subscription_upgradeable,
                          `end-${index}`,
                          subscription_plan
                        )
                      )
                    })}
                </>
              )}
              <UserProfile
                loggedIn={loggedIn}
                className={styles.user_profile_button}
                full_page_mode={full_page_mode}
              />
            </div>
          </nav>

          {loggedIn && (
            <MobileMenuButton
              menuOpen={menuOpen}
              onClick={toggleMobileMenu}
              ariaControls='navigation'
            />
          )}
        </div>

        {children}
      </div>

      {appIsProfile() && (
        <LoadingModal
          header='Loading...'
          message=''
          show={loading && !alt_authenticated}
          full_page={true}
          close={() => { }}
        />
      )}

      {appIsProfile() && (
        <LoginModal
          signup={start_signup === true}
          show={showLoginModal}
          close={handleCloseLoginModal}
          onLogin={handleLoggedIn}
          full_page={true}
          showTermsFooterInModal={show_terms_footer_in_modal}
        />
      )}
    </div>
  )
}

export default TopBarMenuLegacy2
