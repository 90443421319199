import React, { createRef, useContext, useEffect, useMemo, useState } from 'react';
import useIsInViewport from '../../hooks/use-is-in-viewport';

import TermsFooter from '../../SharedLib/Component/TermsFooter/TermsFooter';
import TopNavBar from '../../SharedLib/Component/TopNavBar/TopNavBar';
import { logging } from '../../SharedLib/Util/LoggingUtil';
import NavigationModule, { INavigationModuleItem } from './NavigationModule/NavigationModule';
import Profile from './Profile/Profile';
import PublicProfile from './PublicProfile/PublicProfile';
import SectionWrapper from './SectionWrapper';
import Notifications from '../userlib_new/Notifications';
import { NotificationDismissType } from '../userlib_new/Notifications/utils/TypesInterfaces';
import { NotificationStateContext } from '../../context/notification-context';
import { PublicProfileStateContext } from '../../context/public-profile-context';
import { getUserInfoFromCookies, logOutViaRedirect } from '../../SharedLib/Util/UserSessionUtil';

import styles from './SettingsPage_new.module.scss';

interface IFeatureFlags {
  noUpsell: boolean,
}

interface ISettingsPage {
  version: string,
}

function SettingsPage({ version }: ISettingsPage) {
  const { notifications, dismissNotification } = useContext(NotificationStateContext);
  const { publicProfilePublished, errorSavingPublicProfileData } = useContext(PublicProfileStateContext);

  // userSessionSoftLookupDone - only cookie presence and meta is fetched (no validation / processing / refreshing is done at this point)
  const [userSessionSoftLookupDone, setUserSessionSoftLookupDone] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const ProfileItemRef = createRef<HTMLDivElement>();
  const PublicProfileItemRef = createRef<HTMLDivElement>();
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [hasProfileLoadError, setHasProfileLoadError] = useState(false);
  const [hasProfileSaveError, setHasProfileSaveError] = useState(false);
  const [hasErrorInContactsPP, setHasErrorInContactsPP] = useState(false);
  const [isPublicProfileLoading, setIsPublicProfileLoading] = useState(false);
  const [hasPublicProfileLoadError, setHasPublicProfileLoadError] = useState(false);
  const [errorStringProfile, setErrorStringProfile] = useState('');
  const [errorStringPublicProfile, setErrorStringPublicProfile] = useState('');
  const [publicProfileLoadingData, setPublicProfileLoadingData] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags>({
    noUpsell: false,
  });

  const [subscriptionPlan, setSubscriptionPlan] = useState<string>('free');

  const [isInEditNickMode, setIsInEditNickMode] = useState(false);

  const navModuleItems: INavigationModuleItem[] = useMemo(
    () => [
      {
        id: 'profile',
        title: 'Account',
        elementId: 'profile-section',
        scrollToTop: true,
        loading: isProfileLoading,
        error: hasProfileLoadError || hasProfileSaveError,
        errorText: errorStringProfile,
      },
      /* {
        id: 'public-profile',
        title: 'My Public Profile',
        elementId: 'public-profile-section',
        loading: isPublicProfileLoading,
        error: hasPublicProfileLoadError || errorSavingPublicProfileData,
        errorText: errorStringPublicProfile,
        displayPublishedStatus: true,
        published: publicProfilePublished,
      }, */
    ],
    [isProfileLoading, hasProfileLoadError, hasProfileSaveError, errorStringProfile],
  );

  const isPublicProfileInViewport = useIsInViewport(PublicProfileItemRef);

  const errorLoadingUser = (errorString: string) => {
    logging.logDebug('SettingsPage -> errorLoadingUser -> errorString', errorString);
    setErrorStringProfile(errorString);
    setHasProfileLoadError(true);
  };

  const errorSavingUser = (value: boolean, errorString: string) => {
    logging.logDebug('SettingsPage -> errorSavingUser -> errorString', errorString);
    setErrorStringProfile(errorString);
    setHasProfileSaveError(value);
  };

  const errorLoadingPublicProfile = (errorString: string) => {
    logging.logDebug('SettingsPage -> errorLoadingPublicProfile -> errorString', errorString);
    setErrorStringPublicProfile(errorString);
    setHasPublicProfileLoadError(true);
  };

  const errorSavingPublicProfile = (errorString: string) => {
    logging.logDebug('SettingsPage -> errorSavingPublicProfile -> errorString', errorString);
    setErrorStringPublicProfile(errorString);
  };

  const onErrorInContacts = (value: boolean) => {
    setHasErrorInContactsPP(value);
  };

  const onEnterEditNick = (value: boolean) => {
    setIsInEditNickMode(value);
  };

  useEffect(() => {
    getUserInfoFromCookies()
      .then((res: any) => {
        logging.logDebug('SettingsPage_new -> reading cookie hook: ', res);
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!res) {
          setSubscriptionPlan(res.plan);
          setFeatureFlags({ noUpsell: res.noUpsell || false });
          setLoggedIn(true);
          setUserSessionSoftLookupDone(true);
        } else {
          setUserSessionSoftLookupDone(true);
        }
      })
      .catch(error => {
        logging.logError('SettingsPage_new -> error reading cookie hook: ', error);
        setUserSessionSoftLookupDone(true);
      });
  }, []);

  useEffect(() => {
    if (!userSessionSoftLookupDone) {
      return;
    }

    if (
      !loggedIn
      && (
        window.location.pathname === '/profile'
        || window.location.pathname === '/settings'
      )
    ) {
      logOutViaRedirect({
        context: 'logOut -> Profile anonymous log out',
        originUrl: window.location.href,
      });
    }
  }, [userSessionSoftLookupDone]);

  return (
    <>
      <TopNavBar
        zIndex={999999}
        userSessionProcessed={userSessionSoftLookupDone} // actual validation / refreshing is done in the inner components
        userSessionMeta={{
          loggedIn,
          subscriptionPlan,
          featureFlags,
        }}
      />

      {loggedIn && (
        <>
          <div className={styles.wrapper}>
            {/* <div className={styles.nav_wrapper}>
              <NavigationModule
                data={navModuleItems}
                className={styles.navbar}
                itemInViewport={isPublicProfileInViewport ? 'second' : 'first'}
              />
            </div> */}
            <div className={styles.settings_wrapper}>
              {navModuleItems.map(item => (
                <SectionWrapper
                  error={item.error}
                  errorText={item.id === 'profile' ? errorStringProfile : errorStringPublicProfile}
                  loading={item.loading}
                  key={`section-${item.id}`}
                  id={item.elementId}
                  title={item.title}
                  className={styles.section_wrapper}
                >
                  {item.id === 'profile' && (
                    <Profile
                      userIsLoggedIn={loggedIn}
                      refElement={ProfileItemRef}
                      loadingSet={setIsProfileLoading}
                      loadingSetPublicProfile={setIsPublicProfileLoading}
                      errorLoadingUser={errorLoadingUser}
                      errorSavingUser={errorSavingUser}
                      errorSavingPublicProfile={errorSavingPublicProfile}
                      publicProfileElementId="public-profile-section"
                      className={styles.profile}
                      hasPublicProfileError={hasPublicProfileLoadError}
                      isPublicProfileInitLoading={publicProfileLoadingData}
                      hasErrorInPublicProfile={hasErrorInContactsPP}
                      isInEditNickMode={isInEditNickMode}
                    />
                  )}

                  {item.id === 'public-profile' && (
                    <PublicProfile
                      userIsLoggedIn={loggedIn}
                      refElement={PublicProfileItemRef}
                      loadingSet={setIsPublicProfileLoading}
                      errorLoadingPublicProfile={errorLoadingPublicProfile}
                      errorSavingPublicProfile={errorSavingPublicProfile}
                      className={styles.profile}
                      hasProfileError={hasProfileLoadError}
                      isPublicProfileLoading={setPublicProfileLoadingData}
                      onErrorInContacts={onErrorInContacts}
                      onEnterEditNick={onEnterEditNick}
                    />
                  )}
                </SectionWrapper>
              ))}
            </div>
          </div>

          <Notifications
            className={styles.notifications}
            notifications={notifications}
            onClose={(notif: NotificationDismissType) => dismissNotification(notif.id)}
          />

          <TermsFooter position="relative" className="profile-settings-page-footer" />
        </>
      )}
    </>
  );
}

export default SettingsPage;
