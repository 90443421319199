import React, { useMemo } from 'react';
import {
  Facebook as FacebookIcon,
  Github as GitHubIcon,
  Instagram as InstagramIcon,
  Linkedin as LinkedInIcon,
  SendFill as SendFillIcon,
  TelephoneFill as TelephoneFillIcon,
  Twitter as TwitterIcon,
} from 'react-bootstrap-icons';

import { SocialMediaTypes } from '../../../userlib_new/utils/db-interfaces';
import { ICompDefault } from '../../../userlib_new/utils/types-interfaces';

const getSocialMediaIcon = (type: SocialMediaTypes) => {
  switch (type) {
  case SocialMediaTypes.Facebook:
    return FacebookIcon;
  case SocialMediaTypes.Instagram:
    return InstagramIcon;
  case SocialMediaTypes.Twitter:
    return TwitterIcon;
  case SocialMediaTypes.GitHub:
    return GitHubIcon;
  case SocialMediaTypes.Phone:
    return TelephoneFillIcon;
  case SocialMediaTypes.Email:
    return SendFillIcon;
  default:
    return LinkedInIcon;
  }
};

interface ISocialMediaIcon extends ICompDefault {
	type: SocialMediaTypes,
}

const SocialMediaIcon = ({ type, id, className }: ISocialMediaIcon) => {
  const Icon = useMemo(() => {
    return getSocialMediaIcon(type);
  }, [type]);

  return <Icon id={id} className={className || ''} />;
};

export default SocialMediaIcon;
