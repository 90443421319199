import React from 'react'
import styles from './ResetPasswordVerify.module.scss'
import {
    AuthError,
    ErrorDisplay,
    isAuthError,
    ModalProps
} from '../../Util/Interfaces'
import CloseIcon from '../Icons/CloseIcon/CloseIcon'
import ResetPasswordVerifyForm from '../Forms/ResetPasswordVerifyForm/ResetPasswordVerifyForm'
import Alert from '../Alert/Alert'
import Celebration from '../Icons/Celebration/Celebration'
import { getQueryParamNamed } from '../../Util/QueryParamsUtil'
import { authUtil } from '../../Util/AuthUtil'
import { messageForError } from '../../Util/ErrorUtil'
import Button from '../Buttons/Button/Button'
import { logging } from '../../Util/LoggingUtil'

export interface ResetPasswordVerifyProps extends ModalProps {
    resetDone?: boolean
}

export interface ResetPasswordVerifyState {
    error?: ErrorDisplay
    loading: boolean
    password: string
    passwordValid: boolean
    attemptSubmit: boolean
    resetDone: boolean
}

class ResetPasswordVerify extends React.Component<ResetPasswordVerifyProps, ResetPasswordVerifyState> {

    constructor(props: ResetPasswordVerifyProps) {
        super(props)
        this.state = {
            loading: false,
            password: "",
            passwordValid: false,
            resetDone: props.resetDone === true,
            attemptSubmit: false
        }
    }

    // componentDidMount() {
    //     authUtil.initAuth()
    // }

    handleSubmit = (e: any) => {
        e.preventDefault()
        this.setState({
            attemptSubmit: true
        })

        if (this.state.passwordValid) {
            this.verify()
        }

    }

    verify = async () => {

        const token = getQueryParamNamed('ref')
        const uname = getQueryParamNamed('uname')

        if (token === undefined || uname === undefined) {
            this.setState({
                error: {
                    msg: "Sorry, looks like the reset password link has expired or is incorrect. Please try again.",
                    placement: "general"
                }
            })
            return
        }

        const response = await authUtil.verifyResetPassword(uname.value, token.value, this.state.password)
        logging.logDebug(response)

        if (isAuthError(response)) {
            this.setState({
                error: messageForError(response as AuthError)
            })
            return
        }
        this.setState({ resetDone: true })
    }

    render() {
        return (
            <div
                className={styles.modal + ' ' + (this.props.show ? styles.show : '') + ' ' + (this.props.full_page ? styles.full_page : '')} >
                <div className={styles.modal_inner}>

                    <h1>
                        {this.state.resetDone ? "Done!" : "Reset your password"}
                    </h1>

                    {this.props.full_page ?
                        ""
                        :
                        <CloseIcon
                            size={30}
                            onClick={this.props.close}
                            className={styles.close_button} />
                    }

                    {this.state.resetDone ?
                        <div>
                            <div className={styles.celebration_wrapper}>
                                <Celebration />
                            </div>

                            <div style={{ marginTop: '12px' }}>
                                <Alert
                                    msg={"Success! Your password has been updated."}
                                    type="success" />
                            </div>
                        </div>
                        :
                        <ResetPasswordVerifyForm
                            onSubmit={this.handleSubmit}
                            validPass={this.state.passwordValid}
                            setValidPass={(value: boolean) => this.setState({ passwordValid: value })}
                            setPassword={(value: string) => this.setState({ password: value })} />
                    }
                    {(this.state.error && this.state.error.placement === "general") ?
                        <div className={styles.error}>
                            {this.state.error.msg}
                        </div>
                        :
                        <div className={styles.login_margin}></div>
                    }
                    <div className={styles.cta_bottom_box}>

                        {this.state.resetDone ?
                            <Button
                                variant="primary"
                                as="a"
                                fullwidth={true}
                                href="/login"
                                text="Return to login" />
                            :
                            <Button
                                variant="primary"
                                fullwidth={true}
                                onClick={this.handleSubmit}
                                loading={this.state.loading}
                                disabled={!this.state.passwordValid || this.state.attemptSubmit}
                                text="Reset password" />
                        }
                    </div>
                </div>
            </div >
        )
    }
}
export default ResetPasswordVerify