import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import SettingsPage from '../SettingsPage/SettingsPage';
import SettingsPageNew from '../SettingsPage/SettingsPage_new';
import { NotificationStateProvider } from '../../context/notification-context';
import { PublicProfileStateProvider } from '../../context/public-profile-context';
import RefreshSessionPage from '../RefreshSessionPage/RefreshSessionPage';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import Signup from '../Signup/Signup';
import { getCookie, removeCookie, setCookie } from '../../SharedLib/Util/CookieUtil';
import { setDefaultFetchConfig } from '../../SharedLib/Util/FetchUtil';
import { processUserSession } from '../../SharedLib/Util/CognitoUtil';
import { UserStateProvider } from '../../context/user-context';
import { changeStatusCodeInUserSessionCookies } from '../../SharedLib/Util/UserSessionUtil';
import PlanSelectionPage from '../PlanSelection/PlanSelectionPage';
import { PlanSelectionStateProvider } from '../../context/plan-selection-context';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import ClassroomInvitation from '../ClassroomInvitation/ClassroomInvitation';

interface IBase {
  version: string,
}

function Base({ version }: IBase) {
  if (window.location.href.startsWith('http:')) {
    window.location.href = window.location.href.replace(/^http:/, 'https:');
  }

  const loadSignupOnce = parseInt(getCookie('load_signup_once') || '0');
  // console.log('on load -> loadSignupOnce: ', loadSignupOnce)

  useEffect(() => {
    setDefaultFetchConfig({
      refreshTokenOnFailedCognitoAuth: true,
      processUserSessionRef: processUserSession,
      changeStatusCodeInUserSessionCookiesRef: changeStatusCodeInUserSessionCookies,
    });

    if (loadSignupOnce) {
      setCookie('load_signup_once', '0');
      removeCookie('load_signup_once');
    }
  }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

  return (
    <Router>
      <Switch>
        <Route path={['/classroom-invitation']}>
          <ClassroomInvitation />
        </Route>

        <Route path={['/signup', '/sign-up']}>
          <Login />
        </Route>

        <Route path={['/logout', '/log-out']}>
          <Logout />
        </Route>

        <Route path={['/verify-email', '/verify']}>
          <VerifyEmail />
        </Route>

        <Route path={['/settings', '/editprofile', '/edit-profile', '/profile']}>
          <UserStateProvider>
            <PublicProfileStateProvider>
              <NotificationStateProvider>
                <SettingsPageNew version={version} />
              </NotificationStateProvider>
            </PublicProfileStateProvider>
          </UserStateProvider>
        </Route>

        <Route path={['/refresh-session']}>
          <RefreshSessionPage />
        </Route>

        <Route path={['/plan-selection']}>
          <PlanSelectionStateProvider>
            <PlanSelectionPage />
          </PlanSelectionStateProvider>
        </Route>

        <Route path={['/', '/login', '/log-in']}>
          {loadSignupOnce !== 1 && <Login />}
        </Route>
      </Switch>
    </Router>
  );
}
export default Base;
