import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { ICompDefault } from '../../userlib_new/utils/types-interfaces';
import { ComponentTypes } from '../../userlib_new/utils/db-interfaces';
import Toggle from '../../userlib/buttons/ToggleButton/Toggle';
import Spinner from '../../../SharedLib/Component/Spinner/Spinner';

import styles from './ContentWrapper.module.scss';
import { logging } from '../../../SharedLib/Util/LoggingUtil';

interface IProps extends ICompDefault {
	title: string,
	eventKey: ComponentTypes | string,
	open: boolean,
	disabled: boolean,
	loading?: boolean,
	onToggleClicked: (type: ComponentTypes) => void,
}

interface ICustomToggle {
	eventKey: ComponentTypes | string,
}

const ContentWrapper = ({
  id,
  className,
  title,
  eventKey,
  children,
  open,
  disabled,
  onToggleClicked,
  loading = false,
}: React.PropsWithChildren<IProps>) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(disabled || loading);
  }, [disabled, loading]);

  const CustomToggle = ({ children, eventKey }: React.PropsWithChildren<ICustomToggle>) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      logging.logDebug('ContentWrapper -> CustomToggle -> decoratedOnClick -> [open, eventKey]', !open, eventKey);
      onToggleClicked(eventKey as ComponentTypes);
    });

    return <Toggle checked={open} label="" onChange={event => decoratedOnClick(event)} disabled={isDisabled} />;
  };

  return (
    <Accordion.Item id={id} eventKey={eventKey} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <Card>
        <Card.Header>
          <div className={styles.title_wrapper}>
            <div className={styles.title}>{title}</div>
            {loading && <Spinner size="small" variant="dark" className={styles.spinner} />}
          </div>
          <CustomToggle eventKey={eventKey} />
        </Card.Header>

        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion.Item>
  );
};

export default ContentWrapper;
