import React from 'react'
import styles from './ResetPasswordRequest.module.scss'
import {
    AuthError,
    ErrorDisplay,
    isAuthError,
    ModalProps
} from '../../Util/Interfaces'
import CloseIcon from '../Icons/CloseIcon/CloseIcon'
import ResetPasswordForm from '../Forms/ResetPasswordForm/ResetPasswordForm'
import Alert from '../Alert/Alert'
import { validateEmail } from '../../Util/ValidationUtil'
import { authUtil } from '../../Util/AuthUtil'
import { messageForError } from '../../Util/ErrorUtil'
import Button from '../Buttons/Button/Button'
import TermsFooter from '../TermsFooter/TermsFooter'
import { getMyLearningLegacyUserStatus } from '../../Util/InternalProjects/MyLearningLegacyUtil';
import { logging } from '../../Util/LoggingUtil'

export interface ResetPasswordRequestProps extends ModalProps {
    resetDone?: boolean
}

export interface ResetPasswordRequestState {
    error?: ErrorDisplay
    loading: boolean
    email: string
    resetDone: boolean

}

class ResetPasswordRequest extends React.Component<ResetPasswordRequestProps, ResetPasswordRequestState> {

    constructor(props: ResetPasswordRequestProps) {
        super(props)
        this.state = {
            loading: false,
            email: "",
            resetDone: props.resetDone === true
        }
    }

    // componentDidMount() {
    //     authUtil.initAuth()
    // }

    handleSubmit = (e: any) => {
        e.preventDefault()

        if (!validateEmail(this.state.email)) {
            logging.logInfo("Invalid email")
            this.setState({
                error: {
                    placement: "email",
                    variant: "danger",
                    msg: "Please enter a valid email address"
                }
            })
            return
        }
        this.submit()
    }

    submit = async () => {
        const response = await authUtil.sendResetPassword(this.state.email)

        if (isAuthError(response)) {
            // If user is non migrated classic user
            if ((response as AuthError).code === "UserNotFoundException") {
                const classicStatus = await getMyLearningLegacyUserStatus(this.state.email, "unknown")
                if (classicStatus !== undefined && classicStatus.exists === 1) {
                    let _msg = "We recently updated the systems on our end. Please sign up as a new user with the same email. Your progress will automatically be transferred to this new user, if you use the same email.";
                    if (classicStatus.cid_present) {
                        // already migrated, probably wrong email casing
                        _msg = "Make sure you type your email correctly. The email is case-sensitive.";
                    }
                    this.setState({
                        error: {
                            placement: "general",
                            variant: "danger",
                            msg: _msg
                        }
                    })
                    return
                }
              this.setState({ resetDone: true }); // Set "resetDone" to true to avoid email hinting
              return
            }

            this.setState({
                error: messageForError(response as AuthError)
            })
            return
        }
        this.setState({ resetDone: true })
    }

    render() {
        return (
            <div>
                <div
                    className={styles.modal + ' ' + (this.props.show ? styles.show : '') + ' ' + (this.props.full_page ? styles.full_page : '')} >
                    <div
                        style={this.state.resetDone ? { backgroundImage: `url('Airplane.png')` } : {}}
                        className={styles.modal_inner + ' ' + (this.state.resetDone ? styles.reset_done : '')}>

                        <h1>
                            {this.state.resetDone ? "Done!" : "Reset your password"}
                        </h1>

                        {this.state.resetDone ?

                            <div>
                                <div style={{ marginTop: '140px' }}></div>
                                <Alert
                                    msg={`We’ve sent an email to ${this.state.email} with instructions.`}
                                    type="success" />
                                <p className={styles.reset_done_msg}>
                                    If the email doesn't show up soon, check your spam folder. We sent it from login@w3schools.com.
                                </p>
                            </div>
                            :
                            <p className={styles.instructions}>
                                If the account exists, we'll email you instructions to reset the password.
                            </p>
                        }


                        {this.props.full_page ? "" :
                            <CloseIcon
                                size={30}
                                onClick={this.props.close}
                                className={styles.close_button} />
                        }

                        {this.state.resetDone ?
                            ``
                            :
                            <ResetPasswordForm
                                onSubmit={this.handleSubmit}
                                email={this.state.email}
                                setEmail={(value: string) => this.setState({ email: value })}
                                error={this.state.error}
                            />
                        }

                        {this.state.error && this.state.error.placement === "general" ?
                            <Alert
                                type={this.state.error.variant ? this.state.error.variant : "danger"}
                                msg={this.state.error.msg} />
                            : ``
                        }

                        <div className={styles.cta_bottom_box + ' ' + styles.cta_bottom_box_button_reset}>

                            {this.state.resetDone ?
                                <Button
                                    fullwidth={true}
                                    variant="primary"
                                    as="a"
                                    href="/login"
                                    text="Return to login" />
                                :
                                <div>

                                    <Button
                                        fullwidth={true}
                                        variant="primary"
                                        onClick={(e) => { this.setState({ error: undefined }); this.handleSubmit(e); }}
                                        loading={this.state.loading}
                                        disabled={!validateEmail(this.state.email)}
                                        text="Reset password" />

                                    <div className={styles.return_to_login_wrapper}>
                                        <a href="/" >
                                            Return to login
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                {(this.props.full_page && this.props.show) ?
                    <TermsFooter />
                    : ``}
            </div >
        )
    }
}
export default ResetPasswordRequest
