import React, { useContext, useEffect, useState } from 'react';
import Card from './Card';
import styles from './Plan.module.scss';
import { PlanSelectionStateContext } from '../../context/plan-selection-context';
import Toast from '../Toast/Toast';
import Spinner from 'react-bootstrap/Spinner';
import W3LogoButton from '../../SharedLib/Component/Buttons/W3LogoButton/W3LogoButton';

const PlanSelection: React.FC = () => {
  const { plans, toggleAnnual, purchasePlan, displayToast, setDisplayToast, toastDetails, isLoading } = useContext(PlanSelectionStateContext);
  const [isAnnualPlan, setIsAnnualPlan] = useState(false);

  const handleToastClosed = () => {
    setDisplayToast(false);
  };

  const handleToggleAnnual = () => {
    toggleAnnual();
    setIsAnnualPlan(!isAnnualPlan);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      const element = document.getElementById('pro_monthly');
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  }, []);

  {/*const ClassRoom = () => {
    return (
      <div className={`row ${styles.classroomRow}`}>
        <div className="col-lg-10 col-md-10 col-sm-10">
          <div className={styles.classroomSection}>
            <div className={`${styles.classroomText}`}>
              <div className="col-12">CLASSROOM</div>
            </div>
            <div className="row">
              <div className={`col-4 ${styles.priceUponRequest}`}>Price available upon request</div>
              <div className={`col-5 ${styles.learningSolution}`}>Let's tailor a learning solution that fits your group's unique needs</div>
              <div className="col-3 m-auto">
                <a target='_blank' href="/contact-us" className={`btn ${styles.outlineBtn}`}>More Info</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  */}
  
  const ToggleWrapper = () => {
    return (
      <div className={styles.toggleWrapper}>
        <div className={styles.labels}>
          <span style={{ color: '#9da3ba' }}>Monthly</span>
          <label className={styles.switch}>
            <input type="checkbox" checked={isAnnualPlan} onChange={handleToggleAnnual} />
            <span className={styles.slider}></span>
          </label>
          <span>Yearly (2 months free!)</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.planSelectionContainer}>
      <Toast displayToast={displayToast} onToastClosed={handleToastClosed}  {...toastDetails} />

      {/* For DeskTop */}
      <div className='d-none d-sm-block'>
        <div className={` ${styles.headerLogo}`}>
          <W3LogoButton loggedIn={true} width='69' height='65' />
        </div>
        <div className={` ${styles.chooseYourPlan}`}>
          <h1>Welcome to W3Schools! 👋</h1>
          <p>
            <h5>Choose the right plan for you 💚</h5>
          </p>
          
          <ToggleWrapper />
        </div>
      </div>
      

      {/* For Mobile */}
      <div className={` ${styles.headerLogo} d-block d-sm-none`}>
        <W3LogoButton loggedIn={true} width='50' height='46' />
        <div className={` ${styles.chooseYourPlan}`}>
          <h1>Welcome to W3Schools! 👋</h1>
          <p>
            <h5>Choose the right plan for you 💚</h5>
          </p>
          
          <ToggleWrapper />
        </div>
      </div>

      <div className={`${styles.cardsWrapper} `}>
        <div className={`${styles.cardsContainer} `}>
          <div className={`row ${styles.cardsRow}`}>
            {plans.filter(plan => plan.isVisible).map((plan, index) => (
              <div className={`${styles.cardColumn}`} key={index} id={plan.id} >
                <Card {...plan} id={plan.id} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* For Mobile
      <div className='d-block d-sm-none'>
        <div className={` ${styles.chooseYourPlan}`}>
          <ToggleWrapper />
          <p>
          By subscribing to the Plus Plan you support the W3Schools mission to make learning available to everyone - no matter their background. <b>With W3Schools Plus you support 30 learners per month!</b>
          </p>
        </div>
      </div>
       */}
      {/* For DeskTop */}
      {/*<div className='d-none d-sm-block'>
        <ClassRoom />
      </div>
            */}
      <div className={` ${styles.chooseYourPlan}`}>
        <p>
          By subscribing to the Plus Plan you support the W3Schools mission to make learning available to everyone - no matter their background. <br /> <b>With W3Schools Plus you support 30 learners per month!</b>
        </p>
      </div>
      {
        <div className={`${styles.actionButtons}`}>
          <a target='_blank' href="https://www.w3schools.com/pro/index.php#plans-section" className={`btn ${styles.outlineBtn} pr-5`}>Compare plans</a>
          <button className="btn btn-primary ml-5" onClick={purchasePlan} disabled={isLoading}>
            {isLoading && 
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '5px' }}
            />
            }
          Continue
          </button>
        </div>
      }
      {/*<div className='d-block d-sm-none'>
        <ClassRoom />
      </div>
*/}
    </div>
  );
};

export default PlanSelection;
