import { getEnv } from '../SharedLib/Util/EnvironmentUtil';

/**
 * Returns the base url for public profile depending on environment we are in
 * @returns
 */
const getPublicProfileBaseUrl = () => {
  const env = getEnv();
  let returnValue;

  switch (env) {
  case 'develop':
    returnValue = 'dev.w3profile.com';
    break;
  case 'staging':
    returnValue = 'stage.w3profile.com';
    break;
  default:
    returnValue = 'www.w3profile.com';
    break;
  }

  return returnValue;
};

export const getBillingApiUrl = () => {
  const env = getEnv();
  let returnValue;

  switch (env) {
  case 'develop':
    returnValue = 'billing-api-dev.w3schools.com';
    break;
  case 'staging':
    returnValue = 'billing-api-stage.w3schools.com';
    break;
  default:
    returnValue = 'billing-api.w3schools.com';
    break;
  }

  return returnValue;
};

export default getPublicProfileBaseUrl;
