
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const delay = async (ms: number) => {
  return new Promise(res => setTimeout(res, ms));
};

export const promisifyLite = (
  func: Function, // eslint-disable-line
) => {
  return function (...args: any) { // return a wrapper-function (*)
    return new Promise((resolve, reject) => {
      function callback(err: any, result: any) { // our custom callback for f (**)
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      }

      args.push(callback); // append our custom callback to the end of f arguments

      func.call(
        // @ts-ignore
        this,
        ...args
      ); // call the original function
    });
  };
};

export const noop = (data: any) => {
  return data;
};
