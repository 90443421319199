import React from 'react';

import { ICompDefault } from '../../utils/types-interfaces';

import styles from './TextGrouping.module.scss';

interface ISection extends ICompDefault {
	title: string,
	value: string | number,
	centerValueItems?: boolean,
}

const Section = ({ title, value, className, centerValueItems = false }: ISection) => (
  <div className={`${styles.wrapper}${centerValueItems ? ` ${styles.centerValue}` : ''}${className ? ` ${className}` : ''}`}>
    <div className={styles.title}>{title}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

export default Section;
