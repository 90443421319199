import React, { useEffect, useMemo, useState } from 'react';
import Toggle from '../../../../userlib/buttons/ToggleButton/Toggle';

import W3BadgeIcon from '../../../../userlib_new/assets/icons/W3BadgeIcon';
import { CertificateType } from '../../../../userlib_new/utils/db-interfaces';
import { ICompDefault, MONTH } from '../../../../userlib_new/utils/types-interfaces';

import styles from './Certificate.module.scss';

interface ICertificateComp extends ICompDefault {
	data: CertificateType,
	disabled?: boolean,
	onToggleVisibility: (event: React.ChangeEvent<Element>, id: string) => void,
}

const Certificate = ({ data, className, disabled = false, onToggleVisibility }: ICertificateComp) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const dateCompleted = useMemo(() => {
    if (data.dateCompleted) {
      const myDate = new Date(data.dateCompleted);

      return `${myDate.getDate()} ${MONTH[myDate.getMonth()]}, ${myDate.getFullYear()}`;
    }

    return '';
  }, [data.dateCompleted]);

  const onClickView = () => {
    if (!isDisabled) {
      window.open(data.verifyUrl, '_blank', 'noreferrer');
    }
  };

  return (
    <div className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <div className={styles.section_wrapper}>
        <W3BadgeIcon className={styles.badge} />
        <div>{data.title}</div>
      </div>

      <div className={styles.section_wrapper}>
        <div className={styles.completed_date}>{dateCompleted}</div>
        <div onClick={onClickView} className={`${styles.view}${disabled ? ` ${styles.disabled}` : ''}`}>
					View
        </div>

        <Toggle
          checked={data.visible}
          disabled={isDisabled}
          onChange={(event: React.ChangeEvent<Element>) => onToggleVisibility(event, data.id)}
        />
      </div>
    </div>
  );
};

export default Certificate;
