import React from 'react';

export interface IErrorIcon {
	className?: string,
	ref?: React.MutableRefObject<SVGSVGElement>,
	onMouseEnter?: (event: React.MouseEvent<SVGSVGElement>) => void,
	onMouseLeave?: (event: React.MouseEvent<SVGSVGElement>) => void,
}

const ErrorIcon = ({ className, ref, onMouseEnter, onMouseLeave }: IErrorIcon) => (
  <svg
    ref={ref}
    className={className || ''}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 21.5625C8.83126 21.5625 6.27182 20.5023 4.38474 18.6153C2.49765 16.7282 1.4375 14.1687 1.4375 11.5C1.4375 8.83126 2.49765 6.27182 4.38474 4.38474C6.27182 2.49765 8.83126 1.4375 11.5 1.4375C14.1687 1.4375 16.7282 2.49765 18.6153 4.38474C20.5023 6.27182 21.5625 8.83126 21.5625 11.5C21.5625 14.1687 20.5023 16.7282 18.6153 18.6153C16.7282 20.5023 14.1687 21.5625 11.5 21.5625ZM11.5 23C14.55 23 17.4751 21.7884 19.6317 19.6317C21.7884 17.4751 23 14.55 23 11.5C23 8.45001 21.7884 5.52494 19.6317 3.36827C17.4751 1.2116 14.55 0 11.5 0C8.45001 0 5.52494 1.2116 3.36827 3.36827C1.2116 5.52494 0 8.45001 0 11.5C0 14.55 1.2116 17.4751 3.36827 19.6317C5.52494 21.7884 8.45001 23 11.5 23Z"
      fill="#D9212C"
    />
    <path
      d="M10.0654   15.8126C10.0654 15.6238 10.1026 15.4369 10.1749 15.2625C10.2471 15.0881 10.353 14.9296 10.4865 14.7961C10.6199 14.6627 10.7784 14.5568 10.9528 14.4845C11.1272 14.4123 11.3142 14.3751 11.5029 14.3751C11.6917 14.3751 11.8786 14.4123 12.053 14.4845C12.2274 14.5568 12.3859 14.6627 12.5194 14.7961C12.6529 14.9296 12.7588 15.0881 12.831 15.2625C12.9032 15.4369 12.9404 15.6238 12.9404 15.8126C12.9404 16.1939 12.789 16.5595 12.5194 16.8291C12.2498 17.0987 11.8842 17.2501 11.5029 17.2501C11.1217 17.2501 10.756 17.0987 10.4865 16.8291C10.2169 16.5595 10.0654 16.1939 10.0654 15.8126ZM10.2063 7.18042C10.1872 6.99907 10.2064 6.81571 10.2627 6.64226C10.3189 6.46881 10.4111 6.30912 10.5331 6.17358C10.6551 6.03803 10.8042 5.92964 10.9708 5.85545C11.1374 5.78126 11.3177 5.74292 11.5001 5.74292C11.6824 5.74292 11.8627 5.78126 12.0293 5.85545C12.1959 5.92964 12.345 6.03803 12.467 6.17358C12.589 6.30912 12.6812 6.46881 12.7375 6.64226C12.7937 6.81571 12.8129 6.99907 12.7938 7.18042L12.2907 12.2217C12.2738 12.4198 12.1832 12.6043 12.0368 12.7387C11.8903 12.8731 11.6988 12.9477 11.5001 12.9477C11.3013 12.9477 11.1098 12.8731 10.9634 12.7387C10.817 12.6043 10.7263 12.4198 10.7094 12.2217L10.2063 7.18042Z"
      fill="#D9212C"
    />
  </svg>
);

ErrorIcon.defaultProps = {
  className: undefined,
};

export default ErrorIcon;
