
import React from 'react';
import styles from './Loading.module.scss';
import { Spinner } from 'react-bootstrap';

const Loading: React.FC = () => {
  return (
    <div className={styles.loader_container}>
      <Spinner animation="border" role="status" variant={'white'} className={'loader-spinner'}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loading;
