import {
  getBillingUrl,
  getMyCertificatesUrl,
  getMyLearningUrl,
  getProfileUrl,
  getSpacesUrl,
  getUpgradeLink
} from '../../Util/EnvironmentUtil';
import { TopMenuItem } from '../../Util/Interfaces';

export interface ITopMenu {
  start: TopMenuItem[],
  end: TopMenuItem[],
}

export const MENU_ITEMS_V2: ITopMenu = {
  start: [
    {
      title: 'My Learning',
      link: getMyLearningUrl(),
      new: false
    },
    {
      title: 'Spaces',
      link: getSpacesUrl(),
      new: false
    },
    {
      title: 'Certificates',
      link: getMyCertificatesUrl(),
      new: false
    },
    {
      title: 'Profile',
      link: `${getProfileUrl()}/profile`,
      new: false
    },
    {
      title: 'Videos',
      link: `${getMyLearningUrl()}/videos/html`,
      displayAsButton: {
        type: 'noframe',
        displayStarsIcon: true,
        positionStarsIconMobile: 'right',
        displayStarsIconForSubscription: ['free']
      },
      new: false
    },
    {
      title: 'Domains',
      link: `${getSpacesUrl()}/domains`,
      new: false
    }
  ],
  end: [
    {
      title: 'Upgrade',
      link: getUpgradeLink(),
      upgradeButton: true,
      displayAsButton: {
        type: 'purple',
        displayStarsIcon: true
      },
      new: false
    },
    {
      title: 'Browse Courses & Exams',
      link: 'https://campus.w3schools.com/',
      target: '_blank',
      displayAsButton: {
        type: 'noframe',
        displayStarsIcon: true,
        positionStarsIconMobile: 'right'
      },
      new: false
    },
    {
      title: 'Community',
      link: 'https://discord.gg/6Z7UaRbUQM',
      target: '_blank',
      new: false
    },
    {
      title: 'Billing',
      link: getBillingUrl(),
      new: false
    }
  ]
};

export const MENU_ITEMS: { [key: string]: TopMenuItem[] } = {
  develop: [
    {
      title: 'My learning',
      link: 'https://myl-dev.w3schools.com'
    },
    {
      title: 'Spaces',
      link: 'https://spaces-dev.w3schools.com'
    },
    {
      title: 'Paid courses',
      link: 'https://campus.w3schools.com/',
      target: '_blank'
    },
    {
      title: 'Certificates',
      link: 'https://myl-dev.w3schools.com/certificates',
      new: true
    },
    {
      title: 'Profile',
      link: 'https://profile-dev.w3schools.com/profile'
    },
    {
      title: 'Billing',
      link: getBillingUrl()
    },
    {
      title: 'Upgrade',
      link: getUpgradeLink(),
      displayAsButton: {
        type: 'purple',
        displayStarsIcon: true
      }
    },
    {
      title: 'Community',
      link: 'https://discord.gg/6Z7UaRbUQM',
      target: '_blank'
    }
  ],
  staging: [
    {
      title: 'My learning',
      link: 'https://myl-stage.w3schools.com'
    },
    {
      title: 'Spaces',
      link: 'https://spaces-stage.w3schools.com'
    },
    {
      title: 'Paid courses',
      link: 'https://campus.w3schools.com/',
      target: '_blank'
    },
    {
      title: 'Certificates',
      link: 'https://myl-stage.w3schools.com/certificates',
      new: true
    },
    {
      title: 'Profile',
      link: 'https://profile-stage.w3schools.com/profile'
    },
    {
      title: 'Billing',
      link: getBillingUrl()
    },
    {
      title: 'Upgrade',
      link: getUpgradeLink(),
      displayAsButton: {
        type: 'purple',
        displayStarsIcon: true
      }
    },
    {
      title: 'Community',
      link: 'https://discord.gg/6Z7UaRbUQM',
      target: '_blank'
    }
  ],
  prod: [
    {
      title: 'My learning',
      link: 'https://my-learning.w3schools.com'
    },
    {
      title: 'Spaces',
      link: 'https://spaces.w3schools.com'
    },
    {
      title: 'Paid courses',
      link: 'https://campus.w3schools.com/',
      target: '_blank'
    },
    {
      title: 'Certificates',
      link: 'https://my-learning.w3schools.com/certificates',
      new: true
    },
    {
      title: 'Profile',
      link: 'https://profile.w3schools.com/profile'
    },
    {
      title: 'Billing',
      link: getBillingUrl()
    },
    {
      title: 'Upgrade',
      link: getUpgradeLink(),
      displayAsButton: {
        type: 'purple',
        displayStarsIcon: true
      }
    },
    {
      title: 'Community',
      link: 'https://discord.gg/6Z7UaRbUQM',
      target: '_blank'
    }
  ]
};
