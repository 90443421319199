import { logging } from './LoggingUtil';

export const configGoogleAnalytics = (key: string, value: any) => {
  logging.logDebug('configGoogleAnalytics -> key, value: ', {
    key,
    value,
  });

  // @ts-ignore
  if (typeof window.dataLayer === 'undefined') {
    // @ts-ignore
    window.dataLayer = [];
  }

  // @ts-ignore
  window.dataLayer.push(key, value);
};

export interface IGoogleAnalyticsEvent {
  name: string,
  category?: string,
  callback?: () => void,
}

export const sendGoogleAnalyticsEvent = (event: IGoogleAnalyticsEvent) => {
  logging.logDebug('sendGoogleAnalyticsEvent -> event: ', event);

  // @ts-ignore
  if (typeof window.dataLayer === 'undefined') {
    // @ts-ignore
    window.dataLayer = [];
  }

  const eventOpts: any = {};

  if (typeof event.category !== 'undefined') {
    // eslint-disable-next-line
    eventOpts.event_category = event.category;
  }

  if (typeof event.callback !== 'undefined') {
    // eslint-disable-next-line
    eventOpts.event_callback = event.callback;
  }

  // @ts-ignore
  window.dataLayer.push('event', event.name, eventOpts);
};

export const GoogleAnalytics = {
  config: configGoogleAnalytics,
  sendEvent: sendGoogleAnalyticsEvent,
};
