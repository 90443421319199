import React from 'react'

export interface BackArrowProps {
    size: number
    className?: string
}

function BackArrowProps({ size, className }: BackArrowProps) {
    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 8 15"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.35355 1.14645C7.54882 1.34171 7.54882 1.65829 7.35355 1.85355L1.70711 7.5L7.35355 13.1464C7.54882 13.3417 7.54882 13.6583 7.35355 13.8536C7.15829 14.0488 6.84171 14.0488 6.64645 13.8536L0.646447 7.85355C0.451184 7.65829 0.451184 7.34171 0.646447 7.14645L6.64645 1.14645C6.84171 0.951184 7.15829 0.951184 7.35355 1.14645Z"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )
}
export default BackArrowProps