import React, { useEffect, useState } from 'react';
import styles from './LeftSide.module.scss'; // Make sure to create this SCSS module
import { CheckCircleFill } from 'react-bootstrap-icons';
import W3LogoButton from '../../SharedLib/Component/Buttons/W3LogoButton/W3LogoButton';
import { getClassroomInvitationBrowserStorageMeta, IClassroomInvitationBrowserStorageMeta } from '../ClassroomInvitation/ClassroomInvitationUtil';

const LeftSide: React.FC = () => {
  const [classroomInvitationIsSet, setClassroomInvitationIsSet] = useState<boolean>(false);
  const [classroomInvitation, setClassroomInvitation] = useState<IClassroomInvitationBrowserStorageMeta>({} as IClassroomInvitationBrowserStorageMeta);

  useEffect(() => {
    const _classroomInvitation = getClassroomInvitationBrowserStorageMeta();
    const _classroomInvitationIsSet = _classroomInvitation !== null;

    if (_classroomInvitationIsSet) {
      setClassroomInvitation(_classroomInvitation);
    }

    setClassroomInvitationIsSet(_classroomInvitationIsSet);
  }, []);

  return (
    <div className={styles.leftSideContainer}>
      <div className={styles.header_logo}>
        <W3LogoButton loggedIn={false} width="69" height="65" />
      </div>
      <div className={styles.content}>
        {classroomInvitationIsSet ? (
          <>
            <h1 className={styles.academyTitle}>
              {classroomInvitation.details.invitedMember.canRegister ? (
                <>
                  Register your account to join "{classroomInvitation.details.groupName}" classroom <span className={styles.academy}>at {classroomInvitation.details.organizationName}</span>
                </>
              ) : (
                <>
                  Log in to your account to accept the invitation <span className={styles.academy}>from {classroomInvitation.details.organizationName}</span>
                </>
              )}
            </h1>
            <p className={styles.subtitle}>hosted by W3Schools <span className={styles.heart}>💚</span></p>
          </>
        ) : (
          <>
            <h1 className={styles.title}>
              Become a{' '}
              <span className={styles.w3schooler}>
                W3Schooler <span className={styles.heart}>💚</span>
              </span>
            </h1>
            <p className={styles.subtitle}>Free to use, easy to love</p>
          </>
        )}
        <ul className={styles.featuresList}>
          <li>
            <div className={styles.checkIconContainer}>
              <CheckCircleFill className={styles.checkIcon} />{' '}
            </div>
            Track your progress
          </li>
          <li>
            <div className={styles.checkIconContainer}>
              <CheckCircleFill className={styles.checkIcon} />{' '}
            </div>
            Set your goals
          </li>
          <li>
            <div className={styles.checkIconContainer}>
              <CheckCircleFill className={styles.checkIcon} />{' '}
            </div>
            Get a personalized learning path
          </li>
          <li>
            <div className={styles.checkIconContainer}>
              <CheckCircleFill className={styles.checkIcon} />{' '}
            </div>{' '}
            Test your skills
          </li>
          <li>
            <div className={styles.checkIconContainer}>
              <CheckCircleFill className={styles.checkIcon} />{' '}
            </div>
            Practice coding in browser
          </li>
          <li>
            <div className={styles.checkIconContainer}>
              <CheckCircleFill className={styles.checkIcon} />{' '}
            </div>
            Build and host a website
          </li>
          {classroomInvitationIsSet === false && (
            <li>
              <div className={styles.checkIconContainer}>
                <CheckCircleFill className={styles.checkIcon} />{' '}
              </div>{' '}
              Teacher Toolbox
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LeftSide;
