import React from 'react';
import { Spinner } from '../../SharedLib';
import styles from './CustomButton.module.scss';


export interface ICustomButtonProps {
  width?: string,
  height?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  className?: string,
  children: React.ReactNode,
  variant?: 'primary' | 'secondary',
  type?: 'button' | 'submit',
  disabled?: boolean,
  loading?: boolean,
  isDisabled?: boolean,
}

const CustomButton: React.FC<ICustomButtonProps> = ({height = 50, width, onClick, className, children, variant = 'primary', type = 'button', loading = false, isDisabled = false}) => {
  return (
    <button type={type} onClick={onClick} style={{ width, height }} className={styles.button + (className ? ` ${className}` : '') + ` ${styles[variant]}` + (isDisabled ? ' ' + styles.disabledBtn : '')} disabled={isDisabled}>
      { loading ? <Spinner
        variant={'secondary'}
        size='medium'
        className={styles.spinner}
      /> :
        children
      }
    </button>
  );
};

export default CustomButton;
