/* eslint-disable camelcase */
import React from 'react';
import styles from './LoadingModal.module.scss';
import { ModalProps } from '../../Util/Interfaces';
import CloseIcon from '../Icons/CloseIcon/CloseIcon';

export interface LoadingModalProps extends ModalProps {
  header: string,
  message: string,
  ariaLabelSpinner?: string,
}

function LoadingModal({
  show,
  close,
  full_page,
  header,
  message,
  ariaLabelSpinner
}: LoadingModalProps) {
  const keyDown = (event: React.KeyboardEvent) => {
    if (
      event.code === 'Enter' ||
      event.code === 'NumpadEnter' ||
      event.code === 'Space'
    ) {
      event.stopPropagation();
      event.preventDefault();
      close();
    }
  };

  return (
    <div
      className={
        styles.modal +
        ' ' +
        (show ? styles.show : '') +
        ' ' +
        (full_page ? styles.full_page : '')
      }
    >
      <div
        className={
          styles.modal_inner + ' ' + (full_page ? styles.full_page : '')
        }
      >
        <h1>{header}</h1>

        {full_page ? (
          ''
        ) : (
          <CloseIcon
            size={30}
            onClick={close}
            onKeyDown={keyDown}
            className={styles.close_button}
            tabIndex={0}
            ariaLabel='Close'
          />
        )}

        <div
          className={styles.spinner}
          aria-label={ariaLabelSpinner || 'Please wait'}
          role='status'
          aria-live='polite'
        ></div>

        <p>{message}</p>
      </div>
    </div>
  );
}

export default LoadingModal;
