import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logging } from './LoggingUtil';
import {
  CustomTypeMod,
  ErrorType,
  OperationResultType,
  OptionalTypeMod
} from './InterfaceAndTypeUtil';
import { getADeepCopy } from './DataUtil';
import { getCookie } from './CookieUtil';
import { IChangeStatusCodeInUserSessionCookiesProps, IProcessUserSessionProps, IProcessUserSessionResult, UserSessionStatusCodeEnum } from './Interfaces';

export interface IFetchDefaultCfg {
  timeout: number,
  maxContentLength?: number,
  maxBodyLength?: number,
  attachCookieHeader?: boolean, // used in jsdom tests
  refreshTokenOnFailedCognitoAuth?: boolean, // default to false, requires processUserSessionRef
  processUserSessionRef?: (prs: IProcessUserSessionProps) => Promise<IProcessUserSessionResult>,
  changeStatusCodeInUserSessionCookiesRef?: (prs: IChangeStatusCodeInUserSessionCookiesProps) => void, // required when processUserSessionRef is provided
}

export const FETCH_DEFAULT_CONFIG: IFetchDefaultCfg = {
  timeout: 45000 // ms
};

export const setDefaultFetchConfig = (
  cfg: OptionalTypeMod<IFetchDefaultCfg>
) => {
  logging.logDebug('setDefaultFetchConfig -> cfg: ', cfg);

  Object.keys(cfg).forEach((key) => {
    // @ts-ignore
    FETCH_DEFAULT_CONFIG[key] = cfg[key];
  });

  return FETCH_DEFAULT_CONFIG;
};

export type IFetchPrs = CustomTypeMod<
  AxiosRequestConfig,
  {
    url: string, // force required
    // method: AxiosRequestConfig['method'], // force required
    authenticated?: boolean, // send or not the cognito accessToken along
    authHeaderKey?: 'Authorization' | 'x-access-token', // required if authenticated is set
    authBearerToken?: string, // required if authenticated is set
    attachCookies?: boolean, // alias for Axios.withCredentials
    refreshTokenOnFailedCognitoAuth?: boolean, // default to false, requires processUserSessionRef
    processUserSessionRef?: (prs: IProcessUserSessionProps) => Promise<IProcessUserSessionResult>,
    changeStatusCodeInUserSessionCookiesRef?: (prs: IChangeStatusCodeInUserSessionCookiesProps) => void, // required when processUserSessionRef is provided
    cache?: boolean, // handles false only, else defaults to axios & browser behaviour
    cleanupErrorMetaFromData?: boolean, // e.g. error.code sent along with other data
    context?: string, // not affecting the request, useful for debugging
    // redirectionHook?: IProcessUserSessionProps['redirectionHook'], // useful for debugging, tweaking the redirection url, disabling redirection
    _retriedAfterSessionRefresh?: boolean, // internal prop used for retrying to send the same request again again after the access token was refreshed
  }
>

export type IFetchResult = CustomTypeMod<
  OptionalTypeMod<AxiosResponse>,
  {
    status: number,
    error: ErrorType,
    rawData: string, // will be available when we will switch to browser native fetch
    data: any,
  }
>

export const fetch = async <T>(
  prs: IFetchPrs
): Promise<
  CustomTypeMod<
    IFetchResult,
    {
      data: T,
    }
  >
> => {
  if (typeof prs.method === 'undefined') {
    prs.method = 'POST';
  }

  if (typeof prs.timeout === 'undefined') {
    prs.timeout = FETCH_DEFAULT_CONFIG.timeout;
  }

  if (
    typeof prs.maxContentLength === 'undefined' &&
    typeof FETCH_DEFAULT_CONFIG.maxContentLength !== 'undefined'
  ) {
    prs.maxContentLength = FETCH_DEFAULT_CONFIG.maxContentLength;
  }

  if (
    typeof prs.maxBodyLength === 'undefined' &&
    typeof FETCH_DEFAULT_CONFIG.maxBodyLength !== 'undefined'
  ) {
    prs.maxBodyLength = FETCH_DEFAULT_CONFIG.maxBodyLength;
  }

  if (typeof prs.headers === 'undefined') {
    prs.headers = {};
  }

  if (typeof prs.headers['Content-Type'] === 'undefined') {
    prs.headers['Content-Type'] = 'application/json';
  }

  if (typeof prs.authenticated === 'undefined') {
    prs.authenticated = true; // attach accessToken header
  }

  logging.logDebug('fetch -> prs: ', prs);

  if (prs.authenticated) {
    const authHeaderKey = prs.authHeaderKey || 'Authorization';

    if (typeof prs.headers[authHeaderKey] === 'undefined') {
      logging.logDebug('fetch -> attachUserSessionHeader -> initialize');

      let accessToken;

      if (typeof prs.authBearerToken !== 'undefined') {
        accessToken = prs.authBearerToken;
      } else {
        const refreshTokenOnFailedCognitoAuth = prs.refreshTokenOnFailedCognitoAuth || FETCH_DEFAULT_CONFIG.refreshTokenOnFailedCognitoAuth;

        if (refreshTokenOnFailedCognitoAuth) {
          let processUserSessionRes: OperationResultType | undefined = undefined;

          if (typeof prs.processUserSessionRef !== 'undefined') {
            processUserSessionRes = await prs.processUserSessionRef({
              context: 'FetchUtil -> fetch -> pre request (1)',
              preferDethrottleCache: false,
            });
          } else if (
            typeof FETCH_DEFAULT_CONFIG.processUserSessionRef !== 'undefined'
          ) {
            processUserSessionRes = await FETCH_DEFAULT_CONFIG.processUserSessionRef({
              context: 'FetchUtil -> fetch -> pre request (2)',
              preferDethrottleCache: false,
            });
          }

          logging.logDebug('fetch -> processUserSessionRes (1): ', processUserSessionRes);
        }

        accessToken = getCookie('accessToken');
      }

      if (
        typeof accessToken !== 'undefined'
        && accessToken
      ) {
        logging.logDebug('fetch -> attachUserSessionHeader -> accessToken: ', accessToken);

        prs.headers[authHeaderKey] = 'Bearer ' + accessToken;
      }
    }
  }

  if (typeof prs.attachCookies !== 'undefined') {
    prs.withCredentials = prs.attachCookies;
  }

  if (
    typeof prs.withCredentials !== 'undefined' &&
    prs.withCredentials &&
    typeof FETCH_DEFAULT_CONFIG.attachCookieHeader !== 'undefined' &&
    FETCH_DEFAULT_CONFIG.attachCookieHeader
  ) {
    prs.headers['Cookie'] = window.document.cookie;
  }

  if (typeof prs.cache !== 'undefined') {
    prs.cache = false;
  }

  if (prs.cache === false) {
    if (typeof prs.headers['Cache-Control'] === 'undefined') {
      prs.headers['Cache-Control'] = 'no-cache';
    }

    // if (
    //   typeof prs.headers['Pragma'] === 'undefined'
    // ) {
    //   prs.headers['Pragma'] = 'no-cache';
    // }

    // if (
    //   typeof prs.headers['Expires'] === 'undefined'
    // ) {
    //   prs.headers['Expires'] = '0';
    // }
  }

  if (typeof prs.validateStatus === 'undefined') {
    prs.validateStatus = () => true; // we accept any status as valid. Axios default: status >= 200 && status < 300;
  }

  if (prs.method === 'GET' && typeof prs.data !== 'undefined') {
    prs.url += '?' + getSerializedUrlQueryPrs(prs.data);

    delete prs.data;
  }

  if (typeof prs._retriedAfterSessionRefresh === 'undefined') {
    prs._retriedAfterSessionRefresh = false;
  }

  type IOutput = CustomTypeMod<IFetchResult, {
    data: T,
  }>;

  let output: IOutput;

  try {
    const reqRes = await Axios(prs);

    output = await getPreparedResponse(reqRes, prs) as unknown as IOutput;
    // output = Promise.resolve(getPreparedResponse(reqRes, prs)) as unknown as IOutput;
  } catch (reqExc) {
    output = getPreparedResponseFromException(reqExc, prs) as unknown as IOutput;
  }

  logging.logDebug('fetch -> output: ', output);

  if (
    output.error.code !== '0'
    && typeof output.error.meta !== 'undefined'
    && output.error.meta !== null
    && typeof output.error.meta.failedCognitoAuth !== 'undefined'
    && output.error.meta.failedCognitoAuth === true
    && !prs._retriedAfterSessionRefresh
  ) {
    const refreshTokenOnFailedCognitoAuth = prs.refreshTokenOnFailedCognitoAuth || FETCH_DEFAULT_CONFIG.refreshTokenOnFailedCognitoAuth;

    if (refreshTokenOnFailedCognitoAuth) {
      if (typeof prs.changeStatusCodeInUserSessionCookiesRef !== 'undefined') {
        prs.changeStatusCodeInUserSessionCookiesRef({
          newStatusCode: UserSessionStatusCodeEnum.RefreshRequired,
        });
      } else if (
        typeof FETCH_DEFAULT_CONFIG.changeStatusCodeInUserSessionCookiesRef !== 'undefined'
      ) {
        FETCH_DEFAULT_CONFIG.changeStatusCodeInUserSessionCookiesRef({
          newStatusCode: UserSessionStatusCodeEnum.RefreshRequired,
        });
      } else {
        logging.logError('fetch -> processUserSessionRes -> changeStatusCodeInUserSessionCookiesRef is not set');
      }

      let processUserSessionRes: OperationResultType | undefined = undefined;

      if (typeof prs.processUserSessionRef !== 'undefined') {
        processUserSessionRes = await prs.processUserSessionRef({
          context: 'FetchUtil -> fetch -> post request (1)',
          preferDethrottleCache: false,
        });
      } else if (
        typeof FETCH_DEFAULT_CONFIG.processUserSessionRef !== 'undefined'
      ) {
        processUserSessionRes = await FETCH_DEFAULT_CONFIG.processUserSessionRef({
          context: 'FetchUtil -> fetch -> post request (2)',
          preferDethrottleCache: false,
        });
      }

      logging.logDebug('fetch -> processUserSessionRes (2): ', processUserSessionRes);

      if (
        typeof processUserSessionRes !== 'undefined'
        && processUserSessionRes.error.code === '0' // session seems fine, retry to send the same request  
      ) {
        prs._retriedAfterSessionRefresh = true;

        return await fetch(prs);
      }
    }
  }

  return output;
};

export const getErrorByResponseStatus = async (
  res: AxiosResponse,
  prs: IFetchPrs
): Promise<ErrorType> => {
  // can be improved
  // BRS - By response status

  if (
    typeof res.status !== 'undefined' &&
    res.status &&
    typeof RESPONSE_STATUS_CODE_MAP[`${res.status}`] !== 'undefined'
  ) {
    const { code, description } = RESPONSE_STATUS_CODE_MAP[`${res.status}`];

    const errorMeta: any = {
      origin: 'getErrorByResponseStatus'
    };

    if (
      code === 'UNAUTHORIZED' &&
      (prs.authenticated || prs.withCredentials) &&
      (typeof res.data.error === 'undefined' ||
        typeof res.data.error.code === 'undefined')
    ) {
      logging.logError(
        'getErrorByResponseStatus -> failedCognitoAuth -> res, prs: ',
        res,
        prs
      );

      errorMeta.failedCognitoAuth = true;
    }

    return {
      code,
      description,
      meta: errorMeta
    };
  }

  logging.logError('getErrorByResponseStatus -> res: ', res);

  return {
    code: 'INTERNAL_SERVER_ERROR',
    description: 'Internal server error',
    meta: {
      origin: 'getErrorByResponseStatus',
      res
    }
  };
};

const getPreparedResponse = async (
  res: AxiosResponse,
  prs: IFetchPrs
): Promise<IFetchResult> => {
  // Function that normalizes the Axios response (by reference) for our further needs
  logging.logDebug('getPreparedResponse -> res: ', res);
  logging.logDebug('getPreparedResponse -> prs: ', prs);

  const resDataWrp = res.data;

  let error: ErrorType;

  if (
    typeof resDataWrp.error !== 'undefined' &&
    typeof resDataWrp.error.code !== 'undefined'
  ) {
    error = getADeepCopy(resDataWrp.error) as ErrorType;
  } else if (typeof resDataWrp['error.code'] !== 'undefined') {
    const cleanupErrorMetaFromData = prs.cleanupErrorMetaFromData || false;

    error = {
      code: resDataWrp['error.code']
    };

    if (cleanupErrorMetaFromData) {
      delete resDataWrp['error.code'];
    }

    if (typeof resDataWrp['error.description'] !== 'undefined') {
      error.description = resDataWrp['error.description'];

      if (cleanupErrorMetaFromData) {
        delete resDataWrp['error.description'];
      }
    }

    if (typeof resDataWrp['error.meta'] !== 'undefined') {
      error.meta = resDataWrp['error.meta'];

      if (cleanupErrorMetaFromData) {
        delete resDataWrp['error.meta'];
      }
    }
  } else {
    error = {
      // code: 'FINECIS' // front internal no error code is set
      code: '0'
    };
  }

  if (
    (error.code === '0' || error.code === '1') &&
    typeof res.status !== 'undefined' &&
    res.status &&
    !(res.status >= 200 && res.status < 300)
  ) {
    error = await getErrorByResponseStatus(res, prs);
  }

  if (
    error.code !== '0' &&
    (typeof error.description === 'undefined' || !error.description)
  ) {
    error.description = 'Internal server error';
  }

  // @ts-ignore
  res.error = error;

  logging.logDebug('getPreparedResponse -> error: ', error);

  if (typeof resDataWrp.data !== 'undefined') {
    res.data = resDataWrp.data; // move down the ref
  }

  // @ts-ignore
  res.rawData = '';

  return res as IFetchResult;
};

const getPreparedResponseFromException = (
  exc: unknown | any,
  prs: IFetchPrs
): IFetchResult => {
  logging.logDebug('getPreparedResponseFromException -> exc: ', exc);
  logging.logDebug('getPreparedResponseFromException -> prs: ', prs);

  return {
    status: exc.status,
    rawData: '',
    data: {},
    error: {
      code: exc.status ? '' + exc.status : 'FINESIS', // front internal no exception status is set
      description: exc.message ? exc.message : 'Internal server error'
    }
  };
};

const getSerializedUrlQueryPrs = function (obj: any, prefix?: string): string {
  const str = [];
  let p;

  for (p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      // eslint-disable-line no-prototype-builtins
      const k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? getSerializedUrlQueryPrs(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      );
    }
  }

  return str.join('&');
};

export interface IResponseStatusCodeMap {
  [statusNumericCode: string]: {
    code: string,
    description: string,
  },
}

// based on "http-status-codes": "2.2.0"
export const RESPONSE_STATUS_CODE_MAP: IResponseStatusCodeMap = {
  '100': { code: 'CONTINUE', description: 'Continue' },
  '101': { code: 'SWITCHING_PROTOCOLS', description: 'Switching Protocols' },
  '102': { code: 'PROCESSING', description: 'Processing' },
  '200': { code: 'OK', description: 'OK' },
  '201': { code: 'CREATED', description: 'Created' },
  '202': { code: 'ACCEPTED', description: 'Accepted' },
  '203': {
    code: 'NON_AUTHORITATIVE_INFORMATION',
    description: 'Non Authoritative Information'
  },
  '204': { code: 'NO_CONTENT', description: 'No Content' },
  '205': { code: 'RESET_CONTENT', description: 'Reset Content' },
  '206': { code: 'PARTIAL_CONTENT', description: 'Partial Content' },
  '207': { code: 'MULTI_STATUS', description: 'Multi-Status' },
  '300': { code: 'MULTIPLE_CHOICES', description: 'Multiple Choices' },
  '301': { code: 'MOVED_PERMANENTLY', description: 'Moved Permanently' },
  '302': { code: 'MOVED_TEMPORARILY', description: 'Moved Temporarily' },
  '303': { code: 'SEE_OTHER', description: 'See Other' },
  '304': { code: 'NOT_MODIFIED', description: 'Not Modified' },
  '305': { code: 'USE_PROXY', description: 'Use Proxy' },
  '307': { code: 'TEMPORARY_REDIRECT', description: 'Temporary Redirect' },
  '308': { code: 'PERMANENT_REDIRECT', description: 'Permanent Redirect' },
  '400': { code: 'BAD_REQUEST', description: 'Bad Request' },
  '401': { code: 'UNAUTHORIZED', description: 'Unauthorized' },
  '402': { code: 'PAYMENT_REQUIRED', description: 'Payment Required' },
  '403': { code: 'FORBIDDEN', description: 'Forbidden' },
  '404': { code: 'NOT_FOUND', description: 'Not Found' },
  '405': { code: 'METHOD_NOT_ALLOWED', description: 'Method Not Allowed' },
  '406': { code: 'NOT_ACCEPTABLE', description: 'Not Acceptable' },
  '407': {
    code: 'PROXY_AUTHENTICATION_REQUIRED',
    description: 'Proxy Authentication Required'
  },
  '408': { code: 'REQUEST_TIMEOUT', description: 'Request Timeout' },
  '409': { code: 'CONFLICT', description: 'Conflict' },
  '410': { code: 'GONE', description: 'Gone' },
  '411': { code: 'LENGTH_REQUIRED', description: 'Length Required' },
  '412': { code: 'PRECONDITION_FAILED', description: 'Precondition Failed' },
  '413': { code: 'REQUEST_TOO_LONG', description: 'Request Entity Too Large' },
  '414': { code: 'REQUEST_URI_TOO_LONG', description: 'Request-URI Too Long' },
  '415': {
    code: 'UNSUPPORTED_MEDIA_TYPE',
    description: 'Unsupported Media Type'
  },
  '416': {
    code: 'REQUESTED_RANGE_NOT_SATISFIABLE',
    description: 'Requested Range Not Satisfiable'
  },
  '417': { code: 'EXPECTATION_FAILED', description: 'Expectation Failed' },
  '418': { code: 'IM_A_TEAPOT', description: 'I\'m a teapot' },
  '419': {
    code: 'INSUFFICIENT_SPACE_ON_RESOURCE',
    description: 'Insufficient Space on Resource'
  },
  '420': { code: 'METHOD_FAILURE', description: 'Method Failure' },
  '421': { code: 'MISDIRECTED_REQUEST', description: 'Misdirected Request' },
  '422': { code: 'UNPROCESSABLE_ENTITY', description: 'Unprocessable Entity' },
  '423': { code: 'LOCKED', description: 'Locked' },
  '424': { code: 'FAILED_DEPENDENCY', description: 'Failed Dependency' },
  '428': {
    code: 'PRECONDITION_REQUIRED',
    description: 'Precondition Required'
  },
  '429': { code: 'TOO_MANY_REQUESTS', description: 'Too Many Requests' },
  '431': {
    code: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    description: 'Request Header Fields Too Large'
  },
  '451': {
    code: 'UNAVAILABLE_FOR_LEGAL_REASONS',
    description: 'Unavailable For Legal Reasons'
  },
  '500': {
    code: 'INTERNAL_SERVER_ERROR',
    description: 'Internal Server Error'
  },
  '501': { code: 'NOT_IMPLEMENTED', description: 'Not Implemented' },
  '502': { code: 'BAD_GATEWAY', description: 'Bad Gateway' },
  '503': { code: 'SERVICE_UNAVAILABLE', description: 'Service Unavailable' },
  '504': { code: 'GATEWAY_TIMEOUT', description: 'Gateway Timeout' },
  '505': {
    code: 'HTTP_VERSION_NOT_SUPPORTED',
    description: 'HTTP Version Not Supported'
  },
  '507': { code: 'INSUFFICIENT_STORAGE', description: 'Insufficient Storage' },
  '511': {
    code: 'NETWORK_AUTHENTICATION_REQUIRED',
    description: 'Network Authentication Required'
  }
};
