import React, { useEffect, useState } from 'react';

import NotificationToast from './NotificationToast/NotificationToast';
import { getActiveNotifications, getUploadNotifications } from './utils/NotificationHandler';
import { NotificationType } from './utils/TypesInterfaces';

import styles from './Notifications.module.scss';

export interface INotificationToast {
	notifications: NotificationType | NotificationType[],
	onClose: (notification: NotificationType) => void,
	className?: string,
}

const Notifications = ({ notifications, onClose, className }: INotificationToast) => {
  const [notifs, setNotifs] = useState<NotificationType[]>([]);

  useEffect(() => {
    let myNotifs: NotificationType[] = [];

    if (Array.isArray(notifications)) {
      myNotifs = notifications;
    } else {
      myNotifs = [notifications];
    }
    const notificationsToShow = getActiveNotifications(myNotifs);
    const uploadNotifications = getUploadNotifications(myNotifs);

    const groupedNotifications: any[] = [];
    let uploadsAdded = false;

    notificationsToShow.forEach((notification: NotificationType) => {
      if (!notification.showProgressBar) {
        groupedNotifications.push(notification);
      } else if (!uploadsAdded) {
        groupedNotifications.push(uploadNotifications);
        uploadsAdded = true;
      }
    });

    setNotifs(groupedNotifications);
  }, [notifications]);

  return (
    <div className={`${styles.notifications} ${className ? ` ${className}` : ''}`}>
      {notifs.map((notificationGroup: NotificationType | NotificationType[]) => (
        <NotificationToast
          key={Array.isArray(notificationGroup) ? notificationGroup[0].id : notificationGroup.id}
          notifications={notificationGroup}
          onClose={onClose}
        />
      ))}
    </div>
  );
};

Notifications.defaultProps = {
  className: undefined,
};

export default Notifications;
