import { CustomTypeMod, OperationResultType } from './InterfaceAndTypeUtil';

export type ILiteFetchResult = CustomTypeMod<OperationResultType, {
  status: number,
  dataStr: string,
  xhr: XMLHttpRequest,
}>;

export const liteFetch = (
  method: string,
  url: string,
  data: any,
  callback: (reqRes: ILiteFetchResult) => void,
  timeout?: number,
) => {
  if (typeof timeout === 'undefined') {
    timeout = 15000;
  }

  const xhr = new XMLHttpRequest();

  const reqRes: ILiteFetchResult = {
    error: {
      code: '0'
    },
    status: 0,
    dataStr: '',
    data: null,
    xhr: xhr
  };

  const reqTimeout = setTimeout(function () {
    reqRes.error = {
      code: 'RWTE',
      description: 'Request wait time exceeded'
    };

    callback(reqRes);
  }, timeout);

  xhr.onreadystatechange = function () {
    if (this.readyState == 4) {
      clearTimeout(reqTimeout);

      reqRes.status = this.status;
      reqRes.dataStr = this.responseText;

      if (
        typeof reqRes.status !== 'undefined'
        && reqRes.status
      ) {
        if (reqRes.status == 401) {
          reqRes.error = {
            code: 'UNAUTHORIZED',
            description: 'Request unauthorized'
          };
        } else if (!(reqRes.status >= 200 && reqRes.status < 300)) {
          reqRes.error = {
            code: 'RSC_' + reqRes.status,
            description: (typeof this.statusText !== 'undefined' && this.statusText) ? this.statusText : 'Request failed'
          };
        }
      } else {
        reqRes.error = {
          code: 'RTWNSC', // Request terminated with no status code
          description: 'Request failed'
        };
      }

      callback(reqRes);
    }
  };

  xhr.open(method, url, true);
  xhr.send(data);
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.liteFetch = liteFetch;
}
