import React, { useRef, useState } from 'react';
import Spinner from '../../../../../SharedLib/Component/Spinner/Spinner';
import { getPublicProfileUrl } from '../../../../../SharedLib/Util/EnvironmentUtil';
import Toggle from '../../../../userlib/buttons/ToggleButton/Toggle';
import InputItem from '../../../../userlib_new/InputItem';
import { SpaceType } from '../../../../userlib_new/utils/db-interfaces';
import { ICompDefault, KEYPRESS_WAIT } from '../../../../userlib_new/utils/types-interfaces';

import styles from './Space.module.scss';

interface ISpace extends ICompDefault {
	space: SpaceType,
	disabled?: boolean,
	onDataUpdated: (space: SpaceType) => void,
}

const Space = ({ id, className, space, disabled = false, onDataUpdated }: ISpace) => {
  const [errorLoadingImg, setErrorLoadingImg] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  const timeoutId = useRef<NodeJS.Timeout>();

  const toggleVisibility = () => {
    const mySpace = { ...space };
    mySpace.visible = !mySpace.visible;
    onDataUpdated(mySpace);
  };

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }

    timeoutId.current = setTimeout(() => {
      const mySpace = { ...space };
      mySpace.description = event.target.value;
      onDataUpdated(mySpace);
      timeoutId.current = undefined;
    }, KEYPRESS_WAIT);
  };

  const onKeyPressLink = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
      onClickLink();
    }
  };

  const onClickLink = () => {
    window.open(space.spaceUrl, '_blank', 'noreferrer');
  };

  const onLoadImg = () => {
    setIsLoadingImg(false);
  };

  const onErrorLoadingImg = () => {
    setErrorLoadingImg(true);
    setIsLoadingImg(false);
  };

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <div className={styles.content_wrapper}>
        {space.pictureUrl && !errorLoadingImg ? (
          <>
            <img
              onLoad={onLoadImg}
              onError={onErrorLoadingImg}
              src={`${getPublicProfileUrl()}/${space.pictureUrl}?key=${new Date().getTime()}`}
              className={styles.image}
              alt="Screenshot of space"
            ></img>
            {isLoadingImg && <Spinner className={styles.image_spinner} size="medium" />}
          </>
        ) : (
          <div className={styles.no_preview_wrapper}>
            <div className={`${styles.no_preview}${errorLoadingImg ? ` ${styles.error}` : ''}`}>
              {!errorLoadingImg ? 'No preview' : 'Error loading image'}
            </div>
          </div>
        )}

        <div className={styles.sub_wrapper}>
          <div className={styles.group_wrapper}>
            <div className={styles.title}>URL</div>
            <div
              tabIndex={disabled ? -1 : 0}
              onKeyPress={onKeyPressLink}
              onClick={onClickLink}
              className={`${styles.url} ${styles.last_item}`}
            >
              {space.spaceUrl}
            </div>
          </div>

          <div className={styles.group_wrapper}>
            <InputItem
              label="Description"
              className={`${styles.description} ${styles.last_item}`}
              disabled={disabled}
              defaultValue={space.description}
              autoCapitalize="sentences"
              onValueChange={changeValue}
              placeholder="Add description..."
              maxLength={2000}
            />
          </div>
        </div>
      </div>
      <div className={styles.action_wrapper}>
        <Toggle checked={space.visible} disabled={disabled} onChange={toggleVisibility} />
      </div>
    </div>
  );
};

export default Space;
