import { QueryParam } from './Interfaces';

export const getQueryParamNamed = (paramName: string) => {
  const params = window.location.search
    .split('&')
    .map((param: string): QueryParam => {
      const pair = param.replace('?', '').split('=');
      return {
        key: pair[0],
        value: pair[1]
      };
    });
  const requestedParam = params.filter(
    (param: QueryParam) => param.key === paramName
  );

  if (requestedParam && requestedParam.length > 0) {
    return requestedParam[0];
  }

  return undefined;
};

export const updateUrlQueryParam = ({
  paramName,
  paramValue,
}: {
  paramName: string,
  paramValue: string,
}) => {
  const url = new URL(window.location.href);
  url.searchParams.set(paramName, paramValue);
  window.history.pushState({}, '', url);
};
