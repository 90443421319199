/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useState } from 'react';

import { PublicProfileStateContext } from '../../../../context/public-profile-context';
import { logging } from '../../../../SharedLib/Util/LoggingUtil';
import { ComponentTypes, ICertificates } from '../../../userlib_new/utils/db-interfaces';
import { ICompDefault } from '../../../userlib_new/utils/types-interfaces';

import Certificate from './Certificate';
import styles from './Certificates.module.scss';

const DefaultData: ICertificates = {
  type: ComponentTypes.Certificates,
  visible: true,
  certificates: [],
};

interface ICertificatesProps extends ICompDefault {
	disabled?: boolean,
}

const Certificates = ({ className, id, disabled }: ICertificatesProps) => {
  const { certificatesData, setCertificatesData } = useContext(PublicProfileStateContext);
  const [data, setData] = useState<ICertificates>(DefaultData);

  useEffect(() => {
    if (certificatesData) {
      setData(certificatesData);
    }
  }, [certificatesData]);

  const onToggleVisibility = (event: React.ChangeEvent<Element>, id: string) => {
    if (certificatesData && certificatesData.certificates) {
      const itemIndex = certificatesData.certificates.findIndex(item => item.id === id);
      const myCertData = { ...certificatesData };

      myCertData.certificates[itemIndex].visible = !myCertData.certificates[itemIndex].visible;
      setCertificatesData(myCertData);

      logging.logDebug('SettingsPage -> PublicProfile -> Certificates -> onToggleVisibility -> [id, myCertData]', id, myCertData);
    }
  };

  return data.visible ? (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      {data.certificates.length > 0 &&
				data.certificates.map(item => {
				  return <Certificate key={`tag-${item.id}`} data={item} disabled={disabled} onToggleVisibility={onToggleVisibility} />;
				})}
      {data.certificates.length === 0 && <div className={styles.no_certificates}>No certificates</div>}
    </div>
  ) : null;
};

export default Certificates;
