import { getMetaPreparedFromException } from './ExceptionUtil';
import { CustomTypeMod, OperationResultType } from './InterfaceAndTypeUtil';
import { logging } from './LoggingUtil';

export const getCookieDomain = () => {
  const hostname = window.location.hostname;

  const parts = hostname.split('.');

  if (parts.length < 2) { // i.e. localhost
    return hostname;
  }

  return '.' + parts.slice(parts.length - 2, parts.length).join('.');
};

export const setCookie = (
  cookieName: string,
  value: string,
  expires?: Date,
  domain: string = 'w3schools.com',
  path: string = '/',
  sameSite: string = 'Strict',
  secure: boolean = true,
) => {
  // if (window.location.origin.includes(domain)) {
  let cookieToSet = `${cookieName}=${value}; domain=${domain}; path=${path}; SameSite=${sameSite}`;

  if (typeof expires !== 'undefined') {
    cookieToSet += `; expires=${expires}`;
  }

  if (secure) {
    cookieToSet += '; Secure';
  }

  logging.logDebug('setCookie -> cookieToSet: ', cookieToSet);

  document.cookie = cookieToSet;
  // } else {
  //   // Dont set domain in dev mode
  //   document.cookie = `${cookieName}=${value}`;
  // }
};

export interface ISetCookie {
  name: string,
  value: string,
  expires?: Date, // required by older browsers
  maxAge?: number, // in seconds
  domain?: string,
  path?: string,
  sameSite?: string,
  secure?: boolean,
}

export const setCookieV2 = ({
  name,
  value,
  maxAge,
  expires,
  domain = 'w3schools.com',
  path = '/',
  sameSite = 'Strict',
  secure = true,
}: ISetCookie) => {
  // if (window.location.origin.includes(domain)) {
  let cookieToSet = `${name}=${value}; domain=${domain}; path=${path}; SameSite=${sameSite}`;

  if (typeof expires !== 'undefined') {
    cookieToSet += `; expires=${expires}`;
  }

  if (typeof maxAge !== 'undefined') {
    cookieToSet += `; max-age=${maxAge}`;
  }

  if (secure) {
    cookieToSet += '; Secure';
  }

  logging.logDebug('setCookie -> cookieToSet: ', cookieToSet);

  document.cookie = cookieToSet;
  // } else {
  //   // Dont set domain in dev mode
  //   document.cookie = `${cookieName}=${value}`;
  // }
};

/**
 * Ensures that previous cookie is deleted
 */
export const resetCookie = (prs: ISetCookie) => {
  removeCookie(prs.name);

  setCookieV2(prs);
};

export const removeCookie = (
  cookieName: string,
  domain: string = 'w3schools.com',
  path: string = '/',
  sameSite: string = 'Strict',
  secure: boolean = true,
) => {
  let cookieToRemove = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=${path}; SameSite=${sameSite}`;

  if (secure) {
    cookieToRemove += '; Secure';
  }

  document.cookie = cookieToRemove;
};

export const removeCookiesByPostfix = (
  postfix: string,
  domain: string = 'w3schools.com',
  path: string = '/',
  sameSite: string = 'Strict',
  secure: boolean = true,
) => {
  document.cookie.replace(/;\s+/g, ';').split(';')
    .map((cookie: string) => cookie.split('='))
    .filter((cookiePair: string[]) => cookiePair[0].endsWith(postfix))
    .map((cookiePair: string[]) => cookiePair[0])
    .forEach((cookieName: string) => {
      let cookieToRemove = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=${path}; SameSite=${sameSite}`;

      if (secure) {
        cookieToRemove += '; Secure';
      }

      document.cookie = cookieToRemove;
    });
};

export const getCookie = (cookieName: string): string | undefined => {
  if (document === undefined || document.cookie === undefined) {
    return undefined;
  }

  const allCookies = document.cookie.replace(/;\s+/g, ';').split(';');

  const cookie = allCookies
    .find((cookie: string) => cookie.startsWith(cookieName + '='));

  if (cookie === undefined) {
    return undefined;
  }

  return cookie.split('=')[1];
};

const _b64RemovePadding = (str: string) => {
  return str.replace(/={1,2}$/, '');
};

type IGetCookieValueEncodedStrRes = CustomTypeMod<OperationResultType, {
  data: string,
}>;

export const getCookieValueEncodedStr = ({
  str,
  mode = 2, // handling better special chars
}: {
  str: string,
  mode?: 1 | 2, // 1 - btoa | 2 - encodeURIComponent + btoa + _b64RemovePadding
}) => {
  const output: IGetCookieValueEncodedStrRes = {
    error: {
      code: '1',
      description: 'Failed performing "getCookieValueEncodedStr"',
      meta: {
        rawStr: str,
      },
    },
    data: '',
  };

  try {
    if (mode === 2) {
      output.data = _b64RemovePadding(btoa(encodeURIComponent(str)));
    } else {
      output.data = btoa(str);
    }

    output.error = { code: '0' };
  } catch (exc) {
    output.error = getMetaPreparedFromException(exc);

    if (typeof output.error.meta === 'undefined') {
      output.error.meta = {};
    }

    output.error.meta.rawStr = str;

    logging.logError('CookieUtil -> getCookieValueEncodedStr -> str: ', str);
    logging.logError('CookieUtil -> getCookieValueEncodedStr -> exc: ');
    logging.logError(exc);
    logging.logError('CookieUtil -> getCookieValueEncodedStr -> output: ', output);
  }

  return output;
};

const _b64AddPadding = (str: string) => {
  return str + Array((4 - str.length % 4) % 4 + 1).join('=');
};

type IGetCookieValueDecodedStrRes = CustomTypeMod<OperationResultType, {
  data: string,
}>;

export const getCookieValueDecodedStr = ({
  str,
  mode = 2, // handling better special chars
}: {
  str: string,
  mode?: 1 | 2, // 1 - atob | 2 - _b64AddPadding + atob + decodeURIComponent
}) => {
  const output: IGetCookieValueDecodedStrRes = {
    error: {
      code: '1',
      description: 'Failed performing "getCookieValueDecodedStr"',
      meta: {
        encodedStr: str,
      },
    },
    data: '',
  };

  try {
    if (mode === 2) {
      output.data = decodeURIComponent(atob(_b64AddPadding(str)));
    } else {
      output.data = atob(str);
    }

    output.error = { code: '0' };
  } catch (exc) {
    output.error = getMetaPreparedFromException(exc);

    if (typeof output.error.meta === 'undefined') {
      output.error.meta = {};
    }

    output.error.meta.encodedStr = str;

    logging.logError('CookieUtil -> getCookieValueDecodedStr -> str: ', str);
    logging.logError('CookieUtil -> getCookieValueDecodedStr -> exc: ');
    logging.logError(exc);
    logging.logError('CookieUtil -> getCookieValueDecodedStr -> output: ', output);
  }

  return output;
};
