/* eslint-disable camelcase */
import React from 'react';
import styles from './LoginModal.module.scss';
import {
  AuthError,
  ErrorDisplay,
  isClassicUserToBeMigrated,
  LoginStatus,
  LoginStatusClassicUserToBeMigrated,
  ModalProps,
  PasswordValidationRule,
  UserOrigin
} from '../../Util/Interfaces';
import { authUtil } from '../../Util/AuthUtil';
import CloseIcon from '../Icons/CloseIcon/CloseIcon';
import { nameValid, PWD_RULES, validateEmail } from '../../Util/ValidationUtil';
import { messageForError } from '../../Util/ErrorUtil';
import LoginForm from '../Forms/LoginForm/LoginForm';
import SignupForm from '../Forms/SignupForm/SignupForm';
import Button from '../Buttons/Button/Button';
import ValidationHelper from '../ValidationHelper/ValidationHelper';
import Alert from '../Alert/Alert';
import SetNameForm from '../Forms/SetNameForm/SetNameForm';
import { getQueryParamNamed } from '../../Util/QueryParamsUtil';
import TermsFooter from '../TermsFooter/TermsFooter';
import { logging } from '../../Util/LoggingUtil';
import {
  // envIsProd,
  forgotPwdUrl
} from '../../Util/EnvironmentUtil';
import { ExceptionHandlerPropsType } from '../../Util/ExceptionUtil';
import { CaptchaTokenType } from '../Captcha/Captcha';
import ReCaptchaV2 from 'react-google-recaptcha';
import { RE_CAPTCHA_V2_SITE_KEY } from '../../Cfg/GoogleCfg';
import { getCognitoUserStatusByEmail } from '../../Util/CognitoUtil';
import { OptionalTypeMod, UserDataType } from '../../Util/InterfaceAndTypeUtil';
import CheckBox from '../CheckBox/CheckBox';
import { fetchProfile } from '../../Util/InternalProjects/ProfileUtil';
import UpdateEmailSettingsForm from '../Forms/UpdateEmailSettingsForm/UpdateEmailSettingsForm';
import ButtonV2 from '../Buttons/ButtonV2/ButtonV2';
import facebookIcon from './icons/FacebookIcon';
import googleIcon from './icons/GoogleIcon';
import githubIcon from './icons/GithubIcon';
import feideIcon from './icons/FeideIcon';
import { cacheRedirectUrl, tweakRedirectUrlOnSuccessfulSignup } from '../../Util/RedirectUtil';
import { CognitoHostedUIIdentityProvider } from '../../Util/AmplifyAuth';
import { SignUpOutput } from 'aws-amplify/auth';
// import { strReplaceAt } from '../../Util/StrUtil';

type InputStage =
  | 'login'
  | 'signup'
  | 'set_name'
  | 'pending_verification'
  | 'update_email_settings'

export interface LoginModalProps extends ModalProps {
  onLogin: () => Promise<void>,
  signup: boolean,
  inputStage?: InputStage,
  error?: ErrorDisplay,
  showTermsFooterInModal?: boolean,
}

interface LoginModalState {
  signup: boolean,
  inputStage: InputStage,
  pwdRules: PasswordValidationRule[],
  username: string,
  password: string,
  error?: ErrorDisplay,
  loading: boolean,
  // awaitingServerResponse: boolean,
  first_name: string,
  last_name: string,
  emailConsent: boolean,
  user_origin: UserOrigin,
  user_origin_signed: string,
  original_username: string,
  verification_resend_status: 'none' | 'loading' | 'error' | 'success',
  intervalId?: any,
  captcha_enabled: boolean,
  captcha_cfg: any,
  captcha_token: CaptchaTokenType,
  userData: UserDataType,
}

/**
 * @deprecated This component is deprecated and will be removed in the future.
 */
class LoginModal extends React.Component<LoginModalProps, LoginModalState> {
  // captcha_cross_ref: CaptchaCrossRefPropsType = {} as CaptchaCrossRefPropsType;
  re_captcha_v2_ref = React.createRef<ReCaptchaV2 | null>();

  constructor(props: LoginModalProps) {
    super(props);
    this.state = {
      signup: props.signup,
      inputStage: props.inputStage
        ? props.inputStage
        : props.signup
          ? 'signup'
          : 'login',
      pwdRules: PWD_RULES,
      username: '',
      password: '',
      loading: false,
      // awaitingServerResponse: false,
      first_name: '',
      last_name: '',
      emailConsent: true,
      error: props.error,
      user_origin: 'profile',
      user_origin_signed: '',
      original_username: '',
      verification_resend_status: 'none',
      captcha_enabled: true,
      captcha_cfg: {
        type: 'ReCaptchaV2',
        action: 'signUpUser'
      },
      captcha_token: {
        value: '',
        origin: 'ReCaptchaV2',
        action: 'signUpUser'
      },
      userData: {} as UserDataType
    };
  }

  componentDidMount() {
    // authUtil.initAuth();
    // if (!envIsProd()) {
    //   logging.clearLogsFromStorage('LoginModal');
    //   logging.intializeLogBufferForwarding('LoginModal');
    //   logging.setLogBufferHook((strToLog: string) => {
    //     const debug = false;
    //     if (debug) logging.logDebugAlt('LoginModal -> setLogBufferHook -> strToLog: ', strToLog, true);
    //     if (strToLog.indexOf('password') === -1) {
    //       return strToLog;
    //     }
    //     const getStrToLogPatched = (strToLog: string, regExpStr: string, replacementHelperStr: string): string => {
    //       if (debug) logging.logDebugAlt('LoginModal -> setLogBufferHook -> getStrToLogPatched -> regExpStr: ', regExpStr, true);
    //       if (debug) logging.logDebugAlt('LoginModal -> setLogBufferHook -> getStrToLogPatched -> replacementHelperStr: ', replacementHelperStr, true);
    //       const regExp = new RegExp(regExpStr, 'g');
    //       const matchBuffer = [];
    //       let match;
    //       while ((match = regExp.exec(strToLog)) !== null) {
    //         if (debug) logging.logDebugAlt('LoginModal -> setLogBufferHook -> getStrToLogPatched -> match: ', match, true);
    //         matchBuffer.push(match);
    //       }
    //       matchBuffer.forEach((match) => {
    //         const replacementStr = replacementHelperStr.replace('{hiddenPassword}', Array(match[1].length + 1).join('*'));
    //         if (debug) logging.logDebugAlt('LoginModal -> setLogBufferHook -> getStrToLogPatched -> match -> replacementStr: ', replacementStr, true);
    //         // logging.logDebugAlt(`Found ${match[0]} start=${match.index} end=${regExp.lastIndex}.`, '', true);
    //         strToLog = strReplaceAt(strToLog, match.index, replacementStr);
    //       });
    //       return strToLog;
    //     };
    //     // experimental patters:
    //     // (\\*)"password(\\*)":(\\*)"([^"]*)"[,|}]{1}
    //     // (\\*)"password(\\*)":(\\*)"([^,}]*)(",|"})
    //     strToLog = getStrToLogPatched(strToLog, '"password":"([^"]*)', '"password":"{hiddenPassword}"');
    //     // do not ask why
    //     if (strToLog.indexOf('\\\\') !== -1) {
    //       strToLog = getStrToLogPatched(strToLog, '\\\\\\"password\\\\\\":\\\\\\"([^\\\\\\"]*)', '\\\\"password\\\\":\\\\"{hiddenPassword}\\\\"');
    //     } else if (strToLog.indexOf('\\') !== -1) {
    //       strToLog = getStrToLogPatched(strToLog, '\\\\"password\\\\":\\\\"([^\\\\"]*)', '\\"password\\":\\"{hiddenPassword}\\"');
    //     }
    //     if (debug) logging.logDebugAlt('LoginModal -> setLogBufferHook -> strToLog (output): ', strToLog, true);
    //     return strToLog;
    //   });
    // }
  }

  federatedSignIn = (provider: CognitoHostedUIIdentityProvider | string) => {
    this.federatedLogin(provider);
  };

  federatedLogin = async (
    provider: CognitoHostedUIIdentityProvider | string
  ) => {
    // Store authorization_code, redirect_uri and state in session storage here. To be retrieved later in handleLoggedIn when directed back to profile after social login.
    sessionStorage.clear();
    const auth_code = getQueryParamNamed('authorization_code');
    const redirect_uri = getQueryParamNamed('redirect_uri');

    if (auth_code !== undefined) {
      sessionStorage.setItem('authorization_code', auth_code.value);

      const auth_state = getQueryParamNamed('state');

      if (auth_state !== undefined) {
        sessionStorage.setItem('state', auth_state.value);
      }
    }

    if (redirect_uri !== undefined) {
      sessionStorage.setItem('redirect_uri', redirect_uri.value);
    }

    cacheRedirectUrl();

    const federatedLoginRes = await authUtil.federatedLogin(provider);

    if (federatedLoginRes.status === 'loggedin') {
      tweakRedirectUrlOnSuccessfulSignup(); // TODO: (mid) find a way to distinguish between signup and login
    }
  };

  componentWillUnmount() {
    logging.logDebug('LoginModal -> componentWillUnmount');

    // if (!envIsProd()) {
    //   logging.finishLogBufferForwarding('LoginModal');
    // }

    if (this.state.intervalId !== undefined) {
      clearInterval(this.state.intervalId);
    }
  }

  updateState = (state: OptionalTypeMod<LoginModalState>) => {
    // const debugState = { ...state };

    // if (typeof debugState.password !== 'undefined') {
    //   debugState.password = Array(debugState.password.length + 1).join('*');
    // }

    // logging.logDebugAlt('LoginModal -> updateState -> state: ', state);

    this.setState(state as LoginModalState);
  };

  handleSubmit = (event: any) => {
    logging.logDebugAlt('LoginModal -> handleSubmit -> event: ', event);
    sessionStorage.clear();

    event.preventDefault();

    this.handleSubmitInner();
  };

  handleSubmitInner = async () => {
    logging.logDebugAlt(
      'LoginModal -> handleSubmit -> form: ',
      this.state.inputStage
    );

    // if (this.state.awaitingServerResponse) {
    if (this.state.loading) {
      logging.logDebugAlt(
        'LoginModal -> handleSubmit -> awaitingServerResponse'
      );
      return;
    }

    this.updateState({
      loading: true
    });

    if (this.state.inputStage === 'update_email_settings') {
      const userDataUpdateRes = await fetchProfile<UserDataType>({
        method: 'POST',
        url: '/user',
        data: {
          emailConsent: this.state.userData.emailConsent,
          emailSettingsUpToDate: true
        } as OptionalTypeMod<UserDataType>
      });

      logging.logDebugAlt(
        'LoginModal -> handleSubmit -> userDataUpdateRes: ',
        userDataUpdateRes
      );

      if (userDataUpdateRes.error.code !== '0') {
        this.updateState({
          error: {
            placement: 'general',
            msg: userDataUpdateRes.error.description as string,
            padding: '40px 0 0 0'
          }
        });
        return;
      }

      await this.props.onLogin();

      return;
    }

    if (this.state.username.length === 0) {
      this.updateState({
        error: {
          msg: 'Please enter an email',
          placement: 'email'
        },
        loading: false
      });
      return;
    }

    if (!validateEmail(this.state.username)) {
      this.updateState({
        error: {
          msg: 'Looks like you forgot something',
          placement: 'email'
        },
        loading: false
      });
      return;
    }

    if (this.state.signup) {
      if (!this.passwordIsValid()) {
        this.updateState({
          error: {
            msg: 'Please check password rules',
            placement: 'password'
          },
          loading: false
        });
        return;
      }
    }

    await this.submit();
  };

  setPendingVerification = async () => {
    this.updateState({
      inputStage: 'pending_verification',
      error: undefined
    });

    await this.getOriginalUsername();

    this.updateState({
      intervalId: setInterval(() => this.checkVerification(), 15000)
    });
  };

  getOriginalUsername = async () => {
    const resp = await getCognitoUserStatusByEmail(this.state.username);

    if (resp !== undefined) {
      this.updateState({
        original_username: resp.username
      });
    }
  };

  checkVerification = async () => {
    if (
      this.state.original_username === undefined ||
      this.state.original_username.length === 0
    ) {
      this.updateState({
        error: {
          placement: 'general',
          msg: 'Unknown error, please try again later.'
        }
      });
      return;
    }

    const loginUserRes = await authUtil.loginUser(
      this.state.username,
      this.state.password,
      this.state.original_username
    );
    logging.logDebug(
      'LoginModal -> checkVerification -> loginUserRes: ',
      loginUserRes
    );

    if ((loginUserRes as LoginStatus).status === 'failed') {
      const error = (loginUserRes as LoginStatus).error as AuthError;

      if (error.code === 'UserNotConfirmedException') {
        this.updateState({ loading: false });
        return;
      } else {
        logging.logError(
          'LoginModal -> checkVerification -> loginUserRes: ',
          loginUserRes
        );
        this.updateState({ loading: false });
        this.showError((loginUserRes as LoginStatus).error as AuthError);
      }
    } else {
      if (this.state.intervalId !== undefined) {
        clearInterval(this.state.intervalId);
      }

      tweakRedirectUrlOnSuccessfulSignup();

      // User logged in successful
      this.loginSuccessCloseModal();
    }
  };

  loginSuccessCloseModal = async () => {
    logging.logDebug('LoginModal -> loginSuccessCloseModal');

    const auth_code = getQueryParamNamed('authorization_code');

    if (auth_code !== undefined) {
      await authUtil.storeTokens(auth_code.value);
    }

    const userIsLoggedIn = await authUtil.userIsLoggedIn();

    logging.logDebugAlt(
      'LoginModal -> loginSuccessCloseModal -> userIsLoggedIn: ',
      userIsLoggedIn
    );

    const userDataFetchRes = await fetchProfile<UserDataType>({
      method: 'GET',
      url: '/user'
    });

    logging.logDebugAlt(
      'LoginModal -> loginSuccessCloseModal -> userDataFetchRes: ',
      userDataFetchRes
    );

    let userData: UserDataType | undefined = undefined;

    if (
      userDataFetchRes.error.code === '0' &&
      userDataFetchRes.data.username !== undefined
    ) {
      userData = userDataFetchRes.data;
    }
    // else { // TODO: (high) remove when testing is done
    //   userData = {
    //     emailConsent: false,
    //     emailSettingsUpToDate: false,
    //   } as UserDataType;
    // }

    if (userData !== undefined) {
      this.updateState({
        userData
      });

      if (!userData.emailSettingsUpToDate) {
        this.updateState({
          inputStage: 'update_email_settings',
          loading: false,
          error: undefined
        });

        return;
      }
    }

    await this.props.onLogin();
  };

  showError = (error: AuthError) => {
    this.updateState({
      error: messageForError(error)
    });
  };

  handleResendVerificationClick = (e: any) => {
    e.preventDefault();

    if (
      this.state.verification_resend_status !== 'none' &&
      this.state.verification_resend_status !== 'error'
    ) {
      return;
    }

    this.resendVerification();
  };

  resendVerification = async () => {
    this.updateState({
      verification_resend_status: 'loading'
    });

    if (
      this.state.original_username === undefined ||
      this.state.original_username.length === 0
    ) {
      this.updateState({
        error: {
          placement: 'general',
          msg: 'Unknown error, please try again later.'
        }
      });
      return;
    }

    const response = await authUtil.resendVerificationEmail(
      this.state.original_username
    );

    if (response) {
      this.updateState({
        verification_resend_status: 'success'
      });
    } else {
      this.updateState({
        verification_resend_status: 'error'
      });
    }

    return;
  };

  finalizeSignup = async () => {
    let reqRes: any;

    reqRes = await authUtil.registerUser(
      {
        email: this.state.username,
        password: this.state.password,
        firstName: this.state.first_name,
        lastName: this.state.last_name,
        emailConsent: this.state.emailConsent,
        user_origin: this.state.user_origin,
        user_origin_signed: this.state.user_origin_signed,
        captchaToken: this.state.captcha_token
      }
    );

    if ((reqRes as AuthError).code !== undefined) {
      this.updateState({
        loading: false
      });
      this.showError(reqRes as AuthError);
      this.updateState({ inputStage: 'signup' });
      return reqRes;
    }

    if ((reqRes as SignUpOutput).nextStep.signUpStep === 'DONE') {
      logging.logDebug('LoginModal -> finalizeSignup -> confirmed user');
      this.updateState({ inputStage: 'login' });
      const loginUserRes = await authUtil.loginUser(
        this.state.username,
        this.state.password
      );
      logging.logDebug(
        'LoginModal -> finalizeSignup -> loginUserRes: ',
        loginUserRes
      );

      if (reqRes.status === 'failed') {
        logging.logError(
          'LoginModal -> finalizeSignup -> loginUserRes: ',
          loginUserRes
        );
      }

      reqRes = loginUserRes;
    } else {
      this.updateState({
        loading: false
      });
      this.setPendingVerification();
    }

    return reqRes;
  };

  submit = async () => {
    let reqRes;

    if (this.state.inputStage === 'signup') {
      this.updateState({
        inputStage: 'set_name',
        loading: false,
        error: undefined
      });
      return;
    } else if (this.state.inputStage === 'pending_verification') {
      this.checkVerification();
      return;
    } else if (this.state.inputStage === 'set_name') {
      logging.logDebugAlt('LoginModal -> submit -> set_name -> input: ', {
        firstName: this.state.first_name,
        lastName: this.state.last_name
      });

      if (
        !nameValid(this.state.first_name) ||
        !nameValid(this.state.last_name)
      ) {
        this.updateState({
          loading: false
        });

        if (!nameValid(this.state.first_name)) {
          this.updateState({
            error: {
              placement: 'first_name',
              msg: 'Add a name that only contains letters - and no special characters.'
            }
          });
        } else if (!nameValid(this.state.last_name)) {
          this.updateState({
            error: {
              placement: 'last_name',
              msg: 'Add a name that only contains letters - and no special characters.'
            }
          });
        }

        return; // Errors handled in the respective components
      }

      const res_captcha_token = this.state.captcha_token;
      let res_captcha_exc;

      if (this.state.captcha_enabled) {
        if (
          typeof this.re_captcha_v2_ref.current === 'undefined' ||
          this.re_captcha_v2_ref.current === null
        ) {
          res_captcha_exc = {
            code: 'FSUCM',
            description: 'Failed setting up Captcha module'
          } as ExceptionHandlerPropsType;

          logging.logError(
            `${res_captcha_exc.description} [${res_captcha_exc.code}]`
          );

          this.updateState({
            loading: false,
            error: {
              msg: `Google ReCAPTCHA Error: ${res_captcha_exc.description} [${res_captcha_exc.code}]`,
              placement: 'general'
            }
          });

          return;
        }

        const re_captcha_v2_token = await this.re_captcha_v2_ref.current
          .executeAsync()
          .catch((err) => {
            logging.logError(
              'this.re_captcha_v2_ref -> executeAsync().catch -> err: ',
              err
            );

            res_captcha_exc = {
              code: 'FRRCV2T1',
              description: 'Failed retrieving ReCaptchaV2 token'
            } as ExceptionHandlerPropsType;

            this.updateState({
              loading: false,
              error: {
                msg: `Google ReCAPTCHA Error: ${res_captcha_exc.description} [${res_captcha_exc.code}]`,
                placement: 'general'
              }
            });

            return;
          });

        this.re_captcha_v2_ref.current.reset();

        logging.logDebug(
          'LoginModal -> submit -> res_captcha_token: ',
          res_captcha_token
        );
        logging.logDebug(
          'LoginModal -> submit -> res_captcha_exc: ',
          res_captcha_exc
        );

        if (!re_captcha_v2_token) {
          res_captcha_exc = {
            code: 'FRRCV2T2',
            description: 'Failed retrieving ReCaptchaV2 token'
          } as ExceptionHandlerPropsType;

          this.updateState({
            loading: false,
            error: {
              msg: `Google ReCAPTCHA Error: ${res_captcha_exc.description} [${res_captcha_exc.code}]`,
              placement: 'general'
            }
          });

          return;
        }

        res_captcha_token.value = re_captcha_v2_token;

        this.updateState({
          captcha_token: res_captcha_token
        });

        // if (typeof this.captcha_cross_ref.captcha === 'undefined') {
        //     re_captcha_exc = {
        //         code: 'FSUCM',
        //         description: 'Failed setting up Captcha module'
        //     } as ExceptionHandlerPropsType

        //     logging.logError(`${re_captcha_exc.description} [${re_captcha_exc.code}]`);

        //     this.updateState({
        //         loading: false,
        //         error: {
        //             msg: `Google ReCAPTCHA Error: ${re_captcha_exc.description} [${re_captcha_exc.code}]`,
        //             placement: 'general'
        //         }
        //     });

        //     return;
        // }

        // logging.logDebug(`LoginModal -> captcha_ref: `, this.captcha_cross_ref.captcha.getCaptchaRef());

        // [res_captcha_token, res_captcha_exc] = await this.captcha_cross_ref.captcha.fetchCaptchaToken({
        //     handle_errors_internally: false
        // });

        // logging.logDebug('LoginModal -> res_captcha_token: ', res_captcha_token);
        // logging.logDebug('LoginModal -> res_captcha_exc: ', res_captcha_exc);

        // if (res_captcha_exc) {
        //     this.updateState({
        //         loading: false,
        //         error: {
        //             msg: `Google ReCAPTCHA Error: ${res_captcha_exc.description} [${res_captcha_exc.code}]`,
        //             placement: 'general'
        //         }
        //     });

        //     return;
        // }

        // this.updateState({
        //     captcha_token: res_captcha_token
        // });
      }

      reqRes = await this.finalizeSignup();
      logging.logDebug(
        'LoginModal -> submit -> await this.finalizeSignup -> reqRes: ',
        reqRes
      );

      if ((reqRes as AuthError).code !== undefined) {
        return; // Errors handled in the respective components
      }
    } else {
      // login
      const loginUserRes = await authUtil.loginUser(
        this.state.username,
        this.state.password
      );
      logging.logDebug('LoginModal -> submit -> loginUserRes: ', loginUserRes);

      if (loginUserRes.status === 'failed') {
        logging.logError('LoginModal -> submit -> loginUserRes: ', loginUserRes);

        if (
          typeof loginUserRes.error !== 'undefined' &&
          loginUserRes.error.code.indexOf('CRITICAL_') === 0
        ) {
          this.updateState({ loading: false });
          this.showError(loginUserRes.error as AuthError);
          return;
        }
      }

      reqRes = loginUserRes;

      const is_classic_user = isClassicUserToBeMigrated(reqRes);
      logging.logDebug(
        'LoginModal -> submit -> is_classic_user: ',
        is_classic_user
      );

      if (reqRes !== undefined && is_classic_user) {
        logging.logDebug('LoginModal -> submit -> is classic user');
        const status = reqRes as LoginStatusClassicUserToBeMigrated;

        this.updateState({
          inputStage: 'set_name',
          loading: false,
          first_name: status.first_name,
          last_name: status.last_name,
          user_origin: status.email_verified ? 'classic_verified' : 'classic',
          user_origin_signed: status.signed,
          error: undefined
        });
        return;
      }
    }

    logging.logDebug('LoginModal -> submit -> reqRes: ', reqRes);

    if ((reqRes as LoginStatus).status === 'failed') {
      const resp = await getCognitoUserStatusByEmail(this.state.username);

      if (resp !== undefined && resp.status === 'UNCONFIRMED') {
        this.setPendingVerification();
        this.updateState({ loading: false });
        return;
      } else if (
        resp !== undefined &&
        resp.status === 'CONFIRMED' &&
        resp.username &&
        resp.username.length
      ) {
        (reqRes as LoginStatus).error = {
          code: 'CLASSIC_INCORRECT_PASSWORD',
          msg: 'Make sure you type your email and password correctly. Both your password and email are case-sensitive.'
        };
      }

      this.updateState({ loading: false });
      this.showError((reqRes as LoginStatus).error as AuthError);
    } else if (
      (reqRes as LoginStatus).status === 'classic_incorrect_password'
    ) {
      this.updateState({ loading: false });

      const error = (reqRes as LoginStatus).error;
      logging.logDebug(
        'LoginModal -> submit -> classic_incorrect_password -> error: ',
        error
      );

      if (error !== undefined) {
        this.showError(error);
      }
    } else if (this.state.inputStage === 'login') {
      this.loginSuccessCloseModal();
    }

    // if (!envIsProd()) {
    //   logging.flushLogBufferToStorage('LoginModal');
    // }
  };

  handleChangePassword = (value: string) => {
    const pwdRules = this.state.pwdRules.map((rule: PasswordValidationRule) => {
      rule.valid = rule.regexp.test(value);
      return rule;
    });

    this.updateState({
      pwdRules: pwdRules,
      password: value
    });
  };

  handleToggleSignup = (e: any) => {
    e.preventDefault();

    if (!this.state.signup && this.state.username.length > 0) {
      this.updateState({ username: this.state.username.toLowerCase() });
    }

    this.updateState({
      signup: !this.state.signup,
      inputStage: this.state.signup ? 'login' : 'signup',
      error: undefined
    });
  };

  passwordIsValid = () => {
    return (
      this.state.pwdRules.filter(
        (rule: PasswordValidationRule) => rule.valid !== true
      ).length === 0
    );
  };

  inputForm = () => {
    switch (this.state.inputStage) {
      case 'login':
        return (
          <LoginForm
            onSubmit={this.handleSubmit}
            onToggleSignup={this.handleToggleSignup}
            error={this.state.error}
            username={this.state.username}
            password={this.state.password}
            setUsername={(value: string) =>
              this.updateState({ username: value })
            }
            setPassword={this.handleChangePassword}
          />
        );

      case 'signup':
        return (
          <SignupForm
            onSubmit={this.handleSubmit}
            onToggleSignup={this.handleToggleSignup}
            error={this.state.error}
            username={this.state.username}
            password={this.state.password}
            validCheck={this.passwordIsValid()}
            setUsername={(value: string) =>
              this.updateState({ username: value })
            }
            setPassword={this.handleChangePassword}
          />
        );
      case 'set_name':
        return (
          <SetNameForm
            onSubmit={this.handleSubmit}
            error={this.state.error}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            setFirstName={(value: string) =>
              this.updateState({ first_name: value })
            }
            setLastName={(value: string) =>
              this.updateState({ last_name: value })
            }
          />
        );

      case 'pending_verification':
        switch (this.state.verification_resend_status) {
          case 'none':
            return (
              <div className={styles.pending_inner}>
                <Alert
                  type='success'
                  msg={`We've sent an email to ${this.state.username} with instructions.`}
                />
                <span className={styles.instructions}>
                  If the email doesn’t show up soon, check your spam folder. We
                  sent it from login@w3schools.com.
                </span>
              </div>
            );
          case 'loading':
            return (
              <div className={styles.pending_inner}>
                <Alert type='success' msg={'Loading...'} />
              </div>
            );
          case 'error':
            return <div className={styles.pending_inner}></div>;
          case 'success':
            return (
              <div className={styles.pending_inner}>
                <Alert
                  type='success'
                  msg={`We've resent an email to ${this.state.username} with instructions. Please check your inbox and spam folder.`}
                />
                <span className={styles.instructions}>
                  We sent it from login@w3schools.com.
                </span>
              </div>
            );
        }

        break;

      case 'update_email_settings':
        return (
          <UpdateEmailSettingsForm
            onSubmit={this.handleSubmit}
            userData={this.state.userData}
            onUserDataChange={(userData) => {
              this.updateState({
                userData
              });
            }}
          />
        );
    }
  };

  header = () => {
    switch (this.state.inputStage) {
      case 'login':
        return decodeURIComponent(window.location.href).indexOf(
          '/get-access/'
        ) > 0
          ? 'Log in (or Sign up) to get access to your course or exam'
          : 'Log in';

      case 'signup':
        return decodeURIComponent(window.location.href).indexOf(
          '/get-access/'
        ) > 0
          ? 'Sign up to get access to your course or exam'
          : 'Sign up';

      case 'set_name':
        return 'What is your name?';

      case 'pending_verification':
        return 'Please verify email';

      case 'update_email_settings':
        return 'Please update your email settings';
    }
  };

  ctaText = () => {
    switch (this.state.inputStage) {
      case 'login':
        return 'Log in';

      case 'signup':
        return 'Sign up for free';

      case 'set_name':
        return 'Continue';

      case 'pending_verification':
        return 'I have clicked the link';

      case 'update_email_settings':
        return 'Update email settings';
    }
  };

  wrapperClasses = () => {
    const classes = [styles.modal];

    if (this.props.show) {
      classes.push(styles.show);
    }

    if (this.props.full_page) {
      classes.push(styles.full_page);
    }

    return classes.join(' ');
  };

  render() {
    return (
      <div>
        <div className={this.wrapperClasses()}>
          <div
            style={
              this.state.inputStage === 'pending_verification'
                ? { backgroundImage: 'url(\'Airplane.png\')' }
                : {}
            }
            className={
              styles.modal_inner +
              ' ' +
              (this.state.inputStage === 'pending_verification'
                ? styles.pending_verification
                : '')
            }
          >
            <div>
              <h1
                className={
                  decodeURIComponent(window.location.href).indexOf(
                    '/get-access/'
                  ) > 0
                    ? styles.get_access_header
                    : ''
                }
              >
                {this.header()}
              </h1>

              {this.state.inputStage === 'set_name' && (
                <p className={styles.instructions}>
                  This is also the name that will be displayed on your course
                  certificates.
                </p>
              )}
            </div>

            <CloseIcon
              size={30}
              onClick={() => {
                logging.logDebug('LoginModal -> close button clicked');

                if (this.state.inputStage === 'pending_verification') {
                  this.props.onLogin();
                } else {
                  this.props.close();
                }
              }}
              className={
                styles.close_button +
                ' ' +
                (this.props.full_page ? styles.full_page : '')
              }
            />

            <div>{this.inputForm()}</div>

            {this.state.inputStage === 'signup' && (
              <>
                <ValidationHelper
                  rules={this.state.pwdRules}
                  passMatch={true}
                  error={this.state.error?.placement === 'password'}
                />

                <CheckBox
                  id='emailConsentCheckBox'
                  name='emailConsentCheckBox'
                  padding='15px 0 10px 0'
                  checked={true}
                  onChange={(checked) => {
                    this.updateState({
                      emailConsent: checked
                    });
                  }}
                >
                  Email me with news and updates
                </CheckBox>
              </>
            )}

            {this.state.error && this.state.error.placement === 'general' && (
              <Alert
                type={
                  this.state.error.variant ? this.state.error.variant : 'danger'
                }
                msg={this.state.error.msg}
              />
            )}

            {!this.state.signup && !this.state.error && (
              <div style={{ height: '20px' }}></div>
            )}

            <div className={styles.cta_bottom_box}>
              <div className={styles.cta_bottom_box_button_login}>
                <Button
                  variant='primary'
                  fullwidth={true}
                  onClick={this.handleSubmit}
                  loading={this.state.loading}
                  text={this.ctaText()}
                />
              </div>

              {this.state.inputStage !== 'pending_verification' &&
                this.state.inputStage !== 'update_email_settings' && (
                <div>
                  <div className={styles.cta_bottom_box_divider}>
                    <span className={styles.cta_bottom_box_divider_text}>
                        or
                    </span>
                  </div>

                  <div>
                    <ButtonV2
                      onClick={() => {
                        this.federatedSignIn(
                          CognitoHostedUIIdentityProvider.Facebook
                        );
                      }}
                      text={'Continue with Facebook'}
                      className={styles.cta_bottom_box_button_facebook_login}
                      icon={facebookIcon()}
                      disabled={this.state.loading}
                    />
                  </div>
                  <ButtonV2
                    onClick={() => {
                      this.federatedSignIn(
                        CognitoHostedUIIdentityProvider.Google
                      );
                    }}
                    text={'Continue with Google'}
                    className={styles.cta_bottom_box_button_google_login}
                    textClassName={
                      styles.cta_bottom_box_button_google_login_text
                    }
                    icon={googleIcon()}
                    disabled={this.state.loading}
                  />
                  <ButtonV2
                    onClick={() => {
                      this.federatedSignIn('Github');
                    }}
                    text={'Continue with GitHub'}
                    className={styles.cta_bottom_box_button_github_login}
                    textClassName={
                      styles.cta_bottom_box_button_github_login_text
                    }
                    icon={githubIcon()}
                    disabled={this.state.loading}
                  />
                  <ButtonV2
                    onClick={() => {
                      this.federatedSignIn('Feide');
                    }}
                    text={'Continue with Feide'}
                    className={styles.cta_bottom_box_button_feide_login}
                    textClassName={
                      styles.cta_bottom_box_button_github_login_text
                    }
                    icon={feideIcon({ className: styles.feide_icon })}
                    disabled={this.state.loading}
                  />
                </div>
              )}

              {this.state.inputStage === 'signup' ? (
                <div className={styles.terms}>
                  By clicking the “Sign up for free” button or using any of the
                  social login options, you are creating an account, and agree
                  to W3Schools'{' '}
                  <a
                    href='https://www.w3schools.com/about/about_copyright.asp'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://www.w3schools.com/about/about_privacy.asp'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy Policy
                  </a>
                </div>
              ) : (
                ''
              )}

              {/* <Captcha
                                cross_ref={this.captcha_cross_ref}
                                onErrorCallback={(exc: ExceptionHandlerPropsType) => {
                                    this.updateState({
                                        loading: false,
                                        error: {
                                            msg: `Google ReCAPTCHA Error: ${exc.description} [${exc.code}]`,
                                            placement: 'general'
                                        }
                                    });
                                }}
                                {...this.state.captcha_cfg as CaptchaPropsType}
                            /> */}

              {this.state.inputStage === 'login' ? (
                <div className={styles.forgot_pwd_wrapper}>
                  <a href={forgotPwdUrl()}>Forgot password?</a>
                </div>
              ) : (
                ''
              )}
              {this.state.inputStage === 'pending_verification' ? (
                <div className={styles.forgot_pwd_wrapper}>
                  <button
                    className={
                      this.state.verification_resend_status !== 'none' &&
                      this.state.verification_resend_status !== 'error'
                        ? styles.disabled
                        : ''
                    }
                    onClick={this.handleResendVerificationClick}
                  >
                    Resend verification email
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <ReCaptchaV2
          ref={(re_captcha_v2_ref_passed) => {
            // logging.logDebug('re_captcha_v2_ref_passed: ', re_captcha_v2_ref_passed)
            // @ts-ignore
            this.re_captcha_v2_ref.current = re_captcha_v2_ref_passed;
          }}
          sitekey={RE_CAPTCHA_V2_SITE_KEY}
          // size='normal'
          size='invisible'
        />

        {this.props.showTermsFooterInModal && this.props.show ? (
          <TermsFooter className={styles.footer} />
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default LoginModal;
