import React from 'react';

export const BackgroundSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="272 89 720 1081.29" {...props}>
    <mask id="mask0_209_1292" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="272" y="89" width="720" height="1082">
      <rect x="272" y="89" width="720" height="1081.29" fill="#E36867"></rect>
    </mask>
    <g mask="url(#mask0_209_1292)">
      <rect x="272" y="89" width="720" height="1089.64" fill="url(#paint0_linear_209_1292)"></rect>
      <ellipse
        cx="365.505"
        cy="327.294"
        rx="365.505"
        ry="327.294"
        transform="matrix(-1 0 0 1 1194.57 -148.666)"
        fill="url(#paint1_linear_209_1292)"
      ></ellipse>
      <ellipse
        cx="463.486"
        cy="416.923"
        rx="463.486"
        ry="416.923"
        transform="matrix(-1 0 0 1 580.257 791.928)"
        fill="url(#paint2_linear_209_1292)"
      ></ellipse>
    </g>
    <g opacity="0.8" filter="url(#filter0_f_209_1292)">
      <ellipse
        cx="236.5"
        cy="109.5"
        rx="236.5"
        ry="109.5"
        transform="matrix(-1 0 0 1 858 385)"
        fill="#FFF4A3"
      ></ellipse>
    </g>
    <defs>
      <filter id="filter0_f_209_1292" x="0" y="0" width="1243" height="989" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
        <feGaussianBlur stdDeviation="192.5" result="effect1_foregroundBlur_209_1292"></feGaussianBlur>
      </filter>
      <linearGradient id="paint0_linear_209_1292" x1="632" y1="89" x2="632" y2="1178.64" gradientUnits="userSpaceOnUse">
        <stop stopColor="#04AA6D"></stop>
        <stop offset="1" stopColor="#583A90"></stop>
      </linearGradient>
      <linearGradient id="paint1_linear_209_1292" x1="835.638" y1="740.21" x2="553.586" y2="360.948" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4"></stop>
        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient id="paint2_linear_209_1292" x1="-147.037" y1="-150.236" x2="407.236" y2="385.289" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4"></stop>
        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
);
