import React from 'react';

export interface IFeideIconProps {
  width?: string,
  height?: string,
  viewBox?: string,
}

const FeideIconColored: React.FC<IFeideIconProps> = ({ width = '17', height = '20', viewBox = '0 0 17 20' }) => {
  return (
    <svg
      id="Layer_1"
      role="img"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 38"
      width="24"
      height="24"
      className="LoginModal_feide_icon__Yu0E+"
    >
      <defs></defs>
      <title>Horisontal_Feide</title>
      <rect className="cls-1" x="27.99" y="20.34" width="4.24" height="10.18" fill='#1f4698'></rect>
      <polygon
        className="cls-1"
        points="18.23 23.31 13.99 23.31 13.99 34.76 4.24 34.76 4.24 20.34 0 20.34 0 35.61 0.02 35.61 0.02 39 32.23 39 32.23 34.76 18.23 34.76 18.23 23.31"
        fill='#1f4698'
      ></polygon>
      <circle className="cls-1" cx="16.11" cy="16.53" r="2.54" fill='#1f4698'></circle>
      <path fill='#1f4698' className="cls-1" d="M16.11,4.24A11.87,11.87,0,0,1,28,16.1h4.25A16.11,16.11,0,0,0,0,16.1H4.25A11.87,11.87,0,0,1,16.11,4.24Z"></path>
    </svg>
  );
};

export default FeideIconColored;
