// frontend version ( backend stuff commented out )
/* eslint-disable max-classes-per-file, no-param-reassign, no-console */
import { ErrorType } from './InterfaceAndTypeUtil';

export type ExceptionType = 'INTERNAL' | 'BAD_REQUEST' | 'UNAUTHORIZED' | 'NOT_FOUND' | 'FORBIDDEN'; // the most common used

// export const EXCEPTION_TYPE_TO_STATUS_KEY_MAP: { [key in ExceptionType]: keyof typeof StatusCodes } = {
//   'INTERNAL': 'INTERNAL_SERVER_ERROR',
//   'BAD_REQUEST': 'BAD_REQUEST',
//   'UNAUTHORIZED': 'UNAUTHORIZED',
//   'NOT_FOUND': 'NOT_FOUND',
//   'FORBIDDEN': 'FORBIDDEN',
// };

export type ExceptionPropsType = {
  code: string,
  description: string,
  type?: ExceptionType,
  // responseType?: keyof typeof StatusCodes, // backend prop
  meta?: any | null,
  // publicMeta?: any | null, // backend prop
};

export type ExceptionHandlerPropsType = ExceptionPropsType; // DEPRECATED! Use ExceptionPropsType instead

export class Exception extends Error {
  public code: ExceptionPropsType['code'];

  public description: ExceptionPropsType['description'];

  public type?: ExceptionPropsType['type'];

  // public responseType?: ExceptionPropsType['responseType'];

  public meta?: ExceptionPropsType['meta'];

  // public publicMeta?: ExceptionPropsType['publicMeta'];

  constructor({
    code,
    description,
    type = 'INTERNAL',
    // responseType,
    meta = null,
    // publicMeta = null,
  }: ExceptionPropsType) {
    console.error({
      code,
      description,
      type,
      meta,
      // publicMeta,
    });

    super(description);

    this.name = 'Exception';
    this.code = code;
    this.description = description;
    this.type = type;
    this.meta = meta;

    // this.publicMeta = publicMeta;

    // if (typeof responseType === 'undefined') {
    //   if (typeof EXCEPTION_TYPE_TO_STATUS_KEY_MAP[type] !== 'undefined') {
    //     this.responseType = EXCEPTION_TYPE_TO_STATUS_KEY_MAP[type];
    //   } else {
    //     this.responseType = 'INTERNAL_SERVER_ERROR';
    //   }
    // } else {
    //   this.responseType = responseType;
    // }
  }
}

export class InternalException extends Exception {
  constructor(prs: ExceptionPropsType) {
    prs.type = 'INTERNAL';

    super(prs);
  }
}

export class BadRequestException extends Exception {
  constructor(prs: ExceptionPropsType) {
    prs.type = 'BAD_REQUEST';

    super(prs);
  }
}

export class UnauthorizedException extends Exception {
  constructor(prs: ExceptionPropsType) {
    prs.type = 'UNAUTHORIZED';

    super(prs);
  }
}

export class NotFoundException extends Exception {
  constructor(prs: ExceptionPropsType) {
    prs.type = 'NOT_FOUND';

    super(prs);
  }
}

export class ForbiddenException extends Exception {
  constructor(prs: ExceptionPropsType) {
    prs.type = 'FORBIDDEN';

    super(prs);
  }
}

export const getMetaPreparedFromException = (exc: unknown | any): ErrorType => {
  const output: ErrorType = {
    code: '1',
    description: 'Internal server error',
  };

  if (exc instanceof Exception) {
    output.code = exc.code;
    output.description = exc.description;

    if (typeof exc.meta !== 'undefined') {
      output.meta = exc.meta;
    }
  } else if (exc instanceof Error) {
    output.description = exc.message;
  } else if (typeof exc === 'string') {
    output.description = exc;
  }

  return output;
};
