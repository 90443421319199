import { CognitoAuthSignInDetails } from '@aws-amplify/auth/dist/esm/providers/cognito/types';
import { FetchUserAttributesOutput, JWT, confirmResetPassword, fetchAuthSession, fetchUserAttributes, getCurrentUser, resendSignUpCode, resetPassword, signIn, signInWithRedirect, signOut, signUp, updateUserAttributes } from 'aws-amplify/auth';

export enum CognitoHostedUIIdentityProvider {
    Google = 'Google',
    Facebook = 'Facebook',
    Amazon = 'Amazon',
    Apple = 'Apple',
}

export interface FederatedSignInOptions {
  provider: CognitoHostedUIIdentityProvider,
  customState?: string,
}

export interface FederatedSignInOptionsCustom {
  customProvider: string,
  customState?: string,
}

export interface AuthSession {
  tokens?: {
    idToken?: JWT,
    accessToken: JWT,
  },
  credentials?: {
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken?: string,
    expiration?: Date,
  },
  identityId?: string,
  userSub?: string,
}

export interface SignUpParams {
  username: string,
  password: string,
  attributes?: object,
  validationData?: {
    [key: string]: string,
  },
  clientMetadata?: { [key: string]: string },
  autoSignIn?: {
    enabled: boolean,
    clientMetaData?: { [key: string]: string },
    validationData?: {
      [key: string]: string,
    },
  },
}

export interface CognitoUser {
    attributes: FetchUserAttributesOutput,
    signInDetails?: CognitoAuthSignInDetails | undefined,
    username: string,
    userId: string,
}

export class CognitoUserSession {
  private authSession: AuthSession | undefined;

  constructor(authSession: AuthSession | undefined) {
    this.authSession = authSession;
  }

  public isValid() {
    return this.authSession !== undefined;
  }

  public getAccessToken() {
    return {
      getJwtToken: () => this.authSession?.tokens?.accessToken.toString(),
    };
  }

  public getIdToken() {
    return {
      getJwtToken: () => this.authSession?.tokens?.idToken?.toString(),
    };
  }
}

class AmplifyAuth {
  public async currentSession() {
    const session = await fetchAuthSession();
    return new CognitoUserSession(session);
  }

  public async signIn(username: string, password: string) {
    return await signIn({ username, password });
  }

  public async federatedSignIn(options: FederatedSignInOptions | FederatedSignInOptionsCustom) {
    if ('customProvider' in options) {
      return signInWithRedirect({
        provider: { custom: options.customProvider },
      });
    } else {
      return signInWithRedirect({
        provider: options.provider,
      });
    }
  }

  public async currentAuthenticatedUser() {
    const user = await getCurrentUser();
    const attributes = await fetchUserAttributes();

    return {
      ...user,
      attributes,
    };
  }

  public async signUp({ username, password, attributes = {}, autoSignIn }: SignUpParams) {
    return signUp({
      username,
      password,
      options: {
        userAttributes: attributes,
        autoSignIn: autoSignIn?.enabled ?? false,
      },
    });
  }

  public async signOut() {
    await signOut();
  }

  public async forgotPassword(username: string) {
    return resetPassword({ username });
  }

  public async forgotPasswordSubmit(username: string, code: string, password: string) {
    return await confirmResetPassword({
      username,
      confirmationCode: code,
      newPassword: password,
    });
  }

  public async resendSignUp(username: string) {
    return await resendSignUpCode({ username });
  }

  public async updateUserAttributes(_user: CognitoUser, attributes: object) {
    await updateUserAttributes({ userAttributes: attributes });
    return 'SUCCESS';
  }

  public async currentUserInfo() {
    const {
      username,
      userId: id
    } = await getCurrentUser();

    const attributes =  await fetchUserAttributes();

    return {
      id,
      username,
      attributes
    };
  }
}

export const amplifyAuth = new AmplifyAuth();


