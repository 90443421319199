/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import styles from './TopNavBar.module.scss';
import { getTopNavBarUrl } from '../../Util/EnvironmentUtil';
import { logDebug, logError, logging } from '../../Util/LoggingUtil';
import { Helmet } from 'react-helmet';
import { StrKeyToAnyMapType } from '../../Util/InterfaceAndTypeUtil';
import { getLocalCurrentUtus } from '../../Util/TimeUtil';
import { camelToKebab } from '../../Util/DataUtil';
import parse from 'html-react-parser';
import { IGlobalEntityLookupControlRef, globalEntityOnLoadCallback } from '../../Util/VanillaJsUtil';


export interface IFeatureFlags {
  noUpsell: boolean,
}

export interface ITopNavBarProps {
  userSessionProcessed: boolean, // verified / refreshed
  userSessionMeta?: {
    loggedIn: boolean,
    subscriptionPlan: string,
    featureFlags: IFeatureFlags,
  },
  onIframeMessageCallback?: (message: any) => void,
  className?: string,
  displayBanner?: boolean,
  hide?: boolean,
  zIndex?: number | null,
  lite?: boolean, // without sub nav
}

interface IFloatingComponentConfig {
  action: 'UPSERT' | 'UPDATE' | 'REMOVE',
  id: string,
  tagName: string,
  class?: string | null,
  styles?: StrKeyToAnyMapType,
  innerHtml?: string,
  mountFuncName?: string,
  unmountFuncName?: string,
}

interface IFloatingComponentsRef {
  [id: string]: {
    config: IFloatingComponentConfig,
    html: string,
    mounted: boolean,
  },
}

interface IVanillaIframeWrapper {
  _callFunc: (funcName: string) => void,
  _iframeContentWindow: HTMLIFrameElement['contentWindow'],
}

const TopNavBar = ({
  userSessionProcessed,
  userSessionMeta,
  onIframeMessageCallback,
  className,
  displayBanner = false,
  hide = false,
  zIndex = null,
  lite = false,
}: ITopNavBarProps) => {
  const placeholderElmRef = useRef<HTMLDivElement>(null);
  const iframeElmRef = useRef<HTMLIFrameElement>(null);
  const vanillaIframeWrapperLookupControlRef = useRef<IGlobalEntityLookupControlRef>({ lazyHalt: false });
  const vanillaIframeWrapperRef = useRef<IVanillaIframeWrapper>(null);
  const floatingComponentsRef = useRef<IFloatingComponentsRef>({});
  const [floatingComponentsUpdated, setFloatingComponentsUpdated] = useState(0);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [topNavBarUrl, setTopNavBarUrl] = useState('');

  const updateCssVar = ({
    name,
    value,
  }: {
    name: string,
    value: string,
  }) => {
    try {
      logging.logDebug('TopNavBar -> VanillaIframeWrapper -> updateCssVar: ', {
        name,
        value,
      });

      const rootElm = document.querySelector(':root');

      if (rootElm) {
        // @ts-ignore
        rootElm.style.setProperty(name, value);
      }
    } catch (exc: any) {
      logging.logDebug('TopNavBar -> VanillaIframeWrapper -> updateCssVar -> exc: ', { exc });
    }
  };

  const awaitVanillaIframeWrapper = (
    callback?: (vanillaIframeWrapper: IVanillaIframeWrapper) => void,
  ) => {
    if (vanillaIframeWrapperRef.current !== null) {
      logging.logDebug('TopNavBar -> VanillaIframeWrapper -> await -> ready');

      if (callback) {
        callback(vanillaIframeWrapperRef.current);
      }

      return;
    }

    globalEntityOnLoadCallback<IVanillaIframeWrapper>({
      globalEntityName: 'TopNavBarIframeWrapper',
      onLoadCallback: (res) => {
        logging.logDebug('TopNavBar -> VanillaIframeWrapper -> await -> globalEntityOnLoadCallback -> res, lookupControlRef: ', {
          res,
          lookupControlRef: vanillaIframeWrapperLookupControlRef.current
        });

        if (res.error.code === '0') {
          // @ts-ignore
          vanillaIframeWrapperRef.current = res.data;

          if (callback) {
            callback(vanillaIframeWrapperRef.current);
          }
        } else {
          logging.logError('TopNavBar -> VanillaIframeWrapper -> await -> globalEntityOnLoadCallback -> res: ', res);
        }
      },
      lookupControlRef: vanillaIframeWrapperLookupControlRef.current,
    });
  };

  useEffect(() => {
    logDebug('TopNavBar -> init');

    setTopNavBarUrl(getTopNavBarUrl());

    // awaitVanillaIframeWrapper();

    return () => {
      if (vanillaIframeWrapperLookupControlRef.current._halt !== undefined) {
        vanillaIframeWrapperLookupControlRef.current._halt();
      } else { // fallback
        vanillaIframeWrapperLookupControlRef.current.lazyHalt = true;
      }
    };
  }, []);

  useEffect(() => {
    logDebug('TopNavBar -> floatingComponentsUpdated');

    for (const floatingComponentId in floatingComponentsRef.current) {
      const floatingComponentRef = floatingComponentsRef.current[floatingComponentId];

      // console.log(`${floatingComponentId}: `, floatingComponentRef);

      if (
        !floatingComponentRef.mounted
        && typeof floatingComponentRef.config.mountFuncName !== 'undefined'
      ) {
        const floatingComponentMountFuncName = floatingComponentRef.config.mountFuncName;

        awaitVanillaIframeWrapper((vanillaIframeWrapper) => {
          if (!floatingComponentsRef.current[floatingComponentId].mounted) { // if still not mounted by this point
            vanillaIframeWrapper._callFunc(floatingComponentMountFuncName);
            floatingComponentsRef.current[floatingComponentId].mounted = true;
          }
        });
      }
    }
  }, [floatingComponentsUpdated]);

  const _getIframeFloatingComponentHtmlPatched = (html: string | undefined) => {
    if (typeof html === 'undefined' || !html) {
      return '';
    }

    if (html.indexOf('TopNavBar.') !== -1) {
      const regexp = /TopNavBar\.([^(]*)\(/gm;

      html = html.replace(regexp, 'TopNavBarIframeWrapper.iframeCall(\'$1\', ');
    }

    return html;
  };

  const handleIframeMessage = (message: any, topNavBarUrl: string) => {
    logDebug('TopNavBar -> handleIframeMessage -> message: ', message);
    logDebug('TopNavBar -> handleIframeMessage -> topNavBarUrl: ', topNavBarUrl);

    if (message.origin !== topNavBarUrl) {
      return;
    }

    if (!iframeElmRef.current) {
      logError('Top nav bar iframe ref is not set (1)');
      return;
    }

    if (
      message.data !== undefined
      && message.data.action !== undefined
    ) {
      if (message.data.action === 'LAYOUT' || message.data.action === 'INIT') {
        let iframeStdHeight;

        if (lite) {
          iframeStdHeight = String(message.data.mainNavHeightPx) + 'px';
        } else {
          iframeStdHeight = String(message.data.mainNavHeightPx + message.data.secondaryNavHeightPx) + 'px';
        }

        updateCssVar({
          name: '--w3s-top-nav-bar-height',
          value: iframeStdHeight,
        });

        if (placeholderElmRef.current) {
          placeholderElmRef.current.style.height = iframeStdHeight;
          placeholderElmRef.current.style.minHeight = iframeStdHeight;
        }

        if (message.data.expanded) {
          iframeElmRef.current.style.height = message.data.fullHeight;
        } else {
          iframeElmRef.current.style.height = iframeStdHeight;
        }
      }

      if (message.data.action === 'REDIRECT') {
        window.location.href = message.data.url;
      }

      if (message.data.action === 'PROXY_FLOATING_COMPONENT') {
        const floatingComponent: IFloatingComponentConfig = message.data.floatingComponent;

        if (floatingComponent.action === 'REMOVE') {
          if (typeof floatingComponentsRef.current[floatingComponent.id] !== 'undefined') {
            if (typeof floatingComponent.unmountFuncName !== 'undefined') {
              const floatingComponentUnmountFuncName = floatingComponent.unmountFuncName;

              awaitVanillaIframeWrapper((vanillaIframeWrapper) => {
                vanillaIframeWrapper._callFunc(floatingComponentUnmountFuncName);
              });
            }

            delete floatingComponentsRef.current[floatingComponent.id];

            setFloatingComponentsUpdated(getLocalCurrentUtus());
          }
        } else if (floatingComponent.action === 'UPSERT' || floatingComponent.action === 'UPDATE') {
          if (typeof floatingComponentsRef.current[floatingComponent.id] !== 'undefined') {
            if (typeof floatingComponent.unmountFuncName !== 'undefined') {
              const floatingComponentUnmountFuncName = floatingComponent.unmountFuncName;

              awaitVanillaIframeWrapper((vanillaIframeWrapper) => {
                vanillaIframeWrapper._callFunc(floatingComponentUnmountFuncName);
              });
            }
          }

          let floatingComponentHtml = `<${floatingComponent.tagName} id="${floatingComponent.id}" class="${floatingComponent.class || ''}" style="`;

          if (typeof floatingComponent.styles !== 'undefined') {
            for (const [stylePropName, stylePropValue] of Object.entries(floatingComponent.styles)) {
              floatingComponentHtml += `${camelToKebab(stylePropName)}: ${stylePropValue}; `;
            }
          }

          if (zIndex !== null) {
            floatingComponentHtml += `z-index: ${zIndex + 1}; `;
          }

          floatingComponentHtml += `">${_getIframeFloatingComponentHtmlPatched(floatingComponent.innerHtml)}</${floatingComponent.tagName}>`;

          logging.logDebug('TopNavBarIframeWrapper -> floatingComponentHtml: ', floatingComponentHtml);

          floatingComponentsRef.current[floatingComponent.id] = {
            config: floatingComponent,
            html: floatingComponentHtml,
            mounted: false,
          };

          setFloatingComponentsUpdated(getLocalCurrentUtus());
        }
      }

      if (onIframeMessageCallback) {
        onIframeMessageCallback(message);
      }
    }
  };

  useEffect(() => {
    if (!topNavBarUrl) {
      return;
    }

    window.addEventListener('message', (message: any) => {
      handleIframeMessage(message, topNavBarUrl);
    });
  }, [topNavBarUrl]);

  const handleIframeLoad = (event: any) => {
    const iframeElm = event.target as HTMLIFrameElement;
    const iframeWindow = iframeElm.contentWindow;

    if (iframeElm && iframeWindow) {
      // @ts-ignore
      iframeElmRef.current = iframeElm;
      setIframeLoaded(true);
    } else {
      logError('Top nav bar iframe loaded with some issue');
    }
  };

  useEffect(() => {
    if (!iframeLoaded || !userSessionProcessed) {
      return;
    }

    if (!iframeElmRef.current || !iframeElmRef.current.contentWindow) {
      logError('Top nav bar iframe ref is not set (2)');
      return;
    }

    awaitVanillaIframeWrapper((vanillaIframeWrapper) => {
      vanillaIframeWrapper._iframeContentWindow = iframeElmRef.current!.contentWindow;
    });

    iframeElmRef.current.contentWindow.postMessage(
      {
        type: 'CONFIG',
        value: {
          env: 'network',
          loggedIn: userSessionMeta!.loggedIn,
          subscriptionPlan: userSessionMeta!.subscriptionPlan,
          featureFlags: userSessionMeta!.featureFlags,
          location: {
            href: window.location.href,
            hostname: window.location.hostname,
            pathname: window.location.pathname,
          },
        }
      },
      '*'
    );
  }, [iframeLoaded, userSessionProcessed]);

  return (
    <>
      {displayBanner && (
        <div
          className={`${styles['top-nav-bar-banner']} ${hide ? styles['force-hide'] : ''}`}
        >
          <div
            className={`${styles['top-nav-bar-banner-inner']} ${styles['black']}`}
          >
            <div className={styles['top-nav-bar-banner-badge-new']}>NEW</div>
            <div className={styles['top-nav-bar-banner-text-new']}>
              Build dynamic websites with backend support in Spaces
              <a
                className={styles['top-nav-bar-banner-link-new']}
                href={'https://www.w3schools.com/spaces/'}
                rel='noopener noreferrer'
                target='_blank'
                onClick={() => {
                  /* sendGoogleAnalyticsEvent({
                  name: `Clicked on "Learn more" for Dynamic spaces in banner`,
                  category: 'Topbar after login'
                }) */
                }}
                aria-label='Learn more'
              >
                {'  '}
                Learn more
              </a>
            </div>
          </div>
        </div>
      )}

      {topNavBarUrl &&
        <Helmet>
          <link
            rel="stylesheet"
            href={`${topNavBarUrl}/floating-components.css`}
          ></link>

          <script
            src={`${topNavBarUrl}/main.js`}
            async
          ></script>
        </Helmet>
      }

      <div
        id='top-nav-bar-placeholder'
        className={`${styles['top-nav-bar-placeholder']} ${hide ? styles['force-hide'] : ''} ${displayBanner ? styles['display-banner'] : ''}`}
        ref={placeholderElmRef}
      ></div>

      {topNavBarUrl &&
        <iframe
          id='top-nav-bar-iframe'
          title='Navigation'
          className={`${styles['top-nav-bar']}${hide ? ` ${styles['force-hide']}` : ''}${displayBanner ? ` ${styles['display-banner']}` : ''}${className ? ` ${className}` : ''}`}
          src={topNavBarUrl}
          ref={iframeElmRef}
          onLoad={handleIframeLoad}
        />
      }

      {topNavBarUrl && floatingComponentsUpdated > 0 &&
        Object.keys(floatingComponentsRef.current).map((key) => {
          return (
            <div key={key}>{parse(floatingComponentsRef.current[key].html)}</div>
          );
        })
      }
    </>
  );
};

export default TopNavBar;
