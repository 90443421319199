import React, { createRef, useEffect, useMemo, useState } from 'react';
import { uuidv4 } from '../../../../SharedLib/Util/MixedUtil';

import styles from './Toggle.module.scss';

export interface IToggle {
	id?: string,
	/**
	 * Position of label. Possible values 'left' or 'right'
	 *
	 * @default 'right'
	 */
	labelPosition?: 'left' | 'right',
	/**
	 * Label of the toggle button
	 */
	label?: string,
	/**
	 * Checked value to be displayed
	 * @default false
	 */
	checked?: boolean,
	/**
	 * True if disabled
	 * @default false
	 */
	disabled?: boolean,
	/**
	 * String of classes to be added to the component
	 */
	className?: string,
	/**
	 * Function called when value change
	 */
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
	/* onMouseEnter?: (event: React.MouseEvent) => void,
	onMouseLeave?: (event: React.MouseEvent) => void, */
}

const Toggle = (props: IToggle) => {
  const inputRef = createRef<HTMLInputElement>();

  const name = useMemo(() => {
    return 'switchButton-' + uuidv4();
  }, []);

  const [checked, setChecked] = useState<boolean>(false);
  useEffect(() => {
    setChecked(props.checked ?? false);
  }, [props.checked]);

  const [label, setLabel] = useState<string>('');
  useEffect(() => {
    setLabel(props.label ?? '');
  }, [props.label]);

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    setDisabled(props.disabled ?? false);
  }, [props.disabled]);

  const valueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    props.onChange && props.onChange(event);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
      inputRef.current?.click();
    }
  };

  return (
    <div id={props.id} className={`${styles.toggle}${props.className ? ` ${props.className}` : ''}`}>
      {props.labelPosition === 'left' && (
        <label className={`${styles.switch_label}${disabled ? ' ' + styles.disabled : ''}`} htmlFor={name}>
          {label || ''}
        </label>
      )}
      <div className={`${styles.check} ${styles.switch}${disabled ? ' disabled' : ''}`}>
        <input
          ref={inputRef}
          type="checkbox"
          id={name}
          className={`${styles.check_input}`}
          onChange={valueChanged}
          checked={checked}
          disabled={disabled}
          onKeyPress={onKeyPress}
        />
        <label className={`${styles.switch_label}${disabled ? ' ' + styles.disabled : ''}`} htmlFor={name}>
          {!props.labelPosition || props.labelPosition === 'right' ? label : ''}
        </label>
      </div>
    </div>
  );
};

export default Toggle;
