import React from 'react'
import BackArrow from '../../Icons/BackArrow/BackArrow'
import ExternalLink from '../../Icons/ExternalLink/ExternalLink'
import styles from './Button.module.scss'
import Spinner from '../../Spinner/Spinner';

type ButtonVariant =
    "primary" |
    "secondary" |
    "tertiary" |
    "destructive" |
    "noframe" |
    "pink" |
    "dark"

type ButtonPrefix = "back"

type ButtonSuffix = "extlink"

type ButtonElemType = "a" | "button"

const defaultProps = {
    variant: "primary",
    as: "button"
}

export interface ButtonProps {
    variant: ButtonVariant
    text?: string
    as: ButtonElemType
    onClick?: (e: any) => void
    href?: string
    loading?: boolean
    disabled?: boolean
    prefix?: ButtonPrefix
    suffix?: ButtonSuffix
    fullwidth?: boolean
    target?: string
    children?: React.ReactNode
}

function Button({
    variant,
    text,
    as,
    onClick,
    href,
    loading,
    disabled,
    prefix,
    suffix,
    fullwidth,
    target,
    children }: ButtonProps) {

    function classes() {
        let classes = styles.button

        classes += ' ' + styles[variant]

        if (fullwidth) {
            classes += ' ' + styles.fullwidth
        }

        if (disabled) {
            classes += ' ' + styles.disabled
        }

        return classes
    }

    function prefixElement() {
        if (prefix === undefined) {
            return
        }
        switch (prefix) {
            case "back":
                return <BackArrow
                    size={16}
                    className={styles.prefix_icon} />
            default:
                return
        }
    }

    function suffixElement() {
        if (suffix === undefined) {
            return
        }
        switch (suffix) {
            case "extlink":
                return <ExternalLink
                    className={styles.suffix_icon}
                    size={16} />
            default:
                return
        }
    }

    function getSpinnerVariant() {
        switch (variant) {
            case "noframe":
            case "pink":
                return Spinner.utils.variants.Dark;
            case "tertiary":
            case "secondary":
                return Spinner.utils.variants.Tertiary
            default:
                return Spinner.utils.variants.Secondary;
        }
    }

    if (as === "a") {
        return (
            <a
                href={href}
                target={target}
                className={classes()}>

                {prefixElement()}

                <span>
                    {text}
                </span>

                {children}

                {suffixElement()}

                {loading ?
                    <div className={styles.spin_wrapper}>
                        <Spinner variant={getSpinnerVariant()} />
                    </div>
                    :
                    ``
                }

            </a>
        )
    }

    return (
        <button
            className={classes()}
            disabled={disabled}
            onClick={onClick}>

            {prefixElement()}

            <span>
                {text}
            </span>

            {children}

            {suffixElement()}

            {loading ?
                <div className={styles.spin_wrapper}>
                    <Spinner variant={getSpinnerVariant()} />
                </div>
                :
                ``
            }
        </button>
    )
}

Button.defaultProps = defaultProps

export default Button
