import React, { useEffect } from 'react';
import styles from './NewLoginPage.module.scss';
import LoginPanel from './LoginPanel/LoginPanel';
import { logging } from '../../SharedLib';
import { appIsProfile } from '../../SharedLib/Util/EnvironmentUtil';
import { cacheRedirectUrl, tweakRedirectUrlOnSuccessfulSignup } from '../../SharedLib/Util/RedirectUtil';
import { getQueryParamNamed } from '../../SharedLib/Util/QueryParamsUtil';
import { authUtil } from '../../SharedLib/Util/AuthUtil';
import { handleLoggedIn } from '../../utils/handle-logged-in';
import LeftSide from '../LeftSide/LeftSide';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { handleLegacyClassroomInvitationUrl } from '../ClassroomInvitation/ClassroomInvitationUtil';
export enum LoginState {
  START = 'start',
  SIGNUP_VERIFY_EMAIL_PENDING = 'signupVerifyEmailPending',
}

const NewLoginPage: React.FC = () => {
  const [state, setState] = React.useState<LoginState>(LoginState.START);
  const [loading, setLoading] = React.useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    logging.logDebug('LoginPage-> init');

    if (appIsProfile()) {
      cacheRedirectUrl();

      handleLegacyClassroomInvitationUrl();
    }

    async function check() {
      await checkUrlParams();
      await checkLoginStatus();
    }

    check();
  }, []);

  async function checkUrlParams() {
    const expiredFlag = getQueryParamNamed('expired');

    if (expiredFlag && expiredFlag.value === 'true') {
      await authUtil.logOut({
        context: 'TBMC1',
        reason: {
          topBarMenuHadExpiredUrlQueryParamFlag: expiredFlag,
        },
      });
    }
  }

  const checkLoginStatus = async () => {
    setLoading(true);
    const userIsLoggedIn = await authUtil.userIsLoggedIn({
      context: 'loginPage -> checkLoginStatus',
    });

    if (userIsLoggedIn) {
      if (appIsProfile()) {
        handleLoggedIn(history);
      }
    } else {
      // Skolon/Feide login
      const isSkolonLogin = getQueryParamNamed('skolon_login');
      const isFeideLogin = getQueryParamNamed('feide_login');
      if (isSkolonLogin?.value === 'true' || isFeideLogin?.value === 'true') {
        sessionStorage.clear();
        const redirect_uri = getQueryParamNamed('redirect_uri');
        if (redirect_uri !== undefined) {
          sessionStorage.setItem('redirect_uri', redirect_uri.value);
        }

        cacheRedirectUrl();

        const federatedLoginRes = await authUtil.federatedLogin(isSkolonLogin ? 'Skolon' : 'Feide');

        if (federatedLoginRes.status === 'loggedin') {
          tweakRedirectUrlOnSuccessfulSignup(); // TODO: (mid) find a way to distinguish between signup and login
        }

        return;
      }

      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles.loader_container}>
          <Spinner animation="border" role="status" variant={'white'} className={'loader-spinner'}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.left_panel}>
            <LeftSide />
          </div>
          {state === LoginState.START && <LoginPanel className={styles.right_panel} />}
        </div>
      )}
    </>
  );
};

export default NewLoginPage;
