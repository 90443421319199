import React, { useEffect, useState } from 'react';
import { tagsSort } from '../../../../../context/public-profile-context';
import { logging } from '../../../../../SharedLib/Util/LoggingUtil';
import InputItem from '../../../../userlib_new/InputItem';
import { TagType } from '../../../../userlib_new/utils/db-interfaces';
import { ICompDefault } from '../../../../userlib_new/utils/types-interfaces';
import Tag from '../Tag';

import styles from './Searchbar.module.scss';

interface ISearchbar extends ICompDefault {
	disabled?: boolean,
	allItems: TagType[],
	onItemClicked: (item: TagType) => void,
}

const Searchbar = ({ id = 'searchbar-wrapper', className, disabled = false, allItems, onItemClicked }: ISearchbar) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [itemsToDisplay, setItemsToDisplay] = useState<TagType[]>([]);
  const [displaySelections, setDisplaySelections] = useState(false);
  const [allTags, setAllTags] = useState<TagType[]>([]);

  const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setDisplaySelections(true);
  };

  const onClickTag = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, item: TagType) => {
    logging.logDebug('Interests -> Searchbar -> onClickTag -> [event, item]', event, item);

    onItemClicked(item);
    const itemInput = document.getElementById(`${id}-serchbarInput-input`);
    itemInput?.focus();
  };

  const tagGotFocus = () => {
    setDisplaySelections(true);

    // set focus on next or previous item
    /* const index = itemsToDisplay.findIndex(displayedItem => displayedItem.tagId === item.tagId);
    if (index > -1 && itemsToDisplay.length > 1 && itemsToDisplay.length > index + 1) {
      document.getElementById('tag-' + itemsToDisplay[index + 1].tagId)?.focus();
    } else if (index > -1 && itemsToDisplay.length > 1 && itemsToDisplay.length === index + 1) {
      document.getElementById('tag-' + itemsToDisplay[index - 1].tagId)?.focus();
    } */
  };

  useEffect(() => {
    const doEnter = () => {
      if (!disabled) {
        setDisplaySelections(true);
      }
    };

    const doLeave = () => {
      setDisplaySelections(false);
    };

    const item = document.getElementById(id);
    const itemInput = document.getElementById(`${id}-serchbarInput-input`);

    itemInput?.addEventListener('focusin', doEnter);
    item?.addEventListener('focusout', doLeave);

    return () => {
      itemInput?.removeEventListener('focusin', doEnter);
      item?.removeEventListener('focusout', doLeave);
    };
  }, [disabled]);

  useEffect(() => {
    setAllTags(allItems.sort(tagsSort));
  }, [allItems]);

  useEffect(() => {
    if (searchValue && searchValue?.trim().length > 0) {
      const myItems = allTags.filter(item => item.tagName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1).sort(tagsSort);
      setItemsToDisplay(myItems);
    } else {
      setItemsToDisplay(allTags.sort(tagsSort));
    }
  }, [searchValue, allTags]);

  const onTransitionEnd = () => {
    // setSearchValue('');
  };

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <InputItem
        id={`${id}-serchbarInput`}
        onValueChange={onSearchChanged}
        className={styles.searchbar}
        disabled={disabled}
        displaySearchIcon
        placeholder="Add..."
        value={searchValue}
      />
      <div className={`${styles.tag_list_wrapper}${displaySelections ? ` ${styles.display}` : ''}`} onTransitionEnd={onTransitionEnd}>
        {itemsToDisplay.map(item => (
          <Tag
            tabIndex={displaySelections ? 0 : -1}
            data-tag={item.tagId}
            key={`tag-${item.tagId}`}
            id={`tag-${item.tagId}`}
            onTagFocus={tagGotFocus}
            tag={item}
            disabled={disabled}
            onClick={onClickTag}
            className={`${styles.tag}${disabled ? ` ${styles.disabled}` : ''}`}
          />
        ))}
        {itemsToDisplay.length === 0 && <div>No result</div>}
      </div>
    </div>
  );
};

export default Searchbar;
