import React from 'react';

interface IW3Logo {
	className?: string,
	size?: 'small' | 'large',
	fill?: 'default' | 'white',
}

const W3Logo = ({ className, size = 'large', fill = 'default' }: IW3Logo) => (
  <svg
    className={className || ''}
    width={size === 'large' ? '38' : '24'}
    height={size === 'large' ? '35' : '21'}
    viewBox="0 0 38 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.3987 7.36607C31.3987 7.36607 32.3362 8.37054 33.6458 8.37054C34.68 8.37054 35.4315 7.74554 35.4315 6.88988C35.4315 5.84077 34.4716 5.35714 33.3109 5.35714H32.6264L32.2172 4.43452L34.0327 2.28423C34.4196 1.82292 34.7469 1.53274 34.7469 1.53274C34.7469 1.53274 34.4568 1.54762 33.869 1.54762H30.9151V0L36.9642 0V1.13095L34.5386 3.93601C35.9077 4.12946 37.2469 5.10417 37.2469 6.82292C37.2469 8.51191 35.9672 10.0744 33.7574 10.0744C31.6517 10.0744 30.4836 8.75 30.4836 8.75L31.3987 7.36607Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path
      d="M19.5981 9.45695L27.5892 23.6831L31.5326 16.6519L23.988 3.22183H15.2082L11.5698 9.70249L7.93146 3.22183H0.0371094L11.5252 23.6831L11.5698 23.6087L11.6145 23.6831L19.5981 9.45695Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path
      d="M0.118958 33.3257H1.28711C1.28711 33.668 1.58473 33.9954 2.11301 33.9954C2.60408 33.9954 2.93146 33.7498 2.93146 33.4224C2.93146 33.1397 2.71568 33.0132 2.34366 32.9314L1.67402 32.7602C0.543065 32.4626 0.252886 31.8599 0.252886 31.2201C0.252886 30.4388 1.0267 29.7245 2.12045 29.7245C3.0133 29.7245 4.02521 30.1784 4.01033 31.2945H2.82729C2.82729 30.9522 2.51479 30.7364 2.15765 30.7364C1.77074 30.7364 1.50289 30.9596 1.50289 31.2721C1.50289 31.5326 1.74842 31.6814 2.0386 31.7483L2.84217 31.9641C3.97312 32.2543 4.17402 32.9611 4.17402 33.4224C4.17402 34.4418 3.15467 34.9998 2.13533 34.9998C1.14574 34.9998 0.141279 34.3971 0.118958 33.3257Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path
      d="M5.20837 32.3584C5.20837 30.7662 6.48814 29.7245 7.93903 29.7245C8.80956 29.7245 9.51641 30.1114 9.97772 30.6918L9.09974 31.3912C8.83933 31.0787 8.41522 30.8852 7.96135 30.8852C7.09081 30.8852 6.46581 31.5102 6.46581 32.3584C6.46581 33.1992 7.09081 33.8391 7.96135 33.8391C8.41522 33.8391 8.83933 33.6457 9.09974 33.3332L9.97772 34.0326C9.51641 34.6129 8.80956 34.9998 7.93903 34.9998C6.48814 34.9998 5.20837 33.9507 5.20837 32.3584Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path
      d="M15.9895 32.0535V34.866H14.7395V32.1428C14.7395 31.3392 14.2559 30.9077 13.6979 30.9077C13.1249 30.9077 12.4181 31.2425 12.4181 32.2172V34.8735H11.1681V27.1205H12.4255V30.6101C12.6711 29.9999 13.4821 29.7172 13.9806 29.7172C15.2752 29.7246 15.9895 30.5952 15.9895 32.0535Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path
      d="M17.299 32.3584C17.299 30.7662 18.5341 29.7245 19.9626 29.7245C21.3912 29.7245 22.6412 30.7662 22.6412 32.3584C22.6412 33.9507 21.3912 34.9998 19.9626 34.9998C18.5341 34.9998 17.299 33.9507 17.299 32.3584ZM21.3838 32.3584C21.3838 31.4954 20.7364 30.8852 19.9626 30.8852C19.1888 30.8852 18.5564 31.4954 18.5564 32.3584C18.5564 33.2364 19.1888 33.8391 19.9626 33.8391C20.7364 33.8391 21.3838 33.2364 21.3838 32.3584Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path
      d="M23.7053 32.3584C23.7053 30.7662 24.9404 29.7245 26.369 29.7245C27.7975 29.7245 29.0475 30.7662 29.0475 32.3584C29.0475 33.9507 27.7975 34.9998 26.369 34.9998C24.9478 34.9998 23.7053 33.9507 23.7053 32.3584ZM27.7901 32.3584C27.7901 31.4954 27.1428 30.8852 26.369 30.8852C25.5951 30.8852 24.9627 31.4954 24.9627 32.3584C24.9627 33.2364 25.5951 33.8391 26.369 33.8391C27.1502 33.8391 27.7901 33.2364 27.7901 32.3584Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <path d="M30.4314 34.8735V27.1205H31.6814V34.8735H30.4314Z" fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'} />
    <path
      d="M33.0431 33.3257H34.2112C34.2112 33.668 34.5089 33.9954 35.0371 33.9954C35.5282 33.9954 35.8556 33.7498 35.8556 33.4224C35.8556 33.1397 35.6398 33.0132 35.2678 32.9314L34.5982 32.7602C33.4672 32.4626 33.177 31.8599 33.177 31.2201C33.177 30.4388 33.9508 29.7245 35.0446 29.7245C35.9374 29.7245 36.9493 30.1784 36.9345 31.2945H35.744C35.744 30.9522 35.4315 30.7364 35.0743 30.7364C34.6874 30.7364 34.4196 30.9596 34.4196 31.2721C34.4196 31.5326 34.6651 31.6814 34.9553 31.7483L35.7589 31.9641C36.8898 32.2543 37.0907 32.9611 37.0907 33.4224C37.0907 34.4418 36.0714 34.9998 35.052 34.9998C34.0624 34.9998 33.0654 34.3971 33.0431 33.3257Z"
      fill={fill === 'default' ? '#04AA6D' : '#FFFFFF'}
    />
    <defs />
  </svg>
);

W3Logo.defaultProps = {
  className: undefined,
  size: 'large',
  fill: 'default',
};

export default W3Logo;
