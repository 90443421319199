import { AuthError } from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchProfile } from '../../../SharedLib';
import { authUtil } from '../../../SharedLib/Util/AuthUtil';
import { logDebug, logError, logging } from '../../../SharedLib/Util/LoggingUtil';
import { handleLoggedIn } from '../../../utils/handle-logged-in';
import Alert from '../../Alert/Alert';
import CustomButton from '../../CustomButton/CustomButton';
import Confetti from '../../Icon/Confetti';
import { LoginView } from '../LoginPanel/LoginPanel';
import styles from './SignUpVerifyEmail.module.scss';
import { getClassroomInvitationBrowserStorageMeta } from '../../ClassroomInvitation/ClassroomInvitationUtil';

const AUTO_SIGN_IN_EXCEPTION = 'AutoSignInException';

export interface ISignupVerifyEmailProps {
  className?: string,
  email: string,
  username: string,
  setView: React.Dispatch<React.SetStateAction<LoginView>>,
}

const SignupVerifyEmail: React.FC<ISignupVerifyEmailProps> = ({ className, email, username, setView }) => {
  const [loading, setLoading] = useState(false);
  const [resendingVerificationEmail, setResendingVerificationEmail] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState(
    `We've sent an email to ${email} with instructions.`
  );
  const [verifySuccess, setVerifySuccess] = useState(true);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    autoLogin();
  }, []);

  async function autoLogin() {
    setLoading(true);

    try {
      const user = await authUtil.autoLogin(() => { setSuccess(true); });

      logging.logDebug('autoLogin -> user: ', user);

      await handleLoggedIn(history);
    }
    catch (error) {
      if (error instanceof AuthError) {
        if (error.name === AUTO_SIGN_IN_EXCEPTION) {
          logging.logError('AutoSignInException', error);
          setError(true);

          // TODO: (high) proper handle error.message "The account was not confirmed on time."
        }
      }
    }

    finally {
      setLoading(false);
    }
  }

  async function submitConfirmationCode(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);

    try {
      const req_res = await fetchProfile<any>({
        method: 'POST',
        url: '/verify-email',
        data: {
          username: username,
          confirmation_code: confirmationCode,
          origin: 'sign_up'
        }
      });

      logging.logDebug('VerifyEmail -> req_res: ', req_res);

      setSuccess(true);
    }

    catch (error) {
      logging.logError('VerifyEmail -> error: ', error);
    }

    finally {
      setLoading(false);
    }
  }

  async function resendVerificationEmail(e: React.FormEvent) {
    e.preventDefault();
    try {
      setResendingVerificationEmail(true);
      const req_res = await authUtil.resendVerificationEmail(username);
      logging.logDebug('resendVerificationEmail -> req_res: ', req_res);
      if (req_res === true) {
        setVerificationMessage(`We've resent an email to ${email} with instructions.`);
        setVerifySuccess(true);
      } else {
        setVerificationMessage('We couldn\'t resend the email, please contact the support.');
        setVerifySuccess(false);
      }
    } catch (error) {
      logging.logError('resendVerificationEmail -> error: ', error);
      setVerificationMessage('We couldn\'t resend the email, please contact the support.');
      setVerifySuccess(false);
    } finally {
      setResendingVerificationEmail(false);
    }
  }

  const confirmationCodeInput = () => {
    return (
      <form className={styles.confirmation_code_form} onSubmit={submitConfirmationCode}>
        <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationCode(e.target.value)}></input>
      </form>
    );
  };

  const verifyEmailDone = () => {
    return (
      <div className={styles.verification_done}>
        <div className={styles.verification_done_header}>
          Done!
        </div>
        <div className={styles.confetti_icon}>
          <Confetti />
        </div>

        <Alert
          message={`Success your email has been verified. ${error ? 'Click the button below to return to login' : 'You will be logged in shortly.'} `}
          type="success" />
        <CustomButton variant='primary' className={styles.reset_password_button} onClick={() => setView(LoginView.LOGIN)}>Return to login</CustomButton>
      </div>
    );
  };

  const handleReturnToLogin = () => {
    const classroomInvitation = getClassroomInvitationBrowserStorageMeta();
    logDebug('SignUpVerifyEmail -> handleReturnToLogin -> classroomInvitation: ', classroomInvitation);

    if (classroomInvitation !== null) {
      try {
        const redirectUrlObj = new URL(classroomInvitation.url);

        // redirect to path with url query params
        history.push(redirectUrlObj.pathname + redirectUrlObj.search);

        return;
      } catch (error) {
        logError('SignUpVerifyEmail -> handleReturnToLogin -> error: ', error);
      }
    }

    setView(LoginView.LOGIN);
  };

  const verifyEmail = () => {
    return (
      <div className={styles.verify_email}>
        <div className={styles.header_text}>
          Please verify your email
        </div>

        <Alert message={verificationMessage} type={verifySuccess ? 'success' : 'danger'} />

        <div className={styles.text}>
          If the email doesn't show up soon, check your spam folder. We sent it from login@w3schools.com.
        </div>

        <CustomButton loading={resendingVerificationEmail} variant='primary' className={styles.resendBtn} onClick={resendVerificationEmail}>Resend Link</CustomButton>

        <button onClick={handleReturnToLogin}> Return to login </button>
      </div>
    );
  };

  return (
    <div className={styles.verify_email + (className ? ' ' + className : '')}>

      {
        success ?
          verifyEmailDone() : verifyEmail()
      }


    </div>);
};

export default SignupVerifyEmail;
