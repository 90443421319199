import * as logging from '../Util/LoggingUtil';
import { AuthError, ErrorDisplay } from './Interfaces';

export const messageForError = (error: AuthError): ErrorDisplay => {
  if (error.code.indexOf('CRITICAL_') === 0) {
    return {
      msg: error.msg,
      placement: 'general',
      variant: 'warning'
    };
  }

  switch (error.code) {
    case 'error_code_invalid_credentials':
      return {
        msg: 'Sorry, looks like that’s the wrong email or password.',
        placement: 'general'
      };

    case 'error_code_invalid_request':
      return {
        msg: 'Another user with this email already exists. Try to log in instead.',
        placement: 'email'
      };

    case 'UserNotFoundException':
      return {
        msg: 'A user with this email does not exist',
        placement: 'general'
      };

    case 'UserLambdaValidationException':
      return messageForLambdaValidationError(error);

    case 'NotAuthorizedException':
      return messageForNotAuthorizedError(error);

    case 'Request failed with status code 500':
      return {
        msg: 'Sorry! Looks like the link is not working. Please try again or contact our support.',
        placement: 'general',
        variant: 'warning'
      };

    case 'Network Error':
    case 'Error':
      return {
        msg: 'Sorry, we are having problems reaching our services. Please check your internet connection, firewall or proxy settings and try again.',
        placement: 'general',
        variant: 'warning'
      };
    case 'InvalidParameterException':
      return {
        msg:
          error.msg && error.msg.indexOf('CUSTOM_AUTH') >= 0
            ? 'Please enter your password.'
            : error.msg,
        placement: 'general',
        variant: 'warning'
      };
    case 'ExpiredCodeException':
      return {
        msg: 'Sorry, looks like the reset password link has expired.',
        placement: 'general',
        variant: 'warning'
      };
    default:
      logging.logDebug('no message for: ', error.code);
      return {
        msg: error.msg,
        placement: 'general'
      };
  }
};

const messageForLambdaValidationError = (error: AuthError): ErrorDisplay => {
  switch (error.msg) {
    case 'PreSignUp failed with error Looks like you already have a user. Did you try logging in?.':
      return {
        msg: 'Looks like you already have a user. Did you try logging in?',
        placement: 'email',
        variant: 'warning'
      };

    case 'PreSignUp failed with error ReCAPTCHA rejected this request.':
      return {
        msg: 'Google reCAPTCHA verification failed',
        placement: 'general',
        variant: 'warning'
      };

    default:
      logging.logDebug('no message for: ', error.msg);
      return {
        msg: 'Sign up failed.',
        placement: 'general',
        variant: 'warning'
      };
  }
};

const messageForNotAuthorizedError = (error: AuthError): ErrorDisplay => {
  switch (error.msg) {
    case 'Incorrect username or password.':
      return {
        msg: 'Sorry, looks like that’s the wrong email or password.',
        placement: 'general',
        variant: 'danger'
      };

    default:
      logging.logDebug('no message for: ', error.msg);
      return {
        msg: 'Not authorized.',
        placement: 'general',
        variant: 'danger'
      };
  }
};
