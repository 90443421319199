import React from 'react';
import Footer from '../Footer/Footer';
import styles from './AfterLoginLayout.module.scss';

export interface IAfterLoginLayoutProps {
  children: React.ReactNode,
}

const AfterLoginLayout: React.FC<IAfterLoginLayoutProps> = ({ children }) => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.main}>{children}</div>

      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AfterLoginLayout;
