import React, { useEffect, useState } from 'react';
import { HeartFill } from 'react-bootstrap-icons';
import { TagType } from '../../../../userlib_new/utils/db-interfaces';
import { ICompDefault } from '../../../../userlib_new/utils/types-interfaces';

import styles from './Tag.module.scss';

export interface ITag extends TagType {
	selected?: boolean,
}

interface ITagComp extends ICompDefault {
	tag: ITag,
	disabled?: boolean,
	onClick?: (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, item: ITag) => void,
	onClickRemove?: (event: React.MouseEvent<SVGElement> | React.KeyboardEvent<SVGElement>, item: ITag) => void,
	onTagFocus?: () => void,
}

const Tag = ({ id, tabIndex = -1, tag, className, disabled = false, onClick, onClickRemove, onTagFocus }: ITagComp) => {
  const [displayHeart, setDisplayHeart] = useState(false);

  useEffect(() => {
    if (tag.tagName === 'W3Schools!') setDisplayHeart(true);
  }, [tag.tagName]);

  const onFocus = () => {
    onTagFocus && onTagFocus();
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
      onClick && onClick(event, tag);
    }
  };

  const onRemoveKeyPress = (event: React.KeyboardEvent<SVGElement>) => {
    if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
      onClickRemove && onClickRemove(event, tag);
    }
  };

  return (
    <div
      id={id}
      tabIndex={tabIndex}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      onClick={event => onClick && onClick(event, tag)}
      className={`${styles.tag_wrapper}${onClick ? ` ${styles.selectable}` : ''}${disabled ? ` ${styles.disabled}` : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <svg
        className={`${styles.remove_btn}${disabled ? ` ${styles.disabled}` : ''}`}
        tabIndex={0}
        width="20"
        height="20"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onKeyPress={onRemoveKeyPress}
        onClick={event => onClickRemove && onClickRemove(event, tag)}
      >
        <path
          d="M7 0.601562C3.41594 0.601562 0.5 3.5175 0.5 7.10156C0.5 10.6856 3.41594 13.6016 7 13.6016C10.5841 13.6016 13.5 10.6856 13.5 7.10156C13.5 3.5175 10.5841 0.601562 7 0.601562ZM9.70719 9.10156L9 9.80875L7 7.80875L5 9.80875L4.29281 9.10156L6.29281 7.10156L4.29281 5.10156L5 4.39437L7 6.39437L9 4.39437L9.70719 5.10156L7.70719 7.10156L9.70719 9.10156Z"
          fill="black"
        />
      </svg>
      <div className={`${styles.title}${disabled ? ` ${styles.disabled}` : ''}`}>
        {tag.tagName}
        {displayHeart && <HeartFill style={{ marginLeft: '5px', color: '#04aa6d' }} />}
      </div>
    </div>
  );
};

export default Tag;
