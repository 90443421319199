import React from 'react';
import CloseIcon from '../../Icons/CloseIcon/CloseIcon';
import styles from './CloseButton.module.scss';

type ButtonElemType = 'a' | 'button'

const defaultProps = {
  as: 'button'
};

export interface CloseButtonProps {
  as: ButtonElemType,
  onClick?: (e: any) => void,
  href?: string,
  disabled?: boolean,
  target?: string,
  className?: string,
  size?: number,
}

function CloseButton({
  as,
  onClick,
  href,
  disabled,
  target,
  className,
  size = 50
}: CloseButtonProps) {
  if (as === 'a') {
    return (
      <a
        href={href}
        target={target}
        className={`${styles.button}${disabled ? ` ${styles.disabled}` : ''}${
          className ? ` ${className}` : ''
        }`}
        aria-label='Close'
      >
        <CloseIcon size={size} />
      </a>
    );
  }

  return (
    <button
      className={`${styles.button}${disabled ? ` ${styles.disabled}` : ''}${
        className ? ` ${className}` : ''
      }`}
      disabled={disabled}
      onClick={onClick}
      aria-label='Close'
    >
      <CloseIcon size={size} />
    </button>
  );
}

CloseButton.defaultProps = defaultProps;

export default CloseButton;
