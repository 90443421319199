/* eslint-disable camelcase */
import React from 'react';
import Spinner, { SpinnerSize, SpinnerTypes } from '../Spinner/Spinner';
import './LoadingBox.module.scss';

interface LoadingBoxPropsType {
  text?: string,
  text_placement?: 'top' | 'bottom',
  position?: 'relative' | 'absolute' | 'fixed',
  top_displacement?: string,
  size?: SpinnerSize,
  spinner_type?: SpinnerTypes,
  className?: string,
  ariaLabelSpinner?: string,
}

function LoadingBox({
  text = 'Loading...',
  text_placement = 'top',
  position = 'relative',
  top_displacement = '-10%',
  size = SpinnerSize.lg,
  spinner_type = SpinnerTypes.RoundEven,
  className,
  ariaLabelSpinner
}: LoadingBoxPropsType) {
  return (
    <div
      className={'loading-box' + (className ? ' ' + className : '')}
      style={{
        position
      }}
    >
      <div className='-inner-wrp'>
        <div
          className='-inner-wrp'
          style={{
            top: top_displacement
          }}
        >
          {text_placement === 'top' && <div className='-title'>{text}</div>}

          <Spinner
            className='-spinner'
            size={size}
            type={spinner_type}
            ariaLabel={ariaLabelSpinner}
          />

          {text_placement === 'bottom' && <div className='-title'>{text}</div>}
        </div>
      </div>
    </div>
  );
}

export default LoadingBox;
