import { CustomTypeMod } from '../InterfaceAndTypeUtil';
import { fetch, IFetchPrs, IFetchResult } from '../FetchUtil';
import { EnvType, getEnv } from '../EnvironmentUtil';

export const getProfileApiBaseUrl = (env?: EnvType): string => {
  return `${getProfileBaseUrl(env)}/api`;
};

export const getProfileBaseUrl = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  if (env === 'prod') {
    return 'https://profile.w3schools.com';
  } else if (env === 'staging') {
    return 'https://profile-stage.w3schools.com';
  } else if (env === 'develop') {
    return 'https://profile-dev.w3schools.com';
  }

  return 'https://profile.w3schools.com'; // prod fallback
};

export const fetchProfile = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getProfileApiBaseUrl();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'x-access-token';
  }

  return await fetch(prs);
};

export const getProfileUserSessionApiPath = (): string => {
  return '/api/user/session';
};

export const getProfileUserSessionApiUrl = (env?: EnvType): string => {
  return `${getProfileBaseUrl(env)}${getProfileUserSessionApiPath()}`;
};

export const getProfileUserSessionApiUrlMeta = (env?: EnvType) => {
  const base = getProfileBaseUrl(env);
  const path = getProfileUserSessionApiPath();

  return {
    base,
    path,
    url: `${base}${path}`, // combined
  };
};

export const fetchProfileUserSession = async<T>(prs: CustomTypeMod<IFetchPrs, { url?: string }>): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getProfileUserSessionApiUrl();

  if (typeof prs.url === 'undefined') {
    prs.url = '';
  }

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'x-access-token';
  }

  // prs.attachCookies = true;

  return await fetch(prs as IFetchPrs);
};
