import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Accordion } from 'react-bootstrap';
// import { Discord as DiscordIcon } from 'react-bootstrap-icons';

import { ICompDefault, TIMEOUT } from '../../userlib_new/utils/types-interfaces';
import {
  IUserProfileConfiguration,
  ComponentTypes,
  IDefault,
  IActivity,
  ICertificates,
  IDiscord,
  IInterests,
  ISpaces,
} from '../../userlib_new/utils/db-interfaces';
import InputItem from '../../userlib_new/InputItem';
import ScoreListing, { ITextGroup } from '../../userlib_new/ScoreListing/ScoreListing';
import { NotificationType } from '../../userlib_new/Notifications/utils/TypesInterfaces';
import Toggle from '../../userlib/buttons/ToggleButton/Toggle';
import ModuleWrapper from '../ModuleWrapper/ModuleWrapper';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import { NotificationStateContext } from '../../../context/notification-context';
import { PublicProfileStateContext } from '../../../context/public-profile-context';
import { UserStateContext } from '../../../context/user-context';
import StarIcon from '../../userlib_new/assets/icons/StarIcon';
import ContactInformation from '../ContactInformation/ContactInformation';
import Button from '../../userlib/buttons/Button';
import Certificates from './Certificates';
import Spaces from './Spaces';
import Interests from './Interests';
import Nick from './Nick';
import { fetchPublicProfile } from '../../../SharedLib/Util/InternalProjects/PublicProfileUtil';
import Spinner from '../../../SharedLib/Component/Spinner/Spinner';
import { IFetchResult } from '../../../SharedLib/Util/FetchUtil';
import { logging } from '../../../SharedLib/Util/LoggingUtil';

// import DummyPublicProfileData from '../../../assets/data/DummyPublicProfileData';

import styles from './PublicProfile.module.scss';

const PrivacyModal = React.lazy(() => import('./PrivacyModal/PrivacyModal'));

interface IProps extends ICompDefault {
	refElement: React.RefObject<HTMLDivElement>,
	userIsLoggedIn: boolean,
	startLoadingData?: boolean,
	loadingSet: (value: boolean) => void,
	doneLoading?: (value: boolean) => void,
	errorLoadingPublicProfile: (errorString: string) => void,
	errorSavingPublicProfile: (errorString: string) => void,
	onErrorInContacts: (value: boolean) => void,
	hasProfileError?: boolean,
	isPublicProfileLoading: (value: boolean) => void,
	onEnterEditNick: (value: boolean) => void,
}

// const discordIcon = <DiscordIcon className="score-listing-icon" />;
const activityIcon = <StarIcon className="score-listing-icon" />;

const PublicProfile = ({
  refElement,
  id,
  className,
  userIsLoggedIn,
  startLoadingData = true,
  loadingSet,
  doneLoading = () => {
    //
  },
  errorLoadingPublicProfile,
  errorSavingPublicProfile,
  isPublicProfileLoading,
  hasProfileError = false,
  onErrorInContacts,
  onEnterEditNick,
}: IProps) => {
  const { addNNotification } = useContext(NotificationStateContext);
  const { userData, loadedUserData } = useContext(UserStateContext);
  const {
    getSections,
    setPublicProfileData,
    publicProfileUserData,
    loadedPublicProfileData,
    activityData,
    discordData,
    certificatesData,
    spaceData,
    interestsData,
    publicProfilePublished,
    disablePublicProfile,
    setDisablePublicProfile,
    setActivityData,
    setCertificatesData,
    setDiscordData,
    setInterestsData,
    setSpaceData,
    setPublicProfilePublished,
    setErrorLoadingPublicProfileData,
    setPublicProfileUserData,
    savePublicProfileData,
  } = useContext(PublicProfileStateContext);

  //const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userBio, setUserBio] = useState<string | undefined>();
  const [defaultActiveKey, setDefaultActiveKey] = useState<string[]>([]);
  const [showAccordianWithData, setShowAccordianWithData] = useState(false); // need this to make sure defaultActivityKey is set when component is loaded

  const [errorInContacts, setErrorInContacts] = useState(false);
  const [errorInWebpage, setErrorInWebpage] = useState(false);
  const [errorInPhoneNr, setErrorInPhoneNr] = useState(false);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoadingInteresets, setIsLoadingInteresets] = useState(false);
  const [editNickMode, setEditNickMode] = useState(false);

  const activityDataObject = useMemo(() => {
    const items: ITextGroup[] = [];

    if (activityData) {
      items.push({
        header: 'Stars',
        value: activityData.stars,
      });
      items.push({
        header: 'Lessons',
        value: activityData.lessonsRead,
      });
      items.push({
        header: 'Exercises',
        value: activityData.exercisePoints,
      });
      items.push({
        header: 'Quizzes',
        value: activityData.quizPoints,
      });
      items.push({
        header: 'Total',
        value: activityData.totalPoints,
      });
    }

    return items;
  }, [activityData]);

  /* const discordDataObject = useMemo(() => {
    const items: ITextGroup[] = [];

    if (discordData) {
      items.push({
        header: 'User',
        value: discordData.user,
      });
      items.push({
        header: 'Role',
        value: discordData.role,
      });
      items.push({
        header: 'Messages',
        value: discordData.messages,
      });
      items.push({
        header: 'Experience',
        value: discordData.experience,
      });
      items.push({
        header: 'Level',
        value: discordData.level,
      });
    }

    return items;
  }, [discordData]); */

  const setLoading = (value: boolean) => {
    setIsLoading(value);
    loadingSet(value);
  };

  /**
	 * Bio changed
	 * @param event
	 */
  const onBioChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserBio(event.target.value);

    const ppUserData = { ...publicProfileUserData! };
    ppUserData.aboutMe = event.target.value;
    setPublicProfileUserData(ppUserData);
  };

  const onClickVisibleToggle = (itemType: ComponentTypes) => {
    const data: IDefault = { type: itemType, visible: false };

    switch (itemType) {
    case ComponentTypes.Activity:
      if (activityData) {
        data.visible = !activityData.visible;
        data.type = ComponentTypes.Activity;
        // eslint-disable-next-line no-case-declarations
        const newData: IActivity = { ...activityData, ...data };

        logging.logDebug('SettingsPage -> PublicProfile -> onClickVisibleToggle -> ' + itemType + ' switch -> newData', newData);

        setActivityData(newData);
      }
      break;
    case ComponentTypes.Certificates:
      if (certificatesData) {
        data.visible = !certificatesData.visible;
        data.type = ComponentTypes.Certificates;
        // eslint-disable-next-line no-case-declarations
        const newData: ICertificates = { ...certificatesData, ...data };

        logging.logDebug('SettingsPage -> PublicProfile -> onClickVisibleToggle -> ' + itemType + ' switch -> newData', newData);

        setCertificatesData(newData);
      }
      break;
    case ComponentTypes.Discord:
      if (discordData) {
        data.visible = !discordData.visible;
        data.type = ComponentTypes.Discord;
        // eslint-disable-next-line no-case-declarations
        const newData: IDiscord = { ...discordData, ...data };

        logging.logDebug('SettingsPage -> PublicProfile -> onClickVisibleToggle -> ' + itemType + ' switch -> newData', newData);

        setDiscordData(newData);
      }
      break;
    case ComponentTypes.Interests:
      if (interestsData) {
        data.visible = !interestsData.visible;
        data.type = ComponentTypes.Interests;
        // eslint-disable-next-line no-case-declarations
        const newData: IInterests = { ...interestsData, ...data };

        logging.logDebug('SettingsPage -> PublicProfile -> onClickVisibleToggle -> ' + itemType + ' switch -> newData', newData);

        setInterestsData(newData);
      }
      break;
    case ComponentTypes.Spaces:
      // spaces
      if (spaceData) {
        data.visible = !spaceData.visible;
        data.type = ComponentTypes.Spaces;
        // eslint-disable-next-line no-case-declarations
        const newData: ISpaces = { ...spaceData, ...data };

        logging.logDebug('SettingsPage -> PublicProfile -> onClickVisibleToggle -> ' + itemType + ' switch -> newData', newData);

        setSpaceData(newData);
      }
      break;
    default:
			//
    }
  };

  useEffect(() => {
    setIsSaveDisabled(
      errorInContacts ||
				!publicProfileUserData?.nick ||
				editNickMode ||
				!loadedUserData ||
				!loadedPublicProfileData ||
				disablePublicProfile,
    );
  }, [errorInContacts, publicProfileUserData?.nick, editNickMode, loadedUserData, loadedPublicProfileData, disablePublicProfile]);

  // hook for setting disabled state
  useEffect(() => {
    if (
      hasProfileError ||
			isLoading ||
			!loadedPublicProfileData ||
			!publicProfileUserData ||
			!publicProfileUserData?.nick ||
			publicProfileUserData?.nick.length === 0 ||
			editNickMode
    ) {
      setDisablePublicProfile(true);
    } else {
      setDisablePublicProfile(false);
    }
  }, [isLoading, publicProfileUserData, publicProfileUserData?.nick, loadedPublicProfileData, editNickMode, hasProfileError]);

  // hook for when data has been loaded -> set default values
  useEffect(() => {
    if (loadedPublicProfileData && publicProfileUserData) {
      setUserBio(publicProfileUserData!.aboutMe);

      const defaultKey: string[] = [];
      getSections().forEach((item: IDefault) => {
        if (item.visible) defaultKey.push(item.type);
      });

      setDefaultActiveKey(defaultKey);
      setShowAccordianWithData(true);
    }
  }, [loadedPublicProfileData, publicProfileUserData, publicProfileUserData?.aboutMe, getSections]);

  // hook for loading the data
  useEffect(() => {
    const loadData = async () => {
      if (loadedPublicProfileData || !userIsLoggedIn || !startLoadingData) {
        return;
      }

      setLoading(true);
      isPublicProfileLoading(true);

      try {
        const publicProfileDataFetchRes = await fetchPublicProfile<IUserProfileConfiguration>({
          method: 'GET',
          url: '/user-profile',
          timeout: TIMEOUT,
        });

        logging.logDebug(
          'SettingsPage -> PublicProfile -> hook loading data -> loadData -> publicProfileDataFetchRes',
          publicProfileDataFetchRes,
        );

        if (publicProfileDataFetchRes.error.code === '0') {
          setPublicProfileData(publicProfileDataFetchRes.data as IUserProfileConfiguration);
          if (publicProfileDataFetchRes.data.id && publicProfileDataFetchRes.data.id.length > 0) {
            setDisablePublicProfile(false);
          }
        } else {
          const notifId = uuidv4();
          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Loading public profile',
            body: publicProfileDataFetchRes.error.description || 'Something unexpected happened',
          };

          setErrorLoadingPublicProfileData(true);
          errorLoadingPublicProfile(publicProfileDataFetchRes.error.description || 'Something unexpected happened');
          addNNotification(notifProp);
        }

        setLoading(false);
        doneLoading(true);
        isPublicProfileLoading(false);
      } catch (error) {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Loading public profile',
          body: error as string,
        };

        errorLoadingPublicProfile(error as string);
        setErrorLoadingPublicProfileData(true);
        addNNotification(notifProp);

        setLoading(false);
        doneLoading(true);
        isPublicProfileLoading(false);
      }

      /* setTimeout(() => {
        setPublicProfileData(DummyPublicProfileData as IUserProfileConfiguration);

        setDisabled(false);
        setLoading(false);
        doneLoading(true);
      }, 1500); */
    };

    loadData();
  }, [userIsLoggedIn, startLoadingData, loadedPublicProfileData]);

  const onErrorInWebPage = (error: boolean) => {
    setErrorInWebpage(error);
  };

  const onErrorInPhoneNr = (error: boolean) => {
    setErrorInPhoneNr(error);
  };

  useEffect(() => {
    setErrorInContacts(errorInWebpage || errorInPhoneNr);
    onErrorInContacts(errorInWebpage || errorInPhoneNr);
  }, [errorInWebpage, errorInPhoneNr]);

  const loadingInteresetsData = (value: boolean) => {
    setIsLoadingInteresets(value);
  };

  const clickSave = useCallback(async () => {
    setLoading(true);
    errorSavingPublicProfile('');

    const notifHeader = 'Saving public profile';

    try {
      const saveResponse = await savePublicProfileData(userData.firstName, userData.lastName, userData.email);
      const response = saveResponse as IFetchResult;

      if (response.error.code === '0') {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: true,
          closeButton: false,
          type: 'success',
          header: notifHeader,
          body: 'Successfully saved public profile',
        };

        addNNotification(notifProp);
        setLoading(false);
      } else {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: notifHeader,
          body: response.error.description,
        };

        errorSavingPublicProfile(response.error.description ?? 'Unknown error');
        addNNotification(notifProp);
        setLoading(false);
      }
    } catch (error) {
      const notifId = uuidv4();
      const notifProp: NotificationType = {
        id: notifId,
        dismissed: false,
        autohide: false,
        closeButton: true,
        type: 'error',
        header: notifHeader,
        body: error as string,
      };

      errorSavingPublicProfile((error as string) ?? 'Unknown error');
      addNNotification(notifProp);
      setLoading(false);
    }
  }, [userData.firstName, userData.lastName, userData.email, savePublicProfileData, addNNotification]);

  const [toggledPublish, setToggledPublish] = useState(false);
  const togglePublished = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublicProfilePublished(event.target.checked);
    setToggledPublish(true);
  };
  useEffect(() => {
    if (toggledPublish) {
      setToggledPublish(false);
      clickSave();
    }
  }, [toggledPublish, clickSave]);

  const enterEditNickMode = (value: boolean) => {
    setEditNickMode(value);
    onEnterEditNick(value);
  };

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const openPrivacyModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setShowPrivacyModal(true);
  };

  const onClosePrivacyModal = () => {
    setShowPrivacyModal(false);
  };

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`} ref={refElement}>
      <ModuleWrapper title="Public URL" subTitle="Share your accomplishments with the world!" className={styles.module_wrapper}>
        <Toggle
          label={publicProfilePublished ? 'Online' : 'Offline'}
          labelPosition="left"
          checked={publicProfilePublished}
          disabled={disablePublicProfile || errorInContacts}
          className={styles.published}
          onChange={togglePublished}
        />

        <Nick disabled={disablePublicProfile || errorInContacts} onEnterEditMode={enterEditNickMode} />
      </ModuleWrapper>

      <ModuleWrapper
        title="Bio"
        subTitle={<div>Write a short introduction to best describe yourself to visitors</div>}
        className={styles.module_wrapper}
      >
        <>
          <InputItem
            domType="textarea"
            disabled={disablePublicProfile}
            onValueChange={onBioChanged}
            placeholder="text here..."
            name="profile_bio"
            autoCapitalize="sentences"
            value={userBio}
            maxLength={5000}
          ></InputItem>
          <div className={styles.privacy_text}>
						Protect your privacy. Read more <span onClick={openPrivacyModal}>here</span>.{' '}
          </div>
        </>
      </ModuleWrapper>

      <ModuleWrapper title="Contact Me" subTitle="How visitors will get in touch with you..." className={styles.module_wrapper}>
        <ContactInformation
          loading={isLoading}
          disabled={disablePublicProfile}
          onCheckedForErrorWebPage={onErrorInWebPage}
          onCheckedForErrorPhoneNr={onErrorInPhoneNr}
          className={styles.contact_info}
        />
      </ModuleWrapper>

      <ModuleWrapper
        title="Content"
        subTitle="Specify what content you want to share"
        className={`w3s-settings-module-wrapper ${styles.module_wrapper}`}
      >
        {loadedPublicProfileData && !hasProfileError && showAccordianWithData && (
          <Accordion 
            // alwaysOpen 
            defaultActiveKey={defaultActiveKey[0]}
          >
            <ContentWrapper
              title="Activity Score"
              eventKey={ComponentTypes.Activity}
              open={activityData?.visible || false}
              disabled={!activityData || disablePublicProfile}
              onToggleClicked={onClickVisibleToggle}
            >
              <ScoreListing data={activityDataObject} iconElement={activityIcon} centerValueItems />
            </ContentWrapper>

            {/* <ContentWrapper
              title="Discord"
              eventKey={ComponentTypes.Discord}
              open={discordData?.visible || false}
              disabled={!discordData || disablePublicProfile}
              onToggleClicked={onClickVisibleToggle}
            >
              <ScoreListing data={discordDataObject} iconElement={discordIcon} className={styles.discord_icon} breakpoint2 />
            </ContentWrapper> */}

            <ContentWrapper
              title="Certificates"
              eventKey={ComponentTypes.Certificates}
              open={certificatesData?.visible || false}
              disabled={!certificatesData || disablePublicProfile}
              onToggleClicked={onClickVisibleToggle}
            >
              <Certificates disabled={disablePublicProfile} />
            </ContentWrapper>

            <ContentWrapper
              title="Spaces"
              eventKey={ComponentTypes.Spaces}
              open={spaceData?.visible || false}
              disabled={!spaceData || disablePublicProfile}
              onToggleClicked={onClickVisibleToggle}
            >
              <Spaces disabled={disablePublicProfile} />
            </ContentWrapper>

            <ContentWrapper
              title="Interests"
              eventKey={ComponentTypes.Interests}
              open={interestsData?.visible || false}
              disabled={!interestsData || disablePublicProfile}
              onToggleClicked={onClickVisibleToggle}
              loading={isLoadingInteresets}
            >
              <Interests
                visible={interestsData?.visible || false}
                loadingData={loadingInteresetsData}
                disabled={isLoadingInteresets || disablePublicProfile || !interestsData}
              />
            </ContentWrapper>
          </Accordion>
        )}
        {(!loadedPublicProfileData || hasProfileError || !showAccordianWithData) && (
          <Accordion>
            <ContentWrapper
              title="Activity Score"
              eventKey={'0'}
              open={false}
              disabled={true}
              onToggleClicked={onClickVisibleToggle}
            ></ContentWrapper>

            {/* <ContentWrapper
              title="Discord"
              eventKey={'1'}
              open={false}
              disabled={true}
              onToggleClicked={onClickVisibleToggle}
            ></ContentWrapper> */}

            <ContentWrapper
              title="Certificates"
              eventKey={'2'}
              open={false}
              disabled={true}
              onToggleClicked={onClickVisibleToggle}
            ></ContentWrapper>

            <ContentWrapper
              title="Spaces"
              eventKey={'3'}
              open={false}
              disabled={true}
              onToggleClicked={onClickVisibleToggle}
            ></ContentWrapper>

            <ContentWrapper
              title="Interests"
              eventKey={'4'}
              open={false}
              disabled={true}
              onToggleClicked={onClickVisibleToggle}
            ></ContentWrapper>
          </Accordion>
        )}
      </ModuleWrapper>

      <div className={styles.action_wrapper}>
        <Button
          className={styles.btn_width}
          variant="primary"
          text="Save"
          disabled={isSaveDisabled}
          loading={isLoading}
          onClick={clickSave}
        />
      </div>

      {showPrivacyModal && (
        <Suspense
          fallback={
            <div className="suspense-spinner">
              <Spinner size="medium" />
            </div>
          }
        >
          <PrivacyModal onClose={onClosePrivacyModal} />
        </Suspense>
      )}
    </div>
  );
};

export default PublicProfile;
