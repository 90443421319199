import React, { useState } from 'react';
import styles from './InfoBar.module.scss';
import CloseButton from '../Buttons/CloseButton/CloseButton';

export interface InfoBarProps {
  children?: React.ReactNode,
  id?: string,
  className?: string,
}

function InfoBar({ children, className, id }: InfoBarProps) {
  const [close, setClose] = useState(false);

  const onClickClose = () => {
    setClose(true);
  };

  return (
    <div
      id={id}
      className={`${styles.infobar}${close ? ' d-none' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <CloseButton
        onClick={onClickClose}
        size={24}
        className={styles.close_btn}
      />
      {children}
    </div>
  );
}

export default InfoBar;
