/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext } from 'react';
import { PublicProfileStateContext } from '../../../../context/public-profile-context';
import { SpaceType } from '../../../userlib_new/utils/db-interfaces';
import { ICompDefault } from '../../../userlib_new/utils/types-interfaces';
import Space from './Space';

import styles from './Spaces.module.scss';

interface ISpaces extends ICompDefault {
	disabled?: boolean,
}

const Spaces = ({ id, className, disabled = false }: ISpaces) => {
  const { spaceData, setSpaceData } = useContext(PublicProfileStateContext);

  const updatedSpace = (space: SpaceType) => {
    const spaceIndex = spaceData?.spaces.findIndex(item => item.spaceId === space.spaceId) ?? -1;
    if (spaceData && spaceIndex > -1) {
      const mySpaceData = { ...spaceData };
      mySpaceData.spaces[spaceIndex] = space;
      setSpaceData(mySpaceData);
    }
  };

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      {spaceData!.spaces.length > 0 &&
				spaceData?.spaces.map(space => {
				  return (
				    <div key={`space-${space.spaceId}`} className={styles.wrapper}>
				      <div className={styles.divider} />
				      <Space space={space} disabled={disabled} onDataUpdated={updatedSpace} />
				    </div>
				  );
				})}

      {spaceData!.spaces.length === 0 && <div className={styles.no_spaces}>No spaces.</div>}
    </div>
  );
};

export default Spaces;
