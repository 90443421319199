import React, { createContext, useMemo, useState } from 'react';
import { UserDataType } from '../SharedLib/Util/InterfaceAndTypeUtil';

const DEFAULT_DATA: UserDataType = {
  createdUts: 0,
  email: '',
  emailConsent: false,
  emailSettingsUpToDate: true,
  firstName: '',
  lastName: '',
  sub: '',
  username: '',
};

export type UserContextStateType = {
	userData: UserDataType,
	loadedUserData: boolean,
	setUserData: (data: UserDataType) => void,
};

const DEFAULT_STATE: UserContextStateType = {
  userData: DEFAULT_DATA,
  loadedUserData: false,
  setUserData: () => {
    //
  },
};

const UserStateContext = createContext<UserContextStateType>(DEFAULT_STATE);

const UserStateProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [userData, setUserDataObject] = useState<UserDataType>(DEFAULT_DATA);
  const [loadedUserData, setLoadedUserData] = useState(false);

  const setUserData = (value: UserDataType) => {
    setUserDataObject(value);
    setLoadedUserData(true);
  };

  const value: UserContextStateType = useMemo(
    () => ({
      userData,
      loadedUserData,
      setUserData,
    }),
    [userData, loadedUserData],
  );

  return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>;
};

export { UserStateProvider, UserStateContext };
