import { logging } from './LoggingUtil';
import { getClassicUrl, getPathfinderUrl } from './EnvironmentUtil';
import { getQueryParamNamed, updateUrlQueryParam } from './QueryParamsUtil';
import { getLocalCurrentUts } from './TimeUtil';

const ALLOWED_OAUTH_REDIRECT_URLS = [
  'https://sso.beta.canvaslms.com/login/oauth2/callback',
  'https://sso.canvaslms.com/login/oauth2/callback',
  'https://auth.sandbox.contentraven.com/oauth2/idpresponse',
  'https://sandbox.contentraven.com/awspartners/w3schools/oauth2/acs',
  'https://cloud.contentraven.com/awspartners/W3Schools/oauth2/acs',
  'https://sandbox.contentraven.com/AWSPartners/w3schoolsstaging/oauth2/acs'
].map((item) => item.toLowerCase());

const ALLOWED_REDIRECT_HOSTS = [
  'spaces.w3schools.com',
  'spaces-stage.w3schools.com',
  'spaces-dev.w3schools.com',
  'spaces-local.w3schools.com',
  'auth-api.w3stages.com',
  'auth-api.w3sdevelop.com',
  'auth-api.w3spaces.com',
  'api.w3stages.com',
  'api.w3sdevelop.com',
  'api.w3spaces.com',
  'my-learning.w3schools.com',
  'my-learning-stage.w3schools.com',
  'my-learning-dev.w3schools.com',
  'myl-dev.w3schools.com',
  'myl-stage.w3schools.com',
  'myl.w3schools.com',
  'profile.w3schools.com',
  'profile-stage.w3schools.com',
  'profile-dev.w3schools.com',
  'billing.w3schools.com',
  'billing-stage.w3schools.com',
  'billing-dev.w3schools.com',
  'prototyping.w3sdevelop.com',
  'w3schools.com',
  'www.w3schools.com',
  'kte9uk46s.w3schools.com',
  'ssf18kfg1.w3schools.com',
  'classic-local.w3schools.com',
  'videos-dev.w3schools.com',
  'localdev.w3schools.com',
  'mycourses.w3schools.com',
  'campus.w3schools.com',
  'pathfinder.w3schools.com',
  'pathfinder-stage.w3schools.com',
  'pathfinder-dev.w3schools.com',
  'pathfinder-local.w3schools.com',
].map((item) => item.toLowerCase());

const ALLOWED_REDIRECT_CLASSIC_HOSTS = [
  'w3schools.com',
  'www.w3schools.com',
  'kte9uk46s.w3schools.com',
  'ssf18kfg1.w3schools.com',
  'classic-local.w3schools.com',
].map((item) => item.toLowerCase());

const ALLOWED_REDIRECT_HOSTS_WITH_ANONYMOUS_SESSION = ALLOWED_REDIRECT_CLASSIC_HOSTS;

export const redirectUrlIsCompatibleWithAnonymousSession = (redirectUrl: string): boolean => {
  const redirectUrlObject = getUrlObject(redirectUrl);

  if (!redirectUrlObject) {
    return false;
  }

  if (ALLOWED_REDIRECT_HOSTS_WITH_ANONYMOUS_SESSION.includes(redirectUrlObject.host.toLowerCase())) {
    return true;
  }

  return false;
};

export const isClassicRedirectUrl = ({
  redirectUrl,
  redirectUrlObject,
}: {
  redirectUrl?: string,
  redirectUrlObject?: URL,
}): boolean => {
  if (
    redirectUrlObject === undefined
    && redirectUrl === undefined
  ) {
    return false;
  }

  if (redirectUrlObject === undefined) {
    redirectUrlObject = getUrlObject(redirectUrl!);
  }

  if (!redirectUrlObject) {
    return false;
  }

  if (ALLOWED_REDIRECT_CLASSIC_HOSTS.includes(redirectUrlObject.host.toLowerCase())) {
    return true;
  }

  return false;
};

export const clearCachedRedirectUrl = () => {
  sessionStorage.removeItem('redirect_uri');
  sessionStorage.removeItem('redirectUrl');
  sessionStorage.removeItem('redirectOriginUrl');
};

const _getOauthRedirect = ({
  remove = false,
}: {
  remove?: boolean,
} = {}) => {
  let redirectUrlRaw: string = getQueryParamNamed('redirect_uri')?.value || '';

  if (!redirectUrlRaw) {
    redirectUrlRaw = sessionStorage.getItem('redirect_uri') || '';

    if (remove && redirectUrlRaw) {
      sessionStorage.removeItem('redirect_uri');
    }
  }

  return redirectUrlRaw;
};

const _getCachedRedirect = ({
  remove = false,
  origin = false,
}: {
  remove?: boolean,
  origin?: boolean,
} = {}) => {
  let redirectUrlRaw: string = getQueryParamNamed(origin ? 'origin' : 'redirect_url')?.value || '';

  if (!redirectUrlRaw) {
    redirectUrlRaw = sessionStorage.getItem(origin ? 'redirectOriginUrl' : 'redirectUrl') || '';

    if (remove && redirectUrlRaw) {
      sessionStorage.removeItem(origin ? 'redirectOriginUrl' : 'redirectUrl');
    }
  }

  return redirectUrlRaw;
};

export const getUrlObject = (url: string): URL | undefined => {
  try {
    return new URL(url);
  } catch (exc: unknown) {
    logging.logError(exc);
  }

  return;
};

export const cacheRedirectUrl = ({
  redirectUrlRaw,
}: {
  redirectUrlRaw?: string,
} = {}) => {
  if (redirectUrlRaw === undefined) {
    redirectUrlRaw = getQueryParamNamed('redirect_url')?.value || '';
  }

  if (redirectUrlRaw) { // cache the redirectUrl from url in session storage to be used after session auth redirects
    sessionStorage.setItem('redirectUrl', redirectUrlRaw);
  }
};

export const _getSanitizedRedirectUrl = (redirectUrlRaw: string) => {
  logging.logDebug(`_getSanitizedRedirectUrl-> redirectUrlRaw: ${redirectUrlRaw}`);

  const redirectUrlDecoded = decodeURIComponent(redirectUrlRaw);

  const redirectUrlObject = getUrlObject(redirectUrlDecoded);

  if (!redirectUrlObject) {
    return undefined;
  }

  const redirectUrlStripped = `${redirectUrlObject.protocol}//${redirectUrlObject.host}${redirectUrlObject.pathname}`;

  logging.logDebug('_getSanitizedRedirectUrl -> sanitizing: ', {
    redirectUrlDecoded,
    redirectUrlStripped,
    redirectUrl: redirectUrlObject,
  });

  if (ALLOWED_OAUTH_REDIRECT_URLS.includes(redirectUrlStripped.toLowerCase())) {
    return redirectUrlRaw;
  }

  if (ALLOWED_REDIRECT_HOSTS.includes(redirectUrlObject.host.toLowerCase())) {
    return redirectUrlDecoded;
  }

  return undefined;
};

export const getSanitizedRedirectUrl = ({
  skipOauthRedirectLookup = false,
  loggedOut = null,
  fallback = undefined,
}: {
  skipOauthRedirectLookup?: boolean,
  loggedOut?: boolean | null,
  fallback?: string | undefined,
} = {}): string | undefined => {
  const processOutput = (redirectUrl: string | undefined) => {
    if (
      loggedOut
      && redirectUrl
      && !redirectUrlIsCompatibleWithAnonymousSession(redirectUrl)
    ) {
      const redirectOriginUrlRaw = _getCachedRedirect({
        remove: false,
        origin: true,
      });

      redirectUrl = _getSanitizedRedirectUrl(redirectOriginUrlRaw);

      if (redirectUrl && !redirectUrlIsCompatibleWithAnonymousSession(redirectUrl)) {
        redirectUrl = undefined;
      }
    }

    const output = redirectUrl || fallback;

    logging.logDebug('getSanitizedRedirectUrl -> output: ', output);

    return output;
  };

  let redirectUrlRaw: string = getQueryParamNamed('redirect_url')?.value || '';

  let redirectUrlJustCached = false;

  // origin is used as a fallback for redirect_url in some cases
  const redirectOriginUrlRaw: string = getQueryParamNamed('origin')?.value || '';

  // let redirectOriginUrlJustCached = false;

  if (redirectUrlRaw) { // cache the redirectUrl from url in session storage to be used after session auth redirects
    redirectUrlJustCached = true;
    sessionStorage.setItem(origin ? 'redirectOriginUrl' : 'redirectUrl', redirectUrlRaw);
  }

  if (redirectOriginUrlRaw) { // cache the origin redirectUrl from url in session storage to be used after session auth redirects
    // redirectOriginUrlJustCached = true;
    sessionStorage.setItem('redirectOriginUrl', redirectOriginUrlRaw);
  }

  if (!skipOauthRedirectLookup && !redirectUrlRaw) {
    redirectUrlRaw = _getOauthRedirect({
      remove: true,
    });
  }

  if (!redirectUrlJustCached && !redirectUrlRaw) {
    redirectUrlRaw = _getCachedRedirect({
      remove: false,
      origin: false,
    });
  }

  if (!redirectUrlRaw) {
    return processOutput(undefined);
  }

  return processOutput(_getSanitizedRedirectUrl(redirectUrlRaw));
};

export const safeRedirect = (redirectUrl: string | undefined) => {
  logging.logDebug('safeRedirect -> initialized -> redirectUrl: ', redirectUrl);

  const stopRedirection = localStorage.getItem('stopRedirection');

  if (
    typeof stopRedirection !== 'undefined'
    && stopRedirection !== null
    && stopRedirection
  ) {
    logging.logDebug('safeRedirect -> stopped');

    return;
  }

  const currentUts = getLocalCurrentUts();

  if (
    typeof redirectUrl !== 'undefined'
    && redirectUrl
  ) {
    const prevRedirectUrl = localStorage.getItem('prevRedirectUrl');
    const prevRedirectUts = parseInt(localStorage.getItem('prevRedirectUts') || '0');

    if (
      typeof prevRedirectUrl !== 'undefined'
      && prevRedirectUrl
      && prevRedirectUrl === redirectUrl
      && (currentUts - prevRedirectUts) <= 3
    ) { // trying to redirect to the same place in a short time span -> smells like a redirection loop
      logging.logDebug('safeRedirect -> break', {
        prevRedirectUts,
        currentUts,
      });

      redirectUrl = undefined;
      localStorage.removeItem('prevRedirectUrl');
      localStorage.removeItem('prevRedirectUts');
    }
  }

  if (
    typeof redirectUrl !== 'undefined'
    && redirectUrl
  ) {
    localStorage.setItem('prevRedirectUrl', redirectUrl);
    localStorage.setItem('prevRedirectUts', `${currentUts}`);

    clearCachedRedirectUrl();

    window.location.href = redirectUrl;

    redirectUrl = undefined;
  }

  return redirectUrl;
};

export const handleUserProfileClose = () => {
  const redirectUrl = getSanitizedRedirectUrl({
    skipOauthRedirectLookup: true,
    loggedOut: true,
    fallback: getClassicUrl(),
  });

  logging.logDebug('handleUserProfileClose -> redirectUrl: ', redirectUrl);

  window.location.href = redirectUrl!;
};

export const tweakRedirectUrlOnSuccessfulSignup = () => {
  const currentRedirectUrl = getSanitizedRedirectUrl();

  if (currentRedirectUrl) {
    const currentRedirectUrlObject = getUrlObject(currentRedirectUrl);

    if (isClassicRedirectUrl({ redirectUrlObject: currentRedirectUrlObject })) {
      const redirectUrlRaw = encodeURIComponent(getPathfinderUrl());

      updateUrlQueryParam({
        paramName: 'redirect_url',
        paramValue: redirectUrlRaw
      });

      // session storage cache
      cacheRedirectUrl({ redirectUrlRaw });
    }
  }
};
