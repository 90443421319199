import React from 'react';
import Spinner from '../../../SharedLib/Component/Spinner/Spinner';
import { ICompDefault } from '../../userlib_new/utils/types-interfaces';
import styles from './ModuleWrapper.module.scss';

export interface IProps extends ICompDefault {
	title: string,
	subTitle?: string | React.ReactNode,
	loading?: boolean,
}

const ModuleWrapper = ({
  title,
  className = undefined,
  id = undefined,
  subTitle = undefined,
  loading = false,
  children,
}: React.PropsWithChildren<IProps>) => {
  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <div className={styles.header_wrapper}>
        <div className={styles.title_wrapper}>
          <div className={`${styles.title}`}>{title}</div>
          {loading && <Spinner size="medium" />}
        </div>
        {subTitle && <div className={`${styles.subtitle}`}>{subTitle}</div>}
      </div>

      {children}
    </div>
  );
};

export default ModuleWrapper;
