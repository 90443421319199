import { OperationResultType } from './InterfaceAndTypeUtil';
import { getProfileApiBaseUrl } from './InternalProjects/ProfileUtil';
import { liteFetch } from './LiteFetchUtil';
import { logDebugAlt } from './LoggingUtil';

export const fetchCurrentUtsFromHeader = async (): Promise<OperationResultType> => {
  return new Promise(function (resolve) {
    liteFetch(
      'GET',
      '/',
      // 'https://qqq',
      null,
      function (reqRes) {
        logDebugAlt('fetchCurrentUtsFromHeader -> reqRes: ', reqRes);

        const output: OperationResultType = {
          error: {
            code: '0'
          },
          data: {}
        };

        let currentUts = 0;

        if (
          reqRes.error.code === '0'
          && reqRes.status === 200
        ) {
          const headers = reqRes.xhr.getAllResponseHeaders().trim().split('\r\n') as string[];
          logDebugAlt('fetchCurrentUtsFromHeader -> headers: ', headers);

          for (const index in headers) {
            const headerLine = headers[index];
            // logDebugAlt('fetchCurrentUtsFromHeader -> index, headerLine: ', {
            //   index,
            //   headerLine,
            // });

            if (headerLine.toLowerCase().indexOf('date: ') === 0) {
              const dtfs = headerLine.split(': ', 2)[1];
              logDebugAlt('fetchCurrentUtsFromHeader -> dtfs: ', dtfs);

              if (dtfs.indexOf('GMT') !== -1) {
                currentUts = Date.parse(dtfs) / 1000;

                logDebugAlt('fetchCurrentUtsFromHeader -> currentUts: ', currentUts);
                break;
              }
            }
          }
        }

        if (!currentUts) {
          output.error = {
            code: 'UTRTUTSFH', // from header
            description: 'Unable to retrieve the unix timestamp',
          };
        }

        output.data = {
          currentUts,
        };

        resolve(output);
      },
    );
  });
};

export const fetchCurrentUtsFromProfile = async (): Promise<OperationResultType> => {
  return new Promise(function (resolve) {
    liteFetch(
      'GET',
      getProfileApiBaseUrl() + '/uts',
      null,
      function (reqRes) {
        logDebugAlt('fetchCurrentUtsFromProfile -> reqRes: ', reqRes);

        const output: OperationResultType = {
          error: {
            code: '0'
          },
          data: {}
        };

        let currentUts = 0;


        if (
          reqRes.error.code === '0'
          && reqRes.status === 200
          && reqRes.dataStr.length
        ) {
          currentUts = parseInt(reqRes.dataStr);

          logDebugAlt('fetchCurrentUtsFromProfile -> currentUts: ', currentUts);
        }

        if (!currentUts) {
          output.error = {
            code: 'UTRTUTSFP', // from profile
            description: 'Unable to retrieve the unix timestamp',
          };
        }

        output.data = {
          currentUts,
        };

        resolve(output);
      }
    );
  });
};

let _cachedCurrentUts = false;
let _cachedCurrentUtsVal = 0;
let _cachedCurrentUtsInterval: NodeJS.Timeout = null as unknown as NodeJS.Timeout;

export const setCurrentUts = (currentUts: number) => {
  logDebugAlt('setCurrentUts -> currentUts: ', currentUts);

  _cachedCurrentUts = true;
  _cachedCurrentUtsVal = currentUts;

  clearInterval(_cachedCurrentUtsInterval);

  _cachedCurrentUtsInterval = setInterval(function () {
    _cachedCurrentUtsVal += 1;
  }, 1000);
};

type CurrentUtsOrigin = 'header' | 'profile' | 'local';

export const fetchAndCacheCurrentUts = async (
  onCompleteCallback?: (currentUts: number, origin: CurrentUtsOrigin) => void,
): Promise<number> => {
  if (_cachedCurrentUts) {
    return _cachedCurrentUtsVal;
  }

  let currentUts = 0;

  let origin: CurrentUtsOrigin = 'header';

  let currentUtsFetchRes = await fetchCurrentUtsFromHeader();
  logDebugAlt('fetchAndCacheCurrentUts -> fetchCurrentUtsFromHeader -> currentUtsFetchRes: ', currentUtsFetchRes);

  if (currentUtsFetchRes.error.code !== '0') {
    origin = 'profile';
    currentUtsFetchRes = await fetchCurrentUtsFromProfile();
    logDebugAlt('fetchAndCacheCurrentUts -> fetchCurrentUtsFromProfile -> currentUtsFetchRes: ', currentUtsFetchRes);
  }

  if (
    currentUtsFetchRes.error.code === '0'
    && typeof currentUtsFetchRes.data.currentUts !== 'undefined'
  ) {
    currentUts = currentUtsFetchRes.data.currentUts;
  }

  // fallback
  if (!currentUts) {
    origin = 'local';
    currentUts = getLocalCurrentUts();
    logDebugAlt('fetchAndCacheCurrentUts -> getLocalCurrentUts -> currentUts: ', currentUts);
  }

  setCurrentUts(currentUts);

  if (typeof onCompleteCallback !== 'undefined') {
    onCompleteCallback(currentUts, origin);
  }

  return currentUts;
};

export const currentUtsIsCached = () => {
  return _cachedCurrentUts;
};

export const getCurrentUts = (): number => {
  // can be used if fetchAndCacheCurrentUts was called previously

  if (_cachedCurrentUts) {
    return _cachedCurrentUtsVal;
  }

  console.error('WARNING! getCurrentUts executed before cache was set');

  // fallback
  return getLocalCurrentUts();
};

export const getLocalCurrentUts = (): number => { // seconds
  return Math.floor(new Date().getTime() / 1000);
};

/**
 * @deprecated Use "getLocalCurrentUtms" instead because of function name typo
 */
export const getLocalCurrentUtus = (): number => { // microseconds ( mistake originating in some python util )
  return new Date().getTime();
};

export const getLocalCurrentUtms = (): number => { // milliseconds
  return new Date().getTime();
};

export const getUtsFromDto = (dto: Date): number => {
  return Math.floor(dto.getTime() / 1000);
};
