import React from 'react';

import CloseIcon from '../../../userlib_new/assets/icons/userlib/CloseIcon';

import styles from './CloseButton.module.scss';

type ButtonElemType = 'a' | 'button';

export interface ICloseButtonProps {
	as?: ButtonElemType,
	onClick?: (e: any) => void,
	href?: string,
	disabled?: boolean,
	target?: string,
	className?: string,
	size?: number,
	fill?: string,
}

const CloseButton = ({
  as = 'button',
  onClick = () => {
    //
  },
  href = undefined,
  disabled = false,
  target = undefined,
  className = undefined,
  size = 50,
  fill = '#282a35',
}: ICloseButtonProps) => {
  if (as === 'a') {
    return (
      <a
        href={href}
        target={target}
        className={`${styles.button}${disabled ? ` ${styles.disabled}` : ''}${className ? ` ${className}` : ''}`}
      >
        <CloseIcon size={size} fill={fill} />
      </a>
    );
  }

  return (
    <button
      type="button"
      className={`${styles.button}${disabled ? ` ${styles.disabled}` : ''}${className ? ` ${className}` : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      <CloseIcon size={size} fill={fill} />
    </button>
  );
};

export default CloseButton;
