import React, { useMemo } from 'react';

import { ICompDefault } from '../utils/types-interfaces';
import TextGrouping from './TextGrouping';

import styles from './ScoreListing.module.scss';

export interface ITextGroup {
	header: string,
	value: string | number,
}

interface IScoreListingProps extends ICompDefault {
	data: ITextGroup[],
	iconElement: React.ReactElement | JSX.Element,
	breakpoint2?: boolean,
	centerValueItems?: boolean,
}

const ScoreListing = ({ id, className, data, iconElement, breakpoint2 = false, centerValueItems = false }: IScoreListingProps) => {
  const collections = useMemo(() => {
    let index = 0;
    const items = [];

    while (data.length > 0 && index < data.length) {
      let item2;

      const item1 = <TextGrouping title={data[index].header} value={data[index].value} centerValueItems={centerValueItems} />;
      index += 1;

      if (index < data.length) {
        item2 = <TextGrouping title={data[index].header} value={data[index].value} centerValueItems={centerValueItems} />;
        index += 1;
      }

      items.push(
        <div key={`textgrouping-${items.length}`} className={styles.group_wrapper}>
          {item1}
          {item2 ? item2 : undefined}
        </div>,
      );
    }

    return items;
  }, [data]);

  return (
    <div
      id={id}
      className={`score-listing ${styles.wrapper}${breakpoint2 ? ` ${styles.breakpoint2}` : ` ${styles.breakpoint1}`}${
        className ? ` ${className}` : ''
      }`}
    >
      {iconElement}

      <div className={styles.body_wrapper}>
        {collections.map(item => {
          return item;
        })}

        {/* <div className={styles.group_wrapper}>
          <TextGrouping title="Stars" value={data.stars} />
          <TextGrouping title="Lessons" value={data.lessonsRead} />
        </div>

        <div className={styles.group_wrapper}>
          <TextGrouping title="Exercises" value={data.exercisePoints} />
          <TextGrouping title="Quizes" value={data.quizPoints} />
        </div>

        <div className={styles.group_wrapper}>
          <TextGrouping title="Total" value={data.totalPoints} />
        </div> */}
      </div>
    </div>
  );
};

export default ScoreListing;
