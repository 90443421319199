import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { NotificationStateContext } from '../../../../context/notification-context';
import { PublicProfileStateContext } from '../../../../context/public-profile-context';
import { UserStateContext } from '../../../../context/user-context';
import { getPublicProfileUrl } from '../../../../SharedLib/Util/EnvironmentUtil';
import { IFetchResult } from '../../../../SharedLib/Util/FetchUtil';
import { fetchPublicProfile } from '../../../../SharedLib/Util/InternalProjects/PublicProfileUtil';
import { logging } from '../../../../SharedLib/Util/LoggingUtil';
import getPublicProfileBaseUrl from '../../../../utils/get-public-profile-base-url';
import Button from '../../../userlib/buttons/Button';
import InputItem from '../../../userlib_new/InputItem';
import { NotificationType } from '../../../userlib_new/Notifications/utils/TypesInterfaces';
import { ICompDefault, TIMEOUT } from '../../../userlib_new/utils/types-interfaces';
import IllegalNicks from './IllegalNicks';

import styles from './Nick.module.scss';

interface INick extends ICompDefault {
	disabled?: boolean,
	loading?: boolean,
	onEnterEditMode: (value: boolean) => void,
}

const BASE_URL = `https://${getPublicProfileBaseUrl()}/`;

const Nick = ({ disabled = false, id, className, loading = false, onEnterEditMode }: INick) => {
  const { addNNotification, dismissNotification } = useContext(NotificationStateContext);
  const { userData, loadedUserData } = useContext(UserStateContext);
  const { savePublicProfileData, publicProfileUserData, publicProfilePublished, errorLoadingPublicProfileData, loadedPublicProfileData } =
		useContext(PublicProfileStateContext);

  const [nick, setNick] = useState<string>();
  const [url, setUrl] = useState(BASE_URL);
  const [disableSave, setDisableSave] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const [editMode, setEditMode] = useState(false);

  const errorNotifId = useRef<string>();

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = IllegalNicks.findIndex(item => item === event.target.value.toLowerCase());

    if (index === -1) {
      const re = /^[a-zA-Z0-9_-]+$/;
      if (re.test(event.target.value)) {
        //const newNick = event.target.value.replace(' ', '_');
        // event.target.value = newNick;
        setNick(event.target.value);
        setError(false);
        setErrorText('');
      } else {
        setError(true);
        setErrorText('Invalid character. Use URL safe characters. (a-z, A-Z, 0-9, _, -)');
      }
    } else {
      setError(true);
      setErrorText('Illegal nickname');
    }
  };

  const onClickEdit = useCallback(() => {
    setError(false);
    setErrorText('');
    setEditMode(true);
    onEnterEditMode(true);
    setNick(publicProfileUserData!.nick);
  }, [publicProfileUserData?.nick]);

  const onSaveNick = useCallback(async () => {
    if (errorNotifId.current) {
      dismissNotification(errorNotifId.current);
      errorNotifId.current = undefined;
    }

    if (nick !== publicProfileUserData?.nick) {
      setIsLoading(true);
      setError(false);
      setErrorText('');

      let errorObject;

      try {
        const checkAvailabiliyuDataFetchRes = await fetchPublicProfile<unknown>({
          method: 'GET',
          url: `/handler-availability/${nick}`,
          cache: false,
          timeout: TIMEOUT,
        });

        logging.logDebug(
          'SettingsPage -> PublicProfile -> Nick -> onSaveNick -> checkAvailabiliyuDataFetchRes',
          checkAvailabiliyuDataFetchRes,
        );

        if (checkAvailabiliyuDataFetchRes.error.code === '0') {
          if ((checkAvailabiliyuDataFetchRes.data as any).available) {
            const savePublicProfileDataFetchRes = await savePublicProfileData(userData.firstName, userData.lastName, userData.email, nick);
            const body = editMode ? 'Successfully changed nickname' : 'Successfully created nickname';
            const header = editMode ? 'Edit nickname' : 'Creating nickname';

            if ((savePublicProfileDataFetchRes as IFetchResult).error.code === '0') {
              const notifId = uuidv4();

              const notifProp: NotificationType = {
                id: notifId,
                dismissed: false,
                autohide: true,
                closeButton: false,
                type: 'success',
                header: header,
                body: body,
              };

              addNNotification(notifProp);
              setEditMode(false);
            } else {
              setNick(publicProfileUserData?.nick);

              errorObject = {
                body: (savePublicProfileDataFetchRes as IFetchResult).error.description,
                header: header,
              };
              setEditMode(false);
            }
            //}
          } else {
            errorObject = {
              body: 'Nickname is not available!',
              header: 'Checking nickname availability',
            };

            // setNick(publicProfileUserData?.nick);
            setDisableSave(true);
          }
        } else {
          errorObject = {
            body: checkAvailabiliyuDataFetchRes.error.description,
            header: 'Checking nickname availability',
          };
        }

        if (errorObject) {
          errorNotifId.current = uuidv4();

          const notifProp: NotificationType = {
            id: errorNotifId.current,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: errorObject.header,
            body: errorObject.body,
          };

          setError(true);
          setErrorText(errorObject.body);
          addNNotification(notifProp);
        }

        setIsLoading(false);
        onEnterEditMode(false);
      } catch (error) {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Saving nickname',
          body: error as string,
        };

        setError(true);
        setErrorText(error as string);
        addNNotification(notifProp);

        setEditMode(false);
        setIsLoading(false);
        onEnterEditMode(false);
      }
    } else {
      setEditMode(false);
      setIsLoading(false);
      onEnterEditMode(false);
      setErrorText('');
    }
  }, [publicProfileUserData?.nick, userData.firstName, userData.lastName, userData.email, nick]);

  const onClickUrl = () => {
    if (!errorLoadingPublicProfileData && !disabled && publicProfilePublished) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const onKeyPressUrl = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
      onClickUrl();
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setNick(publicProfileUserData?.nick);
  }, [publicProfileUserData?.nick]);

  useEffect(() => {
    setUrl(`${getPublicProfileUrl()}/${nick}`);

    if (!!nick && nick.length > 0 && !error) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [nick]);

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <div className={styles.description_wrapper}>
        <div className={isLoading || publicProfileUserData?.nick ? styles.disable_text : ''}>
					1. Provide a "<strong>Nickname</strong>", to create your public profile.
        </div>
        <div className={isLoading || publicProfilePublished || !publicProfileUserData?.nick ? styles.disable_text : ''}>
					2. Edit your profile and switch from offline to <strong>online</strong> to make profile available on the web.
        </div>
        <div className={isLoading || !publicProfilePublished || !publicProfileUserData?.nick ? styles.disable_text : ''}>
					3. You need to take your public profile offline in order to edit the nickname.
        </div>
      </div>

      {(!loadedPublicProfileData || !loadedUserData) && !errorLoadingPublicProfileData && <div>Loading data...</div>}

      {(errorLoadingPublicProfileData || (loadedPublicProfileData && loadedUserData)) && (
        <div className={styles.input_wrapper}>
          <div className={styles.url_wrapper}>
            {(!publicProfileUserData?.nick || editMode) && (
              <>
                <div className={styles.url_link}>
                  <div className={styles.url_title}>URL:</div>
                  <InputItem
                    className={styles.input}
                    placeholder='@"nickname"'
                    defaultValue={nick}
                    onValueChange={onValueChange}
                    onEnterPressed={onSaveNick}
                    displayError={error}
                    errorTextClassName={styles.error_text_input}
                    infoTextClassName={styles.error_text_input}
                    errorText={errorText}
                    disabled={isLoading || errorLoadingPublicProfileData}
                    value={nick}
                    maxLength={80}
                  />
                </div>

                <Button
                  variant="primary"
                  text="Save"
                  disabled={disableSave || errorLoadingPublicProfileData}
                  className={styles.action_btn}
                  onClick={onSaveNick}
                  loading={isLoading}
                />
              </>
            )}

            {publicProfileUserData?.nick && !editMode && (
              <>
                <div className={`${styles.url_link}${disabled || !publicProfilePublished ? ` ${styles.disabled}` : ''}`}>
                  <div className={styles.url_title}>URL:</div>
                  <div
                    className={styles.url_clickable}
                    tabIndex={disabled || !publicProfilePublished ? -1 : 0}
                    onKeyPress={onKeyPressUrl}
                    onClick={onClickUrl}
                  >
                    {url}
                  </div>
                </div>
                <Button
                  variant="primary"
                  text="Edit"
                  disabled={publicProfilePublished || disabled || errorLoadingPublicProfileData}
                  className={styles.action_btn}
                  onClick={onClickEdit}
                  loading={isLoading}
                />
              </>
            )}
          </div>

          {!errorLoadingPublicProfileData && !error && (!publicProfileUserData?.nick || editMode) && (
            <div className={styles.url}>{url}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Nick;
