import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import {
  CertificateType,
  ComponentTypes,
  IActivity,
  ICertificates,
  IDefault,
  IDiscord,
  IInterests,
  ISocialMedia,
  ISpaces,
  IUserProfileConfiguration,
  SocialMediaTypes,
  TagType,
  IPublicProfileUserData,
} from '../Component/userlib_new/utils/db-interfaces';
import { TIMEOUT } from '../Component/userlib_new/utils/types-interfaces';
import { IFetchResult } from '../SharedLib/Util/FetchUtil';
import { CustomTypeMod } from '../SharedLib/Util/InterfaceAndTypeUtil';
import { fetchPublicProfile } from '../SharedLib/Util/InternalProjects/PublicProfileUtil';
import {
  DEFAULT_PUBLIC_PROFILE_DATA,
  DEFAULT_ACTIVITY_DATA,
  DEFAULT_SAVE_ACTIVITY_DATA,
  DEFAULT_DISCORD_DATA,
  DEFAULT_SPACES_DATA,
  DEFAULT_CERTIFICATES_DATA,
  DEFAULT_INTERESTS_DATA,
} from './data/default-public-profile-data';

export type PublicProfileContextState = {
	interestsData?: IInterests,
	certificatesData?: ICertificates,
	activityData?: IActivity,
	discordData?: IDiscord,
	socialMediaData: ISocialMedia[],
	spaceData?: ISpaces,
	returnFromAbuseUrl?: string,
	loadedPublicProfileData: boolean,
	publicProfileUserData?: IPublicProfileUserData,
	publicProfilePublished: boolean,
	errorLoadingPublicProfileData: boolean,
	errorSavingPublicProfileData: boolean,
	userId?: string,
	disablePublicProfile: boolean,
	setReturnFromAbuseUrl: (value: string | undefined) => void,
	setPublicProfileData: (data: IUserProfileConfiguration) => void,
	setInterestsData: (data: IInterests) => void,
	setCertificatesData: (data: ICertificates) => void,
	setActivityData: (data: IActivity) => void,
	setDiscordData: (data: IDiscord) => void,
	setSpaceData: (data: ISpaces) => void,
	setSocialMediaData: (data: ISocialMedia[]) => void,
	setPublicProfileUserData: (data: IPublicProfileUserData) => void,
	setPublicProfilePublished: (published: boolean) => void,
	setErrorLoadingPublicProfileData: (value: boolean) => void,
	savePublicProfileData: (firstname?: string, lastName?: string, email?: string, nick?: string) => Promise<unknown>,
	getSections: () => (IDiscord | IActivity | ICertificates | ISpaces | IInterests | IDefault)[],
	setDisablePublicProfile: (value: boolean) => void,
	setErrorSavingPublicProfileData: (value: boolean) => void,
};

const DEFAULT_STATE: PublicProfileContextState = {
  interestsData: undefined,
  certificatesData: undefined,
  activityData: undefined,
  discordData: undefined,
  spaceData: undefined,
  socialMediaData: [],
  publicProfileUserData: undefined,
  returnFromAbuseUrl: undefined,
  loadedPublicProfileData: false,
  publicProfilePublished: false,
  errorLoadingPublicProfileData: false,
  errorSavingPublicProfileData: false,
  userId: undefined,
  disablePublicProfile: false,
  setReturnFromAbuseUrl: () => {
    //
  },
  setPublicProfileData: () => {
    //
  },
  setInterestsData: () => {
    //
  },
  setCertificatesData: () => {
    //
  },
  setActivityData: () => {
    //
  },
  setDiscordData: () => {
    //
  },
  setSpaceData: () => {
    //
  },
  setSocialMediaData: () => {
    //
  },
  setPublicProfileUserData: () => {
    //
  },
  setPublicProfilePublished: () => {
    //
  },
  setErrorLoadingPublicProfileData: () => {
    //
  },
  setErrorSavingPublicProfileData: () => {
    //
  },
  savePublicProfileData: () => Promise.resolve(),
  getSections: () => [],
  setDisablePublicProfile: () => {
    //
  },
};

export const certificatesSort = (item1: CertificateType, item2: CertificateType) => {
  if (item1.title > item2.title) return 1;
  if (item1.title < item2.title) return -1;
  return 0;
};

export const tagsSort = (item1: TagType, item2: TagType) => {
  if (item1.tagName > item2.tagName) return 1;
  if (item1.tagName < item2.tagName) return -1;
  return 0;
};

const PublicProfileStateContext = createContext<PublicProfileContextState>(DEFAULT_STATE);

const PublicProfileStateProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [interestsData, setInterestsDataItem] = useState<IInterests>();
  const [certificatesData, setCertificatesDataItem] = useState<ICertificates>();
  const [activityData, setActivityDataItem] = useState<IActivity>();
  const [discordData, setDiscordDataItem] = useState<IDiscord>();
  const [spaceData, setSpaceDataItem] = useState<ISpaces>();
  const [returnFromAbuseUrl, setReturnFromAbuseUrl] = useState<string>();
  const [socialMediaData, setSocialMediaData] = useState<ISocialMedia[]>(DEFAULT_PUBLIC_PROFILE_DATA.socialMedias || []);
  const [loadedPublicProfileData, setLoadedPublicProfileData] = useState(false);
  const [publicProfileUserData, setPublicProfileUserDataItem] = useState<IPublicProfileUserData>();
  const [publicProfilePublished, setPublicProfilePublishedItem] = useState(false);
  const [userId, setUserId] = useState<string | undefined>();
  const [errorLoadingPublicProfileData, setErrorLoadingPublicProfileData] = useState(false);
  const [errorSavingPublicProfileData, setErrorSavingPublicProfileData] = useState(false);
  const [disablePublicProfile, setDisablePublicProfile] = useState(false);

  const setPublicProfilePublished = useCallback((data: boolean) => {
    setPublicProfilePublishedItem(data);
  }, []);

  const setPublicProfileUserData = useCallback((data: IPublicProfileUserData) => {
    setPublicProfileUserDataItem(data);
  }, []);

  const setActivityData = useCallback((data: IActivity) => {
    const myData = { ...DEFAULT_ACTIVITY_DATA, ...data };
    setActivityDataItem(myData);
  }, []);

  const setDiscordData = useCallback((data: IDiscord) => {
    const myData = { ...DEFAULT_DISCORD_DATA, ...data };
    setDiscordDataItem(myData);
  }, []);

  const setSpaceData = useCallback((data: ISpaces) => {
    const myData = { ...DEFAULT_SPACES_DATA, ...data };
    setSpaceDataItem(myData);
  }, []);

  const setCertificatesData = useCallback((data: ICertificates) => {
    const myData = { ...DEFAULT_CERTIFICATES_DATA, ...data };
    setCertificatesDataItem(myData);
  }, []);

  const setInterestsData = useCallback((data: IInterests) => {
    const myData = { ...DEFAULT_INTERESTS_DATA, ...data };
    myData.tags.sort(tagsSort);
    setInterestsDataItem(myData);
  }, []);

  const getSections = useCallback(() => {
    if (activityData && /* discordData && */ certificatesData && spaceData && interestsData) {
      return [activityData, /* discordData, */ certificatesData, spaceData, interestsData];
    }

    return [];
  }, [activityData, certificatesData, spaceData, interestsData /* , discordData */]);

  /**
	 * Get data needed to save the public profile
	 */
  const getPublicProfileData = useCallback(
    (firstName?: string, lastName?: string, email?: string, nick?: string): IUserProfileConfiguration => {
      const data: IUserProfileConfiguration = { ...DEFAULT_PUBLIC_PROFILE_DATA };

      if (publicProfileUserData) {
        data.id = nick || publicProfileUserData.nick;
        data.createdAt = publicProfileUserData.createdAt;
        data.updatedAt = publicProfileUserData.updatedAt;
        data.basic.aboutMe = publicProfileUserData.aboutMe;
        data.basic.email = { ...publicProfileUserData.email, value: email || publicProfileUserData.email.value };
        data.basic.firstName = firstName || publicProfileUserData.firstName;
        data.basic.lastName = lastName || publicProfileUserData.lastName;
        data.basic.phone = { ...publicProfileUserData.phone };
        data.basic.profilePicture = publicProfileUserData.profilePicture;
        data.basic.website = { ...publicProfileUserData.website };
        data.published = publicProfilePublished;
        data.sections = [
          { ...DEFAULT_SAVE_ACTIVITY_DATA, visible: activityData?.visible || false },
          /* discordData!, */ certificatesData!,
					spaceData!,
					interestsData!,
        ];

        data.socialMedias = socialMediaData;

        data.userId = userId;
      }

      return data;
    },
    [
      publicProfileUserData,
      activityData,
      discordData,
      certificatesData,
      spaceData,
      interestsData,
      socialMediaData,
      publicProfilePublished,
      userId,
    ],
  );

  /**
	 * Saving the public profile data object to the server
	 */
  const savePublicProfileData = useCallback(
    (
      firstName?: string,
      lastName?: string,
      email?: string,
      nick?: string,
    ): Promise<
			CustomTypeMod<
				IFetchResult,
				{
					data: IUserProfileConfiguration,
				}
			>
		> => {
      if (nick || (publicProfileUserData && publicProfileUserData.nick)) {
        setDisablePublicProfile(true);
        setErrorSavingPublicProfileData(false);

        const data = getPublicProfileData(firstName, lastName, email, nick);

        return fetchPublicProfile<IUserProfileConfiguration>({
          method: 'POST',
          url: '/user-profile',
          timeout: TIMEOUT,
          data,
        })
          .then(response => {
            if (response.error.code === '0') {
              if (firstName && lastName && email) {
                const userData = { ...publicProfileUserData!, email: { ...publicProfileUserData!.email } };
                userData.email.value = email;
                userData.firstName = firstName;
                userData.lastName = lastName;

                if (nick) {
                  userData.nick = nick;
                }

                setPublicProfileUserData(userData);
              }
            } else {
              setErrorSavingPublicProfileData(true);
            }

            setDisablePublicProfile(false);
            return response;
          })
          .catch(error => {
            setDisablePublicProfile(false);
            setErrorSavingPublicProfileData(true);
            return error;
          });
      } else {
        return Promise.reject('No nickname set.');
      }
    },
    [publicProfileUserData, publicProfileUserData?.nick, getPublicProfileData, setPublicProfileUserData],
  );

  const setPublicProfileData = useCallback(
    (data: IUserProfileConfiguration) => {
      const { sections } = data;

      sections.forEach((item: IDefault) => {
        switch (item.type) {
        case ComponentTypes.Interests:
          setInterestsData(item as IInterests);
          break;
        case ComponentTypes.Certificates:
          (item as ICertificates).certificates.sort(certificatesSort);
          setCertificatesData(item as ICertificates);
          break;
        case ComponentTypes.Activity:
          setActivityData(item as IActivity);
          break;
        case ComponentTypes.Discord:
          setDiscordData(item as IDiscord);
          break;
        case ComponentTypes.Spaces:
          setSpaceData(item as ISpaces);
          break;
        default:
        }
      });

      if (data.socialMedias && data.socialMedias.length > 0) {
        const tmpSocialData = DEFAULT_PUBLIC_PROFILE_DATA.socialMedias;

        data.socialMedias.forEach(item => {
          const index = tmpSocialData!.findIndex(socialItem => socialItem.type === item.type);
          if (index > -1) {
						tmpSocialData![index] = { ...tmpSocialData![index], ...item };
          }
        });

        setSocialMediaData(tmpSocialData!);
      }

      const userData: IPublicProfileUserData = {
        nick: data.id || '',
        firstName: data.basic.firstName || '',
        lastName: data.basic.lastName || '',
        email: data.basic.email || { value: '', visible: false },
        phone: data.basic.phone || { value: '', visible: false },
        website: data.basic.website || { value: '', visible: false },
        aboutMe: data.basic.aboutMe || '',
        createdAt: data.createdAt || 0,
        updatedAt: data.updatedAt || 0,
        profilePicture: data.basic.profilePicture || '',
      };

      setPublicProfileUserData(userData);

      setPublicProfilePublished(data.published || false);

      setUserId(data.userId || '');

      setLoadedPublicProfileData(true);
    },
    [setPublicProfileUserData, setInterestsData, setCertificatesData, setActivityData, setDiscordData, setSpaceData],
  );

  // hook to change email in socialMedia when changed in userData
  useEffect(() => {
    if (publicProfileUserData) {
      const emailIndex = socialMediaData.findIndex(item => item.type === SocialMediaTypes.Email);

      if (emailIndex > -1 && socialMediaData[emailIndex].userName !== publicProfileUserData.email.value) {
        const mySocialMediaData = [...socialMediaData];
        mySocialMediaData[emailIndex].userName = publicProfileUserData.email.value;
        setSocialMediaData(mySocialMediaData);
      }
    }
  }, [publicProfileUserData?.email.value, socialMediaData]);

  // hook to change phone in socialMedia when changed in userData
  useEffect(() => {
    if (publicProfileUserData) {
      const phoneIndex = socialMediaData.findIndex(item => item.type === SocialMediaTypes.Phone);

      if (phoneIndex > -1 && socialMediaData[phoneIndex].userName !== publicProfileUserData.phone.value) {
        const mySocialMediaData = [...socialMediaData];
        mySocialMediaData[phoneIndex].userName = publicProfileUserData.phone.value;
        setSocialMediaData(mySocialMediaData);
      }
    }
  }, [publicProfileUserData?.phone.value, socialMediaData]);

  const value: PublicProfileContextState = useMemo(
    () => ({
      interestsData,
      certificatesData,
      activityData,
      discordData,
      socialMediaData,
      spaceData,
      returnFromAbuseUrl,
      loadedPublicProfileData,
      publicProfileUserData,
      publicProfilePublished,
      errorLoadingPublicProfileData,
      errorSavingPublicProfileData,
      userId,
      disablePublicProfile,
      setPublicProfileData,
      setReturnFromAbuseUrl,
      setInterestsData,
      setCertificatesData,
      setActivityData,
      setDiscordData,
      setSpaceData,
      setSocialMediaData,
      setPublicProfileUserData,
      setPublicProfilePublished,
      setErrorLoadingPublicProfileData,
      savePublicProfileData,
      getSections,
      setDisablePublicProfile,
      setErrorSavingPublicProfileData,
    }),
    [
      interestsData,
      certificatesData,
      activityData,
      discordData,
      socialMediaData,
      spaceData,
      returnFromAbuseUrl,
      loadedPublicProfileData,
      publicProfileUserData,
      publicProfilePublished,
      errorLoadingPublicProfileData,
      errorSavingPublicProfileData,
      userId,
      disablePublicProfile,
      setPublicProfileData,
      setReturnFromAbuseUrl,
      setInterestsData,
      setCertificatesData,
      setActivityData,
      setDiscordData,
      setSpaceData,
      setSocialMediaData,
      setPublicProfileUserData,
      setPublicProfilePublished,
      savePublicProfileData,
      getSections,
    ],
  );

  return <PublicProfileStateContext.Provider value={value}>{children}</PublicProfileStateContext.Provider>;
};

export { PublicProfileStateProvider, PublicProfileStateContext };
