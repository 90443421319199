// import * as logging from '../Util/LoggingUtil';
import { getCookie } from './CookieUtil';

export type EnvType = 'prod' | 'staging' | 'develop' // ideally should be 'prod' | 'stage' | 'dev'

let _cachedEnvFromUrl: EnvType;

const getEnvFromUrl = (): EnvType => {
  const host = window.location.hostname;
  // logging.logDebug('getEnvFromUrl -> host: ', host);
  // logging.logDebug('getEnvFromUrl -> _cachedEnvFromUrl(1): ', _cachedEnvFromUrl);

  if (typeof _cachedEnvFromUrl !== 'undefined') {
    return _cachedEnvFromUrl;
  }

  let output: EnvType = 'prod';

  if (
    host === 'profile.w3schools.com' ||
    host === 'my-learning.w3schools.com' ||
    host === 'spaces.w3schools.com' ||
    host === 'pathfinder.w3schools.com' ||
    host === 'www.w3profile.com' ||
    host === 'w3profile.com'
  ) {
    output = 'prod';
  } else if (
    host === 'profile-stage.w3schools.com' ||
    host === 'myl-stage.w3schools.com' ||
    host === 'www.w3profile-stage.com' ||
    host === 'spaces-stage.w3schools.com' ||
    host === 'pathfinder-stage.w3schools.com' ||
    host === 'ui.w3stages.com'
  ) {
    output = 'staging';
  } else if (
    host === 'profile-dev.w3schools.com' ||
    host === 'profile-local.w3schools.com' ||
    host === 'www.w3profile-local.com' ||
    host === 'www.w3profile-dev.com' ||
    host === 'myl-dev.w3schools.com' ||
    host === 'myl-local.w3schools.com' ||
    host === 'spaces-dev.w3schools.com' ||
    host === 'spaces-local.w3schools.com' ||
    host === 'pathfinder-dev.w3schools.com' ||
    host === 'pathfinder-local.w3schools.com' ||
    host === 'prototyping.w3sdevelop.com' ||
    host === 'localhost'
  ) {
    output = 'develop';
  }

  _cachedEnvFromUrl = output;

  // logging.logDebug('getEnvFromUrl -> _cachedEnvFromUrl(2): ', _cachedEnvFromUrl);

  return output;
};

let _cachedAppNameFromUrl: string;

export const getAppFromUrl = () => {
  // const host = window.location.host
  const host = window.location.hostname;
  // logging.logDebug('getAppFromUrl -> host: ', host);
  // logging.logDebug('getAppFromUrl -> _cachedAppNameFromUrl(1): ', _cachedAppNameFromUrl);

  if (typeof _cachedAppNameFromUrl !== 'undefined') {
    return _cachedAppNameFromUrl;
  }

  let output = '';

  if (
    host === 'profile.w3schools.com' ||
    host === 'profile-stage.w3schools.com' ||
    host === 'profile-dev.w3schools.com' ||
    host === 'profile-local.w3schools.com'
  ) {
    output = 'profile';
  } else if (
    host === 'spaces.w3schools.com' ||
    host === 'spaces-stage.w3schools.com' ||
    host === 'spaces-dev.w3schools.com' ||
    host === 'spaces-local.w3schools.com' ||
    host === 'prototyping.w3sdevelop.com'
  ) {
    output = 'spaces';
  } else if (
    host === 'pathfinder.w3schools.com' ||
    host === 'pathfinder-stage.w3schools.com' ||
    host === 'pathfinder-dev.w3schools.com' ||
    host === 'pathfinder-local.w3schools.com'
  ) {
    output = 'pathfinder';
  } else if (
    host === 'my-learning.w3schools.com' ||
    host === 'myl-stage.w3schools.com' ||
    host === 'myl-dev.w3schools.com' ||
    host === 'myl-local.w3schools.com'
  ) {
    output = 'my-learning';
  } else if (
    host === 'www.w3profile-local.com' ||
    host === 'www.w3profile-stage.com' ||
    host === 'www.w3profile-dev.com' ||
    host === 'w3profile.com' ||
    host === 'www.w3profile.com'
  ) {
    output = 'public-profile';
  }

  _cachedAppNameFromUrl = output;

  // logging.logDebug('getAppFromUrl -> _cachedAppNameFromUrl(2): ', _cachedAppNameFromUrl);

  return output;
};

export const getAppName = () => {
  return getAppFromUrl();
};

export const appIsProfile = (): boolean => {
  return getAppName() === 'profile';
};

let _cachedEnv: EnvType;

export const getEnv = (): EnvType => {
  // logging.logDebug('getEnv -> _cachedEnv(1): ', _cachedEnv);

  if (typeof _cachedEnv !== 'undefined') {
    return _cachedEnv;
  }

  let output: EnvType = getCookie('alternative_auth') as EnvType;

  if (output === undefined) {
    output = getEnvFromUrl();
  }

  _cachedEnv = output;

  // logging.logDebug('getEnv -> _cachedEnv(2): ', _cachedEnv);

  return output;
};

export const envIsProd = (): boolean => {
  return getEnv() === 'prod';
};

export const getProfileUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://profile.w3schools.com';

    case 'develop':
      return 'https://profile-dev.w3schools.com';

    case 'staging':
      return 'https://profile-stage.w3schools.com';

    default:
      return 'https://profile.w3schools.com';
  }
};

export const getTopNavBarUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://nav.w3schools.com';

    case 'develop':
      return 'https://nav-dev.w3schools.com';

    case 'staging':
      return 'https://nav-stage.w3schools.com';

    default:
      return 'https://nav.w3schools.com';
  }
};

export const forgotPwdUrl = () => {
  return `${getProfileUrl()}/reset`;
};

export const getLoginUrl = () => {
  return `${getProfileUrl()}/log-in`;
};

export const getLogoutUrl = () => {
  return `${getProfileUrl()}/log-out`;
};

/**
 * @deprecated Legacy user session refresh logic kept for backwards compatibility
 */
export const getProfileRefreshSessionUrl = () => {
  return `${getProfileUrl()}/refresh-session`;
};

export const getProfileSettingsUrl = () => {
  return `${getProfileUrl()}/profile`;
};

export const getLegacyProfileSettingsUrl = () => {
  return `${getProfileUrl()}/settings`;
};

export const getListOfProfileUserPrivatePageUrls = () => {
  return [getProfileSettingsUrl(), getLegacyProfileSettingsUrl()];
};

/**
 * @returns current url without "search" and "hash" url parts
 */
export const getCurrentUrlClean = () => {
  if (window.location.pathname === '/') {
    return window.location.origin;
  }

  return `${window.location.origin}${window.location.pathname}`;
};

export const getMyLearningUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://my-learning.w3schools.com';

    case 'develop':
      return 'https://myl-dev.w3schools.com';

    case 'staging':
      return 'https://myl-stage.w3schools.com';

    default:
      return 'https://my-learning.w3schools.com';
  }
};

export const getPublicProfileUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://www.w3profile.com';

    case 'develop':
      return 'https://www.w3profile-dev.com';

    case 'staging':
      return 'https://www.w3profile-stage.com';

    default:
      return 'https://www.w3profile.com';
  }
};

export const getMyCertificatesUrl = () => {
  const myUrl = `${getMyLearningUrl()}/certificates`;
  return myUrl;
};

export const getMyLearningLegacyUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://my-learning-legacy.w3schools.com';

    case 'develop':
      return 'https://my-learning-dev.w3schools.com'; // already legacy

    case 'staging':
      return 'https://my-learning-legacy-stage.w3schools.com';

    default:
      return 'https://my-learning-legacy.w3schools.com';
  }
};

export const getBillingUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://billing.w3schools.com';

    case 'staging':
      return 'https://billing-stage.w3schools.com';

    case 'develop':
      return 'https://billing-dev.w3schools.com';

    default:
      return 'https://billing.w3schools.com';
  }
};

export const getUpgradeLink = () => {
  return `${getBillingUrl()}/products/spaces`;
};

export const getBuyTokensUrl = () => {
  return `${getBillingUrl()}/addons/tenk-kai-tokens`;
};

export const getSpacesUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://spaces.w3schools.com';

    case 'staging':
      return 'https://spaces-stage.w3schools.com';

    case 'develop':
      return 'https://spaces-dev.w3schools.com';

    default:
      return 'https://spaces.w3schools.com';
  }
};

export const getPathfinderUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://pathfinder.w3schools.com';

    case 'staging':
      return 'https://pathfinder-stage.w3schools.com';

    case 'develop':
      return 'https://pathfinder-dev.w3schools.com';

    default:
      return 'https://pathfinder.w3schools.com';
  }
};

export const getClassicUrl = () => {
  const env = getEnv();

  if (env === 'develop') {
    return 'https://kte9uk46s.w3schools.com';
  } else if (env === 'staging') {
    return 'https://ssf18kfg1.w3schools.com';
  }

  return 'https://www.w3schools.com';
};

export const getSpacesDomainsUrl = () => {
  switch (getEnv()) {
    case 'prod':
      return 'https://spaces.w3schools.com/domains/start/';

    case 'staging':
      return 'https://spaces-stage.w3schools.com/domains/start/';

    case 'develop':
      return 'https://spaces-dev.w3schools.com/domains/start/';

    default:
      return 'https://spaces.w3schools.com/domains/start/';
  }
};

export const clearEnvCaches = () => {
  // @ts-ignore
  _cachedEnv = undefined;
  // @ts-ignore
  _cachedEnvFromUrl = undefined;
  // @ts-ignore
  _cachedAppNameFromUrl = undefined;
};
