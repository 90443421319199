import React, { useState } from 'react';
import { authUtil } from '../../../SharedLib/Util/AuthUtil';
import { isAuthError } from '../../../SharedLib/Util/Interfaces';
import Alert from '../../Alert/Alert';
import CustomButton from '../../CustomButton/CustomButton';
import { LoginView } from '../LoginPanel/LoginPanel';
import styles from './ResetPasswordForm.module.scss';

export interface IResetPasswordFormProps {
  className?: string,
  setView: React.Dispatch<React.SetStateAction<LoginView>>,
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({ className, setView }) => {
  const [email, setEmail] = useState('');
  const [resetDone, setResetDone] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await authUtil.sendResetPassword(email);
    setResetDone(true);
    return;
  }

  const resetPasswordDone = () => {

    return (
      <div className={styles.reset_pasword_done}>
        <label className={styles.reset_password_email_label + ' ' + styles.text}>Email</label>
        <Alert
          message={`We’ve sent an email to ${email} with instructions.`}
          type="success" />
        <div className={styles.reset_done_message + ' ' + styles.text}>
          If the email doesn't show up soon, check your spam folder. We sent it from login@w3schools.com.
        </div>
        <CustomButton variant='primary' className={styles.reset_password_button} onClick={() => setView(LoginView.LOGIN)}>Return to login</CustomButton>
      </div>
    );
  };

  return (
    <div className={styles.reset_password_form + (className ? ` ${className}` : '')}>
      <div className={styles.reset_password_header + ' ' + styles.text}>Reset your password</div>
      <div className={styles.reset_password_text + ' ' + styles.text}>
        If the account exist, we will email you instructions to reset the password.
      </div>
      <hr />

      {resetDone ?
        resetPasswordDone() :
        <form className={styles.email_input} onSubmit={handleSubmit}>
          <label className={styles.reset_password_email_label + ' ' + styles.text}>Email</label>
          <input type="text" placeholder="email" className={styles.reset_password_email_input} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
          <div className={styles.reset_password_buttons}>
            <CustomButton width={'167px'} variant='secondary' className={styles.reset_password_button} onClick={() => setView(LoginView.LOGIN)}>Return to login</CustomButton>
            <CustomButton width={'167px'} type='submit' className={styles.reset_password_button}>Reset Password</CustomButton>
          </div>
        </form>}
    </div>
  );
};

export default ResetPasswordForm;
