import React, { useEffect, useState } from 'react';
import { logging } from '../../../../SharedLib/Util/LoggingUtil';
import styles from './CheckBox.module.scss';

export interface ICheckBox {
	/**
	 * Any classes to be added to the component
	 */
	className?: string,
	/**
	 * If the button should be checked or not
	 */
	checked: boolean,
	/**
	 * Displays the checkbox, otherwise only display children
	 * @default true
	 */
	editMode?: boolean,
	/**
	 * Set chekbox disabled
	 */
	disabled?: boolean,
	/**
	 * Size of the checkbox
	 * @default md
	 */
	size?: 'sm' | 'md',
	/**
	 * Checkbox displayed after children, otherwise displayed before children
	 */
	checkAfterChild?: boolean,
	/**
	 * Text for screen readers
	 */
	ariaLabel: string,
	onChange: (checked: boolean) => void,
}

const CheckBox = ({
  editMode = true,
  className,
  size = 'md',
  checkAfterChild,
  disabled,
  checked,
  ariaLabel,
  onChange,
  children,
}: React.PropsWithChildren<ICheckBox>) => {
  const [wrapperClasses, setWrapperClasses] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    let myClasses = `${styles.wrapper} ${styles[`size_${size}`]}`;

    if (disabled) {
      myClasses += ` ${styles.disabled}`;
    }

    if (className) {
      myClasses += ` ${className}`;
    }

    setWrapperClasses(myClasses);
  }, [checkAfterChild, children, className, disabled, size]);

  const onChangeInput = (event: any) => {
    logging.logDebug('Checkbox -> onChangeInput: event', event);
  };

  const onClick = () => {
    if (!disabled) {
      logging.logDebug('Checkbox -> onClick: !isChecked', !isChecked);
      onChange(!isChecked);
    }
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    logging.logDebug('Checkbox -> onKeyPress -> event', event);
    if (event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'space') {
      onClick();
    }
  };

  return (
    <div
      className={wrapperClasses}
      onClick={onClick}
      tabIndex={disabled ? -1 : 0}
      onKeyPress={onKeyPress}
      aria-label={`Checkbox: ${ariaLabel}, ${checked}`}
    >
      {children && checkAfterChild && children}

      {editMode && (
        <>
          <input
            tabIndex={-1}
            className={styles.selection}
            disabled={disabled}
            type="checkbox"
            checked={isChecked}
            onChange={onChangeInput}
          ></input>
          <span tabIndex={-1} className={styles.checkmark}></span>
        </>
      )}

      {children && !checkAfterChild && children}
    </div>
  );
};

export default CheckBox;
