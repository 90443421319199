import { CustomTypeMod, OperationResultType } from './InterfaceAndTypeUtil';
import { logDebug } from './LoggingUtil';

export interface IGlobalEntityLookupControlRef {
  lazyHalt: boolean, // waits lookup interval to complete
  _halt?: () => void,
  _completed?: boolean,
  _elapsedTime?: number, // milliseconds
}

export const globalEntityOnLoadCallback = <T>({
  globalEntityName,
  onLoadCallback,
  lookupControlRef = { lazyHalt: false },
  lookupTimeout = 300,
  lookupIntervalStep = 5,
  debug = true,
}: {
  globalEntityName: string,
  onLoadCallback: (res: CustomTypeMod<OperationResultType, {
    data: T,
  }>) => void,
  lookupControlRef?: IGlobalEntityLookupControlRef,
  lookupTimeout?: number, // seconds
  lookupIntervalStep?: number, // milliseconds
  debug?: boolean,
}) => {
  const globalScopeRef: any = window;

  let intervalHandler: any = null;

  lookupControlRef._completed = false;

  lookupControlRef._elapsedTime = 0;

  lookupControlRef._halt = () => {
    clearInterval(intervalHandler);

    if (lookupControlRef._completed) {
      return;
    }

    lookupControlRef._completed = true;

    return onLoadCallback({
      error: {
        code: 'GEOLCHLT',
        description: 'globalEntityOnLoadCallback halt',
        meta: {
          globalEntityName,
        }
      },
      data: globalScopeRef[globalEntityName] as T
    });
  };

  if (typeof globalScopeRef[globalEntityName] !== 'undefined') {
    lookupControlRef._completed = true;
    lookupControlRef._halt = undefined;

    return onLoadCallback({
      error: {
        code: '0'
      },
      data: globalScopeRef[globalEntityName] as T
    });
  }

  const lookupTimeoutMs = lookupTimeout * 1000;

  intervalHandler = setInterval(() => {
    if (debug) {
      logDebug(`lookupControlRef: ${JSON.stringify(lookupControlRef)}`);
    }

    if (lookupControlRef.lazyHalt) {
      clearInterval(intervalHandler);

      lookupControlRef._completed = true;
      lookupControlRef._halt = undefined;

      return onLoadCallback({
        error: {
          code: 'GEOLCLHLT',
          description: 'globalEntityOnLoadCallback lazy halt',
          meta: {
            globalEntityName,
          }
        },
        data: globalScopeRef[globalEntityName] as T
      });
    }

    if (typeof globalScopeRef[globalEntityName] !== 'undefined') {
      clearInterval(intervalHandler);

      lookupControlRef._completed = true;
      lookupControlRef._halt = undefined;

      return onLoadCallback({
        error: {
          code: '0'
        },
        data: globalScopeRef[globalEntityName] as T
      });
    }

    lookupControlRef._elapsedTime! += lookupIntervalStep;

    if (lookupControlRef._elapsedTime! >= lookupTimeoutMs) {
      clearInterval(intervalHandler);

      lookupControlRef._completed = true;
      lookupControlRef._halt = undefined;

      return onLoadCallback({
        error: {
          code: 'GEOLCTO',
          description: 'globalEntityOnLoadCallback timeout',
          meta: {
            globalEntityName,
            lookupTimeout,
          }
        },
        data: globalScopeRef[globalEntityName] as T
      });
    }
  }, lookupIntervalStep);
};

export const globalEntityOnLoad = async <T>({
  globalEntityName,
  lookupControlRef = { lazyHalt: false },
  lookupTimeout = 300,
  lookupIntervalStep = 5,
}: {
  globalEntityName: string,
  lookupControlRef?: IGlobalEntityLookupControlRef,
  lookupTimeout?: number, // seconds
  lookupIntervalStep?: number, // milliseconds
}): Promise<CustomTypeMod<OperationResultType, {
  data: T,
}>> => {
  return new Promise((resolve) => {
    globalEntityOnLoadCallback<T>({
      globalEntityName,
      onLoadCallback: resolve,
      lookupControlRef,
      lookupTimeout,
      lookupIntervalStep,
    });
  });
};
