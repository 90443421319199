import React from 'react';

export interface IStarIcon {
	className?: string,
}

const StarIcon = ({ className }: IStarIcon) => (
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className || ''}>
    <path
      d="M13.4054 1.76669L9.77759 9.12225L1.66092 10.3056C0.205365 10.5167 -0.377968 12.3111 0.677587 13.3389L6.54981 19.0611L5.16092 27.1445C4.91092 28.6056 6.44981 29.7 7.7387 29.0167L14.9998 25.2L22.2609 29.0167C23.5498 29.6945 25.0887 28.6056 24.8387 27.1445L23.4498 19.0611L29.322 13.3389C30.3776 12.3111 29.7943 10.5167 28.3387 10.3056L20.222 9.12225L16.5943 1.76669C15.9443 0.455582 14.0609 0.438915 13.4054 1.76669Z"
      fill="#FFB800"
    />
  </svg>
);

StarIcon.defaultProps = {
  className: undefined,
};

export default StarIcon;
