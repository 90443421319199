/**
 * UserSessionUtil
 */
import { CustomTypeMod, IOperationResult, OperationResultType, StrKeyToBoolMapTypeMod } from './InterfaceAndTypeUtil';
import { getCognitoConfig, ICognitoConfig } from '../Cfg/CognitoCfg';
import { Exception, getMetaPreparedFromException } from './ExceptionUtil';
import { getDictSnapshot, parseJson } from './DataUtil';
import { fetchProfile, fetchProfileUserSession } from './InternalProjects/ProfileUtil';
import {
  getLocalCurrentUts,
  getLocalCurrentUtus,
} from './TimeUtil';
import { IChangeStatusCodeInUserSessionCookiesProps, ICreateUserSessionResult, IProcessUserSessionProps, IProcessUserSessionResult, IRefreshUserSessionResult, IUserSessionLifespan, IUserSessionLogOut, IUserSessionMeta, IUserSessionRedirectProps, IVerifyUserSessionResult, UserSessionStatusCodeEnum } from './Interfaces';
import { getCookie, resetCookie, getCookieValueEncodedStr, getCookieValueDecodedStr } from './CookieUtil';
import { logging } from './LoggingUtil';
import { appIsProfile, getCurrentUrlClean, getLoginUrl, getProfileUrl, getProfileRefreshSessionUrl, getLogoutUrl } from './EnvironmentUtil';
import { fetchSpaces } from './InternalProjects/SpacesUtil';
import { unpack, syncUnpack } from './cookie/UicCryptUtil';
import { sleep } from './DelayUtil';

export interface IUserSessionInfo {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  fullName: string,
  subscriptionPlan: string,
  noUpsell: boolean,
  accessToken: string | undefined,
  sessionIsPresent: boolean | undefined, // session should be valid ( acceptable to be expired )
  sessionVerificationRes: IVerifyUserSessionResult | undefined,
  sessionProcessedUtms: number, // unix timestamp
  isLoggedIn: boolean | undefined, // will be known after sessionIsPresent and sessionProcessedUtms
  adFree: boolean,
}

export type IGetUserSessionInfoRes = IOperationResult<IUserSessionInfo>;

export const getUserSessionInfo = ({
  skipUicValidation = false,
}: {
  skipUicValidation?: boolean,
} = {}) => {
  const output: IGetUserSessionInfoRes = {
    error: {
      code: '1',
      description: 'Failed performing "getUserSessionInfo"'
    },
    data: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      subscriptionPlan: 'free',
      noUpsell: false,
      accessToken: '',
      sessionIsPresent: undefined,
      sessionVerificationRes: undefined,
      sessionProcessedUtms: 0,
      isLoggedIn: undefined,
      adFree: false,
    }
  };

  const cognitoCfg = getCognitoConfig();

  const verifyUserSessionRes = verifyUserSession({
    cognitoCfg,
    earlyVerifyAccessToken: true,
  });

  output.data.sessionVerificationRes = verifyUserSessionRes;

  output.data.accessToken = verifyUserSessionRes.data.rawStr;

  if (typeof verifyUserSessionRes.data.payload !== 'undefined') { // jwt data extracted
    output.data.id = verifyUserSessionRes.data.payload.sub;
  }

  if (verifyUserSessionRes.error.code !== '0') {
    output.error = verifyUserSessionRes.error;
  }

  const extractUserInfoCookieDataRes = extractUserInfoCookieData({
    skipValidation: skipUicValidation,
  });

  if (typeof extractUserInfoCookieDataRes.data.fname !== 'undefined') { // uic data extracted
    output.data.firstName = extractUserInfoCookieDataRes.data.fname!;
    output.data.lastName = extractUserInfoCookieDataRes.data.lname!;
    output.data.fullName = `${output.data.firstName} ${output.data.lastName}`.trim();
    output.data.email = extractUserInfoCookieDataRes.data.email!;
    output.data.subscriptionPlan = extractUserInfoCookieDataRes.data.plan || 'free';
    output.data.noUpsell = extractUserInfoCookieDataRes.data.noUpsell || false;
    output.data.adFree = extractUserInfoCookieDataRes.data.adFree ?? false;
  }

  if (
    output.error.code === '1'
    && extractUserInfoCookieDataRes.error.code !== '0'
  ) {
    output.error = {
      code: `UIC_${extractUserInfoCookieDataRes.error.code}`,
      description: extractUserInfoCookieDataRes.error.description,
      meta: {
        error: extractUserInfoCookieDataRes.error
      }
    };
  }

  const verifyUserSessionErrorCode = verifyUserSessionRes.error.code;

  if (
    verifyUserSessionErrorCode === '0' || // User session is up and running
    verifyUserSessionErrorCode === 'USSBR' || // User session should be refreshed
    verifyUserSessionErrorCode === 'USHE' || // User session has expired
    verifyUserSessionErrorCode === 'ATHE' || // Access token has expired
    verifyUserSessionErrorCode === 'ATSBR' // Access token should be refreshed
  ) {
    output.data.sessionIsPresent = true;
  }

  if (output.error.code === '1') {
    output.error = { code: '0' };
  }

  return output;
};

export interface IAccessTokenHeader {
  kid: string,
  alg: string,
}

export interface IAccessTokenPayload {
  sub: string,
  event_id: string,
  token_use: string,
  scope: string,
  auth_time: number,
  iss: string,
  exp: number,
  iat: number,
  jti: string,
  client_id: string,
  username: string,
}

let _processUserSessionDethrottleCache: IProcessUserSessionResult | null = null;
let _processUserSessionDethrottleCacheUts = 0;

export const clearProcessUserSessionDethrottleCache = () => {
  _processUserSessionDethrottleCache = null;
  _processUserSessionDethrottleCacheUts = 0;
};

export const processUserSession = async ({
  context,
  handleRedirectionLogic = true,
  preferDethrottleCache = true,
  sessionVerificationRes,
}: IProcessUserSessionProps): Promise<IProcessUserSessionResult> => {
  logging.logDebug(
    '(1) CognitoUtil -> processUserSession -> initialized -> context: ',
    context
  );

  if (appIsProfile()) {
    throw new Exception({
      code: 'CUSPUSBUOOPA',
      description: 'Common user session processing util should be used outside of profile app',
    });
  }

  if (
    preferDethrottleCache &&
    _processUserSessionDethrottleCache !== null &&
    getLocalCurrentUts() - _processUserSessionDethrottleCacheUts < 5 // serve the cached version that is not older than 5 seconds
  ) {
    logging.logDebug(
      '(-1) CognitoUtil -> processUserSession -> _processUserSessionDethrottleCache: ',
      _processUserSessionDethrottleCache
    );

    return _processUserSessionDethrottleCache;
  }

  let output: IProcessUserSessionResult = {
    error: {
      code: 'FPPUS',
      description: 'Failed processing "processUserSession"'
    },
    data: {} as IProcessUserSessionResult['data']
  };

  try {
    const cognitoCfg = getCognitoConfig();

    logging.logDebug(
      '(2) CognitoUtil -> processUserSession -> cognitoCfg: ',
      cognitoCfg
    );

    let cookieUserSessionVerificationRes = sessionVerificationRes || verifyUserSession({
      cognitoCfg
    });

    logging.logDebug(
      '(3) CognitoUtil -> processUserSession -> verifyUserSession -> cookieUserSessionVerificationRes: ',
      cookieUserSessionVerificationRes
    );

    // < refresh
    if (
      cookieUserSessionVerificationRes.error.code === 'USSBR' || // User session should be refreshed
      cookieUserSessionVerificationRes.error.code === 'USHE' || // User session has expired
      cookieUserSessionVerificationRes.error.code === 'ATHE' || // Access token has expired
      cookieUserSessionVerificationRes.error.code === 'ATSBR' // Access token should be refreshed
    ) {
      // refresh
      const legacySession =
        typeof cookieUserSessionVerificationRes.error.meta !== 'undefined' &&
        typeof cookieUserSessionVerificationRes.error.meta.legacy !==
        'undefined' &&
        cookieUserSessionVerificationRes.error.meta.legacy;

      logging.logDebug(
        '(6.1) CognitoUtil -> processUserSession -> refresh -> legacy: ',
        legacySession
      );

      if (!legacySession) {
        // refresh the new way
        const refreshUserSessionRes = await refreshUserSession(
          'CognitoUtil -> processUserSession'
        );

        logging.logDebug(
          '(6.2) CognitoUtil -> processUserSession -> refreshUserSessionRes: ',
          refreshUserSessionRes
        );

        if (refreshUserSessionRes.error.code !== '0') {
          output.error = {
            code: 'RUS_FREFCUS1',
            description: 'Failed refreshing current user sessions',
            meta: refreshUserSessionRes.error
          };

          logging.logDebug(
            '(E2) CognitoUtil -> processUserSession -> output.error: ',
            output.error
          );

          await logOut({
            context: 'CUPUS1',
            skipBackendUserSessionDeletion: false,
            skipUserSessionStatusCodeCookieChange: true,
            reason: {
              refreshUserSessionRes
            }
          });

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          if (handleRedirectionLogic) {
            userSessionRedirectionLogicHandler(output);
          }

          return output;
        }

        // after refresh check again user session data stored in cookies
        cookieUserSessionVerificationRes = verifyUserSession({
          cognitoCfg
        });

        logging.logDebug(
          '(7) CognitoUtil -> processUserSession -> verifyUserSession -> cookieUserSessionVerificationRes: ',
          cookieUserSessionVerificationRes
        );

        if (cookieUserSessionVerificationRes.error.code !== '0') {
          output.error = {
            code: 'RUS_FREFCUS2',
            description: 'Failed refreshing current user sessions',
            meta: cookieUserSessionVerificationRes.error
          };

          logging.logDebug(
            '(E3.1) CognitoUtil -> processUserSession -> output.error: ',
            output.error
          );

          await logOut({
            context: 'CUPUS2.1',
            skipBackendUserSessionDeletion: false,
            skipUserSessionStatusCodeCookieChange: true,
            reason: {
              cookieUserSessionVerificationRes
            }
          });

          _processUserSessionDethrottleCache = output;
          _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

          if (handleRedirectionLogic) {
            userSessionRedirectionLogicHandler(output);
          }

          return output;
        }
      }
    }
    // > refresh

    if (cookieUserSessionVerificationRes.error.code !== '0') {
      output.error = cookieUserSessionVerificationRes.error;

      logging.logDebug(
        '(E4) CognitoUtil -> processUserSession -> output.error: ',
        output.error
      );

      await logOut({
        context: 'CUPUS3',
        skipBackendUserSessionDeletion: false,
        skipUserSessionStatusCodeCookieChange: true,
        reason: {
          cookieUserSessionVerificationRes
        }
      });

      _processUserSessionDethrottleCache = output;
      _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

      if (handleRedirectionLogic) {
        userSessionRedirectionLogicHandler(output);
      }

      return output;
    }

    // all went fine
    output = cookieUserSessionVerificationRes;

    logging.logDebug(
      '(10) CognitoUtil -> processUserSession -> output: ',
      output
    );
  } catch (error) {
    const exc = error as any;
    logging.logDebug('(E1) CognitoUtil -> processUserSession -> exc: ');
    logging.logDebug(exc);

    output.error.meta = getMetaPreparedFromException(exc);

    await logOut({
      context: 'CUPUS5',
      skipBackendUserSessionDeletion: false,
      skipUserSessionStatusCodeCookieChange: true,
      reason: {
        output
      }
    });
  }

  _processUserSessionDethrottleCache = output;
  _processUserSessionDethrottleCacheUts = getLocalCurrentUts();

  return output;
};

/*
 Common user session termination util to be used outside of profile app
*/
export const logOut = async ({
  context,
  skipBackendUserSessionDeletion = false,
  skipUserSessionStatusCodeCookieChange = false,
  reason,
}: IUserSessionLogOut): Promise<void> => {
  logging.logDebug('logOut -> init: ', {
    context,
    skipBackendUserSessionDeletion,
    skipUserSessionStatusCodeCookieChange,
    reason,
  });

  if (appIsProfile()) {
    throw new Exception({
      code: 'CUSTUSBUOOPA',
      description: 'Common user session termination util should be used outside of profile app',
    });
  }

  const currentUserSessionStatusCode = getCookie('userSession');

  setUserSessionCookieDebugMeta({
    origin: window.location.href,
    context,
    description: 'logOut',
    reason,
    prevStatusCode: currentUserSessionStatusCode,
    newStatusCode: skipUserSessionStatusCodeCookieChange
      ? currentUserSessionStatusCode
      : UserSessionStatusCodeEnum.LoggedOut,
    extra: {
      skipUserSessionStatusCodeCookieChange
    }
  });

  await _tmpDebugUserSession(true);

  if (!skipUserSessionStatusCodeCookieChange) {
    changeStatusCodeInUserSessionCookies({
      newStatusCode: UserSessionStatusCodeEnum.LoggedOut
    });
  }
};

/*
 Common user session termination util to be used outside of profile app
*/
export const logOutViaRedirect = ({
  context,
  originUrl,
  reason
}: {
  context?: string,
  originUrl?: string,
  reason?: any,
}) => {
  logging.logDebug('logOutViaRedirect -> context: ', context);
  logging.logDebug('logOutViaRedirect -> originUrl: ', originUrl);

  setUserSessionCookieDebugMeta({
    origin: originUrl,
    context,
    description: 'logOutViaRedirect',
    reason,
    prevStatusCode: getCookie('userSession'),
    newStatusCode: UserSessionStatusCodeEnum.LoggedOut
  });

  changeStatusCodeInUserSessionCookies({
    newStatusCode: UserSessionStatusCodeEnum.LoggedOut
  });

  let redirectUrl = getLogoutUrl();

  if (typeof originUrl !== 'undefined' && originUrl) {
    redirectUrl += `?origin=${encodeURIComponent(originUrl)}`;
  }

  logging.logDebug('logOutViaRedirect -> redirectUrl: ', redirectUrl);

  if (localStorage.getItem('skipRedirect') === 'true') {
    // eslint-disable-next-line
    debugger;
    return;
  }

  window.location.href = redirectUrl;
};

export const getCognitoIssuerUrl = (cfg: ICognitoConfig) => {
  return `https://cognito-idp.${cfg.region}.amazonaws.com/${cfg.userPoolId}`;
};

export const verifyUserSession = ({
  cognitoCfg,
  earlyVerifyAccessToken = false, // optimal
}: {
  cognitoCfg: ICognitoConfig,
  earlyVerifyAccessToken?: boolean,
}) => {
  const defaultErrorCode = 'FPVUS';

  const output: IVerifyUserSessionResult = {
    error: {
      code: defaultErrorCode,
      description: 'Failed performing "verifyUserSession"'
    },
    data: {} as IVerifyUserSessionResult['data']
  };

  const userSessionStatusCode = getCookie('userSession'); // userSessionStatusCode
  const userSessionMetaRawStr = getCookie('userSessionMeta');
  const accessToken = getCookie('accessToken');

  let verifyAccessTokenRes: IVerifyAccessTokenResult | undefined = undefined;

  // with early verification we can return parsed access token jwt even if session errors are present
  if (earlyVerifyAccessToken) {
    verifyAccessTokenRes = verifyAccessToken({
      cognitoCfg,
      accessToken,
    });

    output.data = verifyAccessTokenRes.data;
  }

  const userSessionStatusCodeIsSet =
    typeof userSessionStatusCode !== 'undefined' && userSessionStatusCode;
  const userSessionMetaIsSet =
    typeof userSessionMetaRawStr !== 'undefined' && userSessionMetaRawStr;
  const accessTokenIsSet = typeof accessToken !== 'undefined' && accessToken;

  if (
    !accessTokenIsSet
    && !userSessionStatusCodeIsSet // long time no visit on new sessions
  ) { // backwards compatible
    output.error = {
      code: 'USNF', // legacy code
      description: 'User session not found',
      meta: {
        origin: 'verifyUserSession',
      },
    };

    return output;
  } else if (
    userSessionStatusCodeIsSet
    && userSessionStatusCode === UserSessionStatusCodeEnum.LoggedOut
  ) { // new flow backwards compatible ( new states are fine )
    output.error = {
      code: 'USNF', // legacy code
      description: 'User session not found',
      meta: {
        origin: 'verifyUserSession',
        legacy: false,
        logOut: true,
        flow: 'Explicit "userSessionStatusCode" flag',
      },
    };

    return output;
  } else if (
    userSessionStatusCodeIsSet
    && userSessionStatusCode === UserSessionStatusCodeEnum.LegacySendToRefresh
  ) { // backwards compatible ( previously "userSession" cookie was set to "-1" and user was redirected to profile refresh page )
    output.error = {
      code: 'USSBR', // legacy code
      description: 'User session should be refreshed via redirect',
      meta: {
        legacy: true,
        flow: 'Explicit "userSessionStatusCode" flag',
      }
    };

    return output;
  } else if (
    userSessionStatusCodeIsSet
    && userSessionStatusCode === UserSessionStatusCodeEnum.LoginRequired
  ) { // new flow backwards compatible ( new states are fine )
    output.error = {
      code: 'USLA',
      description: 'User should login again',
      meta: {
        legacy: false,
        flow: 'Explicit "userSessionStatusCode" flag',
      }
    };

    return output;
  } else if (
    userSessionStatusCodeIsSet
    && userSessionStatusCode === UserSessionStatusCodeEnum.RefreshRequired
  ) { // new flow backwards compatible ( new states are fine )
    output.error = {
      code: 'ATSBR',
      description: 'Access token should be refreshed',
      meta: {
        legacy: !userSessionMetaIsSet,
        flow: 'Explicit "userSessionStatusCode" flag',
      }
    };

    return output;
  } else if ( // backwards compatible
    !userSessionMetaIsSet // previously "userSessionMeta" cookie did not exist -> legacy flow
    && userSessionStatusCodeIsSet
    && !accessTokenIsSet
  ) {
    output.error = {
      code: 'USHE', // legacy code
      description: 'User session has expired',
      meta: {
        legacy: true,
      }
    };

    return output;
  } else if (userSessionMetaIsSet) { // new flow backwards compatible
    const getUserSessionCookieMetaRes = getUserSessionCookieMeta();

    if (getUserSessionCookieMetaRes.error.code !== '0') {
      output.error = {
        code: 'USMINV',
        description: 'User session meta is not valid',
        meta: {
          legacy: false,
          getUserSessionCookieMetaRes,
        }
      };

      return output;
    }

    const currentUts = getLocalCurrentUts();

    if (getUserSessionCookieMetaRes.data.rtexp < currentUts) {
      output.error = {
        code: 'RTHE',
        description: 'Refresh token has expired',
        meta: {
          legacy: false,
        }
      };

      return output;
    } else if (getUserSessionCookieMetaRes.data.atexp < currentUts) {
      output.error = {
        code: 'ATHE',
        description: 'Access token has expired',
        meta: {
          legacy: false,
        }
      };

      return output;
    }
  }

  if (typeof verifyAccessTokenRes === 'undefined') { // !earlyVerifyAccessToken
    verifyAccessTokenRes = verifyAccessToken({
      cognitoCfg,
      accessToken,
    });

    output.data = verifyAccessTokenRes.data;
  }

  if (
    output.error.code === defaultErrorCode
    && verifyAccessTokenRes.error.code !== '0'
  ) {
    output.error = verifyAccessTokenRes.error;

    return output;
  }

  output.error = { code: '0' };

  return output;
};

export type IVerifyAccessTokenResult = CustomTypeMod<
  OperationResultType,
  {
    data: IAccessTokenMeta['data'],
  }
>

export const verifyAccessToken = ({
  cognitoCfg,
  accessToken,
  // checkSignature = false, // checkAgainstPublicKeys
  parseHeader = false,
}: {
  cognitoCfg: ICognitoConfig,
  accessToken: string | undefined,
  // checkSignature?: boolean,
    parseHeader?: boolean,
}) => {
  const output: IVerifyAccessTokenResult = {
    error: {
      code: 'FPVAT',
      description: 'Failed performing "verifyAccessToken"'
    },
    data: {} as IVerifyAccessTokenResult['data']
  };

  const accessTokenMeta = parseAccessToken({
    accessToken,
    // parseHeader: checkSignature,
    parseHeader,
  });

  output.data = accessTokenMeta.data;
  output.data.rawStr = accessToken as string;

  if (accessTokenMeta.error.code !== '0') {
    output.error = accessTokenMeta.error;

    return output;
  }

  const claim = accessTokenMeta.data.payload;

  const issuer = getCognitoIssuerUrl(cognitoCfg);

  if (claim.iss !== issuer) {
    output.error = {
      code: 'ATINVCIDNM',
      description: 'Access token is not valid. Claim issuer does not match',
      meta: {
        accessToken,
        issuer,
        claim
      }
    };

    return output;
  }

  if (claim.token_use !== 'access') {
    output.error = {
      code: 'ATINVCUINA',
      description: 'Access token is not valid. Claim use is not access',
      meta: {
        accessToken,
        claim
      }
    };

    return output;
  }

  output.error = { code: '0' };

  return output;
};

export const verifyAccessTokenViaBackend = async ({
  accessToken,
}: {
  accessToken: string | undefined,
}) => {
  const output: OperationResultType = {
    error: {
      code: 'FPVATVB',
      description: 'Failed performing "verifyAccessTokenViaBackend"'
    },
    data: null,
  };

  const reqRes = await fetchProfile<any>({
    method: 'GET',
    url: '/verify-access-token',
    authBearerToken: accessToken
  });

  if (reqRes.error.code !== '0') {
    output.error = {
      code: 'ATINVRBS',
      description: 'Access token is not valid. Refused by server',
      meta: {
        accessToken,
        reqRes
      }
    };
  } else {
    output.error = { code: '0' };
  }

  return output;
};

export type IAccessTokenMeta = CustomTypeMod<
  OperationResultType,
  {
    data: {
      rawStr: string,
      header?: IAccessTokenHeader,
      payload: IAccessTokenPayload,
      expiryDto: Date,
      expiryUts: number,
      ttl: number, // in seconds
      localCurrentUts: number,
      localExpiryUts: number,
      localExpiryDto: Date,
    },
  }
>

export const parseAccessToken = ({
  accessToken,
  parseHeader
}: {
  accessToken: string | undefined,
  parseHeader?: boolean,
}): IAccessTokenMeta => {
  const output: IAccessTokenMeta = {
    error: {
      code: '0'
    },
    data: {} as IAccessTokenMeta['data']
  };

  if (typeof accessToken === 'undefined' || !accessToken) {
    output.error = {
      code: 'USNF', // legacy code
      description: 'User session not found'
    };

    return output;
  }

  try {
    const accessTokenSections = accessToken.split('.');

    if (accessTokenSections.length < 3) {
      // maybe in future we may have more than 3 chunks
      output.error = {
        code: 'ATINVWNOTS',
        description:
          'Access token is not valid. Wrong number of token sections',
        meta: {
          accessToken,
          accessTokenSections
        }
      };

      return output;
    }

    let accessTokenHeader: IAccessTokenHeader =
      undefined as unknown as IAccessTokenHeader;

    if (typeof parseHeader !== 'undefined' && parseHeader) {
      const accessTokenHeaderEncodedStr = accessTokenSections[0];

      const accessTokenHeaderDecodedStrRes = decodeBase64UrlEncodedJwtSection(
        accessTokenHeaderEncodedStr
      );

      if (accessTokenHeaderDecodedStrRes.error.code !== '0') {
        output.error = {
          code: 'ATINVFDTTH',
          description:
            'Access token is not valid. Failed decoding the token header',
          meta: {
            accessToken,
            accessTokenHeaderEncodedStr,
            decodingError: accessTokenHeaderDecodedStrRes.error
          }
        };

        return output;
      }

      const accessTokenHeaderParseRes = parseJson<IAccessTokenHeader>({
        jsonStr: accessTokenHeaderDecodedStrRes.data,
        requiredFields: ['kid', 'alg']
      });

      if (accessTokenHeaderParseRes.error.code !== '0') {
        output.error = {
          code: 'ATINVFPTH',
          description: 'Access token is not valid. Failed parsing token header',
          meta: {
            accessToken,
            accessTokenHeaderEncodedStr,
            accessTokenHeaderDecodedStr: accessTokenHeaderDecodedStrRes.data,
            parseError: accessTokenHeaderParseRes.error
          }
        };

        return output;
      }

      accessTokenHeader = accessTokenHeaderParseRes.data;
    }

    const accessTokenPayloadEncodedStr = accessTokenSections[1];

    const accessTokenPayloadDecodedStrRes = decodeBase64UrlEncodedJwtSection(
      accessTokenPayloadEncodedStr
    );

    if (accessTokenPayloadDecodedStrRes.error.code !== '0') {
      output.error = {
        code: 'ATINVFDTTP',
        description:
          'Access token is not valid. Failed decoding the token payload',
        meta: {
          accessToken,
          accessTokenPayloadEncodedStr,
          decodingError: accessTokenPayloadDecodedStrRes.error
        }
      };

      return output;
    }

    const accessTokenPayloadParseRes = parseJson<IAccessTokenPayload>({
      jsonStr: accessTokenPayloadDecodedStrRes.data,
      requiredFields: [
        'sub',
        // 'event_id',
        'token_use',
        'scope',
        'auth_time',
        'iss',
        'exp',
        'iat',
        'jti',
        'client_id',
        'username'
      ]
    });

    if (accessTokenPayloadParseRes.error.code !== '0') {
      output.error = {
        code: 'ATINVFPTP',
        description: 'Access token is not valid. Failed parsing token payload',
        meta: {
          accessToken,
          accessTokenPayloadEncodedStr,
          accessTokenPayloadDecodedStr: accessTokenPayloadDecodedStrRes.data,
          parseError: accessTokenPayloadParseRes.error
        }
      };

      return output;
    }

    const accessTokenPayload = accessTokenPayloadParseRes.data;

    const accessTokenExpiryUts = parseInt(
      accessTokenPayload.exp as unknown as string
    );

    const accessTokenExpiryDto = new Date(accessTokenExpiryUts * 1000);

    const accessTokenTtl = 43200 - 60; // 12 hours in seconds - 1 minute to invalidate slightly earlier

    const localCurrentUts = getLocalCurrentUts();

    const accessTokenLocalExpiryUts = localCurrentUts + accessTokenTtl;

    const accessTokenLocalExpiryDto = new Date(accessTokenLocalExpiryUts * 1000);

    output.data = {
      rawStr: accessToken,
      header: accessTokenHeader,
      payload: accessTokenPayload,
      expiryDto: accessTokenExpiryDto,
      expiryUts: accessTokenExpiryUts,
      ttl: accessTokenTtl,
      localCurrentUts: localCurrentUts,
      localExpiryUts: accessTokenLocalExpiryUts,
      localExpiryDto: accessTokenLocalExpiryDto
    };
  } catch (error) {
    const exc = error as any;
    output.error = getMetaPreparedFromException(exc);
  }

  return output;
};

export type IGetUserSessionCookieMetaResponse = CustomTypeMod<OperationResultType, {
  data: IUserSessionMeta,
}>;

export const getUserSessionCookieMeta = () => {
  const output: IGetUserSessionCookieMetaResponse = {
    error: {
      code: '1',
      description: 'Failed performing "getUserSessionCookieMeta"'
    },
    data: {} as IGetUserSessionCookieMetaResponse['data'],
  };

  const userSessionMetaRawStr = getCookie('userSessionMeta');

  if (
    typeof userSessionMetaRawStr === 'undefined'
    || !userSessionMetaRawStr
  ) {
    output.error = {
      code: 'USMCINP',
      description: 'User session meta cookie is not present',
    };

    return output;
  }

  const userSessionMetaRawStrDecodeRes = getCookieValueDecodedStr({
    str: userSessionMetaRawStr,
    mode: 1, // atob
  });

  if (userSessionMetaRawStrDecodeRes.error.code !== '0') {
    output.error = {
      code: 'DFUSMC',
      description: 'Failed decoding user session meta cookie',
      meta: {
        userSessionMetaRawStr,
        userSessionMetaRawStrDecodeRes,
      }
    };

    return output;
  }

  const userSessionMetaRawJsonStr = userSessionMetaRawStrDecodeRes.data;

  if (userSessionMetaRawJsonStr.indexOf('{') !== 0) {
    output.error = {
      code: 'USMCINV1',
      description: 'User session meta cookie is not valid',
      meta: {
        userSessionMetaRawStr,
        userSessionMetaRawJsonStr,
      },
    };

    return output;
  }

  const userSessionMetaRequiredFields: StrKeyToBoolMapTypeMod<IUserSessionMeta> = {
    id: true,
    iss: true,
    atexp: true,
    rtexp: true,
  };

  const userSessionMetaParseRes = parseJson<IUserSessionMeta>({
    jsonStr: userSessionMetaRawJsonStr,
    requiredFields: Object.keys(userSessionMetaRequiredFields),
  });

  if (userSessionMetaParseRes.error.code !== '0') {
    output.error = {
      code: 'FPUSMC',
      description: 'Failed parsing user session meta cookie',
      meta: {
        userSessionMetaRawJsonStr,
        userSessionMetaParseRes,
      },
    };

    return output;
  }

  output.data = userSessionMetaParseRes.data;

  output.error = { code: '0' };

  return output;
};

export interface IAccessTokenCookieLifespan {
  currentUts: number,
  expiryUts: number,
  expiryDto: Date,
  ttl: number,
}

export type IGetAccessTokenCookieLifespanResponse = CustomTypeMod<OperationResultType, {
  data: IAccessTokenCookieLifespan,
}>;

/**
 * Access token lives as long as refresh token does or more.
 * With current lifespan configs -> on last refresh access token can live up to almost 1 day more than refresh token
 */
export const getAccessTokenCookieLifespan = ({
  userSessionMeta,
  userSessionLifespan,
}: {
  userSessionMeta?: IUserSessionMeta, // cookie
  userSessionLifespan?: IUserSessionLifespan, // backend response
} = {}) => {
  const output: IGetAccessTokenCookieLifespanResponse = {
    error: {
      code: '1',
      description: 'Failed performing "getAccessTokenCookieLifespan"'
    },
    data: {} as IGetAccessTokenCookieLifespanResponse['data'],
  };

  const currentUts = getLocalCurrentUts();

  let expiryUts: number = 0;

  if (typeof userSessionLifespan !== 'undefined') {
    if (userSessionLifespan.accessTokenExpiryUiUts > userSessionLifespan.refreshTokenExpiryUiUts) { // possible on last refresh
      expiryUts = userSessionLifespan.accessTokenExpiryUiUts;
    } else {
      expiryUts = userSessionLifespan.refreshTokenExpiryUiUts;
    }
  } else if (typeof userSessionMeta === 'undefined') {
    const getUserSessionCookieMetaRes = getUserSessionCookieMeta();

    if (getUserSessionCookieMetaRes.error.code !== '0') {
      output.error = getUserSessionCookieMetaRes.error;

      return output;
    }

    userSessionMeta = getUserSessionCookieMetaRes.data;
  }

  if (
    expiryUts === 0
    && typeof userSessionMeta !== 'undefined'
  ) {
    if (userSessionMeta.atexp > userSessionMeta.rtexp) { // possible on last refresh
      expiryUts = userSessionMeta.atexp;
    } else {
      expiryUts = userSessionMeta.rtexp;
    }
  }

  output.data = {
    currentUts,
    expiryUts,
    expiryDto: new Date(expiryUts * 1000),
    ttl: expiryUts - currentUts,
  };

  output.error = { code: '0' };

  return output;
};

export const changeStatusCodeInUserSessionCookies = ({
  newStatusCode,
}: IChangeStatusCodeInUserSessionCookiesProps) => {
  const userSessionCookieTtl = 7776000; // 90 days in seconds
  const userSessionCookieExpiryUts = getLocalCurrentUts() + userSessionCookieTtl;
  const userSessionCookieExpiryDto = new Date(userSessionCookieExpiryUts * 1000);

  resetCookie({
    name: 'userSession', // userSessionStatusCode
    value: newStatusCode,
    maxAge: userSessionCookieTtl,
    expires: userSessionCookieExpiryDto,
  });

  const userSessionMetaRawStr = getCookie('userSessionMeta');

  if (
    typeof userSessionMetaRawStr !== 'undefined'
    && userSessionMetaRawStr
  ) {
    // reseting only to sync the expiry date-time with "userSession" cookie
    resetCookie({
      name: 'userSessionMeta',
      value: userSessionMetaRawStr,
      maxAge: userSessionCookieTtl,
      expires: userSessionCookieExpiryDto,
    });
  }
};

export type IUpsertActiveUserSessionCookiesRes = CustomTypeMod<OperationResultType, {
  data: IUserSessionState,
}>;

/**
 * We call this function only on successful login/refresh
 * "userSession" cookie is used as session state storage.
 * "userSession" cookie will have a 90 days lifetime with the ttl updated/pushed further on every set/update.
 * "accessToken" cookie will live at least as long as "refreshToken" as it is required in refresh session requests.
 */
export const upsertActiveUserSessionCookies = ({
  accessToken,
  userInfoCookie,
  sessionId,
  sessionLifespan,
}: {
  accessToken: string,
  userInfoCookie: string,
  sessionId?: string, // passed on initialization
  sessionLifespan: IUserSessionLifespan,
}) => {
  const output: IUpsertActiveUserSessionCookiesRes = {
    error: {
      code: '1',
      description: 'Failed performing "upsertActiveUserSessionCookies"',
    },
    data: {} as IUpsertActiveUserSessionCookiesRes['data'],
  };

  const userSessionCookieTtl = 7776000; // 90 days in seconds
  const userSessionCookieExpiryUts = sessionLifespan.currentUiUts + userSessionCookieTtl;
  const userSessionCookieExpiryDto = new Date(userSessionCookieExpiryUts * 1000);

  if (typeof sessionId === 'undefined') { // previously initialized
    const getUserSessionCookieMetaRes = getUserSessionCookieMeta();

    if (getUserSessionCookieMetaRes.error.code !== '0') {
      output.error = {
        code: 'FRUSM',
        description: 'Failed retrieving user session meta',
        meta: {
          getUserSessionCookieMetaRes,
        }
      };

      return output;
    }

    sessionId = getUserSessionCookieMetaRes.data.id;
  }

  const userSessionMeta: IUserSessionMeta = {
    id: sessionId,
    iss: sessionLifespan.refreshTokenBaseUiUts,
    atexp: sessionLifespan.accessTokenExpiryUiUts,
    rtexp: sessionLifespan.refreshTokenExpiryUiUts,
  };

  const getAccessTokenCookieLifespanRes = getAccessTokenCookieLifespan({
    userSessionLifespan: sessionLifespan,
  });

  if (getAccessTokenCookieLifespanRes.error.code !== '0') {
    output.error = {
      code: 'FRATCL',
      description: 'Failed retrieving access token cookie lifespan',
      meta: {
        getAccessTokenCookieLifespanRes,
      }
    };

    return output;
  }

  const userSessionMetaEncodingRes = getCookieValueEncodedStr({
    str: JSON.stringify(userSessionMeta),
    mode: 1, // btoa
  });

  if (userSessionMetaEncodingRes.error.code !== '0') {
    output.error = {
      code: 'FEUSMD',
      description: 'Failed encoding user session meta data',
      meta: {
        userSessionMetaEncodingRes,
      }
    };

    return output;
  }

  resetCookie({
    name: 'userSession', // userSessionStatusCode
    value: UserSessionStatusCodeEnum.Active,
    maxAge: userSessionCookieTtl,
    expires: userSessionCookieExpiryDto,
  });

  resetCookie({
    name: 'userSessionMeta',
    value: userSessionMetaEncodingRes.data,
    maxAge: userSessionCookieTtl,
    expires: userSessionCookieExpiryDto,
  });

  const accessTokenTtl = getAccessTokenCookieLifespanRes.data.ttl;
  const accessTokenExpiryDto = getAccessTokenCookieLifespanRes.data.expiryDto;

  resetCookie({
    name: 'accessToken',
    value: accessToken,
    maxAge: accessTokenTtl,
    expires: accessTokenExpiryDto,
  });

  resetCookie({
    name: '__c_u_i_1', // userInfoCookie
    value: userInfoCookie,
    maxAge: accessTokenTtl,
    expires: accessTokenExpiryDto,
  });

  output.data = {
    ...userSessionMeta,
    statusCode: UserSessionStatusCodeEnum.Active,
  };

  output.error = { code: '0' };

  return output;
};

export type IGetUserSessionCookieStatusCode = CustomTypeMod<OperationResultType, {
  data: UserSessionStatusCodeEnum,
}>;

export const getUserSessionCookieStatusCode = () => {
  const output: IGetUserSessionCookieStatusCode = {
    error: {
      code: '1',
      description: 'Failed performing "getUserSessionCookieStatusCode"'
    },
    data: '0' as unknown as IGetUserSessionCookieStatusCode['data'],
  };

  const userSessionStatusCode = getCookie('userSession') as UserSessionStatusCodeEnum; // userSessionStatusCode

  if (
    typeof userSessionStatusCode === 'undefined'
    || !userSessionStatusCode
  ) {
    output.error = {
      code: 'USSCCINP',
      description: 'User session status code cookie is not present',
    };

    return output;
  }

  const validUserSessionStatusCodes = Object.values(UserSessionStatusCodeEnum);

  if (!validUserSessionStatusCodes.includes(userSessionStatusCode)) {
    output.error = {
      code: 'USSCCINV',
      description: 'User session status code cookie is not valid',
      meta: {
        validUserSessionStatusCodes,
        userSessionStatusCode,
      }
    };

    return output;
  }

  output.data = userSessionStatusCode;

  output.error = { code: '0' };

  return output;
};

export type IUserSessionState = CustomTypeMod<IUserSessionMeta, {
  statusCode: UserSessionStatusCodeEnum,
}>;

export type IGetUserSessionStateFromCookiesResponse = CustomTypeMod<OperationResultType, {
  data: IUserSessionState,
}>;

export const getUserSessionStateFromCookies = () => {
  const output: IGetUserSessionStateFromCookiesResponse = {
    error: {
      code: '1',
      description: 'Failed performing "getUserSessionStateFromCookies"'
    },
    data: {} as IGetUserSessionStateFromCookiesResponse['data'],
  };

  const getUserSessionCookieStatusCodeRes = getUserSessionCookieStatusCode();

  if (getUserSessionCookieStatusCodeRes.error.code !== '0') {
    output.error = getUserSessionCookieStatusCodeRes.error;

    return output;
  }

  const getUserSessionCookieMetaRes = getUserSessionCookieMeta();

  if (getUserSessionCookieMetaRes.error.code !== '0') {
    output.error = getUserSessionCookieMetaRes.error;

    return output;
  }

  output.data = {
    ...getUserSessionCookieMetaRes.data,
    statusCode: getUserSessionCookieStatusCodeRes.data,
  };

  output.error = { code: '0' };

  return output;
};

/**
 * @deprecated Legacy user session refresh logic kept for backwards compatibility
 */
export const setUserSessionRefreshFlag = ({
  context,
  reason,
}: {
  context: string,
  reason?: any,
}) => {
  logging.logDebug('UserSessionUtil -> setUserSessionRefreshFlag -> context: ', context);

  setUserSessionCookieDebugMeta({
    origin: window.location.href,
    context,
    description: 'setUserSessionRefreshFlag',
    reason,
    prevStatusCode: getCookie('userSession'),
    newStatusCode: UserSessionStatusCodeEnum.LegacySendToRefresh,
  });

  changeStatusCodeInUserSessionCookies({
    newStatusCode: UserSessionStatusCodeEnum.LegacySendToRefresh,
  });
};

/**
 * @deprecated Legacy user session refresh logic kept for backwards compatibility
 */
export const refreshUserSessionViaRedirect = ({
  context,
  originUrl,
  reason,
}: IUserSessionRedirectProps) => {
  logging.logDebug('UserSessionUtil -> refreshUserSessionViaRedirect -> init: ', {
    originUrl,
    context,
  });

  const currentUrlClean = getCurrentUrlClean();

  if (currentUrlClean === getProfileRefreshSessionUrl()) {
    logging.logDebug('UserSessionUtil -> refreshUserSessionViaRedirect -> landed -> currentUrlClean: ', currentUrlClean);
    return;
  }

  setUserSessionRefreshFlag({
    context,
    reason,
  });

  const redirectUrl = getProfileRefreshSessionUrl() + `?redirect_url=${encodeURIComponent(originUrl)}`;

  logging.logDebug('UserSessionUtil -> refreshUserSessionViaRedirect -> redirectUrl: ', redirectUrl);

  if (localStorage.getItem('skipRedirect') === 'true') {
    // eslint-disable-next-line
    debugger;
    return;
  }

  window.location.href = redirectUrl;
};

export const restartUserSessionViaRedirect = ({
  context,
  originUrl,
  reason,
}: IUserSessionRedirectProps) => {
  logging.logDebug('UserSessionUtil -> restartUserSessionViaRedirect -> init: ', {
    originUrl,
    context,
  });

  const currentUrlClean = getCurrentUrlClean();

  if (
    currentUrlClean === getLoginUrl()
    || currentUrlClean === getProfileUrl()
  ) {
    logging.logDebug('UserSessionUtil -> restartUserSessionViaRedirect -> landed -> currentUrlClean: ', currentUrlClean);
    return;
  }

  setUserSessionCookieDebugMeta({
    origin: originUrl,
    context,
    description: 'restartUserSessionViaRedirect',
    reason,
    prevStatusCode: getCookie('userSession'),
    newStatusCode: UserSessionStatusCodeEnum.LoginRequired,
  });

  changeStatusCodeInUserSessionCookies({
    newStatusCode: UserSessionStatusCodeEnum.LoginRequired,
  });

  const redirectUrl = getLoginUrl() + `?redirect_url=${encodeURIComponent(originUrl)}`;

  logging.logDebug('UserSessionUtil -> restartUserSessionViaRedirect -> redirectUrl: ', redirectUrl);

  if (localStorage.getItem('skipRedirect') === 'true') {
    // eslint-disable-next-line
    debugger;
    return;
  }

  window.location.href = redirectUrl;
};

/**
 * Creates the backend user session and on response sets the refreshToken HttpOnly cookie
 */
export const registerBackendUserSession = async ({
  accessToken,
  refreshToken,
  legacy,
}: {
  accessToken: string,
  refreshToken: string,
  legacy?: boolean,
}) => {
  return await fetchProfileUserSession<ICreateUserSessionResult['data']>({
    method: 'POST',
    data: {
      method: 'POST',
      cognitoAccessToken: accessToken,
      cognitoRefreshToken: refreshToken,
      currentUiUts: getLocalCurrentUts(),
      legacy,
    },
    authenticated: false,
    attachCookies: false,
    refreshTokenOnFailedCognitoAuth: false, // disable as it's called mainly inside user session initialization/processing logic
  });
};

/**
 * Generates in backend a new access token, updates the backend user session and on response re-sets the refreshToken HttpOnly cookie.
 * Access token is sent along other cookies
 */
const _refreshBackendUserSession = async (
  context: string,
) => {
  logging.logDebug('(1) _refreshBackendUserSession -> init: ', {
    context,
  });

  const accessToken = getCookie('accessToken');

  if (
    typeof accessToken === 'undefined'
    || !accessToken
  ) {
    const output = {
      error: {
        code: 'RUS_ATINPIC',
        description: 'Access token is not present in cookies',
        meta: {
          context,
        }
      },
      data: null,
    };

    logging.logDebug('(E1) _refreshBackendUserSession -> context, output: ', {
      context,
      output,
    });

    return output;
  }

  // removeCookie('accessToken'); // it's restored later on successful refresh

  // resetCookie({
  //   name: 'xAccessToken',
  //   value: accessToken,
  //   maxAge: FETCH_DEFAULT_CONFIG.timeout / 1000, // seconds
  // });

  const reqRes = await fetchProfileUserSession<IRefreshUserSessionResult['data']>({
    method: 'POST',
    data: {
      method: 'PATCH',
      currentUiUts: getLocalCurrentUts(),
    },
    authenticated: false,
    attachCookies: true,
  });

  if (reqRes.error.code !== '0') {
    const output = {
      error: {
        code: 'RUS_FRUS',
        description: 'Failed refreshing user session',
        meta: {
          context,
          reqRes,
        }
      },
      data: null,
    };

    logging.logDebug('(E4) _refreshBackendUserSession -> context, output: ', {
      context,
      output,
    });

    return output;
  }

  // removeCookie('xAccessToken');

  logging.logDebug('(FIN) _refreshBackendUserSession -> context, output: ', {
    context,
    output: reqRes,
  });

  return reqRes;
};


/**
 * State required by "refreshUserSession" in order to ensure only one backend request goes at the same time
 */
const refreshUserSessionState: {
  inProgress: boolean,
  awaitingResult: {
    [awaitId: string]: null | IRefreshUserSessionResult,
  },
} = {
  inProgress: false,
  awaitingResult: {},
};

const _callbackParallelRefreshUserSessionExecutionResponseUnbound = (
  context: string,
  awaitId: string,
  iteration: number,
  callback: (res: IRefreshUserSessionResult) => void,
) => {
  logging.logDebug('(1) _callbackParallelRefreshUserSessionExecutionResponse -> context, awaitId, iteration: ', {
    context,
    awaitId,
    iteration,
  });

  if (typeof refreshUserSessionState.awaitingResult[awaitId] === 'undefined') { // should never happen
    const res = {
      error: {
        code: 'AIDINPIQRUSSAR1',
        description: 'Await id is not present in queue "refreshUserSessionState.awaitingResult"',
        meta: {
          context,
          awaitId,
          iteration,
        }
      },
      data: {},
    } as IRefreshUserSessionResult;

    logging.logDebug('(E1) _callbackParallelRefreshUserSessionExecutionResponse -> context, awaitId, iteration, res: ', {
      context,
      awaitId,
      iteration,
      res,
    });

    return callback(res);
  }

  if (iteration === 30) {
    delete refreshUserSessionState.awaitingResult[awaitId]; // release

    const res = {
      error: {
        code: 'MRRWPAPRUSER',
        description: 'Maximum recursion reached while perfofrming "_awaitParallelRefreshUserSessionExecutionResponse"',
        meta: {
          context,
          awaitId,
          iteration,
        }
      },
      data: {},
    } as IRefreshUserSessionResult;

    logging.logDebug('(E2) _callbackParallelRefreshUserSessionExecutionResponse -> context, awaitId, iteration, res: ', {
      context,
      awaitId,
      iteration,
      res,
    });

    return callback(res);
  }

  setTimeout(() => {
    if (typeof refreshUserSessionState.awaitingResult[awaitId] === 'undefined') { // should never happen
      const res = {
        error: {
          code: 'AIDINPIQRUSSAR2',
          description: 'Await id is not present in queue "refreshUserSessionState.awaitingResult"',
          meta: {
            context,
            awaitId,
            iteration,
          }
        },
        data: {},
      } as IRefreshUserSessionResult;

      logging.logDebug('(E3) _callbackParallelRefreshUserSessionExecutionResponse -> context, awaitId, iteration, res: ', {
        context,
        awaitId,
        iteration,
        res,
      });

      return callback(res);
    }

    if (refreshUserSessionState.awaitingResult[awaitId] !== null) { // response is ready
      const res = getDictSnapshot(refreshUserSessionState.awaitingResult[awaitId]) as IRefreshUserSessionResult;

      delete refreshUserSessionState.awaitingResult[awaitId]; // release

      logging.logDebug('(2) _callbackParallelRefreshUserSessionExecutionResponse -> context, awaitId, iteration, res: ', {
        context,
        awaitId,
        iteration,
        res,
      });

      if (typeof res.error.meta === 'undefined') {
        res.error.meta = {};
      }

      res.error.meta.context = context;
      res.error.meta.awaitId = awaitId;
      res.error.meta.iteration = iteration;

      callback(res);
    } else { // response is not ready yet
      _callbackParallelRefreshUserSessionExecutionResponse(context, awaitId, ++iteration, callback);
    }
  }, 500);
};

const _callbackParallelRefreshUserSessionExecutionResponse = _callbackParallelRefreshUserSessionExecutionResponseUnbound.bind(refreshUserSessionState);

const _awaitParallelRefreshUserSessionExecutionResponse = async (
  context: string,
  awaitId: string,
): Promise<IRefreshUserSessionResult> => {
  return new Promise((resolve) => {
    _callbackParallelRefreshUserSessionExecutionResponse(context, awaitId, 0, (res) => {
      if (
        res.error.code !== '0'
        && res.error.code.indexOf('RUS_') !== 0
      ) { // if no 'RUS_' prefix -> add it
        res.error.code = `RUS_${res.error.code}`;
      }

      logging.logDebug('_awaitParallelRefreshUserSessionExecutionResponse -> context, awaitId, res: ', {
        context,
        awaitId,
        res,
      });

      resolve(res);
    });
  });
};

/**
 * Generates in backend a new access token, updates the backend user session and on response re-sets the refreshToken HttpOnly cookie.
 * Then in ui the session related cookies are updated
 */
const _refreshUserSessionUnbound = async (
  context: string,
  _altRefreshBackendUserSession?: typeof _refreshBackendUserSession, // used for testing
  _sleepBeforeInit?: number, // ms; used for testing
) => {
  logging.logDebug('(1) refreshUserSession -> init: ', {
    context,
    refreshUserSessionState: getDictSnapshot(refreshUserSessionState), // TODO: (mid) remove after WEB-2419 release
    _altRefreshBackendUserSession,
    _sleepBeforeInit,
  });

  if (typeof _sleepBeforeInit !== 'undefined' && _sleepBeforeInit > 0) {
    await sleep(_sleepBeforeInit);
  }

  if (refreshUserSessionState.inProgress) {
    const awaitId = `${getLocalCurrentUtus()}`; // unique enough

    refreshUserSessionState.awaitingResult[awaitId] = null;

    return await _awaitParallelRefreshUserSessionExecutionResponse(context, awaitId);
  }

  refreshUserSessionState.inProgress = true;

  let output: IRefreshUserSessionResult;

  if (typeof _altRefreshBackendUserSession !== 'undefined') {
    output = await _altRefreshBackendUserSession(context) as IRefreshUserSessionResult;
  } else {
    output = await _refreshBackendUserSession(context) as IRefreshUserSessionResult;
  }

  const recentlyConsumed = output.error.code === '0'
    && typeof output.data.recentlyConsumed !== 'undefined'
    && output.data.recentlyConsumed;

  if (recentlyConsumed) { // check cookies once more
    const verifyUserSessionRes = verifyUserSession({
      cognitoCfg: getCognitoConfig(),
    });

    if (verifyUserSessionRes.error.code !== '0') {
      output.error = {
        code: 'RUS_RSTWCRBNWSCBF',
        description: 'Refresh session tokens were consumed recently but no working session can be found',
        meta: {
          context,
          verifyUserSessionRes,
        }
      };
    }
  }

  if (output.error.code === '0' && !recentlyConsumed) { // refreshed just fine
    const upsertActiveUserSessionCookiesRes = upsertActiveUserSessionCookies(output.data!);

    logging.logDebug('(2) refreshUserSession -> context, upsertActiveUserSessionCookiesRes: ', {
      context,
      upsertActiveUserSessionCookiesRes,
    });

    if (upsertActiveUserSessionCookiesRes.error.code !== '0') {
      output.error = {
        code: 'RUS_FUAUSC',
        description: 'Failed upserting active user session cookies',
        meta: {
          context,
          upsertActiveUserSessionCookiesRes,
        }
      };
    }
  }

  logging.logDebug('(3) refreshUserSession -> context, output: ', {
    context,
    output,
  });

  if (output.error.code !== '0') { // a new log-in is required
    logging.logDebug('(4) refreshUserSession -> new log-in required -> context, output: ', {
      context,
      output,
    });

    setUserSessionCookieDebugMeta({
      origin: window.location.href,
      context,
      description: 'refreshUserSession',
      reason: output,
      prevStatusCode: getCookie('userSession'),
      newStatusCode: UserSessionStatusCodeEnum.LoginRequired,
    });

    changeStatusCodeInUserSessionCookies({
      newStatusCode: UserSessionStatusCodeEnum.LoginRequired,
    });
  }

  if (typeof output.error.meta === 'undefined') {
    output.error.meta = {};
  }

  output.error.meta.context = context;

  refreshUserSessionState.inProgress = false;

  logging.logDebug('(5) refreshUserSession -> before return -> context, refreshUserSessionState: ', {
    context,
    refreshUserSessionState: getDictSnapshot(refreshUserSessionState), // TODO: (mid) remove after WEB-2419 release
  });

  const awaitingResultIds = Object.keys(refreshUserSessionState.awaitingResult);

  if (awaitingResultIds.length) {
    for (const awaitIdIndex in awaitingResultIds) {
      const awaitId = awaitingResultIds[awaitIdIndex];

      refreshUserSessionState.awaitingResult[awaitId] = output;
    }
  }

  return output;
};

export const refreshUserSession = _refreshUserSessionUnbound.bind(refreshUserSessionState);

/**
 * It will wipe the backend user session and remove the refreshToken HttpOnly cookie
 */
export const deleteBackendUserSession = async () => {
  const accessToken = getCookie('accessToken');

  if (
    typeof accessToken === 'undefined'
    || !accessToken
  ) {
    return {
      error: {
        code: 'ATIR',
        description: 'Access token is required',
      },
      data: null,
    };
  }

  // removeCookie('accessToken'); // we don't restore it after this op

  // resetCookie({
  //   name: 'xAccessToken',
  //   value: accessToken,
  //   maxAge: FETCH_DEFAULT_CONFIG.timeout / 1000, // seconds
  // });

  const reqRes = await fetchProfileUserSession({
    method: 'POST',
    data: {
      method: 'DELETE',
    },
    authenticated: false,
    attachCookies: true,
  });

  // removeCookie('xAccessToken');

  return reqRes;
};

export const userSessionRedirectionLogicHandler = (userSessionVerificationRes: IVerifyUserSessionResult) => {
  logging.logDebug('userSessionRedirectionLogicHandler -> userSessionVerificationRes: ', userSessionVerificationRes);

  if (userSessionVerificationRes.error.code === '0') {
    return;
  }

  const _appIsProfile = appIsProfile();
  const currentUrlClean = getCurrentUrlClean();

  if (
    userSessionVerificationRes.error.code === 'USSBR' // User session should be refreshed via redirect
    || userSessionVerificationRes.error.code === 'USHE' // User session has expired
    || userSessionVerificationRes.error.code === 'ATHE' // Access token has expired
    || userSessionVerificationRes.error.code === 'ATSBR' // Access token should be refreshed
  ) { // refresh
    if (
      typeof userSessionVerificationRes.error.meta !== 'undefined'
      && typeof userSessionVerificationRes.error.meta.legacy !== 'undefined'
      && userSessionVerificationRes.error.meta.legacy
    ) { // refresh the old way
      refreshUserSessionViaRedirect({
        context: 'userSessionRedirectionLogicHandler',
        originUrl: _appIsProfile ? currentUrlClean : window.location.href,
        reason: userSessionVerificationRes,
      });
    }
  } else if (
    userSessionVerificationRes.error.code === 'USLA' // User should login again
    || userSessionVerificationRes.error.code === 'USMINV' // User session meta is not valid
    || userSessionVerificationRes.error.code === 'RTHE' // Refresh token has expired
    || userSessionVerificationRes.error.code.indexOf('RUS_') === 0 // failed backend refresh user session attempt
  ) {
    restartUserSessionViaRedirect({
      context: 'userSessionRedirectionLogicHandler',
      originUrl: _appIsProfile ? currentUrlClean : window.location.href,
      reason: userSessionVerificationRes,
    });
  }
};

// < debug
interface IUserSessionDebugMeta {
  origin?: string,
  context?: string,
  code?: string,
  description?: string,
  prevStatusCode?: string,
  newStatusCode?: string,
  reason?: any,
  prevDebugMeta?: any,
  currentUts?: number,
  currentDtfs?: string,
  extra?: any,
}

type IGetUserSessionCookieDebugMetaRes = CustomTypeMod<OperationResultType, {
  data: IUserSessionDebugMeta,
}>;

export const _getUserSessionCookieDebugMeta = () => { // exported for tests only
  const output: IGetUserSessionCookieDebugMetaRes = {
    error: {
      code: '1',
      description: 'Failed performing "_getUserSessionCookieDebugMeta"'
    },
    data: {} as IGetUserSessionCookieDebugMetaRes['data'],
  };

  const userSessionDebugMetaRawStr = getCookie('userSessionDebugMeta');

  if (
    typeof userSessionDebugMetaRawStr === 'undefined'
    || !userSessionDebugMetaRawStr
  ) {
    output.error = {
      code: 'USDMCINP',
      description: 'User session debug meta cookie is not present',
    };

    return output;
  }

  const userSessionDebugMetaRawStrDecodeRes = getCookieValueDecodedStr({
    str: userSessionDebugMetaRawStr,
  });

  if (userSessionDebugMetaRawStrDecodeRes.error.code !== '0') {
    output.error = {
      code: 'DFUSDMC',
      description: 'Failed decoding user session debug meta cookie',
      meta: {
        userSessionDebugMetaRawStr,
        userSessionDebugMetaRawStrDecodeRes,
      }
    };

    return output;
  }

  const userSessionDebugMetaRawJsonStr = userSessionDebugMetaRawStrDecodeRes.data;

  if (userSessionDebugMetaRawJsonStr.indexOf('{') !== 0) {
    output.error = {
      code: 'USDMCINV1',
      description: 'User session debug meta cookie is not valid',
      meta: {
        userSessionDebugMetaRawStr,
        userSessionDebugMetaRawJsonStr,
      },
    };

    return output;
  }

  const userSessionDebugMetaParseRes = parseJson<IUserSessionDebugMeta>({
    jsonStr: userSessionDebugMetaRawJsonStr,
  });

  if (userSessionDebugMetaParseRes.error.code !== '0') {
    output.error = {
      code: 'FPUSDMC',
      description: 'Failed parsing user session debug meta cookie',
      meta: {
        userSessionDebugMetaRawJsonStr,
        userSessionDebugMetaParseRes,
      },
    };

    return output;
  }

  output.data = userSessionDebugMetaParseRes.data;

  output.error = { code: '0' };

  return output;
};

const _isUserSessionDebugMode = () => {
  if (
    getCookie('UserSession.debug') === 'true'
    || localStorage.getItem('UserSession.debug') === 'true'
  ) {
    return true;
  }

  return false;
};

export const setUserSessionCookieDebugMeta = (debugMeta: IUserSessionDebugMeta) => {
  logging.logDebug('setUserSessionCookieDebugMeta -> debugMeta: ', debugMeta);

  if (!_isUserSessionDebugMode()) {
    return;
  }

  const currentUts = getLocalCurrentUts();
  const cookieTtl = 7776000; // 90 days in seconds
  const cookieExpiryUts = currentUts + cookieTtl;
  const cookieExpiryDto = new Date(cookieExpiryUts * 1000);

  const getUserSessionCookieDebugMetaRes = _getUserSessionCookieDebugMeta();

  if (getUserSessionCookieDebugMetaRes.error.code === '0') {
    debugMeta.prevDebugMeta = getUserSessionCookieDebugMetaRes.data;
  }

  debugMeta.currentUts = currentUts;
  debugMeta.currentDtfs = new Date(currentUts * 1000).toString();

  const userSessionDebugMetaEncodingRes = getCookieValueEncodedStr({
    str: JSON.stringify(debugMeta),
  });

  if (userSessionDebugMetaEncodingRes.error.code === '0') {
    resetCookie({
      name: 'userSessionDebugMeta',
      value: userSessionDebugMetaEncodingRes.data,
      maxAge: cookieTtl,
      expires: cookieExpiryDto,
    });
  }
};
// > debug

// < mixed
export const getSubscriptionPlan = async (): Promise<string | undefined> => {
  const reqRes = await fetchSpaces<any>({
    url: '/userinfo',
    method: 'POST',
    data: {
      action: 'getUserSubscriptionPlan',
    },
    authenticated: true,
  });

  logging.logDebug('getSubscriptionPlan -> reqRes: ', reqRes);

  if (reqRes.error.code !== '0') {
    logging.logError('getSubscriptionPlan -> reqRes.error: ', reqRes.error);

    return;
  }

  return reqRes.data.subscription_plan.name;
};
// > mixed

// < info token
export interface ICookieUserInfo {
  fname?: string,
  lname?: string,
  email?: string,
  plan?: string,
  noUpsell?: boolean,
  adFree?: boolean,
}

export type IRefreshUserInfoTokenRes = CustomTypeMod<OperationResultType, {
  data: {
    userInfoCookie: string,
    accessTokenCookieLifespan: IAccessTokenCookieLifespan,
  },
}>;

export const refreshUserInfoToken = async (): Promise<IRefreshUserInfoTokenRes> => {
  const output: IRefreshUserInfoTokenRes = {
    error: {
      code: '1',
      description: 'Failed performing "refreshUserInfoToken"'
    },
    data: {} as IRefreshUserInfoTokenRes['data'],
  };

  const getAccessTokenCookieLifespanRes = getAccessTokenCookieLifespan();

  if (getAccessTokenCookieLifespanRes.error.code !== '0') {
    logging.logDebug('refreshUserInfoToken -> getAccessTokenCookieLifespanRes: ', getAccessTokenCookieLifespanRes);

    output.error = getAccessTokenCookieLifespanRes.error;

    return output;
  }

  const userInfoCookieFetchRes = await fetchProfile<{ userInfoCookie: string }>({
    method: 'GET',
    url: '/user/info-cookie',
    data: {
      cookieExpiryUts: getAccessTokenCookieLifespanRes.data.expiryUts,
    },
    authenticated: true,
  });

  logging.logDebug('refreshUserInfoToken -> userInfoCookieFetchRes: ', userInfoCookieFetchRes);

  if (userInfoCookieFetchRes.error.code !== '0') {
    output.error = userInfoCookieFetchRes.error;

    return output;
  }

  const userInfoCookieTtl = getAccessTokenCookieLifespanRes.data.ttl;
  const userInfoCookieExpiryDto = getAccessTokenCookieLifespanRes.data.expiryDto;

  resetCookie({
    name: '__c_u_i_1', // userInfoCookie
    value: userInfoCookieFetchRes.data.userInfoCookie,
    maxAge: userInfoCookieTtl,
    expires: userInfoCookieExpiryDto,
  });

  output.data = {
    userInfoCookie: userInfoCookieFetchRes.data.userInfoCookie,
    accessTokenCookieLifespan: getAccessTokenCookieLifespanRes.data,
  };

  output.error = { code: '0' };

  return output;
};

/**
 * @deprecated Use "extractUserInfoCookieData" instead
 */
export const getUserInfoFromCookies = async (): Promise<ICookieUserInfo | undefined> => {
  const userInfoPacked = getCookie('__c_u_i_1');

  if (
    typeof userInfoPacked === 'undefined'
    || !userInfoPacked
  ) {
    return;
  }

  try {
    return await unpack(userInfoPacked) as ICookieUserInfo;
  } catch (error) {
    const exc = error as any;
    logging.logError('getUserInfoFromCookies -> exc: ', exc);
  }

  return;
};

export const extractUserInfoCookieData = ({
  userInfoCookie,
  skipValidation = false,
  debug = false,
}: {
  userInfoCookie?: string,
  skipValidation?: boolean,
  debug?: boolean,
} = {}) => {
  const output: IOperationResult<ICookieUserInfo> = {
    error: {
      code: '1',
      description: 'Failed performing "getUserInfoFromCookiesV2"',
    },
    data: {}
  };

  if (typeof userInfoCookie === 'undefined') {
    userInfoCookie = getCookie('__c_u_i_1');
  }

  if (
    typeof userInfoCookie === 'undefined'
    || !userInfoCookie
  ) {
    output.error = {
      code: 'NOT_FOUND',
      description: 'User info cookie is not set'
    };

    return output;
  }

  try {
    output.data = syncUnpack(userInfoCookie, skipValidation) as ICookieUserInfo;

    output.error = { code: '0' };
  } catch (exc) {
    const error = getMetaPreparedFromException(exc);
    output.error = {
      code: '2',
      description: error.description,
      meta: {
        error
      }
    };

    if (debug) {
      logging.logError('getUserInfoFromCookiesV2 -> exc: ', exc);
    }
  }

  return output;
};
// > info token

// < util
export type IDecodeBase64UrlEncodedJwtSectionResult = CustomTypeMod<
  OperationResultType,
  {
    data: string,
  }
>

export const decodeBase64UrlEncodedJwtSection = (
  encodedStr: string
): IDecodeBase64UrlEncodedJwtSectionResult => {
  // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  const output: IDecodeBase64UrlEncodedJwtSectionResult = {
    error: {
      code: '0'
    },
    data: ''
  };

  try {
    encodedStr = encodedStr.replace(/-/g, '+').replace(/_/g, '/');

    output.data = decodeURIComponent(
      atob(encodedStr)
        .split('')
        .map(function (char) {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  } catch (error) {
    const exc = error as any;
    output.error = getMetaPreparedFromException(exc);
  }

  return output;
};
// > util

// < tmp TODO: (mid) remove after WEB-2419 release
export const _tmpMimicExpiredAccessToken = () => {
  if (!_isUserSessionDebugMode()) {
    return;
  }

  const getUserSessionCookieMetaRes = getUserSessionCookieMeta();

  if (getUserSessionCookieMetaRes.error.code === '0') {
    const userSessionMeta = getUserSessionCookieMetaRes.data;

    userSessionMeta.atexp = userSessionMeta.iss;

    resetCookie({
      name: 'userSessionMeta',
      value: getCookieValueEncodedStr({
        str: JSON.stringify(userSessionMeta),
        mode: 1, // btoa
      }).data,
    });

    // trigger cookies ttl sync
    changeStatusCodeInUserSessionCookies({
      newStatusCode: getCookie('userSession') as unknown as UserSessionStatusCodeEnum,
    });
  }
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  window._tmpUserSessionMimicExpiredAccessToken = _tmpMimicExpiredAccessToken;
}

export const _tmpMimicExpiredRefreshToken = () => {
  if (!_isUserSessionDebugMode()) {
    return;
  }

  const getUserSessionCookieMetaRes = getUserSessionCookieMeta();

  if (getUserSessionCookieMetaRes.error.code === '0') {
    const userSessionMeta = getUserSessionCookieMetaRes.data;

    userSessionMeta.atexp = userSessionMeta.iss;
    userSessionMeta.rtexp = userSessionMeta.iss;

    resetCookie({
      name: 'userSessionMeta',
      value: getCookieValueEncodedStr({
        str: JSON.stringify(userSessionMeta),
        mode: 1, // btoa
      }).data,
    });

    // trigger cookies ttl sync
    changeStatusCodeInUserSessionCookies({
      newStatusCode: getCookie('userSession') as unknown as UserSessionStatusCodeEnum,
    });
  }
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  window._tmpUserSessionMimicExpiredRefreshToken = _tmpMimicExpiredRefreshToken;
}

export const _tmpDebugUserSession = async (backendPush?: boolean) => {
  if (!_isUserSessionDebugMode()) {
    return;
  }

  const userSessionDebug = {
    statusCode: getCookie('userSession'),
    meta: getUserSessionCookieMeta(),
    accessToken: getCookie('accessToken'),
    accessTokenCookieLifespan: getAccessTokenCookieLifespan(),
    verificationResult: verifyUserSession({
      cognitoCfg: getCognitoConfig(),
    }),
    debugMeta: _getUserSessionCookieDebugMeta(),
  };

  if (
    typeof backendPush !== 'undefined'
    && backendPush === true
  ) {
    await fetchProfileUserSession({
      method: 'POST',
      data: {
        method: 'DEBUG',
        userSessionDebug,
      },
      authenticated: false,
      attachCookies: true,
      refreshTokenOnFailedCognitoAuth: false,
    });
  }

  logging.logDebug('UserSessionDebug: ', userSessionDebug);
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  window._tmpUserSessionDebug = _tmpDebugUserSession;
}
// > tmp
