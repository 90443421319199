import { EnvType, getEnv } from '../Util/EnvironmentUtil';
import { logging } from '../Util/LoggingUtil';

export interface ICognitoConfig {
  region: string,
  userPoolId: string,
  userPoolWebClientId: string,
  authenticationFlowType?: 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',
  userSessionLogicVersion: string, // custom, stored in profile localStorage with field name "userSessionVersion"
  // userSessionDymmyRefreshToken: string, // custom, we are replacing the cognito session fields in localStorage with this value
  authDomain: string,
}

export const COGNITO_CONFIG_BY_ENV: { [env: string]: ICognitoConfig } = {
  develop: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_bJP6gg7TK',
    userPoolWebClientId: '7qg16lngs4v8d06mlth1tevff0',
    userSessionLogicVersion: '1.0.0',
    // userSessionDymmyRefreshToken: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.R_cZMqE6dJ4fD5jHZPqO40NWPFVO1gMe7_mFs7lUOx0UDMmk7plPD4ygyutA0b75I5hsGZSSMViItt_IJ5wLB-WQWT9UiBK0htEtp2ohFa0w868N5NxV0OncgBa6ttrhCjaSjBcuhJm8QRtaYAr7UJkwdRlasb3xWW9zjHggRcTvekfQ4EkDaNvGLlnb0XZUcGhPO-N0foEZdCdwo5AE0nP14h_IH5MNJms_Kjfte610xVdtEwghqbX8V8AdL6w85VplpTfpHAjL96EBPia8UG8AeV6vzXjd4eqg1r_EZ2ggtRObH-YHGoQMs8sfH_HEVmku16n09MqsLTwJqouFwA.mf806qBNwqvos_SR.3_VdM4yX55EwXvgxRHIjHv5g5QzK045GQ8CcS0DBZ_AwkE4nCRVP4c7QjxiFG2gy4TGagN-0Tom_CkJCO8l-WpRqetAj0s8PrM3-9ZelbiKghWoq5ZYkuzlg7rEOymRwasCgH-UpaIg2KlZIZAQwgjsIEqLTmvHvJRkoSlabq4Jb7-WPfiMVLuPmLGoXa736BasoTigccXwsXlmi7byvJhfGhgXlHFqFegFlcKzIb7NY2aCdnadrLhUdDfNu9-FARtecRKEIUD5HxZPDonz3Hfu7vbElxesXOg9D-K3PJx9W5M8tC6Vc_npuJhhS4olKIWL2_kfKAT06M4Hiiyia9tMC9B7446YDiZiMIc-z1KvBMHCykWFhRjkPzg1Zb-pY6ai0Tgf2kiVtcjEQBVRX4qudPs4M-aG9vuuyUhhYLk7KIY2q2SpAKb3SGyWvRCtxjorBRJY2_n3ITwfzsPy2ZmFU7QAg_VR6zzyllpjqcV0-dsi3SsvE86TeXttytTNwm1s9j9H9Cyl8ceAEbQcLfo753GB4LlR3sNq1h6Aj1leadqwWKkFpQ2_DU_aWIaJp0w0T-fZKCbPJTeqLlRXkazLzUFnou4yrcrf3JwMbVIMHfRsG8TRaeYvbzQpIL5dg1JqJ9YcTFYG7ExqkVHWUkZ9gw3B5nrFuqhpkA6kh2BmqWSGjBNZc1qDZDH2USJuR6v9hEL7jrjmcnEzyyJQZMasFbRdtoUG8xoPJN9sw013eRiTHeu9jY7ZDlKjhehYj3Xfarw2P710mK-7nnIdxvAZHKON1ojaenrgCA5cvI9j04HfCC-z1EoO1ZOQpWSCvOTIoFUsp4ON7P2rh73t1Buvh_N6tF81NApzkQaHyjjKof3CuBuwjkhHv4IFbD6Fji2xyArgcsvwmw8cousXX276omZw74SNEAdQ6oGm9TSgeAwNlrIXAkHIPyTLJce1I_tQUDpQrN8QGAbj9sXvy3iaH3fvUzG2_nMkMqGzvMnJIkEmc7LYX2RMK0oipzPCriW1a0w2VT7zP6REpXWRAH_PRu3QCUl_I8XhgRSEEAAQeFJG48QXz7OQoExfCxy6zW288HZQ5NRPk4HjyDgbcIC19ujfMlA4Cm50tTeU9dBBaeEm8tvnqG3fRpMdqeRwlD4mM9tydWPMtbCkgHxsnczS-eye9VP8jEzF2GcZhyuO2dUIvYiYEi6wzdefCzX3nobRhROnQ6gezal0vtzZE1bvI7WpDHNKLBvpX_I5jqP5r2TN102ZHM3TVCILdFpnOM0de6ZF4tXUHnfqkuogPVgqlPaQhBJLpEdTszJGBZ0vZQbCq-HdK5Cr8jI89bw.vWt7ejg6hndUMHSkAQ7PoA',
    authDomain: 'auth.w3sdevelop.com'
  },
  staging: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_U6QSH6gig',
    userPoolWebClientId: 'n9q3fkj1gdjg390800flvsah1',
    userSessionLogicVersion: '1.0.0',
    // userSessionDymmyRefreshToken: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.R_cZMqE6dJ4fD5jHZPqO40NWPFVO1gMe7_mFs7lUOx0UDMmk7plPD4ygyutA0b75I5hsGZSSMViItt_IJ5wLB-WQWT9UiBK0htEtp2ohFa0w868N5NxV0OncgBa6ttrhCjaSjBcuhJm8QRtaYAr7UJkwdRlasb3xWW9zjHggRcTvekfQ4EkDaNvGLlnb0XZUcGhPO-N0foEZdCdwo5AE0nP14h_IH5MNJms_Kjfte610xVdtEwghqbX8V8AdL6w85VplpTfpHAjL96EBPia8UG8AeV6vzXjd4eqg1r_EZ2ggtRObH-YHGoQMs8sfH_HEVmku16n09MqsLTwJqouFwA.mf806qBNwqvos_SR.3_VdM4yX55EwXvgxRHIjHv5g5QzK045GQ8CcS0DBZ_AwkE4nCRVP4c7QjxiFG2gy4TGagN-0Tom_CkJCO8l-WpRqetAj0s8PrM3-9ZelbiKghWoq5ZYkuzlg7rEOymRwasCgH-UpaIg2KlZIZAQwgjsIEqLTmvHvJRkoSlabq4Jb7-WPfiMVLuPmLGoXa736BasoTigccXwsXlmi7byvJhfGhgXlHFqFegFlcKzIb7NY2aCdnadrLhUdDfNu9-FARtecRKEIUD5HxZPDonz3Hfu7vbElxesXOg9D-K3PJx9W5M8tC6Vc_npuJhhS4olKIWL2_kfKAT06M4Hiiyia9tMC9B7446YDiZiMIc-z1KvBMHCykWFhRjkPzg1Zb-pY6ai0Tgf2kiVtcjEQBVRX4qudPs4M-aG9vuuyUhhYLk7KIY2q2SpAKb3SGyWvRCtxjorBRJY2_n3ITwfzsPy2ZmFU7QAg_VR6zzyllpjqcV0-dsi3SsvE86TeXttytTNwm1s9j9H9Cyl8ceAEbQcLfo753GB4LlR3sNq1h6Aj1leadqwWKkFpQ2_DU_aWIaJp0w0T-fZKCbPJTeqLlRXkazLzUFnou4yrcrf3JwMbVIMHfRsG8TRaeYvbzQpIL5dg1JqJ9YcTFYG7ExqkVHWUkZ9gw3B5nrFuqhpkA6kh2BmqWSGjBNZc1qDZDH2USJuR6v9hEL7jrjmcnEzyyJQZMasFbRdtoUG8xoPJN9sw013eRiTHeu9jY7ZDlKjhehYj3Xfarw2P710mK-7nnIdxvAZHKON1ojaenrgCA5cvI9j04HfCC-z1EoO1ZOQpWSCvOTIoFUsp4ON7P2rh73t1Buvh_N6tF81NApzkQaHyjjKof3CuBuwjkhHv4IFbD6Fji2xyArgcsvwmw8cousXX276omZw74SNEAdQ6oGm9TSgeAwNlrIXAkHIPyTLJce1I_tQUDpQrN8QGAbj9sXvy3iaH3fvUzG2_nMkMqGzvMnJIkEmc7LYX2RMK0oipzPCriW1a0w2VT7zP6REpXWRAH_PRu3QCUl_I8XhgRSEEAAQeFJG48QXz7OQoExfCxy6zW288HZQ5NRPk4HjyDgbcIC19ujfMlA4Cm50tTeU9dBBaeEm8tvnqG3fRpMdqeRwlD4mM9tydWPMtbCkgHxsnczS-eye9VP8jEzF2GcZhyuO2dUIvYiYEi6wzdefCzX3nobRhROnQ6gezal0vtzZE1bvI7WpDHNKLBvpX_I5jqP5r2TN102ZHM3TVCILdFpnOM0de6ZF4tXUHnfqkuogPVgqlPaQhBJLpEdTszJGBZ0vZQbCq-HdK5Cr8jI89bw.vWt7ejg6hndUMHSkAQ7PoA',
    authDomain: 'auth.w3stages.com'
  },
  prod: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_uG9SGX7Wd',
    userPoolWebClientId: 'd1grqml0emh7votkb0gtrrn0',
    userSessionLogicVersion: '1.0.0',
    // userSessionDymmyRefreshToken: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.R_cZMqE6dJ4fD5jHZPqO40NWPFVO1gMe7_mFs7lUOx0UDMmk7plPD4ygyutA0b75I5hsGZSSMViItt_IJ5wLB-WQWT9UiBK0htEtp2ohFa0w868N5NxV0OncgBa6ttrhCjaSjBcuhJm8QRtaYAr7UJkwdRlasb3xWW9zjHggRcTvekfQ4EkDaNvGLlnb0XZUcGhPO-N0foEZdCdwo5AE0nP14h_IH5MNJms_Kjfte610xVdtEwghqbX8V8AdL6w85VplpTfpHAjL96EBPia8UG8AeV6vzXjd4eqg1r_EZ2ggtRObH-YHGoQMs8sfH_HEVmku16n09MqsLTwJqouFwA.mf806qBNwqvos_SR.3_VdM4yX55EwXvgxRHIjHv5g5QzK045GQ8CcS0DBZ_AwkE4nCRVP4c7QjxiFG2gy4TGagN-0Tom_CkJCO8l-WpRqetAj0s8PrM3-9ZelbiKghWoq5ZYkuzlg7rEOymRwasCgH-UpaIg2KlZIZAQwgjsIEqLTmvHvJRkoSlabq4Jb7-WPfiMVLuPmLGoXa736BasoTigccXwsXlmi7byvJhfGhgXlHFqFegFlcKzIb7NY2aCdnadrLhUdDfNu9-FARtecRKEIUD5HxZPDonz3Hfu7vbElxesXOg9D-K3PJx9W5M8tC6Vc_npuJhhS4olKIWL2_kfKAT06M4Hiiyia9tMC9B7446YDiZiMIc-z1KvBMHCykWFhRjkPzg1Zb-pY6ai0Tgf2kiVtcjEQBVRX4qudPs4M-aG9vuuyUhhYLk7KIY2q2SpAKb3SGyWvRCtxjorBRJY2_n3ITwfzsPy2ZmFU7QAg_VR6zzyllpjqcV0-dsi3SsvE86TeXttytTNwm1s9j9H9Cyl8ceAEbQcLfo753GB4LlR3sNq1h6Aj1leadqwWKkFpQ2_DU_aWIaJp0w0T-fZKCbPJTeqLlRXkazLzUFnou4yrcrf3JwMbVIMHfRsG8TRaeYvbzQpIL5dg1JqJ9YcTFYG7ExqkVHWUkZ9gw3B5nrFuqhpkA6kh2BmqWSGjBNZc1qDZDH2USJuR6v9hEL7jrjmcnEzyyJQZMasFbRdtoUG8xoPJN9sw013eRiTHeu9jY7ZDlKjhehYj3Xfarw2P710mK-7nnIdxvAZHKON1ojaenrgCA5cvI9j04HfCC-z1EoO1ZOQpWSCvOTIoFUsp4ON7P2rh73t1Buvh_N6tF81NApzkQaHyjjKof3CuBuwjkhHv4IFbD6Fji2xyArgcsvwmw8cousXX276omZw74SNEAdQ6oGm9TSgeAwNlrIXAkHIPyTLJce1I_tQUDpQrN8QGAbj9sXvy3iaH3fvUzG2_nMkMqGzvMnJIkEmc7LYX2RMK0oipzPCriW1a0w2VT7zP6REpXWRAH_PRu3QCUl_I8XhgRSEEAAQeFJG48QXz7OQoExfCxy6zW288HZQ5NRPk4HjyDgbcIC19ujfMlA4Cm50tTeU9dBBaeEm8tvnqG3fRpMdqeRwlD4mM9tydWPMtbCkgHxsnczS-eye9VP8jEzF2GcZhyuO2dUIvYiYEi6wzdefCzX3nobRhROnQ6gezal0vtzZE1bvI7WpDHNKLBvpX_I5jqP5r2TN102ZHM3TVCILdFpnOM0de6ZF4tXUHnfqkuogPVgqlPaQhBJLpEdTszJGBZ0vZQbCq-HdK5Cr8jI89bw.vWt7ejg6hndUMHSkAQ7PoA',
    authDomain: 'auth.w3spaces.com'
  }
};

export const getCognitoConfig = (env?: EnvType) => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  logging.logDebug('getCognitoConfig -> env: ', env);

  if (typeof COGNITO_CONFIG_BY_ENV[env] !== 'undefined') {
    return COGNITO_CONFIG_BY_ENV[env];
  }

  return COGNITO_CONFIG_BY_ENV.prod;
};
