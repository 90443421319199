import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PublicProfileStateContext } from '../../../../context/public-profile-context';
import { IInterests, TagType } from '../../../userlib_new/utils/db-interfaces';
import { ICompDefault, TIMEOUT } from '../../../userlib_new/utils/types-interfaces';
import Tag from './Tag';

import { NotificationStateContext } from '../../../../context/notification-context';
import Searchbar from './Searchbar';

// import dummyAllItems from './AllItems';

import styles from './Interests.module.scss';
import { ITag } from './Tag/Tag';
import { logging } from '../../../../SharedLib/Util/LoggingUtil';
import { fetchPublicProfile } from '../../../../SharedLib/Util/InternalProjects/PublicProfileUtil';
import { NotificationType } from '../../../userlib_new/Notifications/utils/TypesInterfaces';

interface IInterestsProps extends ICompDefault {
	visible: boolean,
	disabled?: boolean,
	loadingData: (loading: boolean) => void,
}

const Interests = ({ id, className, visible, loadingData, disabled = false }: IInterestsProps) => {
  const { interestsData, setInterestsData } = useContext(PublicProfileStateContext);
  const { addNNotification } = useContext(NotificationStateContext); // to be used if errors during loading data occures

  const [allTags, setAllTags] = useState<ITag[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);

  const [errorLoadingTags, setErrorLoadingTags] = useState(false);

  const onAddItem = (item: ITag) => {
    const myTags = [...allTags];
    const index = myTags.findIndex(tag => tag.tagId === item.tagId);
    if (index > -1) {
      myTags.splice(index, 1);
      setAllTags(myTags);

      const myInterests = { ...interestsData! };
      myInterests.tags.push(item);
      setInterestsData(myInterests);
    }
  };

  const onRemoveSelectedTag = (event: React.MouseEvent<SVGElement> | React.KeyboardEvent<SVGElement>, item: ITag) => {
    logging.logDebug('Interests -> onRemoveSelectedTag -> [event, item]', event, item);

    const myInterests = { ...interestsData! };
    const index = myInterests.tags.findIndex(tag => tag.tagId === item.tagId);

    if (index > -1) {
      myInterests.tags.splice(index, 1);
      setInterestsData(myInterests);

      const myAlltags = [...allTags];
      myAlltags.push(item);
      setAllTags(myAlltags);
    }
  };

  useEffect(() => {
    setIsDisabled(isLoading || disabled || errorLoadingTags);
  }, [isLoading, disabled]);

  useEffect(() => {
    loadingData(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const loadAllData = async () => {
      // TODO  load all possible tags we can set
      setIsLoading(true);

      // setAllTags( <data in here> );
      try {
        const allTagsDataFetchRes = await fetchPublicProfile<IInterests>({
          method: 'GET',
          url: '/available-tags',
          timeout: TIMEOUT,
        });

        logging.logDebug('SettingsPage -> PublicProfile -> Interests -> hook loading data -> allTagsDataFetchRes', allTagsDataFetchRes);

        if (allTagsDataFetchRes.error.code === '0') {
          const myList: ITag[] = allTagsDataFetchRes.data.tags.filter((item: TagType) => {
            const index = interestsData!.tags.findIndex(interestsItem => interestsItem.tagId === item.tagId);
            return index === -1;
          });

          setAllTags(myList);
          setIsLoading(false);
          setErrorLoadingTags(false);
          setLoadedData(true);
        } else {
          const notifId = uuidv4();
          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Loading all interests',
            body: allTagsDataFetchRes.error.description || 'Something unexpected happened',
          };

          addNNotification(notifProp);
          setIsLoading(false);
          setErrorLoadingTags(true);
        }
      } catch (error) {
        const notifId = uuidv4();
        const notifProp: NotificationType = {
          id: notifId,
          dismissed: false,
          autohide: false,
          closeButton: true,
          type: 'error',
          header: 'Loading all interests',
          body: error as string,
        };

        addNNotification(notifProp);
        setIsLoading(false);
        setErrorLoadingTags(true);
      }
    };

    if (visible && !loadedData) {
      loadAllData();
    }
  }, [visible, allTags, loadedData]);

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <Searchbar disabled={isDisabled} allItems={allTags} className={styles.searchbar} onItemClicked={onAddItem} />
      <div className={styles.tag_list_wrapper}>
        {interestsData?.tags.map(item => (
          <Tag key={`tag-${item.tagId}`} tag={item} disabled={isDisabled} onClickRemove={onRemoveSelectedTag} />
        ))}
      </div>
    </div>
  );
};

export default Interests;
