import React from 'react';
import Footer from '../Footer/Footer';
import styles from './LoginLayout.module.scss';

export interface ILoginLayoutProps {
  children: React.ReactNode,
}

const LoginLayout: React.FC<ILoginLayoutProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.main}>{children}</div>

      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default LoginLayout;
