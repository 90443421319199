/* eslint-disable no-prototype-builtins */
// @ts-nocheck
// prs - parameters / properties / options / arguments

import { getMetaPreparedFromException } from './ExceptionUtil';
import { CustomTypeMod, OperationResultType, StrKeyToStrMapType } from './InterfaceAndTypeUtil';

export const getPrmType = (prm: any) => {
  let prmType = Object.prototype.toString.call(prm);

  prmType = prmType.substring(8);

  return prmType.substring(0, prmType.length - 1);
};

export const setDefaults = (prs: any, defaultPrs: any, recursively?: boolean) => {
  if (typeof recursively === 'undefined') {
    recursively = true;
  }

  let key,
    prmType;

  if (recursively) {
    let prmIsArray = false,
      prmIsObj = false;

    for (key in defaultPrs) {
      if (defaultPrs.hasOwnProperty(key)) {
        prmType = getPrmType(defaultPrs[key]);

        if (prmType === 'Function') {
          continue;
        }

        prmIsObj = prmType === 'Object';

        prmIsArray = !prmIsObj && prmType === 'Array';

        if (prs.hasOwnProperty(key)) {
          if (prmIsObj || prmIsArray) {
            setDefaults(prs[key], defaultPrs[key], true);
          }
        } else {
          if (prmIsObj) {
            prs[key] = {};
          } else if (prmIsArray) {
            prs[key] = [];
          }

          if (prmIsObj || prmIsArray) {
            setDefaults(prs[key], defaultPrs[key], true);
          } else {
            prs[key] = defaultPrs[key];
          }
        }
      }
    }
  } else {
    const allowedPrmTypes = {
      'Boolean': true,
      'String': true,
      'Number': true
    };

    for (key in defaultPrs) {
      if (defaultPrs.hasOwnProperty(key)) {
        if (!prs.hasOwnProperty(key)) {
          prmType = getPrmType(defaultPrs[key]);

          if (allowedPrmTypes[prmType] !== true) {
            continue;
          }

          prs[key] = defaultPrs[key];
        }
      }
    }
  }
};

export const getACopy = (prs: any) => {
  const output = {};
  let key;
  let prmType;

  const allowedPrmTypes = {
    'Boolean': true,
    'String': true,
    'Number': true
  };

  for (key in prs) {
    if (prs.hasOwnProperty(key)) {
      prmType = getPrmType(prs[key]);

      if (allowedPrmTypes[prmType] !== true) {
        continue;
      }

      output[key] = prs[key];
    }
  }

  return output;
};

export const getADeepCopy = (prs: any, output = {}) => {
  let key,
    prsType = '';

  for (key in prs) {
    if (prs.hasOwnProperty(key)) {
      prsType = getPrmType(prs[key]);

      if (prsType === 'Object') {
        output[key] = getADeepCopy(prs[key], {});
      } else if (prsType === 'Array') {
        output[key] = getADeepCopy(prs[key], []);
      } else if (prsType === 'Function') {
        continue;
      } else {
        output[key] = prs[key];
      }
    }
  }

  return output;
};

export const getWithDefaults = (prs: any, defaultPrs: any, recursively?: boolean) => {
  if (typeof recursively === 'undefined') {
    recursively = true;
  }

  let output;

  if (recursively) {
    output = getADeepCopy(prs);
  } else {
    output = getACopy(prs);
  }

  setDefaults(output, defaultPrs, recursively);

  return output;
};

export const getMerged = (prs1: any, prs2: any, recursively?: boolean) => {
  if (typeof recursively === 'undefined') {
    recursively = true;
  }

  let output;

  if (recursively) {
    output = getADeepCopy(prs2);
  } else {
    output = getACopy(prs2);
  }

  setDefaults(output, prs1, recursively);

  return output;
};

export const checkSet = (prs: any, key: string, value: any) => {
  if (!prs.hasOwnProperty(key)) {
    prs[key] = value;
  }
};

export const getLeftDifference = (prs1: any, prs2: any) => {
  // if (typeof recursively === 'undefined') {
  //     recursively = true;
  // }

  const output = {};
  let key;
  let prmType;
  let prmIsObj;
  let prmIsArray;

  const allowedPrmTypes = {
    'Boolean': true,
    'String': true,
    'Number': true,
    'Array': true,
    'Object': true
  };

  for (key in prs1) {
    if (prs1.hasOwnProperty(key)) {
      prmType = getPrmType(prs1[key]);

      if (allowedPrmTypes[prmType] !== true) {
        continue;
      }

      prmIsObj = prmType === 'Object';

      prmIsArray = !prmIsObj && prmType === 'Array';

      if (prs2.hasOwnProperty(key)) {
        // not tested how it will behave on inner objects or arrays
        if (prs1[key] !== prs2[key]) {
          if (prmIsObj || prmIsArray) {
            output[key] = getADeepCopy(prs2[key]);
          } else {
            output[key] = prs2[key];
          }
        }
      }
      // else {
      //     if (prmIsObj || prmIsArray) {
      //         output[key] = getADeepCopy(prs1[key]);
      //     } else {
      //         output[key] = prs1[key];
      //     }
      // }
    }
  }

  return output;
};

export const getDifference = (prs1: any, prs2: any) => {
  // can be optimized
  const diff = getLeftDifference(prs1, prs2);
  const inversedDiff = getLeftDifference(prs2, prs1);

  return getWithDefaults(diff, inversedDiff);
};

export const isEmpty = (prs: any) => {
  let key;

  for (key in prs) {
    if (prs.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

export const setDataToLocalStorage = (data: StrKeyToStrMapType) => {
  Object.keys(data).forEach((key) => {
    localStorage.setItem(key, data[key]);
  });
};

export const setStrDataToLocalStorage = (dataStr: string) => {
  setDataToLocalStorage(JSON.parse(dataStr));
};

export const getKeyAndValueByKeySuffix = <T>(data: any, keySuffix: string): { key: string, value: T } | undefined => {
  const fullKey = Object.keys(data).find((key) => key.endsWith(keySuffix));

  if (typeof fullKey !== 'undefined' && fullKey) {
    return {
      key: fullKey,
      value: data[fullKey] as T,
    };
  }

  return;
};

export const getKeyAndValueByKeyPrefix = <T>(data: any, keyPrefix: string): { key: string, value: T } | undefined => {
  const fullKey = Object.keys(data).find((key) => key.startsWith(keyPrefix));

  if (typeof fullKey !== 'undefined' && fullKey) {
    return {
      key: fullKey,
      value: data[fullKey] as T,
    };
  }

  return;
};

export type IParseJsonProps = {
  jsonStr: string,
  requiredFields?: string[],
  // dataStruct
};

export type IParseJsonResult = CustomTypeMod<OperationResultType, {
  data: any,
}>;

export const parseJson = <T>({
  jsonStr,
  requiredFields,
}: IParseJsonProps): CustomTypeMod<IParseJsonResult, {
  data: T,
}> => {
  const output: IParseJsonResult = {
    error: {
      code: '0'
    },
    data: {} as IParseJsonResult['data'],
  };

  try {
    output.data = JSON.parse(jsonStr) as T;

    if (typeof requiredFields !== 'undefined') {
      for (let i = 0; i < requiredFields.length; i++) {
        const key = requiredFields[i];

        if (
          typeof output.data[key] === 'undefined'
        ) {
          output.error = {
            code: 'FINPID',
            description: 'Field is not present in data',
            meta: {
              key,
            }
          };

          return output;
        }
      }
    }
  } catch (error) {
    const exc = error as any;
    output.error = getMetaPreparedFromException(exc);
  }

  return output;
};

export const getDictSnapshot = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const camelToKebab = (key: string) => key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
