import { CustomTypeMod } from '../InterfaceAndTypeUtil';
import { logging } from '../LoggingUtil';
import { fetch, IFetchPrs, IFetchResult } from '../FetchUtil';
import { EnvType, getEnv } from '../EnvironmentUtil';

const DISABLED = true;

export const getMyLearningLegacyApiBaseUrl = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  if (env === 'prod') {
    return 'https://my-learning-legacy.w3schools.com/api';
  } else if (env === 'staging') {
    return 'https://my-learning-stage-legacy.w3schools.com/api';
  } else if (env === 'develop') {
    return 'https://my-learning-dev.w3schools.com/api';
  }

  return 'https://my-learning-legacy.w3schools.com/api'; // prod fallback
};

export const fetchMyLearningLegacy = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getMyLearningLegacyApiBaseUrl();

  if (typeof prs.authenticated === 'undefined') {
    prs.authenticated = false;
  }

  if (typeof prs.attachCookies === 'undefined') {
    prs.attachCookies = true; // send the cookies with accessToken
  }

  return await fetch(prs);
};

interface IMyLearningLegacyUserStatusResponse {
  'error.code': string,
  'error.description': string,
  exists: number,
  cid_present: number,
  auth_succeeded: number,
  password_state: string,
  name: string,
  email_verified: number,
  email: string,
  signed?: string, // attached in ui only
}

export const getMyLearningLegacyUserStatus = async (email: string, password: string): Promise<IMyLearningLegacyUserStatusResponse | undefined> => {
  if (DISABLED) {
    return;
  }

  try {
    // anonymous call
    const reqRes = await fetchMyLearningLegacy({
      method: 'POST',
      url: '/user/check-creds/',
      data: {
        email,
        password,
      },
      attachCookies: false,
    });

    logging.logDebug('getMyLearningLegacyUserStatus -> reqRes: ', reqRes);

    if (reqRes.status !== 200) {
      logging.logError('getMyLearningLegacyUserStatus -> error(1): ', reqRes);
      return;
    }

    const userOriginSigned = JSON.stringify(reqRes.data);
    const userStatus = reqRes.data as IMyLearningLegacyUserStatusResponse;
    userStatus.signed = userOriginSigned;

    if (userStatus['error.code'] !== '0') {
      logging.logError('getMyLearningLegacyUserStatus -> error(2): ', {
        'error.code': userStatus['error.code'],
        'error.description': userStatus['error.description'],
      });

      return;
    }

    return userStatus as IMyLearningLegacyUserStatusResponse;
  } catch (error) {
    const exc = error as any;
    logging.logError('getMyLearningLegacyUserStatus -> error(3): ', exc);
  }

  return;
};
