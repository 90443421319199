import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { TopMenuItem } from '../../Util/Interfaces';
import styles from './TopBarMenu.module.scss';
import { MENU_ITEMS } from './menuItems';
import { appIsProfile, getEnv, getMyLearningUrl } from '../../Util/EnvironmentUtil';
import UserProfile from '../UserProfile/UserProfile';
import LoadingModal from '../LoadingModal/LoadingModal';
import LoginModal from '../LoginModal/LoginModal';
import { getQueryParamNamed } from '../../Util/QueryParamsUtil';
import { getSanitizedRedirectUrl } from '../../Util/RedirectUtil';
import { authUtil } from '../../Util/AuthUtil';
import W3LogoButton from '../Buttons/W3LogoButton/W3LogoButton';
import MobileMenuButton from '../Buttons/MobileMenuButton/MobileMenuButton';
import { NewIcon } from './New';
import { NewBanner } from './NewBanner';
import { logging } from '../../Util/LoggingUtil';
import { safeRedirect } from '../../Util/RedirectUtil';

export interface TopBarMenuProps {
    menu_items?: TopMenuItem[]
    full_page_mode?: boolean
    start_signup?: boolean
    onLoginChange?: (loggedIn: boolean) => void
    allow_unauthenticated?: boolean
    skip_authentication?: boolean
    fixed?: boolean
    children?: React.ReactNode
    alt_authenticated?: boolean,
    show_terms_footer_in_modal?: boolean,
    subscription_plan?: string,
    subscription_upgradeable?: boolean
}

const DEFAULT_REDIRECT_URL = getMyLearningUrl()

function TopBarMenu({
    menu_items,
    full_page_mode,
    start_signup,
    onLoginChange,
    allow_unauthenticated,
    skip_authentication,
    alt_authenticated,
    fixed = true,
    children,
    show_terms_footer_in_modal = true,
    subscription_plan = "",
    subscription_upgradeable = true
}: TopBarMenuProps) {

    const ref = useRef(null)
    logging.logAll(subscription_plan);
    logging.logAll(subscription_upgradeable);
    const [placeholder_height, setPlaceholderHeight] = useState(0)
    const [menuOpen, setMenuOpen] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>()
    const [loggedIn, setLoggedIn] = useState(false)

    function updatePlaceholderHeight() {
        if (!fixed) {
            return 0
        }

        //@ts-ignore
        setPlaceholderHeight(ref.current.clientHeight)

        return 0
    }

    useEffect(() => {

        async function check() {
            await checkUrlParams()
            await checkLoginStatus()
        }

        check()

        function handleResize() {
            updatePlaceholderHeight()
        }

        if (fixed) {
            window.addEventListener('resize', handleResize)

            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }

        return () => { }
    }, [])

    useLayoutEffect(() => {
        updatePlaceholderHeight()
    })

    function toggleMobileMenu() {
        setMenuOpen(!menuOpen)
    }

    function items() {
        let result

        if (menu_items !== undefined) {
            result = menu_items
        } else {
            result = MENU_ITEMS[getEnv()]
        }

        const host = window.location.host

        return result.map((item: TopMenuItem) => {
            if (item.link.includes(host)) {
                item.selected = true
            }
            return item
        })
    }

    async function checkUrlParams() {
        const expiredFlag = getQueryParamNamed('expired')

        if (expiredFlag && expiredFlag.value === "true") {
            await authUtil.logOut({
              context: 'TBMC1',
              reason: {
                topBarMenuHadExpiredUrlQueryParamFlag: expiredFlag,
              },
            });
        }

        let redirect_url = getSanitizedRedirectUrl()

        if (redirect_url !== undefined) {
            logging.logDebug("redirect url: ", redirect_url)
            setRedirectUrl(redirect_url)
        }
    }

    async function checkLoginStatus() {
        const userIsLoggedIn = await authUtil.userIsLoggedIn()

        if (userIsLoggedIn) {
            setShowLoginModal(false)
            setLoading(false)
            setLoggedIn(true)
            handleLoggedIn()
        }
        else if (skip_authentication) {
            setShowLoginModal(false)
            setLoading(false)
            setLoggedIn(false)
        }
        else if (alt_authenticated) {
            setLoading(false)
            setLoggedIn(true)
        }
        else {
            setShowLoginModal(true)
            setLoading(false)
            setLoggedIn(false)
            if (onLoginChange !== undefined) {
                onLoginChange(false)
            }
        }
    }

    function handleCloseLoginModal() {
        if (full_page_mode || !allow_unauthenticated) {
            history.back()
        } else {
            setShowLoginModal(false)
        }
    }

    async function handleLoggedIn() {
      setLoading(true)
      setLoggedIn(true)
      setShowLoginModal(false)

      const auth_code = getQueryParamNamed('authorization_code')

      if (auth_code !== undefined) {
          await authUtil.storeTokens(auth_code.value)
      }

      if (!skip_authentication) {
        if (onLoginChange !== undefined) {
            onLoginChange(true)
        }

        let _redirectUrl = full_page_mode ? getRedirectUrl() : redirectUrl;

        _redirectUrl = safeRedirect(_redirectUrl);

        // we expect the code below to never be called
        if (
          typeof _redirectUrl !== 'undefined'
          && _redirectUrl
        ) {
          logging.logError('safeRedirect -> fallback logic');
          setLoggedIn(false);
          setShowLoginModal(true);
          await authUtil.logOut({
            context: 'TBMC2',
            reason: {
              safeRedirectFailed: true,
              full_page_mode,
              _redirectUrl,
              redirectUrl,
            },
          });
        }
      }

      setLoading(false)
  }

    function getRedirectUrl() {
        let redirectUrlToUse = redirectUrl

        if (redirectUrlToUse === undefined) {
            redirectUrlToUse = getSanitizedRedirectUrl()
        }
        if (redirectUrlToUse === undefined) {
            redirectUrlToUse = DEFAULT_REDIRECT_URL
        }

        const auth_code = getQueryParamNamed('authorization_code')
        const auth_state = getQueryParamNamed('state')

        if (auth_code !== undefined) {
            if (auth_state !== undefined) {
                return `${redirectUrlToUse}?code=${auth_code.value}&state=${auth_state.value}`
            } else {
                return `${redirectUrlToUse}?code=${auth_code.value}`
            }
        }

        return redirectUrlToUse
    }

    return (
        <div
            className={'TopBarMenu ' + (fixed ? 'fixed ' : '') + styles['TopBarMenu__main']}
        >
            {fixed &&
                <div
                    className={'-placeholder ' + styles['TopBarMenu__placeholder']}
                    style={{ "height": placeholder_height + 'px' }}
                >
                    &nbsp;
                </div>
            }

            <div
                className={'-inner_wrp ' + styles['TopBarMenu__inner_wrp']}
                ref={ref}
            >
                {loggedIn ?
                    <div className={'-banner ' + styles['TopBarMenu__inner_wrp__banner']}>
                        <a href="https://spaces.w3schools.com/">
                            <NewBanner style={{ display: "block", marginTop: '-1px', marginLeft: "auto", marginRight: "auto" }} />
                        </a>
                    </div> : null
                }

                {/* <div className={'-nav_menu ' + styles.topbarmenu + ' ' + (loggedIn ? "" : styles.full_page) + ' ' + (fixed ? (loggedIn ? styles.fixed_logged_in : styles.fixed) : '')}> */}
                <div className={'-nav_menu ' + styles['TopBarMenu__inner_wrp__nav_menu']}>

                    <div className={styles.buttonbar}>

                        <W3LogoButton
                            loggedIn={loggedIn}
                        />

                        <nav
                            className={menuOpen ? styles.open : ''}
                            id="navigation">

                            {loggedIn &&
                                items().map((item: TopMenuItem, index) => {
                                    return (
                                        <a
                                            key={item.title}
                                            className={styles.topmenubutton + ' ' + styles.desktop + (index >= (items().length - 1) ? ' ' + styles.greenright : '') + (item.selected ? ' ' + styles.selected : '')}
                                            href={item.link}
                                            target={item.target ? item.target : undefined}
                                        >
                                            {item.title}
                                            {item.new ? <NewIcon style={{ float: "right", marginLeft: "8px" }} /> : null}
                                        </a>
                                    )
                                })
                            }

                            <UserProfile
                                loggedIn={loggedIn}
                                className={styles.user_profile_button}
                                full_page_mode={full_page_mode} />


                        </nav>

                        {loggedIn ?
                            <MobileMenuButton
                                menuOpen={menuOpen}
                                onClick={toggleMobileMenu}
                                ariaControls="navigation" />
                            : ``}
                    </div>

                    {children}

                </div>

                {appIsProfile() &&
                    <LoadingModal
                        header="Loading..."
                        message=""
                        show={loading && !alt_authenticated}
                        full_page={true}
                        close={() => { }}
                    />
                }

                {appIsProfile() &&
                    <LoginModal
                        signup={start_signup === true}
                        show={showLoginModal}
                        close={handleCloseLoginModal}
                        onLogin={handleLoggedIn}
                        full_page={true}
                        showTermsFooterInModal={show_terms_footer_in_modal}
                    />
                }

            </div>
        </div>
    )
}

export default TopBarMenu;
