import { fetchOrganizations } from '../../SharedLib/Util/InternalProjects/OrganizationsUtil';
import { logDebug } from '../../SharedLib/Util/LoggingUtil';
import { safeRedirect } from '../../SharedLib/Util/RedirectUtil';
// import { useHistory } from 'react-router-dom';

// WARNING! IOrganizationGroup* interfaces are copy pasted from organizations lib, keep in sync
export interface IOrganizationGroupInviteLicense {
  groupId: string,
  inviteId: string,
  licenseId: string,
  inviteLicenseId: string, // `${inviteId}/${licenseId}` used at sort key
  organizationId: string,
  productType: string,
  productId: string,
  productName: string,
  skillId?: string,
  skillIds?: string[],
  internalProductId?: string,
  createdAt: string,
  updatedAt: string,
}

export interface IOrganizationGroupAdmin {
  name: string,
  email: string,
}

export interface IOrganizationGroupInvitationDetails {
  organizationName: string,
  organizationWebsite?: string,
  organizationLogo?: string,
  groupName: string,
  groupAdmin: string, // name [email], legacy field kept for backward compatibility
  groupAdmins: IOrganizationGroupAdmin[],
  invitationLicenses?: IOrganizationGroupInviteLicense[],
  invitationRole: 'STUDENT' | 'TEACHER',
  invitationStatus: 'PENDING' | 'ACCEPTED' | 'FORBIDDEN',
  invitationError?: string,
  adminProgressAccess?: string[], // List of topic progress accessible to admin
  adminProgressFullAccess?: boolean,
  invitedMember: {
    noNewsletter: boolean | null,
    noAds: boolean | null,
  },
}

// stripped down version of IOrganizationGroupInvitationDetails to avoid sharing too much info with anonymous users
export interface IOrganizationGroupInvitationDetailsLite {
  organizationName: string,
  organizationWebsite?: string,
  organizationLogo?: string,
  groupName: string,
  invitationRole: 'STUDENT' | 'TEACHER',
  invitationStatus: 'PENDING' | 'ACCEPTED',
  invitationError?: string,
  invitedMember: {
    name: string,
    email: string,
    noNewsletter: boolean | null,
    noAds: boolean | null,
    canRegister: boolean,
  },
}

export type IClassroomInvitationDetailsLite = IOrganizationGroupInvitationDetailsLite;

export const getClassroomInvitationDetailsLite = async ({
  invitationToken,
}: {
  invitationToken: string,
}) => {
  return await fetchOrganizations<IClassroomInvitationDetailsLite>({
    method: 'GET',
    url: `/organizations-api/v2/user/invites/${invitationToken}/lite`,
    authenticated: false,
  });
};

export type IClassroomInvitationDetails = IOrganizationGroupInvitationDetails;

export const getClassroomInvitationDetails = async ({
  invitationToken,
}: {
  invitationToken: string,
}) => {
  return await fetchOrganizations<IClassroomInvitationDetails>({
    method: 'GET',
    url: `/organizations-api/v2/user/invites/${invitationToken}`,
    refreshTokenOnFailedCognitoAuth: false,
  });
};

export const acceptClassroomInvitation = async ({
  invitationToken,
}: {
  invitationToken: string,
}) => {
  return await fetchOrganizations<IClassroomInvitationDetails>({
    method: 'POST',
    url: '/organizations-api/v2/user/accept-invite',
    data: {
      inviteToken: invitationToken,
    }
  });
};

export interface IClassroomInvitationBrowserStorageMeta {
  url: string,
  token: string,
  flow: 'signup' | 'login',
  details: IClassroomInvitationDetailsLite,
}

export const setClassroomInvitationBrowserStorageMeta = (prs: IClassroomInvitationBrowserStorageMeta) => {
  // why session storage? continue where left even after page reload
  logDebug('ClassroomInvitation -> setClassroomInvitationBrowserStorageMeta -> prs: ', prs);
  localStorage.setItem('classroomInvitation', JSON.stringify(prs));
};

export const getClassroomInvitationBrowserStorageMeta = () => {
  const packedPrs = localStorage.getItem('classroomInvitation');

  if (packedPrs) {
    try {
      const prs = JSON.parse(packedPrs) as IClassroomInvitationBrowserStorageMeta;

      logDebug('ClassroomInvitation -> getClassroomInvitationBrowserStorageMeta -> prs: ', prs);

      return prs;
    } catch (error) {
      console.error(error);
      console.error('Error parsing classroomInvitation localStorage item');
    }
  }

  return null;
};

export const clearClassroomInvitationBrowserStorageMeta = () => {
  localStorage.removeItem('classroomInvitation');
};

// export const handleLegacyClassroomInvitationUrl = (history: ReturnType<typeof useHistory>) => {
export const handleLegacyClassroomInvitationUrl = () => {
  const redirectOriginUrlRaw = sessionStorage.getItem('redirectOriginUrl');
  logDebug('ClassroomInvitation -> handleLegacyClassroomInvitationUrl -> redirectOriginUrlRaw: ', redirectOriginUrlRaw);

  if (redirectOriginUrlRaw) {
    try {
      const legacyClassroomInvitationUrl = decodeURIComponent(redirectOriginUrlRaw);

      if (
        legacyClassroomInvitationUrl.indexOf('https://pathfinder') === 0
        && legacyClassroomInvitationUrl.indexOf('/classroom-invitation?token=') !== -1
      ) {
        const patchedClassroomInvitationUrl = legacyClassroomInvitationUrl.replace('https://pathfinder', 'https://profile');
        logDebug('ClassroomInvitation -> handleLegacyClassroomInvitationUrl -> patchedClassroomInvitationUrl: ', patchedClassroomInvitationUrl);

        // history.push(patchedClassroomInvitationUrl);

        safeRedirect(patchedClassroomInvitationUrl);
      }
    } catch (error) {
      console.error(error);
      console.error('Error parsing redirectOriginUrl sessionStorage item');
    }
  }
};
