import React, { useEffect } from 'react';
import styles from './Spinner.module.scss';

export enum SpinnerTypes {
  RoundEven = 1,
  RoundSlowStart = 2
}

export enum SpinnerVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Destructive = 'destructive',
  Pink = 'pink',
  Dark = 'dark'
}

export enum SpinnerSize {
  sm = 'small',
  md = 'medium',
  lg = 'large',
  xl = 'xlarge'
}

interface SpinnerProps {
  className?: string,
  type?: 1 | 2,
  ariaLabel?: string,
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'destructive'
    | 'pink'
    | 'dark',
  size?: 'small' | 'medium' | 'large' | 'xlarge',
}

function Spinner({
  className,
  ariaLabel,
  type = SpinnerTypes.RoundEven,
  variant = SpinnerVariants.Primary,
  size = SpinnerSize.md
}: SpinnerProps) {
  const [classes, setClasses] = React.useState('');

  useEffect(() => {
    let returnValue = '';

    if (className) {
      returnValue = className;
    }

    switch (type) {
      case SpinnerTypes.RoundSlowStart:
        returnValue = `${returnValue} ${styles.lds_ring}`;
        break;
      default:
        returnValue = `${returnValue} ${styles._spinner_round_even}`;
    }

    const variantClass = `_spinner_color_${variant}`;
    returnValue = `${returnValue} ${styles[variantClass]}`;

    const sizeClass = `_spinner_size_${size}`;
    returnValue = `${returnValue} ${styles[sizeClass]}`;

    setClasses(returnValue);
  }, []);

  switch (type) {
    case SpinnerTypes.RoundSlowStart:
      return (
        <div className={classes}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );

    default:
      // SpinnerTypes.RoundEven
      return (
        <div
          className={classes + ' w3s-spinner-override-speed'}
          role='status'
          aria-live='polite'
          aria-label={ariaLabel ? ariaLabel : 'Please wait'}
        ></div>
      );
  }
}

/**
 * Contains enumerated values one can use in this components inputs.
 */
Spinner.utils = {
  /**
   * Type of spinner. Possible values are 'RoundEven' | 'RoundSlowStart'
   * @default RoundEven
   */
  types: SpinnerTypes,
  /**
   * Color variants of the spinner.
   * Possible values are 'Primary' | 'Secondary' | 'Tertiary' | 'Destructive' | 'Pink' | 'Dark'
   * @default Primary
   */
  variants: SpinnerVariants,
  /**
   * Size of the spinner.
   * Possible values are 'sm' | 'md' | 'lg' | 'xl'
   * @default md
   */
  size: SpinnerSize
};

export default Spinner;
