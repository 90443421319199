/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Globe as GlobeIcon } from 'react-bootstrap-icons';
import { PublicProfileStateContext } from '../../../context/public-profile-context';
import { logging } from '../../../SharedLib/Util/LoggingUtil';
import Toggle from '../../userlib/buttons/ToggleButton/Toggle';
import InputItem from '../../userlib_new/InputItem';
import { SocialMediaTypes } from '../../userlib_new/utils/db-interfaces';
import { ICompDefault, KEYPRESS_WAIT } from '../../userlib_new/utils/types-interfaces';
import SocialMediaIcon from './SocialMediaIcon';

import styles from './ContactInformation.module.scss';

interface IContactInformation extends ICompDefault {
	loading?: boolean,
	disabled?: boolean,
	onCheckedForErrorPhoneNr?: (error: boolean) => void,
	onCheckedForErrorWebPage?: (error: boolean) => void,
}

const placeholder = (type: SocialMediaTypes) => {
  switch (type) {
  case SocialMediaTypes.Phone:
    return '(+) number';
  case SocialMediaTypes.Email:
    return 'example@email.com';
  default:
    return 'Username';
  }
};

const ContactInformation = ({
  loading = false,
  disabled = false,
  id,
  className,
  onCheckedForErrorPhoneNr,
  onCheckedForErrorWebPage,
}: IContactInformation) => {
  const { loadedPublicProfileData, socialMediaData, setSocialMediaData, publicProfileUserData, setPublicProfileUserData } =
		useContext(PublicProfileStateContext);

  const timeoutId = useRef<NodeJS.Timeout>();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [displayPhoneError, setDisplayPhoneError] = useState(false);
  const [displayWebsiteError, setDisplayWebsiteError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorTextWebsite, setErrorTextWebsite] = useState('');

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!loadedPublicProfileData || isLoading || disabled) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isLoading, disabled]);

  const onToggleSocialMediaItem = (type: string) => {
    const itemIndex = socialMediaData.findIndex(item => item.type === type);
    const data = [...socialMediaData];
    const dataItem = { ...data[itemIndex] };
    dataItem.visible = !dataItem.visible;

    if (type === SocialMediaTypes.Email || type === SocialMediaTypes.Phone) {
      const ppData = { ...publicProfileUserData! };

      switch (type) {
      case SocialMediaTypes.Email:
        ppData.email.visible = dataItem.visible;
        break;
      case SocialMediaTypes.Phone:
        ppData.phone.visible = dataItem.visible;
        break;
      default:
      }

      setPublicProfileUserData(ppData);
    }

    data[itemIndex] = dataItem;
    setSocialMediaData(data);

    logging.logDebug('ContactInformation -> onToggleItem -> data', data);
  };

  const onToggleWebsiteItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (publicProfileUserData) {
      const basicData = { ...publicProfileUserData };
      const basicWebsiteData = { ...publicProfileUserData.website };
      basicWebsiteData.visible = event.target.checked;
      basicData.website = basicWebsiteData;

      setPublicProfileUserData(basicData);

      logging.logDebug('ContactInformation -> onToggleWebsiteItem -> basicData', basicData);
    }
  };

  const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>, type: SocialMediaTypes) => {
    event.stopPropagation();
    event.preventDefault();

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }

    timeoutId.current = setTimeout(() => {
      timeoutId.current = undefined;
      const itemIndex = socialMediaData.findIndex(item => item.type === type);
      const data = [...socialMediaData];
      const dataItem = { ...data[itemIndex] };

      dataItem.userName = event.target.value;

      data[itemIndex] = dataItem;

      if (type === SocialMediaTypes.Phone) {
        let myValue = false;

        if (event.target.value.length === 0 || /^\+/.test(event.target.value)) {
          setErrorText('');
        } else {
          myValue = true;
          setErrorText('Need to start with a + and a country code');
        }

        setDisplayPhoneError(myValue);

        if (onCheckedForErrorPhoneNr) {
          onCheckedForErrorPhoneNr(myValue);
        }

        if (publicProfileUserData) {
          const basicData = { ...publicProfileUserData };
          const basicPhoneData = { ...publicProfileUserData.phone };
          basicPhoneData.value = event.target.value;
          basicData.phone = basicPhoneData;

          setPublicProfileUserData(basicData);
        }
      }

      setSocialMediaData(data);

      logging.logDebug('ContactInformation -> onToggleItem -> [data, publicProfileUserData]', data, publicProfileUserData);
    }, KEYPRESS_WAIT);
  };

  const onWebsiteValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }

    setDisplayWebsiteError(false);

    timeoutId.current = setTimeout(() => {
      if (publicProfileUserData) {
        let foundError = false;
        let errorString = 'Not a valid url pattern.';

        const hasLength = event.target.value.trim().length > 0;

        if (hasLength) {
          const containWhiteSpace = /\s/g.test(event.target.value.trim());
          if (containWhiteSpace) {
            errorString = 'Space not allowed in url.';
            foundError = true;
          } else {
            const legalWebpage = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
              event.target.value,
            );
            if (!legalWebpage) {
              foundError = true;
            } else if (event.target.value.indexOf('http') === 0) {
              if (event.target.value.indexOf('http://') !== 0) {
                foundError = true;

                if (event.target.value.indexOf('https://') === 0) {
                  foundError = false;
                }
              }
            }
          }
        }

        if (onCheckedForErrorWebPage) {
          onCheckedForErrorWebPage(foundError);
        }

        if (foundError) {
          setDisplayWebsiteError(true);
          setErrorTextWebsite(errorString);
        } else {
          const basicData = { ...publicProfileUserData };
          const basicWebsiteData = { ...publicProfileUserData.website };
          basicWebsiteData.value = event.target.value.trim();
          basicData.website = basicWebsiteData;

          setPublicProfileUserData(basicData);
          logging.logDebug('ContactInformation -> onWebsiteValueChanged -> basicData', basicData);
        }
      }

      timeoutId.current = undefined;
    }, KEYPRESS_WAIT);
  };

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      {socialMediaData.map(item => {
        return (
          <div key={`contact-item-${item.type}`} className={styles.item_wrapper}>
            <div className={`${styles.icon_wrapper}${item.userName ? ` ${styles.has_content}` : ''}`}>
              <SocialMediaIcon type={item.type} />
              <div className={styles.title}>{item.type}</div>
            </div>
            <div className={styles.data_wrapper}>
              <InputItem
                className={styles.inputField}
                defaultValue={item.userName}
                disabled={item.type === SocialMediaTypes.Email || disabled}
                readOnly={item.type === SocialMediaTypes.Email}
                placeholder={placeholder(item.type)}
                displayError={item.type === SocialMediaTypes.Phone && displayPhoneError}
                errorText={errorText}
                onValueChange={(event: React.ChangeEvent<HTMLInputElement>) => onValueChanged(event, item.type)}
                value={item.type === SocialMediaTypes.Email ? item.userName : undefined}
              />
              <Toggle checked={item.visible} disabled={isDisabled} onChange={() => onToggleSocialMediaItem(item.type)} />
            </div>
          </div>
        );
      })}

      <div className={styles.item_wrapper}>
        <div className={`${styles.icon_wrapper}${publicProfileUserData?.website.value ? ` ${styles.has_content}` : ''}`}>
          <GlobeIcon />
          <div className={styles.title}>Website</div>
        </div>
        <div className={styles.data_wrapper}>
          <InputItem
            className={styles.inputField}
            defaultValue={publicProfileUserData?.website.value}
            disabled={disabled}
            displayError={displayWebsiteError}
            errorText={errorTextWebsite}
            placeholder="https://www.example.com"
            onValueChange={onWebsiteValueChanged}
          />
          <Toggle checked={publicProfileUserData?.website.visible} disabled={isDisabled} onChange={onToggleWebsiteItem} />
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
