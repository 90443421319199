import { CustomTypeMod, StrKeyToStrMapType } from '../InterfaceAndTypeUtil';
import { fetch, IFetchPrs, IFetchResult } from '../FetchUtil';
import { EnvType, getEnv } from '../EnvironmentUtil';
import { getCookie } from '../CookieUtil';
// import { logging } from '../LoggingUtil';

export const getMyLearningV3DevApiBaseUrl = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  const envToUrlMap = {
    'prod': 'https://my-learning.w3schools.com/api',
    'staging': 'https://myl-stage.w3schools.com/api',
    'develop': 'https://myl-dev.w3schools.com/api',
  };

  return envToUrlMap[env] || envToUrlMap['prod'];
};

export const getMyLearningApiBaseUrl = async (env?: EnvType): Promise<string> => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  let envToUrlMap: StrKeyToStrMapType;

  let mylVersion: string = '3';

  // let mylV3ApiBaseUrl: string = '';

  // // @ts-ignore
  // if (typeof window.MY_LEARNING_VERSION !== 'undefined') {
  //   // @ts-ignore
  //   mylVersion = window.MY_LEARNING_VERSION as string;
  // } else {
  //   mylV3ApiBaseUrl = getMyLearningV3DevApiBaseUrl(env);

  //   const classicMylVersionFetchRes = await fetch({
  //     method: 'GET',
  //     url: `${mylV3ApiBaseUrl}/version`,
  //     authenticated: false,
  //     attachCookies: false,
  //     // cache: false,
  //   });

  //   logging.logDebug('MyLearningUtil -> classicMylVersionFetchRes: ', classicMylVersionFetchRes);

  //   if (
  //     classicMylVersionFetchRes.error.code === '0'
  //     && typeof classicMylVersionFetchRes.data === 'string'
  //     && classicMylVersionFetchRes.data
  //   ) {
  //     mylVersion = classicMylVersionFetchRes.data;
  //   }
  // }

  const mylAltVersion = getCookie('MyLearning.version');

  if (mylAltVersion) {
    mylVersion = mylAltVersion;
  }

  if (mylVersion === '3-dev') {
    // if (mylV3ApiBaseUrl) {
    //   return mylV3ApiBaseUrl;
    // }

    return getMyLearningV3DevApiBaseUrl(env);
  } else { // v3
    envToUrlMap = {
      'prod': 'https://myl-api.w3schools.com/api',
      'staging': 'https://myl-api-stage.w3schools.com/api',
      'develop': 'https://myl-api-dev.w3schools.com/api',
    };
  }

  return envToUrlMap[env] || envToUrlMap['prod'];
};

export const fetchMyLearning = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = await getMyLearningApiBaseUrl();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'Authorization';
  }

  return await fetch(prs);
};
