export interface ICompDefault {
	/**
	 * Any classes to be added to the component
	 */
	className?: string,
	/**
	 * Id of an html element
	 */
	id?: string,
	tabIndex?: number,
}

export enum MONTH {
	'January' = 0,
	'February' = 1,
	'March' = 2,
	'April' = 3,
	'May' = 4,
	'June' = 5,
	'July' = 6,
	'August' = 7,
	'September' = 8,
	'October' = 9,
	'November' = 10,
	'December' = 11,
}

/**
 * **Bootstrap 4.6** media breakpoints.
 *
 * Note from Bootstrap on these numbers:
 *
 * Note that since browsers do not currently support range context queries,
 * we work around the limitations of min- and max- prefixes and viewports with
 * fractional widths (which can occur under certain conditions on high-dpi devices, for instance)
 *  by using values with higher precision for these comparisons.
 */
export const BREAKPOINTS = {
  xsmall: 575.98,
  small: 767.98,
  medium: 991.98,
  large: 1199.98,
};

/**
 * The time in ms we are to wait before executing the timeout content.
 */
export const KEYPRESS_WAIT = 400;

/**
 * Timeout used for server calls
 */
export const TIMEOUT = 120000;
