import React from 'react';

import styles from './W3Badge.module.scss';

export interface IW3BadgeIcon {
	className?: string,
}

const W3BadgeIcon = ({ className }: IW3BadgeIcon) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" className={className || ''}>
    <circle className={styles.cls_1} cx="150" cy="150" r="146.47" transform="translate(-62.13 150) rotate(-45)" />
    <text className={styles.cls_2} transform="translate(93.54 63.89) rotate(-29.5)">
			w
    </text>
    <text className={styles.cls_2} transform="translate(107.13 56.35) rotate(-20.8)">
			3
    </text>
    <text className={styles.cls_2} transform="matrix(0.98, -0.21, 0.21, 0.98, 121.68, 50.97)">
			s
    </text>
    <text className={styles.cls_2} transform="translate(136.89 47.84) rotate(-3.47)">
			c
    </text>
    <text className={styles.cls_2} transform="translate(152.39 47.03) rotate(5.12)">
			h
    </text>
    <text className={styles.cls_2} transform="translate(167.85 48.54) rotate(13.72)">
			o
    </text>
    <text className={styles.cls_2} transform="translate(182.89 52.35) rotate(22.34)">
			o
    </text>
    <text className={styles.cls_2} transform="matrix(0.86, 0.52, -0.52, 0.86, 197.18, 58.36)">
			l
    </text>
    <text className={styles.cls_2} transform="matrix(0.77, 0.64, -0.64, 0.77, 210.4, 66.46)">
			s
    </text>
    <text className={styles.cls_3} transform="translate(35.51 186.66) rotate(69.37)" />
    <text className={styles.cls_3} transform="matrix(0.47, 0.88, -0.88, 0.47, 41.27, 201.28)">
			C
    </text>
    <text className={styles.cls_3} transform="matrix(0.58, 0.81, -0.81, 0.58, 48.91, 215.03)">
			E
    </text>
    <text className={styles.cls_3} transform="matrix(0.67, 0.74, -0.74, 0.67, 58.13, 227.36)">
			R
    </text>
    <text className={styles.cls_3} transform="translate(69.16 238.92) rotate(39.44)">
			T
    </text>
    <text className={styles.cls_3} transform="matrix(0.85, 0.53, -0.53, 0.85, 81.47, 248.73)">
			I
    </text>
    <text className={styles.cls_3} transform="translate(94.94 256.83) rotate(24.36)">
			F
    </text>
    <text className={styles.cls_3} transform="translate(109.34 263.09) rotate(16.83)">
			I
    </text>
    <text className={styles.cls_3} transform="translate(124.46 267.41) rotate(9.34)">
			E
    </text>
    <text className={styles.cls_3} transform="translate(139.99 269.73) rotate(1.88)">
			D
    </text>
    <text className={styles.cls_3} transform="translate(155.7 270.01) rotate(-5.58)" />
    <text className={styles.cls_3} transform="translate(171.32 268.24) rotate(-13.06)" />
    <text className={styles.cls_2} transform="translate(187.55 266.81) rotate(-21.04)">
			•
    </text>
    <text className={styles.cls_3} transform="translate(203.27 257.7) rotate(-29.24)" />
    <text className={styles.cls_3} transform="translate(216.84 249.83) rotate(-36.75)" />
    <text className={styles.cls_3} transform="translate(229.26 240.26) rotate(-44.15)">
			2
    </text>
    <text className={styles.cls_3} transform="translate(240.39 229.13) rotate(-51.62)">
			0
    </text>
    <text className={styles.cls_3} transform="translate(249.97 216.63) rotate(-59.17)">
			2
    </text>
    <text className={styles.cls_3} transform="matrix(0.4, -0.92, 0.92, 0.4, 257.81, 203.04)">
			0
    </text>
    <path
      className={styles.cls_4}
      d="M196.64,136.31s3.53,3.8,8.5,3.8c3.9,0,6.75-2.37,6.75-5.59,0-4-3.64-5.81-8-5.81h-2.59l-1.53-3.48,6.86-8.13a34.07,34.07,0,0,1,2.7-2.85s-1.11,0-3.33,0H194.79v-5.86H217.7v4.28l-9.19,10.61c5.18.74,10.24,4.43,10.24,10.92s-4.85,12.3-13.19,12.3a17.36,17.36,0,0,1-12.41-5Z"
    />
    <path
      className={styles.cls_4}
      d="M152,144.24l30.24,53.86,14.94-26.61L168.6,120.63H135.36l-13.78,24.53-13.77-24.53H77.93l43.5,77.46.15-.28.16.28Z"
    />
  </svg>
);

W3BadgeIcon.defaultProps = {
  className: undefined,
};

export default W3BadgeIcon;
